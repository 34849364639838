import { IReadingsForDay } from "./interfaces";const Data: { [dateKey: string]: IReadingsForDay } = {
    "2024/12/01": {
        "datePretty": "Sunday, December 1, 2024",
        "calendar": "1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 25:1\u20139",
                        "readQS": "Psalm+25:1-9",
                        "audioQS": "Ps.25"
                    },
                    {
                        "pretty": "Jeremiah 33:14\u201316",
                        "readQS": "Jeremiah+33:14-16",
                        "audioQS": "Jer.33"
                    },
                    {
                        "pretty": "1 Thessalonians 3:9\u201313",
                        "readQS": "1+Thessalonians+3:9-13",
                        "audioQS": "1Thess.3"
                    },
                    {
                        "pretty": "Luke 21:25\u201336",
                        "readQS": "Luke+21:25-36",
                        "audioQS": "Luke.21"
                    }
                ],
                "readQS": "Psalm+25:1-9%3B+Jeremiah+33:14-16%3B+1+Thessalonians+3:9-13%3B+Luke+21:25-36",
                "audioQS": "Ps.25,Jer.33,1Thess.3,Luke.21",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "Isaiah 51:4\u201311",
                        "readQS": "Isaiah+51:4-11",
                        "audioQS": "Isa.51"
                    },
                    {
                        "pretty": "Romans 13:11\u201314",
                        "readQS": "Romans+13:11-14",
                        "audioQS": "Rom.13"
                    }
                ],
                "readQS": "Psalm+44%3B+Isaiah+51:4-11%3B+Romans+13:11-14",
                "audioQS": "Ps.44,Isa.51,Rom.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 9",
                        "readQS": "Psalm+9",
                        "audioQS": "Ps.9"
                    },
                    {
                        "pretty": "Joel 3:9\u201321",
                        "readQS": "Joel+3:9-21",
                        "audioQS": "Joel.3"
                    },
                    {
                        "pretty": "Revelation 14:13\u201315:4",
                        "readQS": "Revelation+14:13-15:4",
                        "audioQS": "Rev.14"
                    }
                ],
                "readQS": "Psalm+9%3B+Joel+3:9-21%3B+Revelation+14:13-15:4",
                "audioQS": "Ps.9,Joel.3,Rev.14",
                "note": null
            }
        ]
    },
    "2024/12/02": {
        "datePretty": "Monday, December 2, 2024",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 50",
                        "readQS": "Psalm+50",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "Isaiah 42:18\u2013end",
                        "readQS": "Isaiah+42:18-",
                        "audioQS": "Isa.42"
                    },
                    {
                        "pretty": "Revelation 19",
                        "readQS": "Revelation+19",
                        "audioQS": "Rev.19"
                    }
                ],
                "readQS": "Psalm+50%3B+Isaiah+42:18-%3B+Revelation+19",
                "audioQS": "Ps.50,Isa.42,Rev.19",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Isaiah 25:1\u20139",
                        "readQS": "Isaiah+25:1-9",
                        "audioQS": "Isa.25"
                    },
                    {
                        "pretty": "Matthew 12:1\u201321",
                        "readQS": "Matthew+12:1-21",
                        "audioQS": "Matt.12"
                    }
                ],
                "readQS": "Psalm+71%3B+Isaiah+25:1-9%3B+Matthew+12:1-21",
                "audioQS": "Ps.71,Isa.25,Matt.12",
                "note": null
            }
        ]
    },
    "2024/12/03": {
        "datePretty": "Tuesday, December 3, 2024",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Isaiah 43:1\u201313",
                        "readQS": "Isaiah+43:1-13",
                        "audioQS": "Isa.43"
                    },
                    {
                        "pretty": "Revelation 20",
                        "readQS": "Revelation+20",
                        "audioQS": "Rev.20"
                    }
                ],
                "readQS": "Psalm+80%3B+Isaiah+43:1-13%3B+Revelation+20",
                "audioQS": "Ps.80,Isa.43,Rev.20",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 74",
                        "readQS": "Psalm+74",
                        "audioQS": "Ps.74"
                    },
                    {
                        "pretty": "Isaiah 26:1\u201313",
                        "readQS": "Isaiah+26:1-13",
                        "audioQS": "Isa.26"
                    },
                    {
                        "pretty": "Matthew 12:22\u201337",
                        "readQS": "Matthew+12:22-37",
                        "audioQS": "Matt.12"
                    }
                ],
                "readQS": "Psalm+74%3B+Isaiah+26:1-13%3B+Matthew+12:22-37",
                "audioQS": "Ps.74,Isa.26,Matt.12",
                "note": null
            }
        ]
    },
    "2024/12/04": {
        "datePretty": "Wednesday, December 4, 2024",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 7",
                        "readQS": "Psalm+7",
                        "audioQS": "Ps.7"
                    },
                    {
                        "pretty": "Isaiah 43:14\u2013end",
                        "readQS": "Isaiah+43:14-",
                        "audioQS": "Isa.43"
                    },
                    {
                        "pretty": "Revelation 21:1\u20138",
                        "readQS": "Revelation+21:1-8",
                        "audioQS": "Rev.21"
                    }
                ],
                "readQS": "Psalm+7%3B+Isaiah+43:14-%3B+Revelation+21:1-8",
                "audioQS": "Ps.7,Isa.43,Rev.21",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Isaiah 28:1\u201313",
                        "readQS": "Isaiah+28:1-13",
                        "audioQS": "Isa.28"
                    },
                    {
                        "pretty": "Matthew 12:38\u2013end",
                        "readQS": "Matthew+12:38-",
                        "audioQS": "Matt.12"
                    }
                ],
                "readQS": "Psalm+77%3B+Isaiah+28:1-13%3B+Matthew+12:38-",
                "audioQS": "Ps.77,Isa.28,Matt.12",
                "note": null
            }
        ]
    },
    "2024/12/05": {
        "datePretty": "Thursday, December 5, 2024",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 42",
                        "readQS": "Psalm+42",
                        "audioQS": "Ps.42"
                    },
                    {
                        "pretty": "Isaiah 44:1\u20138",
                        "readQS": "Isaiah+44:1-8",
                        "audioQS": "Isa.44"
                    },
                    {
                        "pretty": "Revelation 21:9\u201321",
                        "readQS": "Revelation+21:9-21",
                        "audioQS": "Rev.21"
                    }
                ],
                "readQS": "Psalm+42%3B+Isaiah+44:1-8%3B+Revelation+21:9-21",
                "audioQS": "Ps.42,Isa.44,Rev.21",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Isaiah 28:14\u2013end",
                        "readQS": "Isaiah+28:14-",
                        "audioQS": "Isa.28"
                    },
                    {
                        "pretty": "Matthew 13:1\u201323",
                        "readQS": "Matthew+13:1-23",
                        "audioQS": "Matt.13"
                    }
                ],
                "readQS": "Psalm+40%3B+Isaiah+28:14-%3B+Matthew+13:1-23",
                "audioQS": "Ps.40,Isa.28,Matt.13",
                "note": null
            }
        ]
    },
    "2024/12/06": {
        "datePretty": "Friday, December 6, 2024",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 25",
                        "readQS": "Psalm+25",
                        "audioQS": "Ps.25"
                    },
                    {
                        "pretty": "Isaiah 44:9\u201323",
                        "readQS": "Isaiah+44:9-23",
                        "audioQS": "Isa.44"
                    },
                    {
                        "pretty": "Revelation 21:22\u201322:5",
                        "readQS": "Revelation+21:22-22:5",
                        "audioQS": "Rev.21"
                    }
                ],
                "readQS": "Psalm+25%3B+Isaiah+44:9-23%3B+Revelation+21:22-22:5",
                "audioQS": "Ps.25,Isa.44,Rev.21",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 17",
                        "readQS": "Psalm+17",
                        "audioQS": "Ps.17"
                    },
                    {
                        "pretty": "Isaiah 29:1\u201314",
                        "readQS": "Isaiah+29:1-14",
                        "audioQS": "Isa.29"
                    },
                    {
                        "pretty": "Matthew 13:24\u201343",
                        "readQS": "Matthew+13:24-43",
                        "audioQS": "Matt.13"
                    }
                ],
                "readQS": "Psalm+17%3B+Isaiah+29:1-14%3B+Matthew+13:24-43",
                "audioQS": "Ps.17,Isa.29,Matt.13",
                "note": null
            }
        ]
    },
    "2024/12/07": {
        "datePretty": "Saturday, December 7, 2024",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 9",
                        "readQS": "Psalm+9",
                        "audioQS": "Ps.9"
                    },
                    {
                        "pretty": "Isaiah 44:24\u201345:13",
                        "readQS": "Isaiah+44:24-45:13",
                        "audioQS": "Isa.44"
                    },
                    {
                        "pretty": "Revelation 22:6\u2013end",
                        "readQS": "Revelation+22:6-",
                        "audioQS": "Rev.22"
                    }
                ],
                "readQS": "Psalm+9%3B+Isaiah+44:24-45:13%3B+Revelation+22:6-",
                "audioQS": "Ps.9,Isa.44,Rev.22",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 27",
                        "readQS": "Psalm+27",
                        "audioQS": "Ps.27"
                    },
                    {
                        "pretty": "Isaiah 29:15\u2013end",
                        "readQS": "Isaiah+29:15-",
                        "audioQS": "Isa.29"
                    },
                    {
                        "pretty": "Matthew 13:44\u2013end",
                        "readQS": "Matthew+13:44-",
                        "audioQS": "Matt.13"
                    }
                ],
                "readQS": "Psalm+27%3B+Isaiah+29:15-%3B+Matthew+13:44-",
                "audioQS": "Ps.27,Isa.29,Matt.13",
                "note": null
            }
        ]
    },
    "2024/12/08": {
        "datePretty": "Sunday, December 8, 2024",
        "calendar": "2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Canticle: Luke 1:68\u201379 (Benedictus)",
                        "readQS": "Luke+1:68-79",
                        "audioQS": "Luke.1"
                    },
                    {
                        "pretty": "Malachi 3:1\u20134",
                        "readQS": "Malachi+3:1-4",
                        "audioQS": "Mal.3"
                    },
                    {
                        "pretty": "Philippians 1:3\u201311",
                        "readQS": "Philippians+1:3-11",
                        "audioQS": "Phil.1"
                    },
                    {
                        "pretty": "Luke 3:1\u20136",
                        "readQS": "Luke+3:1-6",
                        "audioQS": "Luke.3"
                    }
                ],
                "readQS": "Luke+1:68-79%3B+Malachi+3:1-4%3B+Philippians+1:3-11%3B+Luke+3:1-6",
                "audioQS": "Luke.1,Mal.3,Phil.1,Luke.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Isaiah 64:1\u20137",
                        "readQS": "Isaiah+64:1-7",
                        "audioQS": "Isa.64"
                    },
                    {
                        "pretty": "Matthew 11:2\u201311",
                        "readQS": "Matthew+11:2-11",
                        "audioQS": "Matt.11"
                    }
                ],
                "readQS": "Psalm+80%3B+Isaiah+64:1-7%3B+Matthew+11:2-11",
                "audioQS": "Ps.80,Isa.64,Matt.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 75",
                        "readQS": "Psalm+75",
                        "audioQS": "Ps.75"
                    },
                    {
                        "pretty": "Isaiah 40:1\u201311",
                        "readQS": "Isaiah+40:1-11",
                        "audioQS": "Isa.40"
                    },
                    {
                        "pretty": "Luke 1:1\u201325",
                        "readQS": "Luke+1:1-25",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+75%3B+Isaiah+40:1-11%3B+Luke+1:1-25",
                "audioQS": "Ps.75,Isa.40,Luke.1",
                "note": null
            }
        ]
    },
    "2024/12/09": {
        "datePretty": "Monday, December 9, 2024",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "Isaiah 45:14\u2013end",
                        "readQS": "Isaiah+45:14-",
                        "audioQS": "Isa.45"
                    },
                    {
                        "pretty": "1 Thessalonians 1",
                        "readQS": "1+Thessalonians+1",
                        "audioQS": "1Thess.1"
                    }
                ],
                "readQS": "Psalm+44%3B+Isaiah+45:14-%3B+1+Thessalonians+1",
                "audioQS": "Ps.44,Isa.45,1Thess.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 144",
                        "readQS": "Psalm+144",
                        "audioQS": "Ps.144"
                    },
                    {
                        "pretty": "Isaiah 30:1\u201318",
                        "readQS": "Isaiah+30:1-18",
                        "audioQS": "Isa.30"
                    },
                    {
                        "pretty": "Matthew 14:1\u201312",
                        "readQS": "Matthew+14:1-12",
                        "audioQS": "Matt.14"
                    }
                ],
                "readQS": "Psalm+144%3B+Isaiah+30:1-18%3B+Matthew+14:1-12",
                "audioQS": "Ps.144,Isa.30,Matt.14",
                "note": null
            }
        ]
    },
    "2024/12/10": {
        "datePretty": "Tuesday, December 10, 2024",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 56",
                        "readQS": "Psalm+56",
                        "audioQS": "Ps.56"
                    },
                    {
                        "pretty": "Isaiah 46",
                        "readQS": "Isaiah+46",
                        "audioQS": "Isa.46"
                    },
                    {
                        "pretty": "1 Thessalonians 2:1\u201312",
                        "readQS": "1+Thessalonians+2:1-12",
                        "audioQS": "1Thess.2"
                    }
                ],
                "readQS": "Psalm+56%3B+Isaiah+46%3B+1+Thessalonians+2:1-12",
                "audioQS": "Ps.56,Isa.46,1Thess.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 11",
                        "readQS": "Psalm+11",
                        "audioQS": "Ps.11"
                    },
                    {
                        "pretty": "Isaiah 30:19\u2013end",
                        "readQS": "Isaiah+30:19-",
                        "audioQS": "Isa.30"
                    },
                    {
                        "pretty": "Matthew 14:13\u2013end",
                        "readQS": "Matthew+14:13-",
                        "audioQS": "Matt.14"
                    }
                ],
                "readQS": "Psalm+11%3B+Isaiah+30:19-%3B+Matthew+14:13-",
                "audioQS": "Ps.11,Isa.30,Matt.14",
                "note": null
            }
        ]
    },
    "2024/12/11": {
        "datePretty": "Wednesday, December 11, 2024",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 62",
                        "readQS": "Psalm+62",
                        "audioQS": "Ps.62"
                    },
                    {
                        "pretty": "Isaiah 47",
                        "readQS": "Isaiah+47",
                        "audioQS": "Isa.47"
                    },
                    {
                        "pretty": "1 Thessalonians 2:13\u2013end",
                        "readQS": "1+Thessalonians+2:13-",
                        "audioQS": "1Thess.2"
                    }
                ],
                "readQS": "Psalm+62%3B+Isaiah+47%3B+1+Thessalonians+2:13-",
                "audioQS": "Ps.62,Isa.47,1Thess.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 10",
                        "readQS": "Psalm+10",
                        "audioQS": "Ps.10"
                    },
                    {
                        "pretty": "Isaiah 31",
                        "readQS": "Isaiah+31",
                        "audioQS": "Isa.31"
                    },
                    {
                        "pretty": "Matthew 15:1\u201320",
                        "readQS": "Matthew+15:1-20",
                        "audioQS": "Matt.15"
                    }
                ],
                "readQS": "Psalm+10%3B+Isaiah+31%3B+Matthew+15:1-20",
                "audioQS": "Ps.10,Isa.31,Matt.15",
                "note": null
            }
        ]
    },
    "2024/12/12": {
        "datePretty": "Thursday, December 12, 2024",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 54",
                        "readQS": "Psalm+54",
                        "audioQS": "Ps.54"
                    },
                    {
                        "pretty": "Isaiah 48:1\u201311",
                        "readQS": "Isaiah+48:1-11",
                        "audioQS": "Isa.48"
                    },
                    {
                        "pretty": "1 Thessalonians 3",
                        "readQS": "1+Thessalonians+3",
                        "audioQS": "1Thess.3"
                    }
                ],
                "readQS": "Psalm+54%3B+Isaiah+48:1-11%3B+1+Thessalonians+3",
                "audioQS": "Ps.54,Isa.48,1Thess.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Isaiah 32",
                        "readQS": "Isaiah+32",
                        "audioQS": "Isa.32"
                    },
                    {
                        "pretty": "Matthew 15:21\u201328",
                        "readQS": "Matthew+15:21-28",
                        "audioQS": "Matt.15"
                    }
                ],
                "readQS": "Psalm+73%3B+Isaiah+32%3B+Matthew+15:21-28",
                "audioQS": "Ps.73,Isa.32,Matt.15",
                "note": null
            }
        ]
    },
    "2024/12/13": {
        "datePretty": "Friday, December 13, 2024",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 86",
                        "readQS": "Psalm+86",
                        "audioQS": "Ps.86"
                    },
                    {
                        "pretty": "Isaiah 48:12\u2013end",
                        "readQS": "Isaiah+48:12-",
                        "audioQS": "Isa.48"
                    },
                    {
                        "pretty": "1 Thessalonians 4:1\u201312",
                        "readQS": "1+Thessalonians+4:1-12",
                        "audioQS": "1Thess.4"
                    }
                ],
                "readQS": "Psalm+86%3B+Isaiah+48:12-%3B+1+Thessalonians+4:1-12",
                "audioQS": "Ps.86,Isa.48,1Thess.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 90",
                        "readQS": "Psalm+90",
                        "audioQS": "Ps.90"
                    },
                    {
                        "pretty": "Isaiah 33:1\u201322",
                        "readQS": "Isaiah+33:1-22",
                        "audioQS": "Isa.33"
                    },
                    {
                        "pretty": "Matthew 15:29\u2013end",
                        "readQS": "Matthew+15:29-",
                        "audioQS": "Matt.15"
                    }
                ],
                "readQS": "Psalm+90%3B+Isaiah+33:1-22%3B+Matthew+15:29-",
                "audioQS": "Ps.90,Isa.33,Matt.15",
                "note": null
            }
        ]
    },
    "2024/12/14": {
        "datePretty": "Saturday, December 14, 2024",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Isaiah 49:1\u201313",
                        "readQS": "Isaiah+49:1-13",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "1 Thessalonians 4:13\u2013end",
                        "readQS": "1+Thessalonians+4:13-",
                        "audioQS": "1Thess.4"
                    }
                ],
                "readQS": "Psalm+145%3B+Isaiah+49:1-13%3B+1+Thessalonians+4:13-",
                "audioQS": "Ps.145,Isa.49,1Thess.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 94",
                        "readQS": "Psalm+94",
                        "audioQS": "Ps.94"
                    },
                    {
                        "pretty": "Isaiah 35",
                        "readQS": "Isaiah+35",
                        "audioQS": "Isa.35"
                    },
                    {
                        "pretty": "Matthew 16:1\u201312",
                        "readQS": "Matthew+16:1-12",
                        "audioQS": "Matt.16"
                    }
                ],
                "readQS": "Psalm+94%3B+Isaiah+35%3B+Matthew+16:1-12",
                "audioQS": "Ps.94,Isa.35,Matt.16",
                "note": null
            }
        ]
    },
    "2024/12/15": {
        "datePretty": "Sunday, December 15, 2024",
        "calendar": "3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Canticle: Isaiah 12:2\u20136",
                        "readQS": "Isaiah+12:2-6",
                        "audioQS": "Isa.12"
                    },
                    {
                        "pretty": "Zephaniah 3:14\u201320",
                        "readQS": "Zephaniah+3:14-20",
                        "audioQS": "Zeph.3"
                    },
                    {
                        "pretty": "Philippians 4:4\u20137",
                        "readQS": "Philippians+4:4-7",
                        "audioQS": "Phil.4"
                    },
                    {
                        "pretty": "Luke 3:7\u201318",
                        "readQS": "Luke+3:7-18",
                        "audioQS": "Luke.3"
                    }
                ],
                "readQS": "Isaiah+12:2-6%3B+Zephaniah+3:14-20%3B+Philippians+4:4-7%3B+Luke+3:7-18",
                "audioQS": "Isa.12,Zeph.3,Phil.4,Luke.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 12,14",
                        "readQS": "Psalm+12,14",
                        "audioQS": "Ps.12,14"
                    },
                    {
                        "pretty": "Isaiah 25:1\u20139",
                        "readQS": "Isaiah+25:1-9",
                        "audioQS": "Isa.25"
                    },
                    {
                        "pretty": "1 Corinthians 4:1\u20135",
                        "readQS": "1+Corinthians+4:1-5",
                        "audioQS": "1Cor.4"
                    }
                ],
                "readQS": "Psalm+12,14%3B+Isaiah+25:1-9%3B+1+Corinthians+4:1-5",
                "audioQS": "Ps.12,14,Isa.25,1Cor.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 50:1\u20136",
                        "readQS": "Psalm+50:1-6",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "Isaiah 35",
                        "readQS": "Isaiah+35",
                        "audioQS": "Isa.35"
                    },
                    {
                        "pretty": "Luke 1:57\u201366,(67\u201380)",
                        "readQS": "Luke+1:57-66,%2867-80%29",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+50:1-6%3B+Isaiah+35%3B+Luke+1:57-66,%2867-80%29",
                "audioQS": "Ps.50,Isa.35,Luke.1",
                "note": null
            }
        ]
    },
    "2024/12/16": {
        "datePretty": "Monday, December 16, 2024",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Isaiah 49:14\u201325",
                        "readQS": "Isaiah+49:14-25",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "1 Thessalonians 5:1\u201311",
                        "readQS": "1+Thessalonians+5:1-11",
                        "audioQS": "1Thess.5"
                    }
                ],
                "readQS": "Psalm+40%3B+Isaiah+49:14-25%3B+1+Thessalonians+5:1-11",
                "audioQS": "Ps.40,Isa.49,1Thess.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 26",
                        "readQS": "Psalm+26",
                        "audioQS": "Ps.26"
                    },
                    {
                        "pretty": "Isaiah 38:1\u20138,38:21\u201322",
                        "readQS": "Isaiah+38:1-8,38:21-22",
                        "audioQS": "Isa.38"
                    },
                    {
                        "pretty": "Matthew 16:13\u2013end",
                        "readQS": "Matthew+16:13-",
                        "audioQS": "Matt.16"
                    }
                ],
                "readQS": "Psalm+26%3B+Isaiah+38:1-8,38:21-22%3B+Matthew+16:13-",
                "audioQS": "Ps.26,Isa.38,Matt.16",
                "note": null
            }
        ]
    },
    "2024/12/17": {
        "datePretty": "Tuesday, December 17, 2024",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 70",
                        "readQS": "Psalm+70",
                        "audioQS": "Ps.70"
                    },
                    {
                        "pretty": "Isaiah 50",
                        "readQS": "Isaiah+50",
                        "audioQS": "Isa.50"
                    },
                    {
                        "pretty": "1 Thessalonians 5:12\u2013end",
                        "readQS": "1+Thessalonians+5:12-",
                        "audioQS": "1Thess.5"
                    }
                ],
                "readQS": "Psalm+70%3B+Isaiah+50%3B+1+Thessalonians+5:12-",
                "audioQS": "Ps.70,Isa.50,1Thess.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 50",
                        "readQS": "Psalm+50",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "Isaiah 38:9\u201320",
                        "readQS": "Isaiah+38:9-20",
                        "audioQS": "Isa.38"
                    },
                    {
                        "pretty": "Matthew 17:1\u201313",
                        "readQS": "Matthew+17:1-13",
                        "audioQS": "Matt.17"
                    }
                ],
                "readQS": "Psalm+50%3B+Isaiah+38:9-20%3B+Matthew+17:1-13",
                "audioQS": "Ps.50,Isa.38,Matt.17",
                "note": null
            }
        ]
    },
    "2024/12/18": {
        "datePretty": "Wednesday, December 18, 2024",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 75",
                        "readQS": "Psalm+75",
                        "audioQS": "Ps.75"
                    },
                    {
                        "pretty": "Isaiah 51:1\u20138",
                        "readQS": "Isaiah+51:1-8",
                        "audioQS": "Isa.51"
                    },
                    {
                        "pretty": "2 Thessalonians 1",
                        "readQS": "2+Thessalonians+1",
                        "audioQS": "2Thess.1"
                    }
                ],
                "readQS": "Psalm+75%3B+Isaiah+51:1-8%3B+2+Thessalonians+1",
                "audioQS": "Ps.75,Isa.51,2Thess.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 82",
                        "readQS": "Psalm+82",
                        "audioQS": "Ps.82"
                    },
                    {
                        "pretty": "Isaiah 39",
                        "readQS": "Isaiah+39",
                        "audioQS": "Isa.39"
                    },
                    {
                        "pretty": "Matthew 17:14\u201321",
                        "readQS": "Matthew+17:14-21",
                        "audioQS": "Matt.17"
                    }
                ],
                "readQS": "Psalm+82%3B+Isaiah+39%3B+Matthew+17:14-21",
                "audioQS": "Ps.82,Isa.39,Matt.17",
                "note": null
            }
        ]
    },
    "2024/12/19": {
        "datePretty": "Thursday, December 19, 2024",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 146",
                        "readQS": "Psalm+146",
                        "audioQS": "Ps.146"
                    },
                    {
                        "pretty": "Isaiah 51:9\u201316",
                        "readQS": "Isaiah+51:9-16",
                        "audioQS": "Isa.51"
                    },
                    {
                        "pretty": "2 Thessalonians 2",
                        "readQS": "2+Thessalonians+2",
                        "audioQS": "2Thess.2"
                    }
                ],
                "readQS": "Psalm+146%3B+Isaiah+51:9-16%3B+2+Thessalonians+2",
                "audioQS": "Ps.146,Isa.51,2Thess.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 57",
                        "readQS": "Psalm+57",
                        "audioQS": "Ps.57"
                    },
                    {
                        "pretty": "Zephaniah 1:1\u20132:3",
                        "readQS": "Zephaniah+1:1-2:3",
                        "audioQS": "Zeph.1"
                    },
                    {
                        "pretty": "Matthew 17:22\u2013end",
                        "readQS": "Matthew+17:22-",
                        "audioQS": "Matt.17"
                    }
                ],
                "readQS": "Psalm+57%3B+Zephaniah+1:1-2:3%3B+Matthew+17:22-",
                "audioQS": "Ps.57,Zeph.1,Matt.17",
                "note": null
            }
        ]
    },
    "2024/12/20": {
        "datePretty": "Friday, December 20, 2024",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 46",
                        "readQS": "Psalm+46",
                        "audioQS": "Ps.46"
                    },
                    {
                        "pretty": "Isaiah 51:17\u2013end",
                        "readQS": "Isaiah+51:17-",
                        "audioQS": "Isa.51"
                    },
                    {
                        "pretty": "2 Thessalonians 3",
                        "readQS": "2+Thessalonians+3",
                        "audioQS": "2Thess.3"
                    }
                ],
                "readQS": "Psalm+46%3B+Isaiah+51:17-%3B+2+Thessalonians+3",
                "audioQS": "Ps.46,Isa.51,2Thess.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 4",
                        "readQS": "Psalm+4",
                        "audioQS": "Ps.4"
                    },
                    {
                        "pretty": "Zephaniah 3:1\u201313",
                        "readQS": "Zephaniah+3:1-13",
                        "audioQS": "Zeph.3"
                    },
                    {
                        "pretty": "Matthew 18:1\u201320",
                        "readQS": "Matthew+18:1-20",
                        "audioQS": "Matt.18"
                    }
                ],
                "readQS": "Psalm+4%3B+Zephaniah+3:1-13%3B+Matthew+18:1-20",
                "audioQS": "Ps.4,Zeph.3,Matt.18",
                "note": null
            }
        ]
    },
    "2024/12/21": {
        "datePretty": "Saturday, December 21, 2024",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 121",
                        "readQS": "Psalm+121",
                        "audioQS": "Ps.121"
                    },
                    {
                        "pretty": "Isaiah 52:1\u201312",
                        "readQS": "Isaiah+52:1-12",
                        "audioQS": "Isa.52"
                    },
                    {
                        "pretty": "Jude",
                        "readQS": "Jude",
                        "audioQS": "Jude"
                    }
                ],
                "readQS": "Psalm+121%3B+Isaiah+52:1-12%3B+Jude",
                "audioQS": "Ps.121,Isa.52,Jude",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 84",
                        "readQS": "Psalm+84",
                        "audioQS": "Ps.84"
                    },
                    {
                        "pretty": "Zephaniah 3:14\u2013end",
                        "readQS": "Zephaniah+3:14-",
                        "audioQS": "Zeph.3"
                    },
                    {
                        "pretty": "Matthew 18:21\u2013end",
                        "readQS": "Matthew+18:21-",
                        "audioQS": "Matt.18"
                    }
                ],
                "readQS": "Psalm+84%3B+Zephaniah+3:14-%3B+Matthew+18:21-",
                "audioQS": "Ps.84,Zeph.3,Matt.18",
                "note": null
            }
        ]
    },
    "2024/12/22": {
        "datePretty": "Sunday, December 22, 2024",
        "calendar": "4th Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 80:1\u20138",
                        "readQS": "Psalm+80:1-8",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Canticle: Luke 1:46\u201355 (Magnificat)",
                        "readQS": "Luke+1:46-55",
                        "audioQS": "Luke.1"
                    },
                    {
                        "pretty": "Micah 5:2\u20135a",
                        "readQS": "Micah+5:2-5a",
                        "audioQS": "Mic.5"
                    },
                    {
                        "pretty": "Hebrews 10:5\u201310",
                        "readQS": "Hebrews+10:5-10",
                        "audioQS": "Heb.10"
                    },
                    {
                        "pretty": "Luke 1:39\u201345,(46\u201355)",
                        "readQS": "Luke+1:39-45,%2846-55%29",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+80:1-8%3B+Canticle:+Luke+1:46-55+%28Magnificat%29%3B+Micah+5:2-5a%3B+Hebrews+10:5-10%3B+Luke+1:39-45,%2846-55%29",
                "audioQS": "Ps.80,Canticle,Mic.5,Heb.10,Luke.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 144",
                        "readQS": "Psalm+144",
                        "audioQS": "Ps.144"
                    },
                    {
                        "pretty": "Isaiah 32:1\u20138",
                        "readQS": "Isaiah+32:1-8",
                        "audioQS": "Isa.32"
                    },
                    {
                        "pretty": "Revelation 22:6\u201321",
                        "readQS": "Revelation+22:6-21",
                        "audioQS": "Rev.22"
                    }
                ],
                "readQS": "Psalm+144%3B+Isaiah+32:1-8%3B+Revelation+22:6-21",
                "audioQS": "Ps.144,Isa.32,Rev.22",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 123",
                        "readQS": "Psalm+123",
                        "audioQS": "Ps.123"
                    },
                    {
                        "pretty": "Isaiah 10:33\u201311:10",
                        "readQS": "Isaiah+10:33-11:10",
                        "audioQS": "Isa.10"
                    },
                    {
                        "pretty": "Matthew 1:18\u201325",
                        "readQS": "Matthew+1:18-25",
                        "audioQS": "Matt.1"
                    }
                ],
                "readQS": "Psalm+123%3B+Isaiah+10:33-11:10%3B+Matthew+1:18-25",
                "audioQS": "Ps.123,Isa.10,Matt.1",
                "note": null
            }
        ]
    },
    "2024/12/23": {
        "datePretty": "Monday, December 23, 2024",
        "calendar": "Week of 4th Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 130",
                        "readQS": "Psalm+130",
                        "audioQS": "Ps.130"
                    },
                    {
                        "pretty": "Isaiah 52:13\u2013end",
                        "readQS": "Isaiah+52:13-",
                        "audioQS": "Isa.52"
                    },
                    {
                        "pretty": "Isaiah 53",
                        "readQS": "Isaiah+53",
                        "audioQS": "Isa.53"
                    },
                    {
                        "pretty": "2 Peter 1:1\u201315",
                        "readQS": "2+Peter+1:1-15",
                        "audioQS": "2Pet.1"
                    }
                ],
                "readQS": "Psalm+130%3B+Isaiah+52:13-%3B+Isaiah+53%3B+2+Peter+1:1-15",
                "audioQS": "Ps.130,Isa.52,Isa.53,2Pet.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 89:1\u201337",
                        "readQS": "Psalm+89:1-37",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Malachi 1:1,1:6\u2013end",
                        "readQS": "Malachi+1:1,1:6-",
                        "audioQS": "Mal.1"
                    },
                    {
                        "pretty": "Matthew 19:1\u201312",
                        "readQS": "Matthew+19:1-12",
                        "audioQS": "Matt.19"
                    }
                ],
                "readQS": "Psalm+89:1-37%3B+Malachi+1:1,1:6-%3B+Matthew+19:1-12",
                "audioQS": "Ps.89,Mal.1,Matt.19",
                "note": null
            }
        ]
    },
    "2024/12/24": {
        "datePretty": "Tuesday, December 24, 2024",
        "calendar": "Christmas Eve",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 45",
                        "readQS": "Psalm+45",
                        "audioQS": "Ps.45"
                    },
                    {
                        "pretty": "Isaiah 54",
                        "readQS": "Isaiah+54",
                        "audioQS": "Isa.54"
                    },
                    {
                        "pretty": "2 Peter 1:16\u20132:3",
                        "readQS": "2+Peter+1:16-2:3",
                        "audioQS": "2Pet.1"
                    }
                ],
                "readQS": "Psalm+45%3B+Isaiah+54%3B+2+Peter+1:16-2:3",
                "audioQS": "Ps.45,Isa.54,2Pet.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Christmas Eve)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "Zechariah 2",
                        "readQS": "Zechariah+2",
                        "audioQS": "Zech.2"
                    },
                    {
                        "pretty": "Revelation 1:1\u20138",
                        "readQS": "Revelation+1:1-8",
                        "audioQS": "Rev.1"
                    }
                ],
                "readQS": "Psalm+85%3B+Zechariah+2%3B+Revelation+1:1-8",
                "audioQS": "Ps.85,Zech.2,Rev.1",
                "note": null
            }
        ]
    },
    "2024/12/25": {
        "datePretty": "Wednesday, December 25, 2024",
        "calendar": "Christmas Day",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (I)",
                "readingSetSubtitle": "Revised Common Lectionary (Proper I)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 96",
                        "readQS": "Psalm+96",
                        "audioQS": "Ps.96"
                    },
                    {
                        "pretty": "",
                        "readQS": "",
                        "audioQS": ""
                    },
                    {
                        "pretty": "Titus 2:11\u201314",
                        "readQS": "Titus+2:11-14",
                        "audioQS": "Titus.2"
                    },
                    {
                        "pretty": "Luke 2:1\u201314,(15\u201320)",
                        "readQS": "Luke+2:1-14,%2815-20%29",
                        "audioQS": "Luke.2"
                    }
                ],
                "readQS": "Psalm+96%3B+%3B+Titus+2:11-14%3B+Luke+2:1-14,%2815-20%29",
                "audioQS": "Ps.96,,Titus.2,Luke.2",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (II)",
                "readingSetSubtitle": "Revised Common Lectionary (Proper II)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Isaiah 62:6\u201312",
                        "readQS": "Isaiah+62:6-12",
                        "audioQS": "Isa.62"
                    },
                    {
                        "pretty": "Titus 3:4\u20137",
                        "readQS": "Titus+3:4-7",
                        "audioQS": "Titus.3"
                    },
                    {
                        "pretty": "Luke 2:(1\u20137),8\u201320",
                        "readQS": "Luke+2:%281-7%29,8-20",
                        "audioQS": "Luke.2"
                    }
                ],
                "readQS": "Psalm+97%3B+Isaiah+62:6-12%3B+Titus+3:4-7%3B+Luke+2:%281-7%29,8-20",
                "audioQS": "Ps.97,Isa.62,Titus.3,Luke.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 110",
                        "readQS": "Psalm+110",
                        "audioQS": "Ps.110"
                    },
                    {
                        "pretty": "Isaiah 62:1\u20135",
                        "readQS": "Isaiah+62:1-5",
                        "audioQS": "Isa.62"
                    },
                    {
                        "pretty": "Matthew 1:18\u201325",
                        "readQS": "Matthew+1:18-25",
                        "audioQS": "Matt.1"
                    }
                ],
                "readQS": "Psalm+110%3B+Isaiah+62:1-5%3B+Matthew+1:18-25",
                "audioQS": "Ps.110,Isa.62,Matt.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 8",
                        "readQS": "Psalm+8",
                        "audioQS": "Ps.8"
                    },
                    {
                        "pretty": "Isaiah 65:17\u201325",
                        "readQS": "Isaiah+65:17-25",
                        "audioQS": "Isa.65"
                    },
                    {
                        "pretty": "Philippians 2:5\u201311",
                        "readQS": "Philippians+2:5-11",
                        "audioQS": "Phil.2"
                    }
                ],
                "readQS": "Psalm+8%3B+Isaiah+65:17-25%3B+Philippians+2:5-11",
                "audioQS": "Ps.8,Isa.65,Phil.2",
                "note": null
            }
        ]
    },
    "2024/12/26": {
        "datePretty": "Thursday, December 26, 2024",
        "calendar": "St Stephen, the first Christian Martyr",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 13",
                        "readQS": "Psalm+13",
                        "audioQS": "Ps.13"
                    },
                    {
                        "pretty": "Jeremiah 26:12\u201315",
                        "readQS": "Jeremiah+26:12-15",
                        "audioQS": "Jer.26"
                    },
                    {
                        "pretty": "Acts 6",
                        "readQS": "Acts+6",
                        "audioQS": "Acts.6"
                    }
                ],
                "readQS": "Psalm+13%3B+Jeremiah+26:12-15%3B+Acts+6",
                "audioQS": "Ps.13,Jer.26,Acts.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 86",
                        "readQS": "Psalm+86",
                        "audioQS": "Ps.86"
                    },
                    {
                        "pretty": "Genesis 4:1\u201310",
                        "readQS": "Genesis+4:1-10",
                        "audioQS": "Gen.4"
                    },
                    {
                        "pretty": "Matthew 23:34\u201339",
                        "readQS": "Matthew+23:34-39",
                        "audioQS": "Matt.23"
                    }
                ],
                "readQS": "Psalm+86%3B+Genesis+4:1-10%3B+Matthew+23:34-39",
                "audioQS": "Ps.86,Gen.4,Matt.23",
                "note": null
            }
        ]
    },
    "2024/12/27": {
        "datePretty": "Friday, December 27, 2024",
        "calendar": "St John the Evangelist",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 21",
                        "readQS": "Psalm+21",
                        "audioQS": "Ps.21"
                    },
                    {
                        "pretty": "Exodus 33:12\u201323",
                        "readQS": "Exodus+33:12-23",
                        "audioQS": "Exod.33"
                    },
                    {
                        "pretty": "1 John 2:1\u201311",
                        "readQS": "1+John+2:1-11",
                        "audioQS": "1John.2"
                    }
                ],
                "readQS": "Psalm+21%3B+Exodus+33:12-23%3B+1+John+2:1-11",
                "audioQS": "Ps.21,Exod.33,1John.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Isaiah 6:1\u20138",
                        "readQS": "Isaiah+6:1-8",
                        "audioQS": "Isa.6"
                    },
                    {
                        "pretty": "1 John 5:1\u201312",
                        "readQS": "1+John+5:1-12",
                        "audioQS": "1John.5"
                    }
                ],
                "readQS": "Psalm+97%3B+Isaiah+6:1-8%3B+1+John+5:1-12",
                "audioQS": "Ps.97,Isa.6,1John.5",
                "note": null
            }
        ]
    },
    "2024/12/28": {
        "datePretty": "Saturday, December 28, 2024",
        "calendar": "Holy Innocents",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 36",
                        "readQS": "Psalm+36",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "Genesis 37:13\u201320",
                        "readQS": "Genesis+37:13-20",
                        "audioQS": "Gen.37"
                    },
                    {
                        "pretty": "Matthew 18:1\u201310",
                        "readQS": "Matthew+18:1-10",
                        "audioQS": "Matt.18"
                    }
                ],
                "readQS": "Psalm+36%3B+Genesis+37:13-20%3B+Matthew+18:1-10",
                "audioQS": "Ps.36,Gen.37,Matt.18",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 128",
                        "readQS": "Psalm+128",
                        "audioQS": "Ps.128"
                    },
                    {
                        "pretty": "Isaiah 49:14\u201325",
                        "readQS": "Isaiah+49:14-25",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "Mark 10:13\u201316",
                        "readQS": "Mark+10:13-16",
                        "audioQS": "Mark.10"
                    }
                ],
                "readQS": "Psalm+128%3B+Isaiah+49:14-25%3B+Mark+10:13-16",
                "audioQS": "Ps.128,Isa.49,Mark.10",
                "note": null
            }
        ]
    },
    "2024/12/29": {
        "datePretty": "Sunday, December 29, 2024",
        "calendar": "1st Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 148",
                        "readQS": "Psalm+148",
                        "audioQS": "Ps.148"
                    },
                    {
                        "pretty": "Canticle: Colossians 3:12\u201317",
                        "readQS": "Colossians+3:12-17",
                        "audioQS": "Col.3"
                    },
                    {
                        "pretty": "1 Samuel 2:18\u201320,26",
                        "readQS": "1+Samuel+2:18-20,26",
                        "audioQS": "1Sam.2"
                    },
                    {
                        "pretty": "Luke 2:41\u201352",
                        "readQS": "Luke+2:41-52",
                        "audioQS": "Luke.2"
                    },
                    {
                        "pretty": "",
                        "readQS": "",
                        "audioQS": ""
                    }
                ],
                "readQS": "Psalm+148%3B+Canticle:+Colossians+3:12-17%3B+1+Samuel+2:18-20,26%3B+Luke+2:41-52%3B+",
                "audioQS": "Ps.148,Canticle,1Sam.2,Luke.2,",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 105:1\u201311",
                        "readQS": "Psalm+105:1-11",
                        "audioQS": "Ps.105"
                    },
                    {
                        "pretty": "Isaiah 41:21 \u2013 42:1",
                        "readQS": "Isaiah+41:21+-+42:1",
                        "audioQS": "Isa.41"
                    },
                    {
                        "pretty": "1 John 1:1\u20137",
                        "readQS": "1+John+1:1-7",
                        "audioQS": "1John.1"
                    }
                ],
                "readQS": "Psalm+105:1-11%3B+Isaiah+41:21+-+42:1%3B+1+John+1:1-7",
                "audioQS": "Ps.105,Isa.41,1John.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Isaiah 61",
                        "readQS": "Isaiah+61",
                        "audioQS": "Isa.61"
                    },
                    {
                        "pretty": "Galatians 3:27 \u2013 4:7",
                        "readQS": "Galatians+3:27+-+4:7",
                        "audioQS": "Gal.3"
                    }
                ],
                "readQS": "Psalm+132%3B+Isaiah+61%3B+Galatians+3:27+-+4:7",
                "audioQS": "Ps.132,Isa.61,Gal.3",
                "note": null
            }
        ]
    },
    "2024/12/30": {
        "datePretty": "Monday, December 30, 2024",
        "calendar": "Week of 1st Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 113",
                        "readQS": "Psalm+113",
                        "audioQS": "Ps.113"
                    },
                    {
                        "pretty": "Isaiah 59:1\u201315a",
                        "readQS": "Isaiah+59:1-15a",
                        "audioQS": "Isa.59"
                    },
                    {
                        "pretty": "John 1:19\u201328",
                        "readQS": "John+1:19-28",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+113%3B+Isaiah+59:1-15a%3B+John+1:19-28",
                "audioQS": "Ps.113,Isa.59,John.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 65",
                        "readQS": "Psalm+65",
                        "audioQS": "Ps.65"
                    },
                    {
                        "pretty": "Jonah 2",
                        "readQS": "Jonah+2",
                        "audioQS": "Jonah.2"
                    },
                    {
                        "pretty": "Colossians 1:15\u201323",
                        "readQS": "Colossians+1:15-23",
                        "audioQS": "Col.1"
                    }
                ],
                "readQS": "Psalm+65%3B+Jonah+2%3B+Colossians+1:15-23",
                "audioQS": "Ps.65,Jonah.2,Col.1",
                "note": null
            }
        ]
    },
    "2024/12/31": {
        "datePretty": "Tuesday, December 31, 2024",
        "calendar": "Week of 1st Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 102",
                        "readQS": "Psalm+102",
                        "audioQS": "Ps.102"
                    },
                    {
                        "pretty": "Isaiah 59:15b\u2013end",
                        "readQS": "Isaiah+59:15b-",
                        "audioQS": "Isa.59"
                    },
                    {
                        "pretty": "John 1:29\u201334",
                        "readQS": "John+1:29-34",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+102%3B+Isaiah+59:15b-%3B+John+1:29-34",
                "audioQS": "Ps.102,Isa.59,John.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Naming of Jesus)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 148",
                        "readQS": "Psalm+148",
                        "audioQS": "Ps.148"
                    },
                    {
                        "pretty": "Jeremiah 23:1\u20136",
                        "readQS": "Jeremiah+23:1-6",
                        "audioQS": "Jer.23"
                    },
                    {
                        "pretty": "Colossians 2:8\u201315",
                        "readQS": "Colossians+2:8-15",
                        "audioQS": "Col.2"
                    }
                ],
                "readQS": "Psalm+148%3B+Jeremiah+23:1-6%3B+Colossians+2:8-15",
                "audioQS": "Ps.148,Jer.23,Col.2",
                "note": null
            }
        ]
    },
    "2025/01/01": {
        "datePretty": "Wednesday, January 1, 2025",
        "calendar": "The naming of Jesus",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 103",
                        "readQS": "Psalm+103",
                        "audioQS": "Ps.103"
                    },
                    {
                        "pretty": "Genesis 17:1\u201313",
                        "readQS": "Genesis+17:1-13",
                        "audioQS": "Gen.17"
                    },
                    {
                        "pretty": "Romans 2:17\u201329",
                        "readQS": "Romans+2:17-29",
                        "audioQS": "Rom.2"
                    }
                ],
                "readQS": "Psalm+103%3B+Genesis+17:1-13%3B+Romans+2:17-29",
                "audioQS": "Ps.103,Gen.17,Rom.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 115",
                        "readQS": "Psalm+115",
                        "audioQS": "Ps.115"
                    },
                    {
                        "pretty": "Deuteronomy 30:11\u201320",
                        "readQS": "Deuteronomy+30:11-20",
                        "audioQS": "Deut.30"
                    },
                    {
                        "pretty": "Acts 3:1\u201316",
                        "readQS": "Acts+3:1-16",
                        "audioQS": "Acts.3"
                    }
                ],
                "readQS": "Psalm+115%3B+Deuteronomy+30:11-20%3B+Acts+3:1-16",
                "audioQS": "Ps.115,Deut.30,Acts.3",
                "note": null
            }
        ]
    },
    "2025/01/02": {
        "datePretty": "Thursday, January 2, 2025",
        "calendar": "Week of 1st Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 18:1\u201330",
                        "readQS": "Psalm+18:1-30",
                        "audioQS": "Ps.18"
                    },
                    {
                        "pretty": "Isaiah 60:1\u201312",
                        "readQS": "Isaiah+60:1-12",
                        "audioQS": "Isa.60"
                    },
                    {
                        "pretty": "John 1:35\u201342",
                        "readQS": "John+1:35-42",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+18:1-30%3B+Isaiah+60:1-12%3B+John+1:35-42",
                "audioQS": "Ps.18,Isa.60,John.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 46",
                        "readQS": "Psalm+46",
                        "audioQS": "Ps.46"
                    },
                    {
                        "pretty": "Ruth 1",
                        "readQS": "Ruth+1",
                        "audioQS": "Ruth.1"
                    },
                    {
                        "pretty": "Colossians 2:8\u2013end",
                        "readQS": "Colossians+2:8-",
                        "audioQS": "Col.2"
                    }
                ],
                "readQS": "Psalm+46%3B+Ruth+1%3B+Colossians+2:8-",
                "audioQS": "Ps.46,Ruth.1,Col.2",
                "note": null
            }
        ]
    },
    "2025/01/03": {
        "datePretty": "Friday, January 3, 2025",
        "calendar": "Week of 1st Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 127",
                        "readQS": "Psalm+127",
                        "audioQS": "Ps.127"
                    },
                    {
                        "pretty": "Isaiah 60:13\u2013end",
                        "readQS": "Isaiah+60:13-",
                        "audioQS": "Isa.60"
                    },
                    {
                        "pretty": "John 1:43\u2013end",
                        "readQS": "John+1:43-",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+127%3B+Isaiah+60:13-%3B+John+1:43-",
                "audioQS": "Ps.127,Isa.60,John.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 2",
                        "readQS": "Psalm+2",
                        "audioQS": "Ps.2"
                    },
                    {
                        "pretty": "Ruth 2",
                        "readQS": "Ruth+2",
                        "audioQS": "Ruth.2"
                    },
                    {
                        "pretty": "Colossians 3:1\u201311",
                        "readQS": "Colossians+3:1-11",
                        "audioQS": "Col.3"
                    }
                ],
                "readQS": "Psalm+2%3B+Ruth+2%3B+Colossians+3:1-11",
                "audioQS": "Ps.2,Ruth.2,Col.3",
                "note": null
            }
        ]
    },
    "2025/01/04": {
        "datePretty": "Saturday, January 4, 2025",
        "calendar": "Week of 1st Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 89:1\u201337",
                        "readQS": "Psalm+89:1-37",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Isaiah 61",
                        "readQS": "Isaiah+61",
                        "audioQS": "Isa.61"
                    },
                    {
                        "pretty": "John 2:1\u201312",
                        "readQS": "John+2:1-12",
                        "audioQS": "John.2"
                    }
                ],
                "readQS": "Psalm+89:1-37%3B+Isaiah+61%3B+John+2:1-12",
                "audioQS": "Ps.89,Isa.61,John.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 87",
                        "readQS": "Psalm+87",
                        "audioQS": "Ps.87"
                    },
                    {
                        "pretty": "Ruth 3",
                        "readQS": "Ruth+3",
                        "audioQS": "Ruth.3"
                    },
                    {
                        "pretty": "Colossians 3:12\u20134:1",
                        "readQS": "Colossians+3:12-4:1",
                        "audioQS": "Col.3"
                    }
                ],
                "readQS": "Psalm+87%3B+Ruth+3%3B+Colossians+3:12-4:1",
                "audioQS": "Ps.87,Ruth.3,Col.3",
                "note": null
            }
        ]
    },
    "2025/01/05": {
        "datePretty": "Sunday, January 5, 2025",
        "calendar": "2nd Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 147:13\u201321",
                        "readQS": "Psalm+147:13-21",
                        "audioQS": "Ps.147"
                    },
                    {
                        "pretty": "Jeremiah 31:7\u201314",
                        "readQS": "Jeremiah+31:7-14",
                        "audioQS": "Jer.31"
                    },
                    {
                        "pretty": "Ephesians 1:3\u201314",
                        "readQS": "Ephesians+1:3-14",
                        "audioQS": "Eph.1"
                    },
                    {
                        "pretty": "John 1:(1\u20139),10\u201318",
                        "readQS": "John+1:%281-9%29,10-18",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+147:13-21%3B+Jeremiah+31:7-14%3B+Ephesians+1:3-14%3B+John+1:%281-9%29,10-18",
                "audioQS": "Ps.147,Jer.31,Eph.1,John.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 48",
                        "readQS": "Psalm+48",
                        "audioQS": "Ps.48"
                    },
                    {
                        "pretty": "Isaiah 12",
                        "readQS": "Isaiah+12",
                        "audioQS": "Isa.12"
                    },
                    {
                        "pretty": "1 Thessalonians 2:1\u20138",
                        "readQS": "1+Thessalonians+2:1-8",
                        "audioQS": "1Thess.2"
                    }
                ],
                "readQS": "Psalm+48%3B+Isaiah+12%3B+1+Thessalonians+2:1-8",
                "audioQS": "Ps.48,Isa.12,1Thess.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Epiphany)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Isaiah 49:1\u201313",
                        "readQS": "Isaiah+49:1-13",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "John 4:7\u201326",
                        "readQS": "John+4:7-26",
                        "audioQS": "John.4"
                    }
                ],
                "readQS": "Psalm+97%3B+Isaiah+49:1-13%3B+John+4:7-26",
                "audioQS": "Ps.97,Isa.49,John.4",
                "note": null
            }
        ]
    },
    "2025/01/06": {
        "datePretty": "Monday, January 6, 2025",
        "calendar": "The Epiphany",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 72:(1\u20139),10\u201315",
                        "readQS": "Psalm+72:%281-9%29,10-15",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "Isaiah 60.1\u20136",
                        "readQS": "Isaiah+60.1-6",
                        "audioQS": "Isa.60.1\u20136"
                    },
                    {
                        "pretty": "Ephesians 3:1\u201312",
                        "readQS": "Ephesians+3:1-12",
                        "audioQS": "Eph.3"
                    },
                    {
                        "pretty": "Matthew 2:1\u201312",
                        "readQS": "Matthew+2:1-12",
                        "audioQS": "Matt.2"
                    }
                ],
                "readQS": "Psalm+72:%281-9%29,10-15%3B+Isaiah+60.1-6%3B+Ephesians+3:1-12%3B+Matthew+2:1-12",
                "audioQS": "Ps.72,Isa.60.1\u20136,Eph.3,Matt.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Jeremiah 31:7\u201314",
                        "readQS": "Jeremiah+31:7-14",
                        "audioQS": "Jer.31"
                    },
                    {
                        "pretty": "John 1:29\u201334",
                        "readQS": "John+1:29-34",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+132%3B+Jeremiah+31:7-14%3B+John+1:29-34",
                "audioQS": "Ps.132,Jer.31,John.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 98,100",
                        "readQS": "Psalm+98,100",
                        "audioQS": "Ps.98,100"
                    },
                    {
                        "pretty": "Isaiah 60:1\u20139",
                        "readQS": "Isaiah+60:1-9",
                        "audioQS": "Isa.60"
                    },
                    {
                        "pretty": "John 2:1\u201311",
                        "readQS": "John+2:1-11",
                        "audioQS": "John.2"
                    }
                ],
                "readQS": "Psalm+98,100%3B+Isaiah+60:1-9%3B+John+2:1-11",
                "audioQS": "Ps.98,100,Isa.60,John.2",
                "note": null
            }
        ]
    },
    "2025/01/07": {
        "datePretty": "Tuesday, January 7, 2025",
        "calendar": "Week of 2nd Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 99",
                        "readQS": "Psalm+99",
                        "audioQS": "Ps.99"
                    },
                    {
                        "pretty": "Isaiah 63:7\u2013end",
                        "readQS": "Isaiah+63:7-",
                        "audioQS": "Isa.63"
                    },
                    {
                        "pretty": "1 John 3",
                        "readQS": "1+John+3",
                        "audioQS": "1John.3"
                    }
                ],
                "readQS": "Psalm+99%3B+Isaiah+63:7-%3B+1+John+3",
                "audioQS": "Ps.99,Isa.63,1John.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 118",
                        "readQS": "Psalm+118",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "Jeremiah 23:1\u20138",
                        "readQS": "Jeremiah+23:1-8",
                        "audioQS": "Jer.23"
                    },
                    {
                        "pretty": "Matthew 20:1\u201316",
                        "readQS": "Matthew+20:1-16",
                        "audioQS": "Matt.20"
                    }
                ],
                "readQS": "Psalm+118%3B+Jeremiah+23:1-8%3B+Matthew+20:1-16",
                "audioQS": "Ps.118,Jer.23,Matt.20",
                "note": null
            }
        ]
    },
    "2025/01/08": {
        "datePretty": "Wednesday, January 8, 2025",
        "calendar": "Week of 2nd Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 46",
                        "readQS": "Psalm+46",
                        "audioQS": "Ps.46"
                    },
                    {
                        "pretty": "Isaiah 64",
                        "readQS": "Isaiah+64",
                        "audioQS": "Isa.64"
                    },
                    {
                        "pretty": "1 John 4:7\u2013end",
                        "readQS": "1+John+4:7-",
                        "audioQS": "1John.4"
                    }
                ],
                "readQS": "Psalm+46%3B+Isaiah+64%3B+1+John+4:7-",
                "audioQS": "Ps.46,Isa.64,1John.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Jeremiah 30:1\u201317",
                        "readQS": "Jeremiah+30:1-17",
                        "audioQS": "Jer.30"
                    },
                    {
                        "pretty": "Matthew 20:17\u201328",
                        "readQS": "Matthew+20:17-28",
                        "audioQS": "Matt.20"
                    }
                ],
                "readQS": "Psalm+145%3B+Jeremiah+30:1-17%3B+Matthew+20:17-28",
                "audioQS": "Ps.145,Jer.30,Matt.20",
                "note": null
            }
        ]
    },
    "2025/01/09": {
        "datePretty": "Thursday, January 9, 2025",
        "calendar": "Week of 2nd Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 148",
                        "readQS": "Psalm+148",
                        "audioQS": "Ps.148"
                    },
                    {
                        "pretty": "Isaiah 65:1\u201316",
                        "readQS": "Isaiah+65:1-16",
                        "audioQS": "Isa.65"
                    },
                    {
                        "pretty": "1 John 5:1\u201312",
                        "readQS": "1+John+5:1-12",
                        "audioQS": "1John.5"
                    }
                ],
                "readQS": "Psalm+148%3B+Isaiah+65:1-16%3B+1+John+5:1-12",
                "audioQS": "Ps.148,Isa.65,1John.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 67",
                        "readQS": "Psalm+67",
                        "audioQS": "Ps.67"
                    },
                    {
                        "pretty": "Jeremiah 30:18\u201331:9",
                        "readQS": "Jeremiah+30:18-31:9",
                        "audioQS": "Jer.30"
                    },
                    {
                        "pretty": "Matthew 20:29\u2013end",
                        "readQS": "Matthew+20:29-",
                        "audioQS": "Matt.20"
                    }
                ],
                "readQS": "Psalm+67%3B+Jeremiah+30:18-31:9%3B+Matthew+20:29-",
                "audioQS": "Ps.67,Jer.30,Matt.20",
                "note": null
            }
        ]
    },
    "2025/01/10": {
        "datePretty": "Friday, January 10, 2025",
        "calendar": "Week of 2nd Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 149",
                        "readQS": "Psalm+149",
                        "audioQS": "Ps.149"
                    },
                    {
                        "pretty": "Isaiah 65:17\u2013end",
                        "readQS": "Isaiah+65:17-",
                        "audioQS": "Isa.65"
                    },
                    {
                        "pretty": "1 John 5:13\u2013end",
                        "readQS": "1+John+5:13-",
                        "audioQS": "1John.5"
                    }
                ],
                "readQS": "Psalm+149%3B+Isaiah+65:17-%3B+1+John+5:13-",
                "audioQS": "Ps.149,Isa.65,1John.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 29",
                        "readQS": "Psalm+29",
                        "audioQS": "Ps.29"
                    },
                    {
                        "pretty": "Jeremiah 31:10\u201317",
                        "readQS": "Jeremiah+31:10-17",
                        "audioQS": "Jer.31"
                    },
                    {
                        "pretty": "Matthew 23:1\u201312",
                        "readQS": "Matthew+23:1-12",
                        "audioQS": "Matt.23"
                    }
                ],
                "readQS": "Psalm+29%3B+Jeremiah+31:10-17%3B+Matthew+23:1-12",
                "audioQS": "Ps.29,Jer.31,Matt.23",
                "note": null
            }
        ]
    },
    "2025/01/11": {
        "datePretty": "Saturday, January 11, 2025",
        "calendar": "Week of 2nd Sunday of Christmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 150",
                        "readQS": "Psalm+150",
                        "audioQS": "Ps.150"
                    },
                    {
                        "pretty": "Isaiah 66:1\u201311",
                        "readQS": "Isaiah+66:1-11",
                        "audioQS": "Isa.66"
                    },
                    {
                        "pretty": "2 John",
                        "readQS": "2+John",
                        "audioQS": "2John"
                    }
                ],
                "readQS": "Psalm+150%3B+Isaiah+66:1-11%3B+2+John",
                "audioQS": "Ps.150,Isa.66,2John",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Baptism of the Lord)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 36",
                        "readQS": "Psalm+36",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "Isaiah 61",
                        "readQS": "Isaiah+61",
                        "audioQS": "Isa.61"
                    },
                    {
                        "pretty": "Titus 2:11\u201314",
                        "readQS": "Titus+2:11-14",
                        "audioQS": "Titus.2"
                    },
                    {
                        "pretty": "Titus 3:4\u20137",
                        "readQS": "Titus+3:4-7",
                        "audioQS": "Titus.3"
                    }
                ],
                "readQS": "Psalm+36%3B+Isaiah+61%3B+Titus+2:11-14%3B+Titus+3:4-7",
                "audioQS": "Ps.36,Isa.61,Titus.2,Titus.3",
                "note": null
            }
        ]
    },
    "2025/01/12": {
        "datePretty": "Sunday, January 12, 2025",
        "calendar": "The Baptism of the Lord",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 29",
                        "readQS": "Psalm+29",
                        "audioQS": "Ps.29"
                    },
                    {
                        "pretty": "Isaiah 43:1\u20137",
                        "readQS": "Isaiah+43:1-7",
                        "audioQS": "Isa.43"
                    },
                    {
                        "pretty": "Acts 8:14\u201317",
                        "readQS": "Acts+8:14-17",
                        "audioQS": "Acts.8"
                    },
                    {
                        "pretty": "Luke 3:15\u201317,21,22",
                        "readQS": "Luke+3:15-17,21,22",
                        "audioQS": "Luke.3"
                    }
                ],
                "readQS": "Psalm+29%3B+Isaiah+43:1-7%3B+Acts+8:14-17%3B+Luke+3:15-17,21,22",
                "audioQS": "Ps.29,Isa.43,Acts.8,Luke.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 89:19\u201329",
                        "readQS": "Psalm+89:19-29",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Isaiah 42:1\u20139",
                        "readQS": "Isaiah+42:1-9",
                        "audioQS": "Isa.42"
                    },
                    {
                        "pretty": "Acts 19:1\u20137",
                        "readQS": "Acts+19:1-7",
                        "audioQS": "Acts.19"
                    }
                ],
                "readQS": "Psalm+89:19-29%3B+Isaiah+42:1-9%3B+Acts+19:1-7",
                "audioQS": "Ps.89,Isa.42,Acts.19",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 46,47",
                        "readQS": "Psalm+46,47",
                        "audioQS": "Ps.46,47"
                    },
                    {
                        "pretty": "Isaiah 55:1\u201311",
                        "readQS": "Isaiah+55:1-11",
                        "audioQS": "Isa.55"
                    },
                    {
                        "pretty": "Romans 6:1\u201311",
                        "readQS": "Romans+6:1-11",
                        "audioQS": "Rom.6"
                    }
                ],
                "readQS": "Psalm+46,47%3B+Isaiah+55:1-11%3B+Romans+6:1-11",
                "audioQS": "Ps.46,47,Isa.55,Rom.6",
                "note": null
            }
        ]
    },
    "2025/01/13": {
        "datePretty": "Monday, January 13, 2025",
        "calendar": "Week of 1st Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 2",
                        "readQS": "Psalm+2",
                        "audioQS": "Ps.2"
                    },
                    {
                        "pretty": "Amos 1",
                        "readQS": "Amos+1",
                        "audioQS": "Amos.1"
                    },
                    {
                        "pretty": "1 Corinthians 1:1\u201317",
                        "readQS": "1+Corinthians+1:1-17",
                        "audioQS": "1Cor.1"
                    }
                ],
                "readQS": "Psalm+2%3B+Amos+1%3B+1+Corinthians+1:1-17",
                "audioQS": "Ps.2,Amos.1,1Cor.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 34",
                        "readQS": "Psalm+34",
                        "audioQS": "Ps.34"
                    },
                    {
                        "pretty": "Genesis 1:1\u201319",
                        "readQS": "Genesis+1:1-19",
                        "audioQS": "Gen.1"
                    },
                    {
                        "pretty": "Matthew 21:1\u201317",
                        "readQS": "Matthew+21:1-17",
                        "audioQS": "Matt.21"
                    }
                ],
                "readQS": "Psalm+34%3B+Genesis+1:1-19%3B+Matthew+21:1-17",
                "audioQS": "Ps.34,Gen.1,Matt.21",
                "note": null
            }
        ]
    },
    "2025/01/14": {
        "datePretty": "Tuesday, January 14, 2025",
        "calendar": "Week of 1st Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 9",
                        "readQS": "Psalm+9",
                        "audioQS": "Ps.9"
                    },
                    {
                        "pretty": "Amos 2",
                        "readQS": "Amos+2",
                        "audioQS": "Amos.2"
                    },
                    {
                        "pretty": "1 Corinthians 1:18\u2013end",
                        "readQS": "1+Corinthians+1:18-",
                        "audioQS": "1Cor.1"
                    }
                ],
                "readQS": "Psalm+9%3B+Amos+2%3B+1+Corinthians+1:18-",
                "audioQS": "Ps.9,Amos.2,1Cor.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 45",
                        "readQS": "Psalm+45",
                        "audioQS": "Ps.45"
                    },
                    {
                        "pretty": "Genesis 1:20\u20132:3",
                        "readQS": "Genesis+1:20-2:3",
                        "audioQS": "Gen.1"
                    },
                    {
                        "pretty": "Matthew 21:18\u201332",
                        "readQS": "Matthew+21:18-32",
                        "audioQS": "Matt.21"
                    }
                ],
                "readQS": "Psalm+45%3B+Genesis+1:20-2:3%3B+Matthew+21:18-32",
                "audioQS": "Ps.45,Gen.1,Matt.21",
                "note": null
            }
        ]
    },
    "2025/01/15": {
        "datePretty": "Wednesday, January 15, 2025",
        "calendar": "Week of 1st Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 20",
                        "readQS": "Psalm+20",
                        "audioQS": "Ps.20"
                    },
                    {
                        "pretty": "Amos 3",
                        "readQS": "Amos+3",
                        "audioQS": "Amos.3"
                    },
                    {
                        "pretty": "1 Corinthians 2",
                        "readQS": "1+Corinthians+2",
                        "audioQS": "1Cor.2"
                    }
                ],
                "readQS": "Psalm+20%3B+Amos+3%3B+1+Corinthians+2",
                "audioQS": "Ps.20,Amos.3,1Cor.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 47",
                        "readQS": "Psalm+47",
                        "audioQS": "Ps.47"
                    },
                    {
                        "pretty": "Genesis 2:4\u2013end",
                        "readQS": "Genesis+2:4-",
                        "audioQS": "Gen.2"
                    },
                    {
                        "pretty": "Matthew 21:33\u2013end",
                        "readQS": "Matthew+21:33-",
                        "audioQS": "Matt.21"
                    }
                ],
                "readQS": "Psalm+47%3B+Genesis+2:4-%3B+Matthew+21:33-",
                "audioQS": "Ps.47,Gen.2,Matt.21",
                "note": null
            }
        ]
    },
    "2025/01/16": {
        "datePretty": "Thursday, January 16, 2025",
        "calendar": "Week of 1st Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 21",
                        "readQS": "Psalm+21",
                        "audioQS": "Ps.21"
                    },
                    {
                        "pretty": "Amos 4",
                        "readQS": "Amos+4",
                        "audioQS": "Amos.4"
                    },
                    {
                        "pretty": "1 Corinthians 3",
                        "readQS": "1+Corinthians+3",
                        "audioQS": "1Cor.3"
                    }
                ],
                "readQS": "Psalm+21%3B+Amos+4%3B+1+Corinthians+3",
                "audioQS": "Ps.21,Amos.4,1Cor.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 61",
                        "readQS": "Psalm+61",
                        "audioQS": "Ps.61"
                    },
                    {
                        "pretty": "Genesis 3",
                        "readQS": "Genesis+3",
                        "audioQS": "Gen.3"
                    },
                    {
                        "pretty": "Matthew 22:1\u201314",
                        "readQS": "Matthew+22:1-14",
                        "audioQS": "Matt.22"
                    }
                ],
                "readQS": "Psalm+61%3B+Genesis+3%3B+Matthew+22:1-14",
                "audioQS": "Ps.61,Gen.3,Matt.22",
                "note": null
            }
        ]
    },
    "2025/01/17": {
        "datePretty": "Friday, January 17, 2025",
        "calendar": "Week of 1st Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 67",
                        "readQS": "Psalm+67",
                        "audioQS": "Ps.67"
                    },
                    {
                        "pretty": "Amos 5:1\u201317",
                        "readQS": "Amos+5:1-17",
                        "audioQS": "Amos.5"
                    },
                    {
                        "pretty": "1 Corinthians 4",
                        "readQS": "1+Corinthians+4",
                        "audioQS": "1Cor.4"
                    }
                ],
                "readQS": "Psalm+67%3B+Amos+5:1-17%3B+1+Corinthians+4",
                "audioQS": "Ps.67,Amos.5,1Cor.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 68",
                        "readQS": "Psalm+68",
                        "audioQS": "Ps.68"
                    },
                    {
                        "pretty": "Genesis 4:1\u201316,4:25\u201326",
                        "readQS": "Genesis+4:1-16,4:25-26",
                        "audioQS": "Gen.4"
                    },
                    {
                        "pretty": "Matthew 22:15\u201333",
                        "readQS": "Matthew+22:15-33",
                        "audioQS": "Matt.22"
                    }
                ],
                "readQS": "Psalm+68%3B+Genesis+4:1-16,4:25-26%3B+Matthew+22:15-33",
                "audioQS": "Ps.68,Gen.4,Matt.22",
                "note": null
            }
        ]
    },
    "2025/01/18": {
        "datePretty": "Saturday, January 18, 2025",
        "calendar": "Week of 1st Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 33",
                        "readQS": "Psalm+33",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "Amos 5:18\u2013end",
                        "readQS": "Amos+5:18-",
                        "audioQS": "Amos.5"
                    },
                    {
                        "pretty": "1 Corinthians 5",
                        "readQS": "1+Corinthians+5",
                        "audioQS": "1Cor.5"
                    }
                ],
                "readQS": "Psalm+33%3B+Amos+5:18-%3B+1+Corinthians+5",
                "audioQS": "Ps.33,Amos.5,1Cor.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "Genesis 6:1\u201310",
                        "readQS": "Genesis+6:1-10",
                        "audioQS": "Gen.6"
                    },
                    {
                        "pretty": "Matthew 22:34\u2013end",
                        "readQS": "Matthew+22:34-",
                        "audioQS": "Matt.22"
                    }
                ],
                "readQS": "Psalm+85%3B+Genesis+6:1-10%3B+Matthew+22:34-",
                "audioQS": "Ps.85,Gen.6,Matt.22",
                "note": null
            }
        ]
    },
    "2025/01/19": {
        "datePretty": "Sunday, January 19, 2025",
        "calendar": "2nd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 36:5\u201310",
                        "readQS": "Psalm+36:5-10",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "Isaiah 62:1\u20135",
                        "readQS": "Isaiah+62:1-5",
                        "audioQS": "Isa.62"
                    },
                    {
                        "pretty": "1 Corinthians 12:1\u201311",
                        "readQS": "1+Corinthians+12:1-11",
                        "audioQS": "1Cor.12"
                    },
                    {
                        "pretty": "John 2:1\u201311",
                        "readQS": "John+2:1-11",
                        "audioQS": "John.2"
                    }
                ],
                "readQS": "Psalm+36:5-10%3B+Isaiah+62:1-5%3B+1+Corinthians+12:1-11%3B+John+2:1-11",
                "audioQS": "Ps.36,Isa.62,1Cor.12,John.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 145:1\u201313",
                        "readQS": "Psalm+145:1-13",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Isaiah 49:1\u20137",
                        "readQS": "Isaiah+49:1-7",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "Acts 16:11\u201315",
                        "readQS": "Acts+16:11-15",
                        "audioQS": "Acts.16"
                    }
                ],
                "readQS": "Psalm+145:1-13%3B+Isaiah+49:1-7%3B+Acts+16:11-15",
                "audioQS": "Ps.145,Isa.49,Acts.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 96",
                        "readQS": "Psalm+96",
                        "audioQS": "Ps.96"
                    },
                    {
                        "pretty": "1 Samuel 3:1\u201320",
                        "readQS": "1+Samuel+3:1-20",
                        "audioQS": "1Sam.3"
                    },
                    {
                        "pretty": "Ephesians 4:1\u201316",
                        "readQS": "Ephesians+4:1-16",
                        "audioQS": "Eph.4"
                    }
                ],
                "readQS": "Psalm+96%3B+1+Samuel+3:1-20%3B+Ephesians+4:1-16",
                "audioQS": "Ps.96,1Sam.3,Eph.4",
                "note": null
            }
        ]
    },
    "2025/01/20": {
        "datePretty": "Monday, January 20, 2025",
        "calendar": "Week of 2nd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 146",
                        "readQS": "Psalm+146",
                        "audioQS": "Ps.146"
                    },
                    {
                        "pretty": "Amos 6",
                        "readQS": "Amos+6",
                        "audioQS": "Amos.6"
                    },
                    {
                        "pretty": "1 Corinthians 6:1\u201311",
                        "readQS": "1+Corinthians+6:1-11",
                        "audioQS": "1Cor.6"
                    }
                ],
                "readQS": "Psalm+146%3B+Amos+6%3B+1+Corinthians+6:1-11",
                "audioQS": "Ps.146,Amos.6,1Cor.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Genesis 6:11\u20137:10",
                        "readQS": "Genesis+6:11-7:10",
                        "audioQS": "Gen.6"
                    },
                    {
                        "pretty": "Matthew 24:1\u201314",
                        "readQS": "Matthew+24:1-14",
                        "audioQS": "Matt.24"
                    }
                ],
                "readQS": "Psalm+71%3B+Genesis+6:11-7:10%3B+Matthew+24:1-14",
                "audioQS": "Ps.71,Gen.6,Matt.24",
                "note": null
            }
        ]
    },
    "2025/01/21": {
        "datePretty": "Tuesday, January 21, 2025",
        "calendar": "Week of 2nd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Amos 7",
                        "readQS": "Amos+7",
                        "audioQS": "Amos.7"
                    },
                    {
                        "pretty": "1 Corinthians 6:12\u2013end",
                        "readQS": "1+Corinthians+6:12-",
                        "audioQS": "1Cor.6"
                    }
                ],
                "readQS": "Psalm+132%3B+Amos+7%3B+1+Corinthians+6:12-",
                "audioQS": "Ps.132,Amos.7,1Cor.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 89:1\u201337",
                        "readQS": "Psalm+89:1-37",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Genesis 7:11\u2013end",
                        "readQS": "Genesis+7:11-",
                        "audioQS": "Gen.7"
                    },
                    {
                        "pretty": "Matthew 24:15\u201328",
                        "readQS": "Matthew+24:15-28",
                        "audioQS": "Matt.24"
                    }
                ],
                "readQS": "Psalm+89:1-37%3B+Genesis+7:11-%3B+Matthew+24:15-28",
                "audioQS": "Ps.89,Gen.7,Matt.24",
                "note": null
            }
        ]
    },
    "2025/01/22": {
        "datePretty": "Wednesday, January 22, 2025",
        "calendar": "Week of 2nd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 81",
                        "readQS": "Psalm+81",
                        "audioQS": "Ps.81"
                    },
                    {
                        "pretty": "Amos 8",
                        "readQS": "Amos+8",
                        "audioQS": "Amos.8"
                    },
                    {
                        "pretty": "1 Corinthians 7:1\u201324",
                        "readQS": "1+Corinthians+7:1-24",
                        "audioQS": "1Cor.7"
                    }
                ],
                "readQS": "Psalm+81%3B+Amos+8%3B+1+Corinthians+7:1-24",
                "audioQS": "Ps.81,Amos.8,1Cor.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Genesis 8:1\u201314",
                        "readQS": "Genesis+8:1-14",
                        "audioQS": "Gen.8"
                    },
                    {
                        "pretty": "Matthew 24:29\u2013end",
                        "readQS": "Matthew+24:29-",
                        "audioQS": "Matt.24"
                    }
                ],
                "readQS": "Psalm+97%3B+Genesis+8:1-14%3B+Matthew+24:29-",
                "audioQS": "Ps.97,Gen.8,Matt.24",
                "note": null
            }
        ]
    },
    "2025/01/23": {
        "datePretty": "Thursday, January 23, 2025",
        "calendar": "Week of 2nd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 76",
                        "readQS": "Psalm+76",
                        "audioQS": "Ps.76"
                    },
                    {
                        "pretty": "Amos 9",
                        "readQS": "Amos+9",
                        "audioQS": "Amos.9"
                    },
                    {
                        "pretty": "1 Corinthians 7:25\u2013end",
                        "readQS": "1+Corinthians+7:25-",
                        "audioQS": "1Cor.7"
                    }
                ],
                "readQS": "Psalm+76%3B+Amos+9%3B+1+Corinthians+7:25-",
                "audioQS": "Ps.76,Amos.9,1Cor.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 111",
                        "readQS": "Psalm+111",
                        "audioQS": "Ps.111"
                    },
                    {
                        "pretty": "Genesis 8:15\u20139:7",
                        "readQS": "Genesis+8:15-9:7",
                        "audioQS": "Gen.8"
                    },
                    {
                        "pretty": "Matthew 25:1\u201313",
                        "readQS": "Matthew+25:1-13",
                        "audioQS": "Matt.25"
                    }
                ],
                "readQS": "Psalm+111%3B+Genesis+8:15-9:7%3B+Matthew+25:1-13",
                "audioQS": "Ps.111,Gen.8,Matt.25",
                "note": null
            }
        ]
    },
    "2025/01/24": {
        "datePretty": "Friday, January 24, 2025",
        "calendar": "Week of 2nd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 27",
                        "readQS": "Psalm+27",
                        "audioQS": "Ps.27"
                    },
                    {
                        "pretty": "Hosea 1:1\u20132:1",
                        "readQS": "Hosea+1:1-2:1",
                        "audioQS": "Hos.1"
                    },
                    {
                        "pretty": "1 Corinthians 8",
                        "readQS": "1+Corinthians+8",
                        "audioQS": "1Cor.8"
                    }
                ],
                "readQS": "Psalm+27%3B+Hosea+1:1-2:1%3B+1+Corinthians+8",
                "audioQS": "Ps.27,Hos.1,1Cor.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Conversion of St Paul)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 149",
                        "readQS": "Psalm+149",
                        "audioQS": "Ps.149"
                    },
                    {
                        "pretty": "Isaiah 49:1\u201313",
                        "readQS": "Isaiah+49:1-13",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "Acts 22:3\u201316",
                        "readQS": "Acts+22:3-16",
                        "audioQS": "Acts.22"
                    }
                ],
                "readQS": "Psalm+149%3B+Isaiah+49:1-13%3B+Acts+22:3-16",
                "audioQS": "Ps.149,Isa.49,Acts.22",
                "note": null
            }
        ]
    },
    "2025/01/25": {
        "datePretty": "Saturday, January 25, 2025",
        "calendar": "The Conversion of Saint Paul",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 66,147:12\u201320",
                        "readQS": "Psalm+66,147:12-20",
                        "audioQS": "Ps.66,147"
                    },
                    {
                        "pretty": "Ezekiel 3:22\u201327",
                        "readQS": "Ezekiel+3:22-27",
                        "audioQS": "Ezek.3"
                    },
                    {
                        "pretty": "Philippians 3:1\u201314",
                        "readQS": "Philippians+3:1-14",
                        "audioQS": "Phil.3"
                    }
                ],
                "readQS": "Psalm+66,147:12-20%3B+Ezekiel+3:22-27%3B+Philippians+3:1-14",
                "audioQS": "Ps.66,147,Ezek.3,Phil.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:41\u201356",
                        "readQS": "Psalm+119:41-56",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Isaiah 56:1\u20138",
                        "readQS": "Isaiah+56:1-8",
                        "audioQS": "Isa.56"
                    },
                    {
                        "pretty": "Colossians 1:24\u2013end,2:1\u20137",
                        "readQS": "Colossians+1:24-,2:1-7",
                        "audioQS": "Col.1"
                    }
                ],
                "readQS": "Psalm+119:41-56%3B+Isaiah+56:1-8%3B+Colossians+1:24-,2:1-7",
                "audioQS": "Ps.119,Isa.56,Col.1",
                "note": null
            }
        ]
    },
    "2025/01/26": {
        "datePretty": "Sunday, January 26, 2025",
        "calendar": "3rd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 19",
                        "readQS": "Psalm+19",
                        "audioQS": "Ps.19"
                    },
                    {
                        "pretty": "Nehemiah 8:1\u20133,5\u20136,8\u201310",
                        "readQS": "Nehemiah+8:1-3,5-6,8-10",
                        "audioQS": "Neh.8"
                    },
                    {
                        "pretty": "1 Corinthians 12:12\u201331a",
                        "readQS": "1+Corinthians+12:12-31a",
                        "audioQS": "1Cor.12"
                    },
                    {
                        "pretty": "Luke 4:14\u201321",
                        "readQS": "Luke+4:14-21",
                        "audioQS": "Luke.4"
                    }
                ],
                "readQS": "Psalm+19%3B+Nehemiah+8:1-3,5-6,8-10%3B+1+Corinthians+12:12-31a%3B+Luke+4:14-21",
                "audioQS": "Ps.19,Neh.8,1Cor.12,Luke.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 113",
                        "readQS": "Psalm+113",
                        "audioQS": "Ps.113"
                    },
                    {
                        "pretty": "Deuteronomy 30:11\u201315",
                        "readQS": "Deuteronomy+30:11-15",
                        "audioQS": "Deut.30"
                    },
                    {
                        "pretty": "3 John 1,5\u20138",
                        "readQS": "3+John+1,5-8",
                        "audioQS": "3John.1,5\u20138"
                    }
                ],
                "readQS": "Psalm+113%3B+Deuteronomy+30:11-15%3B+3+John+1,5-8",
                "audioQS": "Ps.113,Deut.30,3John.1,5\u20138",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 33",
                        "readQS": "Psalm+33",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "Numbers 9:15\u201323",
                        "readQS": "Numbers+9:15-23",
                        "audioQS": "Num.9"
                    },
                    {
                        "pretty": "1 Corinthians 7:17\u201324",
                        "readQS": "1+Corinthians+7:17-24",
                        "audioQS": "1Cor.7"
                    }
                ],
                "readQS": "Psalm+33%3B+Numbers+9:15-23%3B+1+Corinthians+7:17-24",
                "audioQS": "Ps.33,Num.9,1Cor.7",
                "note": null
            }
        ]
    },
    "2025/01/27": {
        "datePretty": "Monday, January 27, 2025",
        "calendar": "Week of 3rd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 108",
                        "readQS": "Psalm+108",
                        "audioQS": "Ps.108"
                    },
                    {
                        "pretty": "Hosea 2:18\u2013end",
                        "readQS": "Hosea+2:18-",
                        "audioQS": "Hos.2"
                    },
                    {
                        "pretty": "Hosea 3",
                        "readQS": "Hosea+3",
                        "audioQS": "Hos.3"
                    },
                    {
                        "pretty": "1 Corinthians 9:15\u2013end",
                        "readQS": "1+Corinthians+9:15-",
                        "audioQS": "1Cor.9"
                    }
                ],
                "readQS": "Psalm+108%3B+Hosea+2:18-%3B+Hosea+3%3B+1+Corinthians+9:15-",
                "audioQS": "Ps.108,Hos.2,Hos.3,1Cor.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 138",
                        "readQS": "Psalm+138",
                        "audioQS": "Ps.138"
                    },
                    {
                        "pretty": "Genesis 11:27\u201312:9",
                        "readQS": "Genesis+11:27-12:9",
                        "audioQS": "Gen.11"
                    },
                    {
                        "pretty": "Matthew 26:1\u201316",
                        "readQS": "Matthew+26:1-16",
                        "audioQS": "Matt.26"
                    }
                ],
                "readQS": "Psalm+138%3B+Genesis+11:27-12:9%3B+Matthew+26:1-16",
                "audioQS": "Ps.138,Gen.11,Matt.26",
                "note": null
            }
        ]
    },
    "2025/01/28": {
        "datePretty": "Tuesday, January 28, 2025",
        "calendar": "Week of 3rd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 36",
                        "readQS": "Psalm+36",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "Hosea 4:1\u201316",
                        "readQS": "Hosea+4:1-16",
                        "audioQS": "Hos.4"
                    },
                    {
                        "pretty": "1 Corinthians 10:1\u201313",
                        "readQS": "1+Corinthians+10:1-13",
                        "audioQS": "1Cor.10"
                    }
                ],
                "readQS": "Psalm+36%3B+Hosea+4:1-16%3B+1+Corinthians+10:1-13",
                "audioQS": "Ps.36,Hos.4,1Cor.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Genesis 13:2\u2013end",
                        "readQS": "Genesis+13:2-",
                        "audioQS": "Gen.13"
                    },
                    {
                        "pretty": "Matthew 26:17\u201335",
                        "readQS": "Matthew+26:17-35",
                        "audioQS": "Matt.26"
                    }
                ],
                "readQS": "Psalm+145%3B+Genesis+13:2-%3B+Matthew+26:17-35",
                "audioQS": "Ps.145,Gen.13,Matt.26",
                "note": null
            }
        ]
    },
    "2025/01/29": {
        "datePretty": "Wednesday, January 29, 2025",
        "calendar": "Week of 3rd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 46",
                        "readQS": "Psalm+46",
                        "audioQS": "Ps.46"
                    },
                    {
                        "pretty": "Hosea 5:1\u20137",
                        "readQS": "Hosea+5:1-7",
                        "audioQS": "Hos.5"
                    },
                    {
                        "pretty": "1 Corinthians 10:14\u201311:1",
                        "readQS": "1+Corinthians+10:14-11:1",
                        "audioQS": "1Cor.10"
                    }
                ],
                "readQS": "Psalm+46%3B+Hosea+5:1-7%3B+1+Corinthians+10:14-11:1",
                "audioQS": "Ps.46,Hos.5,1Cor.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 29",
                        "readQS": "Psalm+29",
                        "audioQS": "Ps.29"
                    },
                    {
                        "pretty": "Genesis 14",
                        "readQS": "Genesis+14",
                        "audioQS": "Gen.14"
                    },
                    {
                        "pretty": "Matthew 26:36\u201346",
                        "readQS": "Matthew+26:36-46",
                        "audioQS": "Matt.26"
                    }
                ],
                "readQS": "Psalm+29%3B+Genesis+14%3B+Matthew+26:36-46",
                "audioQS": "Ps.29,Gen.14,Matt.26",
                "note": null
            }
        ]
    },
    "2025/01/30": {
        "datePretty": "Thursday, January 30, 2025",
        "calendar": "Week of 3rd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 47",
                        "readQS": "Psalm+47",
                        "audioQS": "Ps.47"
                    },
                    {
                        "pretty": "Hosea 5:8\u20136:6",
                        "readQS": "Hosea+5:8-6:6",
                        "audioQS": "Hos.5"
                    },
                    {
                        "pretty": "1 Corinthians 11:2\u201316",
                        "readQS": "1+Corinthians+11:2-16",
                        "audioQS": "1Cor.11"
                    }
                ],
                "readQS": "Psalm+47%3B+Hosea+5:8-6:6%3B+1+Corinthians+11:2-16",
                "audioQS": "Ps.47,Hos.5,1Cor.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 24",
                        "readQS": "Psalm+24",
                        "audioQS": "Ps.24"
                    },
                    {
                        "pretty": "Genesis 15",
                        "readQS": "Genesis+15",
                        "audioQS": "Gen.15"
                    },
                    {
                        "pretty": "Matthew 26:47\u201356",
                        "readQS": "Matthew+26:47-56",
                        "audioQS": "Matt.26"
                    }
                ],
                "readQS": "Psalm+24%3B+Genesis+15%3B+Matthew+26:47-56",
                "audioQS": "Ps.24,Gen.15,Matt.26",
                "note": null
            }
        ]
    },
    "2025/01/31": {
        "datePretty": "Friday, January 31, 2025",
        "calendar": "Week of 3rd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 65",
                        "readQS": "Psalm+65",
                        "audioQS": "Ps.65"
                    },
                    {
                        "pretty": "Hosea 6:7\u20137:2",
                        "readQS": "Hosea+6:7-7:2",
                        "audioQS": "Hos.6"
                    },
                    {
                        "pretty": "1 Corinthians 11:17\u2013end",
                        "readQS": "1+Corinthians+11:17-",
                        "audioQS": "1Cor.11"
                    }
                ],
                "readQS": "Psalm+65%3B+Hosea+6:7-7:2%3B+1+Corinthians+11:17-",
                "audioQS": "Ps.65,Hos.6,1Cor.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 67",
                        "readQS": "Psalm+67",
                        "audioQS": "Ps.67"
                    },
                    {
                        "pretty": "Genesis 16",
                        "readQS": "Genesis+16",
                        "audioQS": "Gen.16"
                    },
                    {
                        "pretty": "Matthew 26:57\u2013end",
                        "readQS": "Matthew+26:57-",
                        "audioQS": "Matt.26"
                    }
                ],
                "readQS": "Psalm+67%3B+Genesis+16%3B+Matthew+26:57-",
                "audioQS": "Ps.67,Gen.16,Matt.26",
                "note": null
            }
        ]
    },
    "2025/02/01": {
        "datePretty": "Saturday, February 1, 2025",
        "calendar": "Week of 3rd Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 68",
                        "readQS": "Psalm+68",
                        "audioQS": "Ps.68"
                    },
                    {
                        "pretty": "Hosea 8",
                        "readQS": "Hosea+8",
                        "audioQS": "Hos.8"
                    },
                    {
                        "pretty": "1 Corinthians 12:1\u201311",
                        "readQS": "1+Corinthians+12:1-11",
                        "audioQS": "1Cor.12"
                    }
                ],
                "readQS": "Psalm+68%3B+Hosea+8%3B+1+Corinthians+12:1-11",
                "audioQS": "Ps.68,Hos.8,1Cor.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Presentation)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 118",
                        "readQS": "Psalm+118",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "1 Samuel 1:19b\u201328",
                        "readQS": "1+Samuel+1:19b-28",
                        "audioQS": "1Sam.1"
                    },
                    {
                        "pretty": "Hebrews 4:11\u201316",
                        "readQS": "Hebrews+4:11-16",
                        "audioQS": "Heb.4"
                    }
                ],
                "readQS": "Psalm+118%3B+1+Samuel+1:19b-28%3B+Hebrews+4:11-16",
                "audioQS": "Ps.118,1Sam.1,Heb.4",
                "note": null
            }
        ]
    },
    "2025/02/02": {
        "datePretty": "Sunday, February 2, 2025",
        "calendar": "Presentation of Jesus at the Temple",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 24:(1\u20136),7\u201310",
                        "readQS": "Psalm+24:%281-6%29,7-10",
                        "audioQS": "Ps.24"
                    },
                    {
                        "pretty": "Malachi 3.1\u20135",
                        "readQS": "Malachi+3.1-5",
                        "audioQS": "Mal.3.1\u20135"
                    },
                    {
                        "pretty": "Hebrews 2:14\u201318",
                        "readQS": "Hebrews+2:14-18",
                        "audioQS": "Heb.2"
                    },
                    {
                        "pretty": "Luke 2:22\u201340",
                        "readQS": "Luke+2:22-40",
                        "audioQS": "Luke.2"
                    }
                ],
                "readQS": "Psalm+24:%281-6%29,7-10%3B+Malachi+3.1-5%3B+Hebrews+2:14-18%3B+Luke+2:22-40",
                "audioQS": "Ps.24,Mal.3.1\u20135,Heb.2,Luke.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 48,146",
                        "readQS": "Psalm+48,146",
                        "audioQS": "Ps.48,146"
                    },
                    {
                        "pretty": "Exodus 13:1\u201316",
                        "readQS": "Exodus+13:1-16",
                        "audioQS": "Exod.13"
                    },
                    {
                        "pretty": "Romans 12:1\u20135",
                        "readQS": "Romans+12:1-5",
                        "audioQS": "Rom.12"
                    }
                ],
                "readQS": "Psalm+48,146%3B+Exodus+13:1-16%3B+Romans+12:1-5",
                "audioQS": "Ps.48,146,Exod.13,Rom.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 122,132",
                        "readQS": "Psalm+122,132",
                        "audioQS": "Ps.122,132"
                    },
                    {
                        "pretty": "Haggai 2:1\u20139",
                        "readQS": "Haggai+2:1-9",
                        "audioQS": "Hag.2"
                    },
                    {
                        "pretty": "John 2:18\u201322",
                        "readQS": "John+2:18-22",
                        "audioQS": "John.2"
                    }
                ],
                "readQS": "Psalm+122,132%3B+Haggai+2:1-9%3B+John+2:18-22",
                "audioQS": "Ps.122,132,Hag.2,John.2",
                "note": null
            }
        ]
    },
    "2025/02/03": {
        "datePretty": "Monday, February 3, 2025",
        "calendar": "Week of 4th Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 1",
                        "readQS": "Psalm+1",
                        "audioQS": "Ps.1"
                    },
                    {
                        "pretty": "Hosea 9",
                        "readQS": "Hosea+9",
                        "audioQS": "Hos.9"
                    },
                    {
                        "pretty": "1 Corinthians 12:12\u2013end",
                        "readQS": "1+Corinthians+12:12-",
                        "audioQS": "1Cor.12"
                    }
                ],
                "readQS": "Psalm+1%3B+Hosea+9%3B+1+Corinthians+12:12-",
                "audioQS": "Ps.1,Hos.9,1Cor.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 4",
                        "readQS": "Psalm+4",
                        "audioQS": "Ps.4"
                    },
                    {
                        "pretty": "Genesis 18:1\u201315",
                        "readQS": "Genesis+18:1-15",
                        "audioQS": "Gen.18"
                    },
                    {
                        "pretty": "Matthew 27:11\u201326",
                        "readQS": "Matthew+27:11-26",
                        "audioQS": "Matt.27"
                    }
                ],
                "readQS": "Psalm+4%3B+Genesis+18:1-15%3B+Matthew+27:11-26",
                "audioQS": "Ps.4,Gen.18,Matt.27",
                "note": null
            }
        ]
    },
    "2025/02/04": {
        "datePretty": "Tuesday, February 4, 2025",
        "calendar": "Week of 4th Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 5",
                        "readQS": "Psalm+5",
                        "audioQS": "Ps.5"
                    },
                    {
                        "pretty": "Hosea 10",
                        "readQS": "Hosea+10",
                        "audioQS": "Hos.10"
                    },
                    {
                        "pretty": "1 Corinthians 13",
                        "readQS": "1+Corinthians+13",
                        "audioQS": "1Cor.13"
                    }
                ],
                "readQS": "Psalm+5%3B+Hosea+10%3B+1+Corinthians+13",
                "audioQS": "Ps.5,Hos.10,1Cor.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 9",
                        "readQS": "Psalm+9",
                        "audioQS": "Ps.9"
                    },
                    {
                        "pretty": "Genesis 18:16\u2013end",
                        "readQS": "Genesis+18:16-",
                        "audioQS": "Gen.18"
                    },
                    {
                        "pretty": "Matthew 27:27\u201344",
                        "readQS": "Matthew+27:27-44",
                        "audioQS": "Matt.27"
                    }
                ],
                "readQS": "Psalm+9%3B+Genesis+18:16-%3B+Matthew+27:27-44",
                "audioQS": "Ps.9,Gen.18,Matt.27",
                "note": null
            }
        ]
    },
    "2025/02/05": {
        "datePretty": "Wednesday, February 5, 2025",
        "calendar": "Week of 4th Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:1\u201332",
                        "readQS": "Psalm+119:1-32",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Hosea 11:1\u201311",
                        "readQS": "Hosea+11:1-11",
                        "audioQS": "Hos.11"
                    },
                    {
                        "pretty": "1 Corinthians 14:1\u201319",
                        "readQS": "1+Corinthians+14:1-19",
                        "audioQS": "1Cor.14"
                    }
                ],
                "readQS": "Psalm+119:1-32%3B+Hosea+11:1-11%3B+1+Corinthians+14:1-19",
                "audioQS": "Ps.119,Hos.11,1Cor.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 11",
                        "readQS": "Psalm+11",
                        "audioQS": "Ps.11"
                    },
                    {
                        "pretty": "Genesis 19:1\u20133,19:12\u201329",
                        "readQS": "Genesis+19:1-3,19:12-29",
                        "audioQS": "Gen.19"
                    },
                    {
                        "pretty": "Matthew 27:45\u201356",
                        "readQS": "Matthew+27:45-56",
                        "audioQS": "Matt.27"
                    }
                ],
                "readQS": "Psalm+11%3B+Genesis+19:1-3,19:12-29%3B+Matthew+27:45-56",
                "audioQS": "Ps.11,Gen.19,Matt.27",
                "note": null
            }
        ]
    },
    "2025/02/06": {
        "datePretty": "Thursday, February 6, 2025",
        "calendar": "Week of 4th Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 15",
                        "readQS": "Psalm+15",
                        "audioQS": "Ps.15"
                    },
                    {
                        "pretty": "Hosea 11:12\u2013end",
                        "readQS": "Hosea+11:12-",
                        "audioQS": "Hos.11"
                    },
                    {
                        "pretty": "Hosea 12",
                        "readQS": "Hosea+12",
                        "audioQS": "Hos.12"
                    },
                    {
                        "pretty": "1 Corinthians 14:20\u2013end",
                        "readQS": "1+Corinthians+14:20-",
                        "audioQS": "1Cor.14"
                    }
                ],
                "readQS": "Psalm+15%3B+Hosea+11:12-%3B+Hosea+12%3B+1+Corinthians+14:20-",
                "audioQS": "Ps.15,Hos.11,Hos.12,1Cor.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 18",
                        "readQS": "Psalm+18",
                        "audioQS": "Ps.18"
                    },
                    {
                        "pretty": "Genesis 21:1\u201321",
                        "readQS": "Genesis+21:1-21",
                        "audioQS": "Gen.21"
                    },
                    {
                        "pretty": "Matthew 27:57\u2013end",
                        "readQS": "Matthew+27:57-",
                        "audioQS": "Matt.27"
                    }
                ],
                "readQS": "Psalm+18%3B+Genesis+21:1-21%3B+Matthew+27:57-",
                "audioQS": "Ps.18,Gen.21,Matt.27",
                "note": null
            }
        ]
    },
    "2025/02/07": {
        "datePretty": "Friday, February 7, 2025",
        "calendar": "Week of 4th Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 19",
                        "readQS": "Psalm+19",
                        "audioQS": "Ps.19"
                    },
                    {
                        "pretty": "Hosea 13:1\u201314",
                        "readQS": "Hosea+13:1-14",
                        "audioQS": "Hos.13"
                    },
                    {
                        "pretty": "1 Corinthians 16:1\u20139",
                        "readQS": "1+Corinthians+16:1-9",
                        "audioQS": "1Cor.16"
                    }
                ],
                "readQS": "Psalm+19%3B+Hosea+13:1-14%3B+1+Corinthians+16:1-9",
                "audioQS": "Ps.19,Hos.13,1Cor.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 22",
                        "readQS": "Psalm+22",
                        "audioQS": "Ps.22"
                    },
                    {
                        "pretty": "Genesis 22:1\u201319",
                        "readQS": "Genesis+22:1-19",
                        "audioQS": "Gen.22"
                    },
                    {
                        "pretty": "Matthew 28:1\u201315",
                        "readQS": "Matthew+28:1-15",
                        "audioQS": "Matt.28"
                    }
                ],
                "readQS": "Psalm+22%3B+Genesis+22:1-19%3B+Matthew+28:1-15",
                "audioQS": "Ps.22,Gen.22,Matt.28",
                "note": null
            }
        ]
    },
    "2025/02/08": {
        "datePretty": "Saturday, February 8, 2025",
        "calendar": "Week of 4th Sunday after the Epiphany",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 23",
                        "readQS": "Psalm+23",
                        "audioQS": "Ps.23"
                    },
                    {
                        "pretty": "Hosea 14",
                        "readQS": "Hosea+14",
                        "audioQS": "Hos.14"
                    },
                    {
                        "pretty": "1 Corinthians 16:10\u2013end",
                        "readQS": "1+Corinthians+16:10-",
                        "audioQS": "1Cor.16"
                    }
                ],
                "readQS": "Psalm+23%3B+Hosea+14%3B+1+Corinthians+16:10-",
                "audioQS": "Ps.23,Hos.14,1Cor.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 24",
                        "readQS": "Psalm+24",
                        "audioQS": "Ps.24"
                    },
                    {
                        "pretty": "Genesis 23",
                        "readQS": "Genesis+23",
                        "audioQS": "Gen.23"
                    },
                    {
                        "pretty": "Matthew 28:16\u2013end",
                        "readQS": "Matthew+28:16-",
                        "audioQS": "Matt.28"
                    }
                ],
                "readQS": "Psalm+24%3B+Genesis+23%3B+Matthew+28:16-",
                "audioQS": "Ps.24,Gen.23,Matt.28",
                "note": null
            }
        ]
    },
    "2025/02/09": {
        "datePretty": "Sunday, February 9, 2025",
        "calendar": "4th Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 138",
                        "readQS": "Psalm+138",
                        "audioQS": "Ps.138"
                    },
                    {
                        "pretty": "Isaiah 6:1\u20138,(9\u201313)",
                        "readQS": "Isaiah+6:1-8,%289-13%29",
                        "audioQS": "Isa.6"
                    },
                    {
                        "pretty": "1 Corinthians 15:1\u201311",
                        "readQS": "1+Corinthians+15:1-11",
                        "audioQS": "1Cor.15"
                    },
                    {
                        "pretty": "Luke 5:1\u201311",
                        "readQS": "Luke+5:1-11",
                        "audioQS": "Luke.5"
                    }
                ],
                "readQS": "Psalm+138%3B+Isaiah+6:1-8,%289-13%29%3B+1+Corinthians+15:1-11%3B+Luke+5:1-11",
                "audioQS": "Ps.138,Isa.6,1Cor.15,Luke.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 3,4",
                        "readQS": "Psalm+3,4",
                        "audioQS": "Ps.3,4"
                    },
                    {
                        "pretty": "Jeremiah 26:1\u201316",
                        "readQS": "Jeremiah+26:1-16",
                        "audioQS": "Jer.26"
                    },
                    {
                        "pretty": "Acts 3:1\u201310",
                        "readQS": "Acts+3:1-10",
                        "audioQS": "Acts.3"
                    }
                ],
                "readQS": "Psalm+3,4%3B+Jeremiah+26:1-16%3B+Acts+3:1-10",
                "audioQS": "Ps.3,4,Jer.26,Acts.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 2",
                        "readQS": "Psalm+2",
                        "audioQS": "Ps.2"
                    },
                    {
                        "pretty": "Hosea 1",
                        "readQS": "Hosea+1",
                        "audioQS": "Hos.1"
                    },
                    {
                        "pretty": "Colossians 3:1\u201322",
                        "readQS": "Colossians+3:1-22",
                        "audioQS": "Col.3"
                    }
                ],
                "readQS": "Psalm+2%3B+Hosea+1%3B+Colossians+3:1-22",
                "audioQS": "Ps.2,Hos.1,Col.3",
                "note": null
            }
        ]
    },
    "2025/02/10": {
        "datePretty": "Monday, February 10, 2025",
        "calendar": "Week of 4th Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 30",
                        "readQS": "Psalm+30",
                        "audioQS": "Ps.30"
                    },
                    {
                        "pretty": "Joel 1:1\u201314",
                        "readQS": "Joel+1:1-14",
                        "audioQS": "Joel.1"
                    },
                    {
                        "pretty": "John 15:1\u201311",
                        "readQS": "John+15:1-11",
                        "audioQS": "John.15"
                    }
                ],
                "readQS": "Psalm+30%3B+Joel+1:1-14%3B+John+15:1-11",
                "audioQS": "Ps.30,Joel.1,John.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 28",
                        "readQS": "Psalm+28",
                        "audioQS": "Ps.28"
                    },
                    {
                        "pretty": "Leviticus 19:1\u201318,19:30\u2013end",
                        "readQS": "Leviticus+19:1-18,19:30-",
                        "audioQS": "Lev.19"
                    },
                    {
                        "pretty": "1 Timothy 1:1\u201317",
                        "readQS": "1+Timothy+1:1-17",
                        "audioQS": "1Tim.1"
                    }
                ],
                "readQS": "Psalm+28%3B+Leviticus+19:1-18,19:30-%3B+1+Timothy+1:1-17",
                "audioQS": "Ps.28,Lev.19,1Tim.1",
                "note": null
            }
        ]
    },
    "2025/02/11": {
        "datePretty": "Tuesday, February 11, 2025",
        "calendar": "Week of 4th Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 36",
                        "readQS": "Psalm+36",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "Joel 1:15\u2013end",
                        "readQS": "Joel+1:15-",
                        "audioQS": "Joel.1"
                    },
                    {
                        "pretty": "John 15:12\u201317",
                        "readQS": "John+15:12-17",
                        "audioQS": "John.15"
                    }
                ],
                "readQS": "Psalm+36%3B+Joel+1:15-%3B+John+15:12-17",
                "audioQS": "Ps.36,Joel.1,John.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 33",
                        "readQS": "Psalm+33",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "Leviticus 23:1\u201322",
                        "readQS": "Leviticus+23:1-22",
                        "audioQS": "Lev.23"
                    },
                    {
                        "pretty": "1 Timothy 1:18\u2013end",
                        "readQS": "1+Timothy+1:18-",
                        "audioQS": "1Tim.1"
                    },
                    {
                        "pretty": "1 Timothy 2",
                        "readQS": "1+Timothy+2",
                        "audioQS": "1Tim.2"
                    }
                ],
                "readQS": "Psalm+33%3B+Leviticus+23:1-22%3B+1+Timothy+1:18-%3B+1+Timothy+2",
                "audioQS": "Ps.33,Lev.23,1Tim.1,1Tim.2",
                "note": null
            }
        ]
    },
    "2025/02/12": {
        "datePretty": "Wednesday, February 12, 2025",
        "calendar": "Week of 4th Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 34",
                        "readQS": "Psalm+34",
                        "audioQS": "Ps.34"
                    },
                    {
                        "pretty": "Joel 2:1\u201317",
                        "readQS": "Joel+2:1-17",
                        "audioQS": "Joel.2"
                    },
                    {
                        "pretty": "John 15:18\u2013end",
                        "readQS": "John+15:18-",
                        "audioQS": "John.15"
                    }
                ],
                "readQS": "Psalm+34%3B+Joel+2:1-17%3B+John+15:18-",
                "audioQS": "Ps.34,Joel.2,John.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:33\u201356",
                        "readQS": "Psalm+119:33-56",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Leviticus 23:23\u2013end",
                        "readQS": "Leviticus+23:23-",
                        "audioQS": "Lev.23"
                    },
                    {
                        "pretty": "1 Timothy 3",
                        "readQS": "1+Timothy+3",
                        "audioQS": "1Tim.3"
                    }
                ],
                "readQS": "Psalm+119:33-56%3B+Leviticus+23:23-%3B+1+Timothy+3",
                "audioQS": "Ps.119,Lev.23,1Tim.3",
                "note": null
            }
        ]
    },
    "2025/02/13": {
        "datePretty": "Thursday, February 13, 2025",
        "calendar": "Week of 4th Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 37",
                        "readQS": "Psalm+37",
                        "audioQS": "Ps.37"
                    },
                    {
                        "pretty": "Joel 2:18\u201327",
                        "readQS": "Joel+2:18-27",
                        "audioQS": "Joel.2"
                    },
                    {
                        "pretty": "John 16:1\u201315",
                        "readQS": "John+16:1-15",
                        "audioQS": "John.16"
                    }
                ],
                "readQS": "Psalm+37%3B+Joel+2:18-27%3B+John+16:1-15",
                "audioQS": "Ps.37,Joel.2,John.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Leviticus 24:1\u20139",
                        "readQS": "Leviticus+24:1-9",
                        "audioQS": "Lev.24"
                    },
                    {
                        "pretty": "1 Timothy 4",
                        "readQS": "1+Timothy+4",
                        "audioQS": "1Tim.4"
                    }
                ],
                "readQS": "Psalm+40%3B+Leviticus+24:1-9%3B+1+Timothy+4",
                "audioQS": "Ps.40,Lev.24,1Tim.4",
                "note": null
            }
        ]
    },
    "2025/02/14": {
        "datePretty": "Friday, February 14, 2025",
        "calendar": "Week of 4th Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 31",
                        "readQS": "Psalm+31",
                        "audioQS": "Ps.31"
                    },
                    {
                        "pretty": "Joel 2:28\u2013end",
                        "readQS": "Joel+2:28-",
                        "audioQS": "Joel.2"
                    },
                    {
                        "pretty": "John 16:16\u201322",
                        "readQS": "John+16:16-22",
                        "audioQS": "John.16"
                    }
                ],
                "readQS": "Psalm+31%3B+Joel+2:28-%3B+John+16:16-22",
                "audioQS": "Ps.31,Joel.2,John.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 35",
                        "readQS": "Psalm+35",
                        "audioQS": "Ps.35"
                    },
                    {
                        "pretty": "Leviticus 25:1\u201324",
                        "readQS": "Leviticus+25:1-24",
                        "audioQS": "Lev.25"
                    },
                    {
                        "pretty": "1 Timothy 5:1\u201316",
                        "readQS": "1+Timothy+5:1-16",
                        "audioQS": "1Tim.5"
                    }
                ],
                "readQS": "Psalm+35%3B+Leviticus+25:1-24%3B+1+Timothy+5:1-16",
                "audioQS": "Ps.35,Lev.25,1Tim.5",
                "note": null
            }
        ]
    },
    "2025/02/15": {
        "datePretty": "Saturday, February 15, 2025",
        "calendar": "Week of 4th Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 42",
                        "readQS": "Psalm+42",
                        "audioQS": "Ps.42"
                    },
                    {
                        "pretty": "Joel 3:1\u20133,3:9\u2013end",
                        "readQS": "Joel+3:1-3,3:9-",
                        "audioQS": "Joel.3"
                    },
                    {
                        "pretty": "John 16:23\u2013end",
                        "readQS": "John+16:23-",
                        "audioQS": "John.16"
                    }
                ],
                "readQS": "Psalm+42%3B+Joel+3:1-3,3:9-%3B+John+16:23-",
                "audioQS": "Ps.42,Joel.3,John.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 46",
                        "readQS": "Psalm+46",
                        "audioQS": "Ps.46"
                    },
                    {
                        "pretty": "Numbers 6:1\u20135,6:21\u2013end",
                        "readQS": "Numbers+6:1-5,6:21-",
                        "audioQS": "Num.6"
                    },
                    {
                        "pretty": "1 Timothy 5:17\u2013end",
                        "readQS": "1+Timothy+5:17-",
                        "audioQS": "1Tim.5"
                    }
                ],
                "readQS": "Psalm+46%3B+Numbers+6:1-5,6:21-%3B+1+Timothy+5:17-",
                "audioQS": "Ps.46,Num.6,1Tim.5",
                "note": null
            }
        ]
    },
    "2025/02/16": {
        "datePretty": "Sunday, February 16, 2025",
        "calendar": "3rd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 1",
                        "readQS": "Psalm+1",
                        "audioQS": "Ps.1"
                    },
                    {
                        "pretty": "Jeremiah 17:5\u201310",
                        "readQS": "Jeremiah+17:5-10",
                        "audioQS": "Jer.17"
                    },
                    {
                        "pretty": "1 Corinthians 15:12\u201320",
                        "readQS": "1+Corinthians+15:12-20",
                        "audioQS": "1Cor.15"
                    },
                    {
                        "pretty": "Luke 6:17\u201326",
                        "readQS": "Luke+6:17-26",
                        "audioQS": "Luke.6"
                    }
                ],
                "readQS": "Psalm+1%3B+Jeremiah+17:5-10%3B+1+Corinthians+15:12-20%3B+Luke+6:17-26",
                "audioQS": "Ps.1,Jer.17,1Cor.15,Luke.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 7",
                        "readQS": "Psalm+7",
                        "audioQS": "Ps.7"
                    },
                    {
                        "pretty": "Jeremiah 30:1\u20133,10\u201322",
                        "readQS": "Jeremiah+30:1-3,10-22",
                        "audioQS": "Jer.30"
                    },
                    {
                        "pretty": "Acts 6",
                        "readQS": "Acts+6",
                        "audioQS": "Acts.6"
                    }
                ],
                "readQS": "Psalm+7%3B+Jeremiah+30:1-3,10-22%3B+Acts+6",
                "audioQS": "Ps.7,Jer.30,Acts.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 6",
                        "readQS": "Psalm+6",
                        "audioQS": "Ps.6"
                    },
                    {
                        "pretty": "Hosea 10:1\u20138,12",
                        "readQS": "Hosea+10:1-8,12",
                        "audioQS": "Hos.10"
                    },
                    {
                        "pretty": "Galatians 4:8\u201320",
                        "readQS": "Galatians+4:8-20",
                        "audioQS": "Gal.4"
                    }
                ],
                "readQS": "Psalm+6%3B+Hosea+10:1-8,12%3B+Galatians+4:8-20",
                "audioQS": "Ps.6,Hos.10,Gal.4",
                "note": null
            }
        ]
    },
    "2025/02/17": {
        "datePretty": "Monday, February 17, 2025",
        "calendar": "Week of 3rd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "Ecclesiastes 1",
                        "readQS": "Ecclesiastes+1",
                        "audioQS": "Eccl.1"
                    },
                    {
                        "pretty": "John 17:1\u20135",
                        "readQS": "John+17:1-5",
                        "audioQS": "John.17"
                    }
                ],
                "readQS": "Psalm+44%3B+Ecclesiastes+1%3B+John+17:1-5",
                "audioQS": "Ps.44,Eccl.1,John.17",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 47",
                        "readQS": "Psalm+47",
                        "audioQS": "Ps.47"
                    },
                    {
                        "pretty": "Genesis 24:1\u201328",
                        "readQS": "Genesis+24:1-28",
                        "audioQS": "Gen.24"
                    },
                    {
                        "pretty": "1 Timothy 6:1\u201310",
                        "readQS": "1+Timothy+6:1-10",
                        "audioQS": "1Tim.6"
                    }
                ],
                "readQS": "Psalm+47%3B+Genesis+24:1-28%3B+1+Timothy+6:1-10",
                "audioQS": "Ps.47,Gen.24,1Tim.6",
                "note": null
            }
        ]
    },
    "2025/02/18": {
        "datePretty": "Tuesday, February 18, 2025",
        "calendar": "Week of 3rd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 48",
                        "readQS": "Psalm+48",
                        "audioQS": "Ps.48"
                    },
                    {
                        "pretty": "Ecclesiastes 2",
                        "readQS": "Ecclesiastes+2",
                        "audioQS": "Eccl.2"
                    },
                    {
                        "pretty": "John 17:6\u201319",
                        "readQS": "John+17:6-19",
                        "audioQS": "John.17"
                    }
                ],
                "readQS": "Psalm+48%3B+Ecclesiastes+2%3B+John+17:6-19",
                "audioQS": "Ps.48,Eccl.2,John.17",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 50",
                        "readQS": "Psalm+50",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "Genesis 24:29\u2013end",
                        "readQS": "Genesis+24:29-",
                        "audioQS": "Gen.24"
                    },
                    {
                        "pretty": "1 Timothy 6:11\u2013end",
                        "readQS": "1+Timothy+6:11-",
                        "audioQS": "1Tim.6"
                    }
                ],
                "readQS": "Psalm+50%3B+Genesis+24:29-%3B+1+Timothy+6:11-",
                "audioQS": "Ps.50,Gen.24,1Tim.6",
                "note": null
            }
        ]
    },
    "2025/02/19": {
        "datePretty": "Wednesday, February 19, 2025",
        "calendar": "Week of 3rd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:57\u201380",
                        "readQS": "Psalm+119:57-80",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Ecclesiastes 3:1\u201315",
                        "readQS": "Ecclesiastes+3:1-15",
                        "audioQS": "Eccl.3"
                    },
                    {
                        "pretty": "John 17:20\u2013end",
                        "readQS": "John+17:20-",
                        "audioQS": "John.17"
                    }
                ],
                "readQS": "Psalm+119:57-80%3B+Ecclesiastes+3:1-15%3B+John+17:20-",
                "audioQS": "Ps.119,Eccl.3,John.17",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 59",
                        "readQS": "Psalm+59",
                        "audioQS": "Ps.59"
                    },
                    {
                        "pretty": "Genesis 25:7\u201311,25:19\u2013end",
                        "readQS": "Genesis+25:7-11,25:19-",
                        "audioQS": "Gen.25"
                    },
                    {
                        "pretty": "2 Timothy 1:1\u201314",
                        "readQS": "2+Timothy+1:1-14",
                        "audioQS": "2Tim.1"
                    }
                ],
                "readQS": "Psalm+59%3B+Genesis+25:7-11,25:19-%3B+2+Timothy+1:1-14",
                "audioQS": "Ps.59,Gen.25,2Tim.1",
                "note": null
            }
        ]
    },
    "2025/02/20": {
        "datePretty": "Thursday, February 20, 2025",
        "calendar": "Week of 3rd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 57",
                        "readQS": "Psalm+57",
                        "audioQS": "Ps.57"
                    },
                    {
                        "pretty": "Ecclesiastes 3:16\u2013end",
                        "readQS": "Ecclesiastes+3:16-",
                        "audioQS": "Eccl.3"
                    },
                    {
                        "pretty": "Ecclesiastes 4",
                        "readQS": "Ecclesiastes+4",
                        "audioQS": "Eccl.4"
                    },
                    {
                        "pretty": "John 18:1\u201311",
                        "readQS": "John+18:1-11",
                        "audioQS": "John.18"
                    }
                ],
                "readQS": "Psalm+57%3B+Ecclesiastes+3:16-%3B+Ecclesiastes+4%3B+John+18:1-11",
                "audioQS": "Ps.57,Eccl.3,Eccl.4,John.18",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 62",
                        "readQS": "Psalm+62",
                        "audioQS": "Ps.62"
                    },
                    {
                        "pretty": "Genesis 26:34\u201327:40",
                        "readQS": "Genesis+26:34-27:40",
                        "audioQS": "Gen.26"
                    },
                    {
                        "pretty": "2 Timothy 1:15\u20132:13",
                        "readQS": "2+Timothy+1:15-2:13",
                        "audioQS": "2Tim.1"
                    }
                ],
                "readQS": "Psalm+62%3B+Genesis+26:34-27:40%3B+2+Timothy+1:15-2:13",
                "audioQS": "Ps.62,Gen.26,2Tim.1",
                "note": null
            }
        ]
    },
    "2025/02/21": {
        "datePretty": "Friday, February 21, 2025",
        "calendar": "Week of 3rd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 51",
                        "readQS": "Psalm+51",
                        "audioQS": "Ps.51"
                    },
                    {
                        "pretty": "Ecclesiastes 5",
                        "readQS": "Ecclesiastes+5",
                        "audioQS": "Eccl.5"
                    },
                    {
                        "pretty": "John 18:12\u201327",
                        "readQS": "John+18:12-27",
                        "audioQS": "John.18"
                    }
                ],
                "readQS": "Psalm+51%3B+Ecclesiastes+5%3B+John+18:12-27",
                "audioQS": "Ps.51,Eccl.5,John.18",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 38",
                        "readQS": "Psalm+38",
                        "audioQS": "Ps.38"
                    },
                    {
                        "pretty": "Genesis 27:41\u2013end",
                        "readQS": "Genesis+27:41-",
                        "audioQS": "Gen.27"
                    },
                    {
                        "pretty": "Genesis 28",
                        "readQS": "Genesis+28",
                        "audioQS": "Gen.28"
                    },
                    {
                        "pretty": "2 Timothy 2:14\u2013end",
                        "readQS": "2+Timothy+2:14-",
                        "audioQS": "2Tim.2"
                    }
                ],
                "readQS": "Psalm+38%3B+Genesis+27:41-%3B+Genesis+28%3B+2+Timothy+2:14-",
                "audioQS": "Ps.38,Gen.27,Gen.28,2Tim.2",
                "note": null
            }
        ]
    },
    "2025/02/22": {
        "datePretty": "Saturday, February 22, 2025",
        "calendar": "Week of 3rd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 68",
                        "readQS": "Psalm+68",
                        "audioQS": "Ps.68"
                    },
                    {
                        "pretty": "Ecclesiastes 6",
                        "readQS": "Ecclesiastes+6",
                        "audioQS": "Eccl.6"
                    },
                    {
                        "pretty": "John 18:28\u2013end",
                        "readQS": "John+18:28-",
                        "audioQS": "John.18"
                    }
                ],
                "readQS": "Psalm+68%3B+Ecclesiastes+6%3B+John+18:28-",
                "audioQS": "Ps.68,Eccl.6,John.18",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 66",
                        "readQS": "Psalm+66",
                        "audioQS": "Ps.66"
                    },
                    {
                        "pretty": "Genesis 29:1\u201330",
                        "readQS": "Genesis+29:1-30",
                        "audioQS": "Gen.29"
                    },
                    {
                        "pretty": "2 Timothy 3",
                        "readQS": "2+Timothy+3",
                        "audioQS": "2Tim.3"
                    }
                ],
                "readQS": "Psalm+66%3B+Genesis+29:1-30%3B+2+Timothy+3",
                "audioQS": "Ps.66,Gen.29,2Tim.3",
                "note": null
            }
        ]
    },
    "2025/02/23": {
        "datePretty": "Sunday, February 23, 2025",
        "calendar": "2nd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 65",
                        "readQS": "Psalm+65",
                        "audioQS": "Ps.65"
                    },
                    {
                        "pretty": "Genesis 2:4b\u20139,15\u201325",
                        "readQS": "Genesis+2:4b-9,15-25",
                        "audioQS": "Gen.2"
                    },
                    {
                        "pretty": "Revelation 4",
                        "readQS": "Revelation+4",
                        "audioQS": "Rev.4"
                    },
                    {
                        "pretty": "Luke 8:22\u201325",
                        "readQS": "Luke+8:22-25",
                        "audioQS": "Luke.8"
                    }
                ],
                "readQS": "Psalm+65%3B+Genesis+2:4b-9,15-25%3B+Revelation+4%3B+Luke+8:22-25",
                "audioQS": "Ps.65,Gen.2,Rev.4,Luke.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 104:1\u201326",
                        "readQS": "Psalm+104:1-26",
                        "audioQS": "Ps.104"
                    },
                    {
                        "pretty": "Job 28:1\u201311",
                        "readQS": "Job+28:1-11",
                        "audioQS": "Job.28"
                    },
                    {
                        "pretty": "Acts 14:8\u201317",
                        "readQS": "Acts+14:8-17",
                        "audioQS": "Acts.14"
                    }
                ],
                "readQS": "Psalm+104:1-26%3B+Job+28:1-11%3B+Acts+14:8-17",
                "audioQS": "Ps.104,Job.28,Acts.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 147",
                        "readQS": "Psalm+147",
                        "audioQS": "Ps.147"
                    },
                    {
                        "pretty": "Genesis 1:1\u20132:3",
                        "readQS": "Genesis+1:1-2:3",
                        "audioQS": "Gen.1"
                    },
                    {
                        "pretty": "Matthew 6:25\u201334",
                        "readQS": "Matthew+6:25-34",
                        "audioQS": "Matt.6"
                    }
                ],
                "readQS": "Psalm+147%3B+Genesis+1:1-2:3%3B+Matthew+6:25-34",
                "audioQS": "Ps.147,Gen.1,Matt.6",
                "note": null
            }
        ]
    },
    "2025/02/24": {
        "datePretty": "Monday, February 24, 2025",
        "calendar": "Week of 2nd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Ecclesiastes 7:1\u201314",
                        "readQS": "Ecclesiastes+7:1-14",
                        "audioQS": "Eccl.7"
                    },
                    {
                        "pretty": "John 19:1\u201316",
                        "readQS": "John+19:1-16",
                        "audioQS": "John.19"
                    }
                ],
                "readQS": "Psalm+71%3B+Ecclesiastes+7:1-14%3B+John+19:1-16",
                "audioQS": "Ps.71,Eccl.7,John.19",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 72",
                        "readQS": "Psalm+72",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "Genesis 29:31\u201330:24",
                        "readQS": "Genesis+29:31-30:24",
                        "audioQS": "Gen.29"
                    },
                    {
                        "pretty": "2 Timothy 4:1\u20138",
                        "readQS": "2+Timothy+4:1-8",
                        "audioQS": "2Tim.4"
                    }
                ],
                "readQS": "Psalm+72%3B+Genesis+29:31-30:24%3B+2+Timothy+4:1-8",
                "audioQS": "Ps.72,Gen.29,2Tim.4",
                "note": null
            }
        ]
    },
    "2025/02/25": {
        "datePretty": "Tuesday, February 25, 2025",
        "calendar": "Week of 2nd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Ecclesiastes 7:15\u2013end",
                        "readQS": "Ecclesiastes+7:15-",
                        "audioQS": "Eccl.7"
                    },
                    {
                        "pretty": "John 19:17\u201330",
                        "readQS": "John+19:17-30",
                        "audioQS": "John.19"
                    }
                ],
                "readQS": "Psalm+73%3B+Ecclesiastes+7:15-%3B+John+19:17-30",
                "audioQS": "Ps.73,Eccl.7,John.19",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 74",
                        "readQS": "Psalm+74",
                        "audioQS": "Ps.74"
                    },
                    {
                        "pretty": "Genesis 31:1\u201324",
                        "readQS": "Genesis+31:1-24",
                        "audioQS": "Gen.31"
                    },
                    {
                        "pretty": "2 Timothy 4:9\u2013end",
                        "readQS": "2+Timothy+4:9-",
                        "audioQS": "2Tim.4"
                    }
                ],
                "readQS": "Psalm+74%3B+Genesis+31:1-24%3B+2+Timothy+4:9-",
                "audioQS": "Ps.74,Gen.31,2Tim.4",
                "note": null
            }
        ]
    },
    "2025/02/26": {
        "datePretty": "Wednesday, February 26, 2025",
        "calendar": "Week of 2nd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Ecclesiastes 8",
                        "readQS": "Ecclesiastes+8",
                        "audioQS": "Eccl.8"
                    },
                    {
                        "pretty": "John 19:31\u2013end",
                        "readQS": "John+19:31-",
                        "audioQS": "John.19"
                    }
                ],
                "readQS": "Psalm+77%3B+Ecclesiastes+8%3B+John+19:31-",
                "audioQS": "Ps.77,Eccl.8,John.19",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:81\u2013104",
                        "readQS": "Psalm+119:81-104",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Genesis 31:25\u201332:2",
                        "readQS": "Genesis+31:25-32:2",
                        "audioQS": "Gen.31"
                    },
                    {
                        "pretty": "Titus 1",
                        "readQS": "Titus+1",
                        "audioQS": "Titus.1"
                    }
                ],
                "readQS": "Psalm+119:81-104%3B+Genesis+31:25-32:2%3B+Titus+1",
                "audioQS": "Ps.119,Gen.31,Titus.1",
                "note": null
            }
        ]
    },
    "2025/02/27": {
        "datePretty": "Thursday, February 27, 2025",
        "calendar": "Week of 2nd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 78:1\u201339",
                        "readQS": "Psalm+78:1-39",
                        "audioQS": "Ps.78"
                    },
                    {
                        "pretty": "Ecclesiastes 9",
                        "readQS": "Ecclesiastes+9",
                        "audioQS": "Eccl.9"
                    },
                    {
                        "pretty": "John 20:1\u201310",
                        "readQS": "John+20:1-10",
                        "audioQS": "John.20"
                    }
                ],
                "readQS": "Psalm+78:1-39%3B+Ecclesiastes+9%3B+John+20:1-10",
                "audioQS": "Ps.78,Eccl.9,John.20",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 78:40\u2013end",
                        "readQS": "Psalm+78:40-",
                        "audioQS": "Ps.78"
                    },
                    {
                        "pretty": "Genesis 32:3\u201330",
                        "readQS": "Genesis+32:3-30",
                        "audioQS": "Gen.32"
                    },
                    {
                        "pretty": "Titus 2",
                        "readQS": "Titus+2",
                        "audioQS": "Titus.2"
                    }
                ],
                "readQS": "Psalm+78:40-%3B+Genesis+32:3-30%3B+Titus+2",
                "audioQS": "Ps.78,Gen.32,Titus.2",
                "note": null
            }
        ]
    },
    "2025/02/28": {
        "datePretty": "Friday, February 28, 2025",
        "calendar": "Week of 2nd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 55",
                        "readQS": "Psalm+55",
                        "audioQS": "Ps.55"
                    },
                    {
                        "pretty": "Ecclesiastes 11:1\u20138",
                        "readQS": "Ecclesiastes+11:1-8",
                        "audioQS": "Eccl.11"
                    },
                    {
                        "pretty": "John 20:11\u201318",
                        "readQS": "John+20:11-18",
                        "audioQS": "John.20"
                    }
                ],
                "readQS": "Psalm+55%3B+Ecclesiastes+11:1-8%3B+John+20:11-18",
                "audioQS": "Ps.55,Eccl.11,John.20",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 69",
                        "readQS": "Psalm+69",
                        "audioQS": "Ps.69"
                    },
                    {
                        "pretty": "Genesis 33:1\u201317",
                        "readQS": "Genesis+33:1-17",
                        "audioQS": "Gen.33"
                    },
                    {
                        "pretty": "Titus 3",
                        "readQS": "Titus+3",
                        "audioQS": "Titus.3"
                    }
                ],
                "readQS": "Psalm+69%3B+Genesis+33:1-17%3B+Titus+3",
                "audioQS": "Ps.69,Gen.33,Titus.3",
                "note": null
            }
        ]
    },
    "2025/03/01": {
        "datePretty": "Saturday, March 1, 2025",
        "calendar": "Week of 2nd Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 76",
                        "readQS": "Psalm+76",
                        "audioQS": "Ps.76"
                    },
                    {
                        "pretty": "Ecclesiastes 11:9\u2013end",
                        "readQS": "Ecclesiastes+11:9-",
                        "audioQS": "Eccl.11"
                    },
                    {
                        "pretty": "Ecclesiastes 12",
                        "readQS": "Ecclesiastes+12",
                        "audioQS": "Eccl.12"
                    },
                    {
                        "pretty": "John 20:19\u2013end",
                        "readQS": "John+20:19-",
                        "audioQS": "John.20"
                    }
                ],
                "readQS": "Psalm+76%3B+Ecclesiastes+11:9-%3B+Ecclesiastes+12%3B+John+20:19-",
                "audioQS": "Ps.76,Eccl.11,Eccl.12,John.20",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 84",
                        "readQS": "Psalm+84",
                        "audioQS": "Ps.84"
                    },
                    {
                        "pretty": "Genesis 35",
                        "readQS": "Genesis+35",
                        "audioQS": "Gen.35"
                    },
                    {
                        "pretty": "Philemon",
                        "readQS": "Philemon",
                        "audioQS": "Phlm"
                    }
                ],
                "readQS": "Psalm+84%3B+Genesis+35%3B+Philemon",
                "audioQS": "Ps.84,Gen.35,Phlm",
                "note": null
            }
        ]
    },
    "2025/03/02": {
        "datePretty": "Sunday, March 2, 2025",
        "calendar": "The Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 99",
                        "readQS": "Psalm+99",
                        "audioQS": "Ps.99"
                    },
                    {
                        "pretty": "Exodus 34:29\u201335",
                        "readQS": "Exodus+34:29-35",
                        "audioQS": "Exod.34"
                    },
                    {
                        "pretty": "2 Corinthians 3:12\u20134:2",
                        "readQS": "2+Corinthians+3:12-4:2",
                        "audioQS": "2Cor.3"
                    },
                    {
                        "pretty": "Luke 9:28\u201336,(37\u201343a)",
                        "readQS": "Luke+9:28-36,%2837-43a%29",
                        "audioQS": "Luke.9"
                    }
                ],
                "readQS": "Psalm+99%3B+Exodus+34:29-35%3B+2+Corinthians+3:12-4:2%3B+Luke+9:28-36,%2837-43a%29",
                "audioQS": "Ps.99,Exod.34,2Cor.3,Luke.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 2",
                        "readQS": "Psalm+2",
                        "audioQS": "Ps.2"
                    },
                    {
                        "pretty": "Exodus 33:17\u201323",
                        "readQS": "Exodus+33:17-23",
                        "audioQS": "Exod.33"
                    },
                    {
                        "pretty": "1 John 3:1\u20133",
                        "readQS": "1+John+3:1-3",
                        "audioQS": "1John.3"
                    }
                ],
                "readQS": "Psalm+2%3B+Exodus+33:17-23%3B+1+John+3:1-3",
                "audioQS": "Ps.2,Exod.33,1John.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 89:1\u201318",
                        "readQS": "Psalm+89:1-18",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Exodus 3:1\u20136",
                        "readQS": "Exodus+3:1-6",
                        "audioQS": "Exod.3"
                    },
                    {
                        "pretty": "John 12:27\u201336a",
                        "readQS": "John+12:27-36a",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+89:1-18%3B+Exodus+3:1-6%3B+John+12:27-36a",
                "audioQS": "Ps.89,Exod.3,John.12",
                "note": null
            }
        ]
    },
    "2025/03/03": {
        "datePretty": "Monday, March 3, 2025",
        "calendar": "Week of the Sunday before Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Jeremiah 1",
                        "readQS": "Jeremiah+1",
                        "audioQS": "Jer.1"
                    },
                    {
                        "pretty": "John 3:1\u201321",
                        "readQS": "John+3:1-21",
                        "audioQS": "John.3"
                    }
                ],
                "readQS": "Psalm+80%3B+Jeremiah+1%3B+John+3:1-21",
                "audioQS": "Ps.80,Jer.1,John.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "Genesis 37:1\u201311",
                        "readQS": "Genesis+37:1-11",
                        "audioQS": "Gen.37"
                    },
                    {
                        "pretty": "Galatians 1",
                        "readQS": "Galatians+1",
                        "audioQS": "Gal.1"
                    }
                ],
                "readQS": "Psalm+85%3B+Genesis+37:1-11%3B+Galatians+1",
                "audioQS": "Ps.85,Gen.37,Gal.1",
                "note": null
            }
        ]
    },
    "2025/03/04": {
        "datePretty": "Tuesday, March 4, 2025",
        "calendar": "Shrove Tuesday",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 89:1\u201318",
                        "readQS": "Psalm+89:1-18",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Jeremiah 2:1\u201313",
                        "readQS": "Jeremiah+2:1-13",
                        "audioQS": "Jer.2"
                    },
                    {
                        "pretty": "John 3:22\u2013end",
                        "readQS": "John+3:22-",
                        "audioQS": "John.3"
                    }
                ],
                "readQS": "Psalm+89:1-18%3B+Jeremiah+2:1-13%3B+John+3:22-",
                "audioQS": "Ps.89,Jer.2,John.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 89:19\u2013end",
                        "readQS": "Psalm+89:19-",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Genesis 37:12\u2013end",
                        "readQS": "Genesis+37:12-",
                        "audioQS": "Gen.37"
                    },
                    {
                        "pretty": "Galatians 2:1\u201310",
                        "readQS": "Galatians+2:1-10",
                        "audioQS": "Gal.2"
                    }
                ],
                "readQS": "Psalm+89:19-%3B+Genesis+37:12-%3B+Galatians+2:1-10",
                "audioQS": "Ps.89,Gen.37,Gal.2",
                "note": null
            }
        ]
    },
    "2025/03/05": {
        "datePretty": "Wednesday, March 5, 2025",
        "calendar": "Ash Wednesday",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 51:1\u201318",
                        "readQS": "Psalm+51:1-18",
                        "audioQS": "Ps.51"
                    },
                    {
                        "pretty": "Joel 2:1\u20132,12\u201317",
                        "readQS": "Joel+2:1-2,12-17",
                        "audioQS": "Joel.2"
                    },
                    {
                        "pretty": "2 Corinthians 5:20b\u20136.10",
                        "readQS": "2+Corinthians+5:20b-6.10",
                        "audioQS": "2Cor.5"
                    },
                    {
                        "pretty": "Matthew 6:1\u20136,16\u201321",
                        "readQS": "Matthew+6:1-6,16-21",
                        "audioQS": "Matt.6"
                    }
                ],
                "readQS": "Psalm+51:1-18%3B+Joel+2:1-2,12-17%3B+2+Corinthians+5:20b-6.10%3B+Matthew+6:1-6,16-21",
                "audioQS": "Ps.51,Joel.2,2Cor.5,Matt.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 38",
                        "readQS": "Psalm+38",
                        "audioQS": "Ps.38"
                    },
                    {
                        "pretty": "Daniel 9:3\u20136,17\u201319",
                        "readQS": "Daniel+9:3-6,17-19",
                        "audioQS": "Dan.9"
                    },
                    {
                        "pretty": "1 Timothy 6:6\u201319",
                        "readQS": "1+Timothy+6:6-19",
                        "audioQS": "1Tim.6"
                    }
                ],
                "readQS": "Psalm+38%3B+Daniel+9:3-6,17-19%3B+1+Timothy+6:6-19",
                "audioQS": "Ps.38,Dan.9,1Tim.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 51",
                        "readQS": "Psalm+51",
                        "audioQS": "Ps.51"
                    },
                    {
                        "pretty": "Isaiah 1:10\u201318",
                        "readQS": "Isaiah+1:10-18",
                        "audioQS": "Isa.1"
                    },
                    {
                        "pretty": "Luke 15:11\u201332",
                        "readQS": "Luke+15:11-32",
                        "audioQS": "Luke.15"
                    }
                ],
                "readQS": "Psalm+51%3B+Isaiah+1:10-18%3B+Luke+15:11-32",
                "audioQS": "Ps.51,Isa.1,Luke.15",
                "note": null
            }
        ]
    },
    "2025/03/06": {
        "datePretty": "Thursday, March 6, 2025",
        "calendar": "1st day of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Jeremiah 2:14\u201332",
                        "readQS": "Jeremiah+2:14-32",
                        "audioQS": "Jer.2"
                    },
                    {
                        "pretty": "John 4:1\u201326",
                        "readQS": "John+4:1-26",
                        "audioQS": "John.4"
                    }
                ],
                "readQS": "Psalm+77%3B+Jeremiah+2:14-32%3B+John+4:1-26",
                "audioQS": "Ps.77,Jer.2,John.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 74",
                        "readQS": "Psalm+74",
                        "audioQS": "Ps.74"
                    },
                    {
                        "pretty": "Genesis 39",
                        "readQS": "Genesis+39",
                        "audioQS": "Gen.39"
                    },
                    {
                        "pretty": "Galatians 2:11\u2013end",
                        "readQS": "Galatians+2:11-",
                        "audioQS": "Gal.2"
                    }
                ],
                "readQS": "Psalm+74%3B+Genesis+39%3B+Galatians+2:11-",
                "audioQS": "Ps.74,Gen.39,Gal.2",
                "note": null
            }
        ]
    },
    "2025/03/07": {
        "datePretty": "Friday, March 7, 2025",
        "calendar": "2nd day of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 3",
                        "readQS": "Psalm+3",
                        "audioQS": "Ps.3"
                    },
                    {
                        "pretty": "Jeremiah 3:6\u201322",
                        "readQS": "Jeremiah+3:6-22",
                        "audioQS": "Jer.3"
                    },
                    {
                        "pretty": "John 4:27\u201342",
                        "readQS": "John+4:27-42",
                        "audioQS": "John.4"
                    }
                ],
                "readQS": "Psalm+3%3B+Jeremiah+3:6-22%3B+John+4:27-42",
                "audioQS": "Ps.3,Jer.3,John.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 31",
                        "readQS": "Psalm+31",
                        "audioQS": "Ps.31"
                    },
                    {
                        "pretty": "Genesis 40",
                        "readQS": "Genesis+40",
                        "audioQS": "Gen.40"
                    },
                    {
                        "pretty": "Galatians 3:1\u201314",
                        "readQS": "Galatians+3:1-14",
                        "audioQS": "Gal.3"
                    }
                ],
                "readQS": "Psalm+31%3B+Genesis+40%3B+Galatians+3:1-14",
                "audioQS": "Ps.31,Gen.40,Gal.3",
                "note": null
            }
        ]
    },
    "2025/03/08": {
        "datePretty": "Saturday, March 8, 2025",
        "calendar": "3rd day of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Jeremiah 4:1\u201318",
                        "readQS": "Jeremiah+4:1-18",
                        "audioQS": "Jer.4"
                    },
                    {
                        "pretty": "John 4:43\u2013end",
                        "readQS": "John+4:43-",
                        "audioQS": "John.4"
                    }
                ],
                "readQS": "Psalm+71%3B+Jeremiah+4:1-18%3B+John+4:43-",
                "audioQS": "Ps.71,Jer.4,John.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Genesis 41:1\u201324",
                        "readQS": "Genesis+41:1-24",
                        "audioQS": "Gen.41"
                    },
                    {
                        "pretty": "Galatians 3:15\u201322",
                        "readQS": "Galatians+3:15-22",
                        "audioQS": "Gal.3"
                    }
                ],
                "readQS": "Psalm+73%3B+Genesis+41:1-24%3B+Galatians+3:15-22",
                "audioQS": "Ps.73,Gen.41,Gal.3",
                "note": null
            }
        ]
    },
    "2025/03/09": {
        "datePretty": "Sunday, March 9, 2025",
        "calendar": "1st Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 91:1\u20132,9\u201316",
                        "readQS": "Psalm+91:1-2,9-16",
                        "audioQS": "Ps.91"
                    },
                    {
                        "pretty": "Deuteronomy 26:1\u201311",
                        "readQS": "Deuteronomy+26:1-11",
                        "audioQS": "Deut.26"
                    },
                    {
                        "pretty": "Romans 10:8b\u201313",
                        "readQS": "Romans+10:8b-13",
                        "audioQS": "Rom.10"
                    },
                    {
                        "pretty": "Luke 4:1\u201313",
                        "readQS": "Luke+4:1-13",
                        "audioQS": "Luke.4"
                    }
                ],
                "readQS": "Psalm+91:1-2,9-16%3B+Deuteronomy+26:1-11%3B+Romans+10:8b-13%3B+Luke+4:1-13",
                "audioQS": "Ps.91,Deut.26,Rom.10,Luke.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 50:1\u201315",
                        "readQS": "Psalm+50:1-15",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "Micah 6:1\u20138",
                        "readQS": "Micah+6:1-8",
                        "audioQS": "Mic.6"
                    },
                    {
                        "pretty": "Luke 5:27\u201339",
                        "readQS": "Luke+5:27-39",
                        "audioQS": "Luke.5"
                    }
                ],
                "readQS": "Psalm+50:1-15%3B+Micah+6:1-8%3B+Luke+5:27-39",
                "audioQS": "Ps.50,Mic.6,Luke.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:73\u201388",
                        "readQS": "Psalm+119:73-88",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Jonah 3",
                        "readQS": "Jonah+3",
                        "audioQS": "Jonah.3"
                    },
                    {
                        "pretty": "Luke 18:9\u201314",
                        "readQS": "Luke+18:9-14",
                        "audioQS": "Luke.18"
                    }
                ],
                "readQS": "Psalm+119:73-88%3B+Jonah+3%3B+Luke+18:9-14",
                "audioQS": "Ps.119,Jonah.3,Luke.18",
                "note": null
            }
        ]
    },
    "2025/03/10": {
        "datePretty": "Monday, March 10, 2025",
        "calendar": "Week of 1st Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 11",
                        "readQS": "Psalm+11",
                        "audioQS": "Ps.11"
                    },
                    {
                        "pretty": "Jeremiah 4:19\u2013end",
                        "readQS": "Jeremiah+4:19-",
                        "audioQS": "Jer.4"
                    },
                    {
                        "pretty": "John 5:1\u201318",
                        "readQS": "John+5:1-18",
                        "audioQS": "John.5"
                    }
                ],
                "readQS": "Psalm+11%3B+Jeremiah+4:19-%3B+John+5:1-18",
                "audioQS": "Ps.11,Jer.4,John.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 13",
                        "readQS": "Psalm+13",
                        "audioQS": "Ps.13"
                    },
                    {
                        "pretty": "Genesis 41:25\u201345",
                        "readQS": "Genesis+41:25-45",
                        "audioQS": "Gen.41"
                    },
                    {
                        "pretty": "Galatians 3:23\u20134:7",
                        "readQS": "Galatians+3:23-4:7",
                        "audioQS": "Gal.3"
                    }
                ],
                "readQS": "Psalm+13%3B+Genesis+41:25-45%3B+Galatians+3:23-4:7",
                "audioQS": "Ps.13,Gen.41,Gal.3",
                "note": null
            }
        ]
    },
    "2025/03/11": {
        "datePretty": "Tuesday, March 11, 2025",
        "calendar": "Week of 1st Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "Jeremiah 5:1\u201319",
                        "readQS": "Jeremiah+5:1-19",
                        "audioQS": "Jer.5"
                    },
                    {
                        "pretty": "John 5:19\u201329",
                        "readQS": "John+5:19-29",
                        "audioQS": "John.5"
                    }
                ],
                "readQS": "Psalm+44%3B+Jeremiah+5:1-19%3B+John+5:19-29",
                "audioQS": "Ps.44,Jer.5,John.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 49",
                        "readQS": "Psalm+49",
                        "audioQS": "Ps.49"
                    },
                    {
                        "pretty": "Genesis 41:46\u201342:5",
                        "readQS": "Genesis+41:46-42:5",
                        "audioQS": "Gen.41"
                    },
                    {
                        "pretty": "Galatians 4:8\u201320",
                        "readQS": "Galatians+4:8-20",
                        "audioQS": "Gal.4"
                    }
                ],
                "readQS": "Psalm+49%3B+Genesis+41:46-42:5%3B+Galatians+4:8-20",
                "audioQS": "Ps.49,Gen.41,Gal.4",
                "note": null
            }
        ]
    },
    "2025/03/12": {
        "datePretty": "Wednesday, March 12, 2025",
        "calendar": "Week of 1st Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 6",
                        "readQS": "Psalm+6",
                        "audioQS": "Ps.6"
                    },
                    {
                        "pretty": "Jeremiah 5:20\u2013end",
                        "readQS": "Jeremiah+5:20-",
                        "audioQS": "Jer.5"
                    },
                    {
                        "pretty": "John 5:30\u2013end",
                        "readQS": "John+5:30-",
                        "audioQS": "John.5"
                    }
                ],
                "readQS": "Psalm+6%3B+Jeremiah+5:20-%3B+John+5:30-",
                "audioQS": "Ps.6,Jer.5,John.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 28",
                        "readQS": "Psalm+28",
                        "audioQS": "Ps.28"
                    },
                    {
                        "pretty": "Genesis 42:6\u201317",
                        "readQS": "Genesis+42:6-17",
                        "audioQS": "Gen.42"
                    },
                    {
                        "pretty": "Galatians 4:21\u20135:1",
                        "readQS": "Galatians+4:21-5:1",
                        "audioQS": "Gal.4"
                    }
                ],
                "readQS": "Psalm+28%3B+Genesis+42:6-17%3B+Galatians+4:21-5:1",
                "audioQS": "Ps.28,Gen.42,Gal.4",
                "note": null
            }
        ]
    },
    "2025/03/13": {
        "datePretty": "Thursday, March 13, 2025",
        "calendar": "Week of 1st Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 42",
                        "readQS": "Psalm+42",
                        "audioQS": "Ps.42"
                    },
                    {
                        "pretty": "Jeremiah 6:9\u201321",
                        "readQS": "Jeremiah+6:9-21",
                        "audioQS": "Jer.6"
                    },
                    {
                        "pretty": "John 6:1\u201315",
                        "readQS": "John+6:1-15",
                        "audioQS": "John.6"
                    }
                ],
                "readQS": "Psalm+42%3B+Jeremiah+6:9-21%3B+John+6:1-15",
                "audioQS": "Ps.42,Jer.6,John.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 142",
                        "readQS": "Psalm+142",
                        "audioQS": "Ps.142"
                    },
                    {
                        "pretty": "Genesis 42:18\u201328",
                        "readQS": "Genesis+42:18-28",
                        "audioQS": "Gen.42"
                    },
                    {
                        "pretty": "Galatians 5:2\u201315",
                        "readQS": "Galatians+5:2-15",
                        "audioQS": "Gal.5"
                    }
                ],
                "readQS": "Psalm+142%3B+Genesis+42:18-28%3B+Galatians+5:2-15",
                "audioQS": "Ps.142,Gen.42,Gal.5",
                "note": null
            }
        ]
    },
    "2025/03/14": {
        "datePretty": "Friday, March 14, 2025",
        "calendar": "Week of 1st Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 22",
                        "readQS": "Psalm+22",
                        "audioQS": "Ps.22"
                    },
                    {
                        "pretty": "Jeremiah 6:22\u2013end",
                        "readQS": "Jeremiah+6:22-",
                        "audioQS": "Jer.6"
                    },
                    {
                        "pretty": "John 6:16\u201327",
                        "readQS": "John+6:16-27",
                        "audioQS": "John.6"
                    }
                ],
                "readQS": "Psalm+22%3B+Jeremiah+6:22-%3B+John+6:16-27",
                "audioQS": "Ps.22,Jer.6,John.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 55",
                        "readQS": "Psalm+55",
                        "audioQS": "Ps.55"
                    },
                    {
                        "pretty": "Genesis 42:29\u2013end",
                        "readQS": "Genesis+42:29-",
                        "audioQS": "Gen.42"
                    },
                    {
                        "pretty": "Galatians 5:16\u2013end",
                        "readQS": "Galatians+5:16-",
                        "audioQS": "Gal.5"
                    }
                ],
                "readQS": "Psalm+55%3B+Genesis+42:29-%3B+Galatians+5:16-",
                "audioQS": "Ps.55,Gen.42,Gal.5",
                "note": null
            }
        ]
    },
    "2025/03/15": {
        "datePretty": "Saturday, March 15, 2025",
        "calendar": "Week of 1st Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 63",
                        "readQS": "Psalm+63",
                        "audioQS": "Ps.63"
                    },
                    {
                        "pretty": "Jeremiah 7:1\u201320",
                        "readQS": "Jeremiah+7:1-20",
                        "audioQS": "Jer.7"
                    },
                    {
                        "pretty": "John 6:27\u201340",
                        "readQS": "John+6:27-40",
                        "audioQS": "John.6"
                    }
                ],
                "readQS": "Psalm+63%3B+Jeremiah+7:1-20%3B+John+6:27-40",
                "audioQS": "Ps.63,Jer.7,John.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 4",
                        "readQS": "Psalm+4",
                        "audioQS": "Ps.4"
                    },
                    {
                        "pretty": "Genesis 43:1\u201315",
                        "readQS": "Genesis+43:1-15",
                        "audioQS": "Gen.43"
                    },
                    {
                        "pretty": "Galatians 6",
                        "readQS": "Galatians+6",
                        "audioQS": "Gal.6"
                    }
                ],
                "readQS": "Psalm+4%3B+Genesis+43:1-15%3B+Galatians+6",
                "audioQS": "Ps.4,Gen.43,Gal.6",
                "note": null
            }
        ]
    },
    "2025/03/16": {
        "datePretty": "Sunday, March 16, 2025",
        "calendar": "2nd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 27",
                        "readQS": "Psalm+27",
                        "audioQS": "Ps.27"
                    },
                    {
                        "pretty": "Genesis 15:1\u201312,17\u201318",
                        "readQS": "Genesis+15:1-12,17-18",
                        "audioQS": "Gen.15"
                    },
                    {
                        "pretty": "Philippians 3:17\u20134:1",
                        "readQS": "Philippians+3:17-4:1",
                        "audioQS": "Phil.3"
                    },
                    {
                        "pretty": "Luke 13:31\u201335",
                        "readQS": "Luke+13:31-35",
                        "audioQS": "Luke.13"
                    }
                ],
                "readQS": "Psalm+27%3B+Genesis+15:1-12,17-18%3B+Philippians+3:17-4:1%3B+Luke+13:31-35",
                "audioQS": "Ps.27,Gen.15,Phil.3,Luke.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:161\u2013176",
                        "readQS": "Psalm+119:161-176",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Genesis 17:1\u20137,15,16",
                        "readQS": "Genesis+17:1-7,15,16",
                        "audioQS": "Gen.17"
                    },
                    {
                        "pretty": "Romans 11:13\u201324",
                        "readQS": "Romans+11:13-24",
                        "audioQS": "Rom.11"
                    }
                ],
                "readQS": "Psalm+119:161-176%3B+Genesis+17:1-7,15,16%3B+Romans+11:13-24",
                "audioQS": "Ps.119,Gen.17,Rom.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 135",
                        "readQS": "Psalm+135",
                        "audioQS": "Ps.135"
                    },
                    {
                        "pretty": "Jeremiah 22:1\u20139,13\u201317",
                        "readQS": "Jeremiah+22:1-9,13-17",
                        "audioQS": "Jer.22"
                    },
                    {
                        "pretty": "Luke 14:27\u201333",
                        "readQS": "Luke+14:27-33",
                        "audioQS": "Luke.14"
                    }
                ],
                "readQS": "Psalm+135%3B+Jeremiah+22:1-9,13-17%3B+Luke+14:27-33",
                "audioQS": "Ps.135,Jer.22,Luke.14",
                "note": null
            }
        ]
    },
    "2025/03/17": {
        "datePretty": "Monday, March 17, 2025",
        "calendar": "Week of 2nd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 32",
                        "readQS": "Psalm+32",
                        "audioQS": "Ps.32"
                    },
                    {
                        "pretty": "Jeremiah 7:21\u2013end",
                        "readQS": "Jeremiah+7:21-",
                        "audioQS": "Jer.7"
                    },
                    {
                        "pretty": "John 6:41\u201351",
                        "readQS": "John+6:41-51",
                        "audioQS": "John.6"
                    }
                ],
                "readQS": "Psalm+32%3B+Jeremiah+7:21-%3B+John+6:41-51",
                "audioQS": "Ps.32,Jer.7,John.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 74",
                        "readQS": "Psalm+74",
                        "audioQS": "Ps.74"
                    },
                    {
                        "pretty": "Genesis 43:16\u2013end",
                        "readQS": "Genesis+43:16-",
                        "audioQS": "Gen.43"
                    },
                    {
                        "pretty": "Hebrews 1",
                        "readQS": "Hebrews+1",
                        "audioQS": "Heb.1"
                    }
                ],
                "readQS": "Psalm+74%3B+Genesis+43:16-%3B+Hebrews+1",
                "audioQS": "Ps.74,Gen.43,Heb.1",
                "note": null
            }
        ]
    },
    "2025/03/18": {
        "datePretty": "Tuesday, March 18, 2025",
        "calendar": "Week of 2nd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 50",
                        "readQS": "Psalm+50",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "Jeremiah 8:1\u201315",
                        "readQS": "Jeremiah+8:1-15",
                        "audioQS": "Jer.8"
                    },
                    {
                        "pretty": "John 6:52\u201359",
                        "readQS": "John+6:52-59",
                        "audioQS": "John.6"
                    }
                ],
                "readQS": "Psalm+50%3B+Jeremiah+8:1-15%3B+John+6:52-59",
                "audioQS": "Ps.50,Jer.8,John.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of Joseph)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Hosea 11:1\u20139",
                        "readQS": "Hosea+11:1-9",
                        "audioQS": "Hos.11"
                    },
                    {
                        "pretty": "Luke 2:41\u201352",
                        "readQS": "Luke+2:41-52",
                        "audioQS": "Luke.2"
                    }
                ],
                "readQS": "Psalm+132%3B+Hosea+11:1-9%3B+Luke+2:41-52",
                "audioQS": "Ps.132,Hos.11,Luke.2",
                "note": null
            }
        ]
    },
    "2025/03/19": {
        "datePretty": "Wednesday, March 19, 2025",
        "calendar": "St Joseph of Nazereth",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 25,147:1\u201312",
                        "readQS": "Psalm+25,147:1-12",
                        "audioQS": "Ps.25,147"
                    },
                    {
                        "pretty": "Isaiah 11:1\u201310",
                        "readQS": "Isaiah+11:1-10",
                        "audioQS": "Isa.11"
                    },
                    {
                        "pretty": "Matthew 13:54\u201358",
                        "readQS": "Matthew+13:54-58",
                        "audioQS": "Matt.13"
                    }
                ],
                "readQS": "Psalm+25,147:1-12%3B+Isaiah+11:1-10%3B+Matthew+13:54-58",
                "audioQS": "Ps.25,147,Isa.11,Matt.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 1,112",
                        "readQS": "Psalm+1,112",
                        "audioQS": "Ps.1,112"
                    },
                    {
                        "pretty": "Genesis 50:22\u201326",
                        "readQS": "Genesis+50:22-26",
                        "audioQS": "Gen.50"
                    },
                    {
                        "pretty": "Matthew 2:13\u201323",
                        "readQS": "Matthew+2:13-23",
                        "audioQS": "Matt.2"
                    }
                ],
                "readQS": "Psalm+1,112%3B+Genesis+50:22-26%3B+Matthew+2:13-23",
                "audioQS": "Ps.1,112,Gen.50,Matt.2",
                "note": null
            }
        ]
    },
    "2025/03/20": {
        "datePretty": "Thursday, March 20, 2025",
        "calendar": "Week of 2nd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 34",
                        "readQS": "Psalm+34",
                        "audioQS": "Ps.34"
                    },
                    {
                        "pretty": "Jeremiah 9:12\u201324",
                        "readQS": "Jeremiah+9:12-24",
                        "audioQS": "Jer.9"
                    },
                    {
                        "pretty": "John 7:1\u201313",
                        "readQS": "John+7:1-13",
                        "audioQS": "John.7"
                    }
                ],
                "readQS": "Psalm+34%3B+Jeremiah+9:12-24%3B+John+7:1-13",
                "audioQS": "Ps.34,Jer.9,John.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Genesis 45:1\u201315",
                        "readQS": "Genesis+45:1-15",
                        "audioQS": "Gen.45"
                    },
                    {
                        "pretty": "Hebrews 3:1\u20136",
                        "readQS": "Hebrews+3:1-6",
                        "audioQS": "Heb.3"
                    }
                ],
                "readQS": "Psalm+71%3B+Genesis+45:1-15%3B+Hebrews+3:1-6",
                "audioQS": "Ps.71,Gen.45,Heb.3",
                "note": null
            }
        ]
    },
    "2025/03/21": {
        "datePretty": "Friday, March 21, 2025",
        "calendar": "Week of 2nd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 41",
                        "readQS": "Psalm+41",
                        "audioQS": "Ps.41"
                    },
                    {
                        "pretty": "Jeremiah 10:1\u201316",
                        "readQS": "Jeremiah+10:1-16",
                        "audioQS": "Jer.10"
                    },
                    {
                        "pretty": "John 7:14\u201324",
                        "readQS": "John+7:14-24",
                        "audioQS": "John.7"
                    }
                ],
                "readQS": "Psalm+41%3B+Jeremiah+10:1-16%3B+John+7:14-24",
                "audioQS": "Ps.41,Jer.10,John.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 6",
                        "readQS": "Psalm+6",
                        "audioQS": "Ps.6"
                    },
                    {
                        "pretty": "Genesis 45:16\u2013end",
                        "readQS": "Genesis+45:16-",
                        "audioQS": "Gen.45"
                    },
                    {
                        "pretty": "Hebrews 3:7\u2013end",
                        "readQS": "Hebrews+3:7-",
                        "audioQS": "Heb.3"
                    }
                ],
                "readQS": "Psalm+6%3B+Genesis+45:16-%3B+Hebrews+3:7-",
                "audioQS": "Ps.6,Gen.45,Heb.3",
                "note": null
            }
        ]
    },
    "2025/03/22": {
        "datePretty": "Saturday, March 22, 2025",
        "calendar": "Week of 2nd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 25",
                        "readQS": "Psalm+25",
                        "audioQS": "Ps.25"
                    },
                    {
                        "pretty": "Jeremiah 10:17\u201324",
                        "readQS": "Jeremiah+10:17-24",
                        "audioQS": "Jer.10"
                    },
                    {
                        "pretty": "John 7:25\u201336",
                        "readQS": "John+7:25-36",
                        "audioQS": "John.7"
                    }
                ],
                "readQS": "Psalm+25%3B+Jeremiah+10:17-24%3B+John+7:25-36",
                "audioQS": "Ps.25,Jer.10,John.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 23",
                        "readQS": "Psalm+23",
                        "audioQS": "Ps.23"
                    },
                    {
                        "pretty": "Genesis 46:1\u20137,46:28\u2013end",
                        "readQS": "Genesis+46:1-7,46:28-",
                        "audioQS": "Gen.46"
                    },
                    {
                        "pretty": "Hebrews 4:1\u201313",
                        "readQS": "Hebrews+4:1-13",
                        "audioQS": "Heb.4"
                    }
                ],
                "readQS": "Psalm+23%3B+Genesis+46:1-7,46:28-%3B+Hebrews+4:1-13",
                "audioQS": "Ps.23,Gen.46,Heb.4",
                "note": null
            }
        ]
    },
    "2025/03/23": {
        "datePretty": "Sunday, March 23, 2025",
        "calendar": "3rd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 63:1\u20139",
                        "readQS": "Psalm+63:1-9",
                        "audioQS": "Ps.63"
                    },
                    {
                        "pretty": "Isaiah 55:1\u20139",
                        "readQS": "Isaiah+55:1-9",
                        "audioQS": "Isa.55"
                    },
                    {
                        "pretty": "1 Corinthians 10:1\u201313",
                        "readQS": "1+Corinthians+10:1-13",
                        "audioQS": "1Cor.10"
                    },
                    {
                        "pretty": "Luke 13:1\u20139",
                        "readQS": "Luke+13:1-9",
                        "audioQS": "Luke.13"
                    }
                ],
                "readQS": "Psalm+63:1-9%3B+Isaiah+55:1-9%3B+1+Corinthians+10:1-13%3B+Luke+13:1-9",
                "audioQS": "Ps.63,Isa.55,1Cor.10,Luke.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 26,28",
                        "readQS": "Psalm+26,28",
                        "audioQS": "Ps.26,28"
                    },
                    {
                        "pretty": "Deuteronomy 6:4\u20139",
                        "readQS": "Deuteronomy+6:4-9",
                        "audioQS": "Deut.6"
                    },
                    {
                        "pretty": "John 17:1a,11b\u201319",
                        "readQS": "John+17:1a,11b-19",
                        "audioQS": "John.17"
                    }
                ],
                "readQS": "Psalm+26,28%3B+Deuteronomy+6:4-9%3B+John+17:1a,11b-19",
                "audioQS": "Ps.26,28,Deut.6,John.17",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 12,13",
                        "readQS": "Psalm+12,13",
                        "audioQS": "Ps.12,13"
                    },
                    {
                        "pretty": "Genesis 28:10\u201319a",
                        "readQS": "Genesis+28:10-19a",
                        "audioQS": "Gen.28"
                    },
                    {
                        "pretty": "John 1:35\u201351",
                        "readQS": "John+1:35-51",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+12,13%3B+Genesis+28:10-19a%3B+John+1:35-51",
                "audioQS": "Ps.12,13,Gen.28,John.1",
                "note": null
            }
        ]
    },
    "2025/03/24": {
        "datePretty": "Monday, March 24, 2025",
        "calendar": "Week of 3rd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 5",
                        "readQS": "Psalm+5",
                        "audioQS": "Ps.5"
                    },
                    {
                        "pretty": "Jeremiah 11:1\u201317",
                        "readQS": "Jeremiah+11:1-17",
                        "audioQS": "Jer.11"
                    },
                    {
                        "pretty": "John 7:37\u201352",
                        "readQS": "John+7:37-52",
                        "audioQS": "John.7"
                    }
                ],
                "readQS": "Psalm+5%3B+Jeremiah+11:1-17%3B+John+7:37-52",
                "audioQS": "Ps.5,Jer.11,John.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Annunciation)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "Genesis 3:8\u201315",
                        "readQS": "Genesis+3:8-15",
                        "audioQS": "Gen.3"
                    },
                    {
                        "pretty": "Galatians 4:1\u20135",
                        "readQS": "Galatians+4:1-5",
                        "audioQS": "Gal.4"
                    }
                ],
                "readQS": "Psalm+85%3B+Genesis+3:8-15%3B+Galatians+4:1-5",
                "audioQS": "Ps.85,Gen.3,Gal.4",
                "note": null
            }
        ]
    },
    "2025/03/25": {
        "datePretty": "Tuesday, March 25, 2025",
        "calendar": "The Annunciation",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 40:5\u201311",
                        "readQS": "Psalm+40:5-11",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Isaiah 7:10\u201314",
                        "readQS": "Isaiah+7:10-14",
                        "audioQS": "Isa.7"
                    },
                    {
                        "pretty": "Hebrews 10:4\u201310",
                        "readQS": "Hebrews+10:4-10",
                        "audioQS": "Heb.10"
                    },
                    {
                        "pretty": "Luke 1:26\u201338",
                        "readQS": "Luke+1:26-38",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+40:5-11%3B+Isaiah+7:10-14%3B+Hebrews+10:4-10%3B+Luke+1:26-38",
                "audioQS": "Ps.40,Isa.7,Heb.10,Luke.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 111,113",
                        "readQS": "Psalm+111,113",
                        "audioQS": "Ps.111,113"
                    },
                    {
                        "pretty": "1 Samuel 2:1\u201310",
                        "readQS": "1+Samuel+2:1-10",
                        "audioQS": "1Sam.2"
                    },
                    {
                        "pretty": "Romans 5:12\u201321",
                        "readQS": "Romans+5:12-21",
                        "audioQS": "Rom.5"
                    }
                ],
                "readQS": "Psalm+111,113%3B+1+Samuel+2:1-10%3B+Romans+5:12-21",
                "audioQS": "Ps.111,113,1Sam.2,Rom.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 131,146",
                        "readQS": "Psalm+131,146",
                        "audioQS": "Ps.131,146"
                    },
                    {
                        "pretty": "Isaiah 52:1\u201312",
                        "readQS": "Isaiah+52:1-12",
                        "audioQS": "Isa.52"
                    },
                    {
                        "pretty": "Hebrews 2:5\u201318",
                        "readQS": "Hebrews+2:5-18",
                        "audioQS": "Heb.2"
                    }
                ],
                "readQS": "Psalm+131,146%3B+Isaiah+52:1-12%3B+Hebrews+2:5-18",
                "audioQS": "Ps.131,146,Isa.52,Heb.2",
                "note": null
            }
        ]
    },
    "2025/03/26": {
        "datePretty": "Wednesday, March 26, 2025",
        "calendar": "Week of 3rd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 38",
                        "readQS": "Psalm+38",
                        "audioQS": "Ps.38"
                    },
                    {
                        "pretty": "Jeremiah 13:1\u201311",
                        "readQS": "Jeremiah+13:1-11",
                        "audioQS": "Jer.13"
                    },
                    {
                        "pretty": "John 8:12\u201330",
                        "readQS": "John+8:12-30",
                        "audioQS": "John.8"
                    }
                ],
                "readQS": "Psalm+38%3B+Jeremiah+13:1-11%3B+John+8:12-30",
                "audioQS": "Ps.38,Jer.13,John.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 39",
                        "readQS": "Psalm+39",
                        "audioQS": "Ps.39"
                    },
                    {
                        "pretty": "Genesis 49:1\u201332",
                        "readQS": "Genesis+49:1-32",
                        "audioQS": "Gen.49"
                    },
                    {
                        "pretty": "Hebrews 6:13\u2013end",
                        "readQS": "Hebrews+6:13-",
                        "audioQS": "Heb.6"
                    }
                ],
                "readQS": "Psalm+39%3B+Genesis+49:1-32%3B+Hebrews+6:13-",
                "audioQS": "Ps.39,Gen.49,Heb.6",
                "note": null
            }
        ]
    },
    "2025/03/27": {
        "datePretty": "Thursday, March 27, 2025",
        "calendar": "Week of 3rd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 56",
                        "readQS": "Psalm+56",
                        "audioQS": "Ps.56"
                    },
                    {
                        "pretty": "Jeremiah 14",
                        "readQS": "Jeremiah+14",
                        "audioQS": "Jer.14"
                    },
                    {
                        "pretty": "John 8:31\u201347",
                        "readQS": "John+8:31-47",
                        "audioQS": "John.8"
                    }
                ],
                "readQS": "Psalm+56%3B+Jeremiah+14%3B+John+8:31-47",
                "audioQS": "Ps.56,Jer.14,John.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 59",
                        "readQS": "Psalm+59",
                        "audioQS": "Ps.59"
                    },
                    {
                        "pretty": "Genesis 49:33\u2013end",
                        "readQS": "Genesis+49:33-",
                        "audioQS": "Gen.49"
                    },
                    {
                        "pretty": "Genesis 50",
                        "readQS": "Genesis+50",
                        "audioQS": "Gen.50"
                    },
                    {
                        "pretty": "Hebrews 7:1\u201310",
                        "readQS": "Hebrews+7:1-10",
                        "audioQS": "Heb.7"
                    }
                ],
                "readQS": "Psalm+59%3B+Genesis+49:33-%3B+Genesis+50%3B+Hebrews+7:1-10",
                "audioQS": "Ps.59,Gen.49,Gen.50,Heb.7",
                "note": null
            }
        ]
    },
    "2025/03/28": {
        "datePretty": "Friday, March 28, 2025",
        "calendar": "Week of 3rd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 22",
                        "readQS": "Psalm+22",
                        "audioQS": "Ps.22"
                    },
                    {
                        "pretty": "Jeremiah 15:10\u2013end",
                        "readQS": "Jeremiah+15:10-",
                        "audioQS": "Jer.15"
                    },
                    {
                        "pretty": "John 8:48\u2013end",
                        "readQS": "John+8:48-",
                        "audioQS": "John.8"
                    }
                ],
                "readQS": "Psalm+22%3B+Jeremiah+15:10-%3B+John+8:48-",
                "audioQS": "Ps.22,Jer.15,John.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 69",
                        "readQS": "Psalm+69",
                        "audioQS": "Ps.69"
                    },
                    {
                        "pretty": "Exodus 1:1\u201314",
                        "readQS": "Exodus+1:1-14",
                        "audioQS": "Exod.1"
                    },
                    {
                        "pretty": "Hebrews 7:11\u2013end",
                        "readQS": "Hebrews+7:11-",
                        "audioQS": "Heb.7"
                    }
                ],
                "readQS": "Psalm+69%3B+Exodus+1:1-14%3B+Hebrews+7:11-",
                "audioQS": "Ps.69,Exod.1,Heb.7",
                "note": null
            }
        ]
    },
    "2025/03/29": {
        "datePretty": "Saturday, March 29, 2025",
        "calendar": "Week of 3rd Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 31",
                        "readQS": "Psalm+31",
                        "audioQS": "Ps.31"
                    },
                    {
                        "pretty": "Jeremiah 16:10\u201317:4",
                        "readQS": "Jeremiah+16:10-17:4",
                        "audioQS": "Jer.16"
                    },
                    {
                        "pretty": "John 9:1\u201317",
                        "readQS": "John+9:1-17",
                        "audioQS": "John.9"
                    }
                ],
                "readQS": "Psalm+31%3B+Jeremiah+16:10-17:4%3B+John+9:1-17",
                "audioQS": "Ps.31,Jer.16,John.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 116",
                        "readQS": "Psalm+116",
                        "audioQS": "Ps.116"
                    },
                    {
                        "pretty": "Exodus 1:22\u20132:10",
                        "readQS": "Exodus+1:22-2:10",
                        "audioQS": "Exod.1"
                    },
                    {
                        "pretty": "Hebrews 8",
                        "readQS": "Hebrews+8",
                        "audioQS": "Heb.8"
                    }
                ],
                "readQS": "Psalm+116%3B+Exodus+1:22-2:10%3B+Hebrews+8",
                "audioQS": "Ps.116,Exod.1,Heb.8",
                "note": null
            }
        ]
    },
    "2025/03/30": {
        "datePretty": "Sunday, March 30, 2025",
        "calendar": "4th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 32",
                        "readQS": "Psalm+32",
                        "audioQS": "Ps.32"
                    },
                    {
                        "pretty": "Joshua 5:9\u201312",
                        "readQS": "Joshua+5:9-12",
                        "audioQS": "Josh.5"
                    },
                    {
                        "pretty": "2 Corinthians 5:16\u201321",
                        "readQS": "2+Corinthians+5:16-21",
                        "audioQS": "2Cor.5"
                    },
                    {
                        "pretty": "Luke 15:1\u20133,11b\u201332",
                        "readQS": "Luke+15:1-3,11b-32",
                        "audioQS": "Luke.15"
                    }
                ],
                "readQS": "Psalm+32%3B+Joshua+5:9-12%3B+2+Corinthians+5:16-21%3B+Luke+15:1-3,11b-32",
                "audioQS": "Ps.32,Josh.5,2Cor.5,Luke.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 84,85",
                        "readQS": "Psalm+84,85",
                        "audioQS": "Ps.84,85"
                    },
                    {
                        "pretty": "Genesis 37:3\u20134,12\u201336",
                        "readQS": "Genesis+37:3-4,12-36",
                        "audioQS": "Gen.37"
                    },
                    {
                        "pretty": "1 Peter 2:16\u201325",
                        "readQS": "1+Peter+2:16-25",
                        "audioQS": "1Pet.2"
                    }
                ],
                "readQS": "Psalm+84,85%3B+Genesis+37:3-4,12-36%3B+1+Peter+2:16-25",
                "audioQS": "Ps.84,85,Gen.37,1Pet.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 30",
                        "readQS": "Psalm+30",
                        "audioQS": "Ps.30"
                    },
                    {
                        "pretty": "Isaiah 40:27\u201341:13",
                        "readQS": "Isaiah+40:27-41:13",
                        "audioQS": "Isa.40"
                    },
                    {
                        "pretty": "2 Timothy 4:1\u201318",
                        "readQS": "2+Timothy+4:1-18",
                        "audioQS": "2Tim.4"
                    }
                ],
                "readQS": "Psalm+30%3B+Isaiah+40:27-41:13%3B+2+Timothy+4:1-18",
                "audioQS": "Ps.30,Isa.40,2Tim.4",
                "note": null
            }
        ]
    },
    "2025/03/31": {
        "datePretty": "Monday, March 31, 2025",
        "calendar": "Week of 4th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Jeremiah 17:5\u201318",
                        "readQS": "Jeremiah+17:5-18",
                        "audioQS": "Jer.17"
                    },
                    {
                        "pretty": "John 9:18\u2013end",
                        "readQS": "John+9:18-",
                        "audioQS": "John.9"
                    }
                ],
                "readQS": "Psalm+77%3B+Jeremiah+17:5-18%3B+John+9:18-",
                "audioQS": "Ps.77,Jer.17,John.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 25",
                        "readQS": "Psalm+25",
                        "audioQS": "Ps.25"
                    },
                    {
                        "pretty": "Exodus 2:11\u201322",
                        "readQS": "Exodus+2:11-22",
                        "audioQS": "Exod.2"
                    },
                    {
                        "pretty": "Hebrews 9:1\u201314",
                        "readQS": "Hebrews+9:1-14",
                        "audioQS": "Heb.9"
                    }
                ],
                "readQS": "Psalm+25%3B+Exodus+2:11-22%3B+Hebrews+9:1-14",
                "audioQS": "Ps.25,Exod.2,Heb.9",
                "note": null
            }
        ]
    },
    "2025/04/01": {
        "datePretty": "Tuesday, April 1, 2025",
        "calendar": "Week of 4th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 79",
                        "readQS": "Psalm+79",
                        "audioQS": "Ps.79"
                    },
                    {
                        "pretty": "Jeremiah 18:1\u201312",
                        "readQS": "Jeremiah+18:1-12",
                        "audioQS": "Jer.18"
                    },
                    {
                        "pretty": "John 10:1\u201310",
                        "readQS": "John+10:1-10",
                        "audioQS": "John.10"
                    }
                ],
                "readQS": "Psalm+79%3B+Jeremiah+18:1-12%3B+John+10:1-10",
                "audioQS": "Ps.79,Jer.18,John.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Exodus 2:23\u20133:20",
                        "readQS": "Exodus+2:23-3:20",
                        "audioQS": "Exod.2"
                    },
                    {
                        "pretty": "Hebrews 9:15\u2013end",
                        "readQS": "Hebrews+9:15-",
                        "audioQS": "Heb.9"
                    }
                ],
                "readQS": "Psalm+80%3B+Exodus+2:23-3:20%3B+Hebrews+9:15-",
                "audioQS": "Ps.80,Exod.2,Heb.9",
                "note": null
            }
        ]
    },
    "2025/04/02": {
        "datePretty": "Wednesday, April 2, 2025",
        "calendar": "Week of 4th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 90",
                        "readQS": "Psalm+90",
                        "audioQS": "Ps.90"
                    },
                    {
                        "pretty": "Jeremiah 18:13\u2013end",
                        "readQS": "Jeremiah+18:13-",
                        "audioQS": "Jer.18"
                    },
                    {
                        "pretty": "John 10:11\u201321",
                        "readQS": "John+10:11-21",
                        "audioQS": "John.10"
                    }
                ],
                "readQS": "Psalm+90%3B+Jeremiah+18:13-%3B+John+10:11-21",
                "audioQS": "Ps.90,Jer.18,John.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 91",
                        "readQS": "Psalm+91",
                        "audioQS": "Ps.91"
                    },
                    {
                        "pretty": "Exodus 4:1\u201323",
                        "readQS": "Exodus+4:1-23",
                        "audioQS": "Exod.4"
                    },
                    {
                        "pretty": "Hebrews 10:1\u201318",
                        "readQS": "Hebrews+10:1-18",
                        "audioQS": "Heb.10"
                    }
                ],
                "readQS": "Psalm+91%3B+Exodus+4:1-23%3B+Hebrews+10:1-18",
                "audioQS": "Ps.91,Exod.4,Heb.10",
                "note": null
            }
        ]
    },
    "2025/04/03": {
        "datePretty": "Thursday, April 3, 2025",
        "calendar": "Week of 4th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 86",
                        "readQS": "Psalm+86",
                        "audioQS": "Ps.86"
                    },
                    {
                        "pretty": "Jeremiah 19:1\u201313",
                        "readQS": "Jeremiah+19:1-13",
                        "audioQS": "Jer.19"
                    },
                    {
                        "pretty": "John 10:22\u2013end",
                        "readQS": "John+10:22-",
                        "audioQS": "John.10"
                    }
                ],
                "readQS": "Psalm+86%3B+Jeremiah+19:1-13%3B+John+10:22-",
                "audioQS": "Ps.86,Jer.19,John.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 94",
                        "readQS": "Psalm+94",
                        "audioQS": "Ps.94"
                    },
                    {
                        "pretty": "Exodus 4:27\u20136:1",
                        "readQS": "Exodus+4:27-6:1",
                        "audioQS": "Exod.4"
                    },
                    {
                        "pretty": "Hebrews 10:19\u201325",
                        "readQS": "Hebrews+10:19-25",
                        "audioQS": "Heb.10"
                    }
                ],
                "readQS": "Psalm+94%3B+Exodus+4:27-6:1%3B+Hebrews+10:19-25",
                "audioQS": "Ps.94,Exod.4,Heb.10",
                "note": null
            }
        ]
    },
    "2025/04/04": {
        "datePretty": "Friday, April 4, 2025",
        "calendar": "Week of 4th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 102",
                        "readQS": "Psalm+102",
                        "audioQS": "Ps.102"
                    },
                    {
                        "pretty": "Jeremiah 19:14\u201320:6",
                        "readQS": "Jeremiah+19:14-20:6",
                        "audioQS": "Jer.19"
                    },
                    {
                        "pretty": "John 11:1\u201316",
                        "readQS": "John+11:1-16",
                        "audioQS": "John.11"
                    }
                ],
                "readQS": "Psalm+102%3B+Jeremiah+19:14-20:6%3B+John+11:1-16",
                "audioQS": "Ps.102,Jer.19,John.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 16",
                        "readQS": "Psalm+16",
                        "audioQS": "Ps.16"
                    },
                    {
                        "pretty": "Exodus 6:2\u201313",
                        "readQS": "Exodus+6:2-13",
                        "audioQS": "Exod.6"
                    },
                    {
                        "pretty": "Hebrews 10:26\u2013end",
                        "readQS": "Hebrews+10:26-",
                        "audioQS": "Heb.10"
                    }
                ],
                "readQS": "Psalm+16%3B+Exodus+6:2-13%3B+Hebrews+10:26-",
                "audioQS": "Ps.16,Exod.6,Heb.10",
                "note": null
            }
        ]
    },
    "2025/04/05": {
        "datePretty": "Saturday, April 5, 2025",
        "calendar": "Week of 4th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 32",
                        "readQS": "Psalm+32",
                        "audioQS": "Ps.32"
                    },
                    {
                        "pretty": "Jeremiah 20:7\u2013end",
                        "readQS": "Jeremiah+20:7-",
                        "audioQS": "Jer.20"
                    },
                    {
                        "pretty": "John 11:17\u201327",
                        "readQS": "John+11:17-27",
                        "audioQS": "John.11"
                    }
                ],
                "readQS": "Psalm+32%3B+Jeremiah+20:7-%3B+John+11:17-27",
                "audioQS": "Ps.32,Jer.20,John.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 140",
                        "readQS": "Psalm+140",
                        "audioQS": "Ps.140"
                    },
                    {
                        "pretty": "Exodus 7:8\u2013end",
                        "readQS": "Exodus+7:8-",
                        "audioQS": "Exod.7"
                    },
                    {
                        "pretty": "Hebrews 11:1\u201316",
                        "readQS": "Hebrews+11:1-16",
                        "audioQS": "Heb.11"
                    }
                ],
                "readQS": "Psalm+140%3B+Exodus+7:8-%3B+Hebrews+11:1-16",
                "audioQS": "Ps.140,Exod.7,Heb.11",
                "note": null
            }
        ]
    },
    "2025/04/06": {
        "datePretty": "Sunday, April 6, 2025",
        "calendar": "5th Sunday of Lent - Passion Sunday",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 126",
                        "readQS": "Psalm+126",
                        "audioQS": "Ps.126"
                    },
                    {
                        "pretty": "Isaiah 43:16\u201321",
                        "readQS": "Isaiah+43:16-21",
                        "audioQS": "Isa.43"
                    },
                    {
                        "pretty": "Philippians 3:4b\u201314",
                        "readQS": "Philippians+3:4b-14",
                        "audioQS": "Phil.3"
                    },
                    {
                        "pretty": "John 12:1\u20138",
                        "readQS": "John+12:1-8",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+126%3B+Isaiah+43:16-21%3B+Philippians+3:4b-14%3B+John+12:1-8",
                "audioQS": "Ps.126,Isa.43,Phil.3,John.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 111,112",
                        "readQS": "Psalm+111,112",
                        "audioQS": "Ps.111,112"
                    },
                    {
                        "pretty": "Isaiah 35:1\u201310",
                        "readQS": "Isaiah+35:1-10",
                        "audioQS": "Isa.35"
                    },
                    {
                        "pretty": "Romans 7:21\u20138:4",
                        "readQS": "Romans+7:21-8:4",
                        "audioQS": "Rom.7"
                    }
                ],
                "readQS": "Psalm+111,112%3B+Isaiah+35:1-10%3B+Romans+7:21-8:4",
                "audioQS": "Ps.111,112,Isa.35,Rom.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 35",
                        "readQS": "Psalm+35",
                        "audioQS": "Ps.35"
                    },
                    {
                        "pretty": "2 Chronicles 35:1\u20136,10\u201316",
                        "readQS": "2+Chronicles+35:1-6,10-16",
                        "audioQS": "2Chr.35"
                    },
                    {
                        "pretty": "Luke 22:1\u201313",
                        "readQS": "Luke+22:1-13",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+35%3B+2+Chronicles+35:1-6,10-16%3B+Luke+22:1-13",
                "audioQS": "Ps.35,2Chr.35,Luke.22",
                "note": null
            }
        ]
    },
    "2025/04/07": {
        "datePretty": "Monday, April 7, 2025",
        "calendar": "Week of 5th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Jeremiah 21:1\u201310",
                        "readQS": "Jeremiah+21:1-10",
                        "audioQS": "Jer.21"
                    },
                    {
                        "pretty": "John 11:28\u201344",
                        "readQS": "John+11:28-44",
                        "audioQS": "John.11"
                    }
                ],
                "readQS": "Psalm+73%3B+Jeremiah+21:1-10%3B+John+11:28-44",
                "audioQS": "Ps.73,Jer.21,John.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 26",
                        "readQS": "Psalm+26",
                        "audioQS": "Ps.26"
                    },
                    {
                        "pretty": "Exodus 8:1\u201319",
                        "readQS": "Exodus+8:1-19",
                        "audioQS": "Exod.8"
                    },
                    {
                        "pretty": "Hebrews 11:17\u201331",
                        "readQS": "Hebrews+11:17-31",
                        "audioQS": "Heb.11"
                    }
                ],
                "readQS": "Psalm+26%3B+Exodus+8:1-19%3B+Hebrews+11:17-31",
                "audioQS": "Ps.26,Exod.8,Heb.11",
                "note": null
            }
        ]
    },
    "2025/04/08": {
        "datePretty": "Tuesday, April 8, 2025",
        "calendar": "Week of 5th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 35",
                        "readQS": "Psalm+35",
                        "audioQS": "Ps.35"
                    },
                    {
                        "pretty": "Jeremiah 22:1\u20135,22:13\u201319",
                        "readQS": "Jeremiah+22:1-5,22:13-19",
                        "audioQS": "Jer.22"
                    },
                    {
                        "pretty": "John 11:45\u2013end",
                        "readQS": "John+11:45-",
                        "audioQS": "John.11"
                    }
                ],
                "readQS": "Psalm+35%3B+Jeremiah+22:1-5,22:13-19%3B+John+11:45-",
                "audioQS": "Ps.35,Jer.22,John.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 61",
                        "readQS": "Psalm+61",
                        "audioQS": "Ps.61"
                    },
                    {
                        "pretty": "Exodus 8:20\u2013end",
                        "readQS": "Exodus+8:20-",
                        "audioQS": "Exod.8"
                    },
                    {
                        "pretty": "Hebrews 11:32\u201312:2",
                        "readQS": "Hebrews+11:32-12:2",
                        "audioQS": "Heb.11"
                    }
                ],
                "readQS": "Psalm+61%3B+Exodus+8:20-%3B+Hebrews+11:32-12:2",
                "audioQS": "Ps.61,Exod.8,Heb.11",
                "note": null
            }
        ]
    },
    "2025/04/09": {
        "datePretty": "Wednesday, April 9, 2025",
        "calendar": "Week of 5th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 55",
                        "readQS": "Psalm+55",
                        "audioQS": "Ps.55"
                    },
                    {
                        "pretty": "Jeremiah 22:20\u201323:8",
                        "readQS": "Jeremiah+22:20-23:8",
                        "audioQS": "Jer.22"
                    },
                    {
                        "pretty": "John 12:1\u201311",
                        "readQS": "John+12:1-11",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+55%3B+Jeremiah+22:20-23:8%3B+John+12:1-11",
                "audioQS": "Ps.55,Jer.22,John.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 62",
                        "readQS": "Psalm+62",
                        "audioQS": "Ps.62"
                    },
                    {
                        "pretty": "Exodus 9:1\u201312",
                        "readQS": "Exodus+9:1-12",
                        "audioQS": "Exod.9"
                    },
                    {
                        "pretty": "Hebrews 12:3\u201313",
                        "readQS": "Hebrews+12:3-13",
                        "audioQS": "Heb.12"
                    }
                ],
                "readQS": "Psalm+62%3B+Exodus+9:1-12%3B+Hebrews+12:3-13",
                "audioQS": "Ps.62,Exod.9,Heb.12",
                "note": null
            }
        ]
    },
    "2025/04/10": {
        "datePretty": "Thursday, April 10, 2025",
        "calendar": "Week of 5th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Jeremiah 23:9\u201332",
                        "readQS": "Jeremiah+23:9-32",
                        "audioQS": "Jer.23"
                    },
                    {
                        "pretty": "John 12:12\u201319",
                        "readQS": "John+12:12-19",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+40%3B+Jeremiah+23:9-32%3B+John+12:12-19",
                "audioQS": "Ps.40,Jer.23,John.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 43",
                        "readQS": "Psalm+43",
                        "audioQS": "Ps.43"
                    },
                    {
                        "pretty": "Exodus 9:13\u2013end",
                        "readQS": "Exodus+9:13-",
                        "audioQS": "Exod.9"
                    },
                    {
                        "pretty": "Hebrews 12:14\u2013end",
                        "readQS": "Hebrews+12:14-",
                        "audioQS": "Heb.12"
                    }
                ],
                "readQS": "Psalm+43%3B+Exodus+9:13-%3B+Hebrews+12:14-",
                "audioQS": "Ps.43,Exod.9,Heb.12",
                "note": null
            }
        ]
    },
    "2025/04/11": {
        "datePretty": "Friday, April 11, 2025",
        "calendar": "Week of 5th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 22",
                        "readQS": "Psalm+22",
                        "audioQS": "Ps.22"
                    },
                    {
                        "pretty": "Jeremiah 24",
                        "readQS": "Jeremiah+24",
                        "audioQS": "Jer.24"
                    },
                    {
                        "pretty": "John 12:20\u201336a",
                        "readQS": "John+12:20-36a",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+22%3B+Jeremiah+24%3B+John+12:20-36a",
                "audioQS": "Ps.22,Jer.24,John.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 31",
                        "readQS": "Psalm+31",
                        "audioQS": "Ps.31"
                    },
                    {
                        "pretty": "Exodus 10",
                        "readQS": "Exodus+10",
                        "audioQS": "Exod.10"
                    },
                    {
                        "pretty": "Hebrews 13:1\u201316",
                        "readQS": "Hebrews+13:1-16",
                        "audioQS": "Heb.13"
                    }
                ],
                "readQS": "Psalm+31%3B+Exodus+10%3B+Hebrews+13:1-16",
                "audioQS": "Ps.31,Exod.10,Heb.13",
                "note": null
            }
        ]
    },
    "2025/04/12": {
        "datePretty": "Saturday, April 12, 2025",
        "calendar": "Week of 5th Sunday of Lent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 23",
                        "readQS": "Psalm+23",
                        "audioQS": "Ps.23"
                    },
                    {
                        "pretty": "Jeremiah 25:1\u201314",
                        "readQS": "Jeremiah+25:1-14",
                        "audioQS": "Jer.25"
                    },
                    {
                        "pretty": "John 12:36b\u2013end",
                        "readQS": "John+12:36b-",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+23%3B+Jeremiah+25:1-14%3B+John+12:36b-",
                "audioQS": "Ps.23,Jer.25,John.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 130",
                        "readQS": "Psalm+130",
                        "audioQS": "Ps.130"
                    },
                    {
                        "pretty": "Exodus 11",
                        "readQS": "Exodus+11",
                        "audioQS": "Exod.11"
                    },
                    {
                        "pretty": "Hebrews 13:17\u2013end",
                        "readQS": "Hebrews+13:17-",
                        "audioQS": "Heb.13"
                    }
                ],
                "readQS": "Psalm+130%3B+Exodus+11%3B+Hebrews+13:17-",
                "audioQS": "Ps.130,Exod.11,Heb.13",
                "note": null
            }
        ]
    },
    "2025/04/13": {
        "datePretty": "Sunday, April 13, 2025",
        "calendar": "6th Sunday of Lent - Palm Sunday",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "Passion",
                "readingSetSubtitle": "RCL (Liturgy of the Passion)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 31:9\u201316",
                        "readQS": "Psalm+31:9-16",
                        "audioQS": "Ps.31"
                    },
                    {
                        "pretty": "Isaiah 50:4\u20139a",
                        "readQS": "Isaiah+50:4-9a",
                        "audioQS": "Isa.50"
                    },
                    {
                        "pretty": "Philippians 2:5\u201313",
                        "readQS": "Philippians+2:5-13",
                        "audioQS": "Phil.2"
                    },
                    {
                        "pretty": "Luke 22:14\u201323:56",
                        "readQS": "Luke+22:14-23:56",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+31:9-16%3B+Isaiah+50:4-9a%3B+Philippians+2:5-13%3B+Luke+22:14-23:56",
                "audioQS": "Ps.31,Isa.50,Phil.2,Luke.22",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "Palms",
                "readingSetSubtitle": "RCL (Liturgy of the Palms)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 118:1\u20132,19\u201329",
                        "readQS": "Psalm+118:1-2,19-29",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "",
                        "readQS": "",
                        "audioQS": ""
                    },
                    {
                        "pretty": "",
                        "readQS": "",
                        "audioQS": ""
                    },
                    {
                        "pretty": "Luke 19:28\u201340",
                        "readQS": "Luke+19:28-40",
                        "audioQS": "Luke.19"
                    }
                ],
                "readQS": "Psalm+118:1-2,19-29%3B+%3B+%3B+Luke+19:28-40",
                "audioQS": "Ps.118,,,Luke.19",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 61,62",
                        "readQS": "Psalm+61,62",
                        "audioQS": "Ps.61,62"
                    },
                    {
                        "pretty": "Zechariah 9:9\u201312",
                        "readQS": "Zechariah+9:9-12",
                        "audioQS": "Zech.9"
                    },
                    {
                        "pretty": "1 Corinthians 2:1\u201312",
                        "readQS": "1+Corinthians+2:1-12",
                        "audioQS": "1Cor.2"
                    }
                ],
                "readQS": "Psalm+61,62%3B+Zechariah+9:9-12%3B+1+Corinthians+2:1-12",
                "audioQS": "Ps.61,62,Zech.9,1Cor.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 69:1\u201320",
                        "readQS": "Psalm+69:1-20",
                        "audioQS": "Ps.69"
                    },
                    {
                        "pretty": "Isaiah 5:1\u20137",
                        "readQS": "Isaiah+5:1-7",
                        "audioQS": "Isa.5"
                    },
                    {
                        "pretty": "Luke 20:9\u201319",
                        "readQS": "Luke+20:9-19",
                        "audioQS": "Luke.20"
                    }
                ],
                "readQS": "Psalm+69:1-20%3B+Isaiah+5:1-7%3B+Luke+20:9-19",
                "audioQS": "Ps.69,Isa.5,Luke.20",
                "note": null
            }
        ]
    },
    "2025/04/14": {
        "datePretty": "Monday, April 14, 2025",
        "calendar": "Monday in Holy Week",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 36:5\u201311",
                        "readQS": "Psalm+36:5-11",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "Isaiah 42:1\u20139",
                        "readQS": "Isaiah+42:1-9",
                        "audioQS": "Isa.42"
                    },
                    {
                        "pretty": "Hebrews 9:11\u201315",
                        "readQS": "Hebrews+9:11-15",
                        "audioQS": "Heb.9"
                    },
                    {
                        "pretty": "John 12:1\u201311",
                        "readQS": "John+12:1-11",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+36:5-11%3B+Isaiah+42:1-9%3B+Hebrews+9:11-15%3B+John+12:1-11",
                "audioQS": "Ps.36,Isa.42,Heb.9,John.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 41",
                        "readQS": "Psalm+41",
                        "audioQS": "Ps.41"
                    },
                    {
                        "pretty": "Lamentations 1:1\u201312a",
                        "readQS": "Lamentations+1:1-12a",
                        "audioQS": "Lam.1"
                    },
                    {
                        "pretty": "Luke 22:1\u201323",
                        "readQS": "Luke+22:1-23",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+41%3B+Lamentations+1:1-12a%3B+Luke+22:1-23",
                "audioQS": "Ps.41,Lam.1,Luke.22",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 25",
                        "readQS": "Psalm+25",
                        "audioQS": "Ps.25"
                    },
                    {
                        "pretty": "Lamentations 2:8\u201319",
                        "readQS": "Lamentations+2:8-19",
                        "audioQS": "Lam.2"
                    },
                    {
                        "pretty": "Colossians 1:18\u201323",
                        "readQS": "Colossians+1:18-23",
                        "audioQS": "Col.1"
                    }
                ],
                "readQS": "Psalm+25%3B+Lamentations+2:8-19%3B+Colossians+1:18-23",
                "audioQS": "Ps.25,Lam.2,Col.1",
                "note": null
            }
        ]
    },
    "2025/04/15": {
        "datePretty": "Tuesday, April 15, 2025",
        "calendar": "Tuesday in Holy Week",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 71:(1\u20138),9\u201314",
                        "readQS": "Psalm+71:%281-8%29,9-14",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Isaiah 49:1\u20137",
                        "readQS": "Isaiah+49:1-7",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "1 Corinthians 1:18\u201331",
                        "readQS": "1+Corinthians+1:18-31",
                        "audioQS": "1Cor.1"
                    },
                    {
                        "pretty": "John 12:20\u201336",
                        "readQS": "John+12:20-36",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+71:%281-8%29,9-14%3B+Isaiah+49:1-7%3B+1+Corinthians+1:18-31%3B+John+12:20-36",
                "audioQS": "Ps.71,Isa.49,1Cor.1,John.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 27",
                        "readQS": "Psalm+27",
                        "audioQS": "Ps.27"
                    },
                    {
                        "pretty": "Lamentations 3:1\u201318",
                        "readQS": "Lamentations+3:1-18",
                        "audioQS": "Lam.3"
                    },
                    {
                        "pretty": "Luke 22:(24\u201338),39\u201353",
                        "readQS": "Luke+22:%2824-38%29,39-53",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+27%3B+Lamentations+3:1-18%3B+Luke+22:%2824-38%29,39-53",
                "audioQS": "Ps.27,Lam.3,Luke.22",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 55:13\u201324",
                        "readQS": "Psalm+55:13-24",
                        "audioQS": "Ps.55"
                    },
                    {
                        "pretty": "Lamentations 3:40\u201351",
                        "readQS": "Lamentations+3:40-51",
                        "audioQS": "Lam.3"
                    },
                    {
                        "pretty": "Galatians 6:11\u2013end",
                        "readQS": "Galatians+6:11-",
                        "audioQS": "Gal.6"
                    }
                ],
                "readQS": "Psalm+55:13-24%3B+Lamentations+3:40-51%3B+Galatians+6:11-",
                "audioQS": "Ps.55,Lam.3,Gal.6",
                "note": null
            }
        ]
    },
    "2025/04/16": {
        "datePretty": "Wednesday, April 16, 2025",
        "calendar": "Wednesday in Holy Week",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 70",
                        "readQS": "Psalm+70",
                        "audioQS": "Ps.70"
                    },
                    {
                        "pretty": "Isaiah 50:4\u20139a",
                        "readQS": "Isaiah+50:4-9a",
                        "audioQS": "Isa.50"
                    },
                    {
                        "pretty": "Hebrews 12:1\u20133",
                        "readQS": "Hebrews+12:1-3",
                        "audioQS": "Heb.12"
                    },
                    {
                        "pretty": "John 13:21\u201332",
                        "readQS": "John+13:21-32",
                        "audioQS": "John.13"
                    }
                ],
                "readQS": "Psalm+70%3B+Isaiah+50:4-9a%3B+Hebrews+12:1-3%3B+John+13:21-32",
                "audioQS": "Ps.70,Isa.50,Heb.12,John.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 102",
                        "readQS": "Psalm+102",
                        "audioQS": "Ps.102"
                    },
                    {
                        "pretty": "Jeremiah 11:18\u201320",
                        "readQS": "Jeremiah+11:18-20",
                        "audioQS": "Jer.11"
                    },
                    {
                        "pretty": "Luke 22:54\u201371",
                        "readQS": "Luke+22:54-71",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+102%3B+Jeremiah+11:18-20%3B+Luke+22:54-71",
                "audioQS": "Ps.102,Jer.11,Luke.22",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 88",
                        "readQS": "Psalm+88",
                        "audioQS": "Ps.88"
                    },
                    {
                        "pretty": "Isaiah 63:1\u20139",
                        "readQS": "Isaiah+63:1-9",
                        "audioQS": "Isa.63"
                    },
                    {
                        "pretty": "Revelation 14:18\u201315:4",
                        "readQS": "Revelation+14:18-15:4",
                        "audioQS": "Rev.14"
                    }
                ],
                "readQS": "Psalm+88%3B+Isaiah+63:1-9%3B+Revelation+14:18-15:4",
                "audioQS": "Ps.88,Isa.63,Rev.14",
                "note": null
            }
        ]
    },
    "2025/04/17": {
        "datePretty": "Thursday, April 17, 2025",
        "calendar": "Maundy Thursday",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 116:1,10\u201317",
                        "readQS": "Psalm+116:1,10-17",
                        "audioQS": "Ps.116"
                    },
                    {
                        "pretty": "Exodus 12:1\u20134,(5\u201310),11\u201314",
                        "readQS": "Exodus+12:1-4,%285-10%29,11-14",
                        "audioQS": "Exod.12"
                    },
                    {
                        "pretty": "1 Corinthians 11:23\u201326",
                        "readQS": "1+Corinthians+11:23-26",
                        "audioQS": "1Cor.11"
                    },
                    {
                        "pretty": "John 13:1\u201317,31b\u201335",
                        "readQS": "John+13:1-17,31b-35",
                        "audioQS": "John.13"
                    }
                ],
                "readQS": "Psalm+116:1,10-17%3B+Exodus+12:1-4,%285-10%29,11-14%3B+1+Corinthians+11:23-26%3B+John+13:1-17,31b-35",
                "audioQS": "Ps.116,Exod.12,1Cor.11,John.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 42,43",
                        "readQS": "Psalm+42,43",
                        "audioQS": "Ps.42,43"
                    },
                    {
                        "pretty": "Leviticus 16:2\u201324",
                        "readQS": "Leviticus+16:2-24",
                        "audioQS": "Lev.16"
                    },
                    {
                        "pretty": "Luke 23:1\u201325",
                        "readQS": "Luke+23:1-25",
                        "audioQS": "Luke.23"
                    }
                ],
                "readQS": "Psalm+42,43%3B+Leviticus+16:2-24%3B+Luke+23:1-25",
                "audioQS": "Ps.42,43,Lev.16,Luke.23",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 39",
                        "readQS": "Psalm+39",
                        "audioQS": "Ps.39"
                    },
                    {
                        "pretty": "Exodus 11",
                        "readQS": "Exodus+11",
                        "audioQS": "Exod.11"
                    },
                    {
                        "pretty": "Ephesians 2:11\u201318",
                        "readQS": "Ephesians+2:11-18",
                        "audioQS": "Eph.2"
                    }
                ],
                "readQS": "Psalm+39%3B+Exodus+11%3B+Ephesians+2:11-18",
                "audioQS": "Ps.39,Exod.11,Eph.2",
                "note": null
            }
        ]
    },
    "2025/04/18": {
        "datePretty": "Friday, April 18, 2025",
        "calendar": "Good Friday",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 22",
                        "readQS": "Psalm+22",
                        "audioQS": "Ps.22"
                    },
                    {
                        "pretty": "Isaiah 52:13\u201353:12",
                        "readQS": "Isaiah+52:13-53:12",
                        "audioQS": "Isa.52"
                    },
                    {
                        "pretty": "Hebrews 10:16\u201325",
                        "readQS": "Hebrews+10:16-25",
                        "audioQS": "Heb.10"
                    },
                    {
                        "pretty": "John 18:1\u201319:42",
                        "readQS": "John+18:1-19:42",
                        "audioQS": "John.18"
                    }
                ],
                "readQS": "Psalm+22%3B+Isaiah+52:13-53:12%3B+Hebrews+10:16-25%3B+John+18:1-19:42",
                "audioQS": "Ps.22,Isa.52,Heb.10,John.18",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 69",
                        "readQS": "Psalm+69",
                        "audioQS": "Ps.69"
                    },
                    {
                        "pretty": "Genesis 22:1\u201318",
                        "readQS": "Genesis+22:1-18",
                        "audioQS": "Gen.22"
                    },
                    {
                        "pretty": "Hebrews 10:1\u201310",
                        "readQS": "Hebrews+10:1-10",
                        "audioQS": "Heb.10"
                    }
                ],
                "readQS": "Psalm+69%3B+Genesis+22:1-18%3B+Hebrews+10:1-10",
                "audioQS": "Ps.69,Gen.22,Heb.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 130,143",
                        "readQS": "Psalm+130,143",
                        "audioQS": "Ps.130,143"
                    },
                    {
                        "pretty": "Lamentations 5:15\u201322",
                        "readQS": "Lamentations+5:15-22",
                        "audioQS": "Lam.5"
                    },
                    {
                        "pretty": "John 19:38\u201342",
                        "readQS": "John+19:38-42",
                        "audioQS": "John.19"
                    }
                ],
                "readQS": "Psalm+130,143%3B+Lamentations+5:15-22%3B+John+19:38-42",
                "audioQS": "Ps.130,143,Lam.5,John.19",
                "note": null
            }
        ]
    },
    "2025/04/19": {
        "datePretty": "Saturday, April 19, 2025",
        "calendar": "Holy Saturday",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "Holy Sat.",
                "readingSetSubtitle": "RCL (Holy Saturday)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 31:1\u20134,15,16",
                        "readQS": "Psalm+31:1-4,15,16",
                        "audioQS": "Ps.31"
                    },
                    {
                        "pretty": "Job 14:1\u201314",
                        "readQS": "Job+14:1-14",
                        "audioQS": "Job.14"
                    },
                    {
                        "pretty": "1 Peter 4:1\u20138",
                        "readQS": "1+Peter+4:1-8",
                        "audioQS": "1Pet.4"
                    },
                    {
                        "pretty": "Matthew 27:57\u201366",
                        "readQS": "Matthew+27:57-66",
                        "audioQS": "Matt.27"
                    }
                ],
                "readQS": "Psalm+31:1-4,15,16%3B+Job+14:1-14%3B+1+Peter+4:1-8%3B+Matthew+27:57-66",
                "audioQS": "Ps.31,Job.14,1Pet.4,Matt.27",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "Vigil",
                "readingSetSubtitle": "RCL (Easter Vigil)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 114",
                        "readQS": "Psalm+114",
                        "audioQS": "Ps.114"
                    },
                    {
                        "pretty": "Canticle: Exodus 15:1b\u201313,17\u201318",
                        "readQS": "Exodus+15:1b-13,17-18",
                        "audioQS": "Exod.15"
                    },
                    {
                        "pretty": "Exodus 14:10\u201331,15:20\u201321",
                        "readQS": "Exodus+14:10-31,15:20-21",
                        "audioQS": "Exod.14"
                    },
                    {
                        "pretty": "",
                        "readQS": "",
                        "audioQS": ""
                    },
                    {
                        "pretty": "Romans 6:3\u201311",
                        "readQS": "Romans+6:3-11",
                        "audioQS": "Rom.6"
                    },
                    {
                        "pretty": "Luke 24:1\u201312",
                        "readQS": "Luke+24:1-12",
                        "audioQS": "Luke.24"
                    }
                ],
                "readQS": "Psalm+114%3B+Canticle:+Exodus+15:1b-13,17-18%3B+Exodus+14:10-31,15:20-21%3B+%3B+Romans+6:3-11%3B+Luke+24:1-12",
                "audioQS": "Ps.114,Canticle,Exod.14,,Rom.6,Luke.24",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 142",
                        "readQS": "Psalm+142",
                        "audioQS": "Ps.142"
                    },
                    {
                        "pretty": "Hosea 6:1\u20136",
                        "readQS": "Hosea+6:1-6",
                        "audioQS": "Hos.6"
                    },
                    {
                        "pretty": "John 2:18\u201322",
                        "readQS": "John+2:18-22",
                        "audioQS": "John.2"
                    }
                ],
                "readQS": "Psalm+142%3B+Hosea+6:1-6%3B+John+2:18-22",
                "audioQS": "Ps.142,Hos.6,John.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 116",
                        "readQS": "Psalm+116",
                        "audioQS": "Ps.116"
                    },
                    {
                        "pretty": "Job 19:21\u201327",
                        "readQS": "Job+19:21-27",
                        "audioQS": "Job.19"
                    },
                    {
                        "pretty": "1 John 5:5\u201312",
                        "readQS": "1+John+5:5-12",
                        "audioQS": "1John.5"
                    }
                ],
                "readQS": "Psalm+116%3B+Job+19:21-27%3B+1+John+5:5-12",
                "audioQS": "Ps.116,Job.19,1John.5",
                "note": null
            }
        ]
    },
    "2025/04/20": {
        "datePretty": "Sunday, April 20, 2025",
        "calendar": "Easter Sunday",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 118:1\u20132,14\u201324",
                        "readQS": "Psalm+118:1-2,14-24",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "Acts 10:34\u201343",
                        "readQS": "Acts+10:34-43",
                        "audioQS": "Acts.10"
                    },
                    {
                        "pretty": "1 Corinthians 15:19\u201326",
                        "readQS": "1+Corinthians+15:19-26",
                        "audioQS": "1Cor.15"
                    },
                    {
                        "pretty": "John 20:1\u201318",
                        "readQS": "John+20:1-18",
                        "audioQS": "John.20"
                    }
                ],
                "readQS": "Psalm+118:1-2,14-24%3B+Acts+10:34-43%3B+1+Corinthians+15:19-26%3B+John+20:1-18",
                "audioQS": "Ps.118,Acts.10,1Cor.15,John.20",
                "note": "The reading from Acts should be used as the 1st or 2nd reading."
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 114,117",
                        "readQS": "Psalm+114,117",
                        "audioQS": "Ps.114,117"
                    },
                    {
                        "pretty": "Ezekiel 47:1\u201312",
                        "readQS": "Ezekiel+47:1-12",
                        "audioQS": "Ezek.47"
                    },
                    {
                        "pretty": "John 2:13\u201322",
                        "readQS": "John+2:13-22",
                        "audioQS": "John.2"
                    }
                ],
                "readQS": "Psalm+114,117%3B+Ezekiel+47:1-12%3B+John+2:13-22",
                "audioQS": "Ps.114,117,Ezek.47,John.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 105",
                        "readQS": "Psalm+105",
                        "audioQS": "Ps.105"
                    },
                    {
                        "pretty": "Isaiah 43:1\u201321",
                        "readQS": "Isaiah+43:1-21",
                        "audioQS": "Isa.43"
                    },
                    {
                        "pretty": "1 Corinthians 15:1\u201311",
                        "readQS": "1+Corinthians+15:1-11",
                        "audioQS": "1Cor.15"
                    }
                ],
                "readQS": "Psalm+105%3B+Isaiah+43:1-21%3B+1+Corinthians+15:1-11",
                "audioQS": "Ps.105,Isa.43,1Cor.15",
                "note": null
            }
        ]
    },
    "2025/04/21": {
        "datePretty": "Monday, April 21, 2025",
        "calendar": "Monday in Easter Week",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 111",
                        "readQS": "Psalm+111",
                        "audioQS": "Ps.111"
                    },
                    {
                        "pretty": "Song of Solomon 1:9\u20132:7",
                        "readQS": "Song+of+Solomon+1:9-2:7",
                        "audioQS": "Song.1"
                    },
                    {
                        "pretty": "Mark 16:1\u20138",
                        "readQS": "Mark+16:1-8",
                        "audioQS": "Mark.16"
                    }
                ],
                "readQS": "Psalm+111%3B+Song+of+Solomon+1:9-2:7%3B+Mark+16:1-8",
                "audioQS": "Ps.111,Song.1,Mark.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 135",
                        "readQS": "Psalm+135",
                        "audioQS": "Ps.135"
                    },
                    {
                        "pretty": "Exodus 12:1\u201314",
                        "readQS": "Exodus+12:1-14",
                        "audioQS": "Exod.12"
                    },
                    {
                        "pretty": "1 Corinthians 15:1\u201311",
                        "readQS": "1+Corinthians+15:1-11",
                        "audioQS": "1Cor.15"
                    }
                ],
                "readQS": "Psalm+135%3B+Exodus+12:1-14%3B+1+Corinthians+15:1-11",
                "audioQS": "Ps.135,Exod.12,1Cor.15",
                "note": null
            }
        ]
    },
    "2025/04/22": {
        "datePretty": "Tuesday, April 22, 2025",
        "calendar": "Tuesday in Easter Week",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 112",
                        "readQS": "Psalm+112",
                        "audioQS": "Ps.112"
                    },
                    {
                        "pretty": "Song of Solomon 2:8\u2013end",
                        "readQS": "Song+of+Solomon+2:8-",
                        "audioQS": "Song.2"
                    },
                    {
                        "pretty": "Luke 24:1\u201312",
                        "readQS": "Luke+24:1-12",
                        "audioQS": "Luke.24"
                    }
                ],
                "readQS": "Psalm+112%3B+Song+of+Solomon+2:8-%3B+Luke+24:1-12",
                "audioQS": "Ps.112,Song.2,Luke.24",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 136",
                        "readQS": "Psalm+136",
                        "audioQS": "Ps.136"
                    },
                    {
                        "pretty": "Exodus 12:14\u201336",
                        "readQS": "Exodus+12:14-36",
                        "audioQS": "Exod.12"
                    },
                    {
                        "pretty": "1 Corinthians 15:12\u201319",
                        "readQS": "1+Corinthians+15:12-19",
                        "audioQS": "1Cor.15"
                    }
                ],
                "readQS": "Psalm+136%3B+Exodus+12:14-36%3B+1+Corinthians+15:12-19",
                "audioQS": "Ps.136,Exod.12,1Cor.15",
                "note": null
            }
        ]
    },
    "2025/04/23": {
        "datePretty": "Wednesday, April 23, 2025",
        "calendar": "Wednesday in Easter Week",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 113",
                        "readQS": "Psalm+113",
                        "audioQS": "Ps.113"
                    },
                    {
                        "pretty": "Song of Solomon 3",
                        "readQS": "Song+of+Solomon+3",
                        "audioQS": "Song.3"
                    },
                    {
                        "pretty": "Matthew 28:16\u2013end",
                        "readQS": "Matthew+28:16-",
                        "audioQS": "Matt.28"
                    }
                ],
                "readQS": "Psalm+113%3B+Song+of+Solomon+3%3B+Matthew+28:16-",
                "audioQS": "Ps.113,Song.3,Matt.28",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 105",
                        "readQS": "Psalm+105",
                        "audioQS": "Ps.105"
                    },
                    {
                        "pretty": "Exodus 12:37\u2013end",
                        "readQS": "Exodus+12:37-",
                        "audioQS": "Exod.12"
                    },
                    {
                        "pretty": "1 Corinthians 15:20\u201328",
                        "readQS": "1+Corinthians+15:20-28",
                        "audioQS": "1Cor.15"
                    }
                ],
                "readQS": "Psalm+105%3B+Exodus+12:37-%3B+1+Corinthians+15:20-28",
                "audioQS": "Ps.105,Exod.12,1Cor.15",
                "note": null
            }
        ]
    },
    "2025/04/24": {
        "datePretty": "Thursday, April 24, 2025",
        "calendar": "Thursday in Easter Week",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 114",
                        "readQS": "Psalm+114",
                        "audioQS": "Ps.114"
                    },
                    {
                        "pretty": "Song of Solomon 5:2\u20136:3",
                        "readQS": "Song+of+Solomon+5:2-6:3",
                        "audioQS": "Song.5"
                    },
                    {
                        "pretty": "Luke 7:11\u201317",
                        "readQS": "Luke+7:11-17",
                        "audioQS": "Luke.7"
                    }
                ],
                "readQS": "Psalm+114%3B+Song+of+Solomon+5:2-6:3%3B+Luke+7:11-17",
                "audioQS": "Ps.114,Song.5,Luke.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 106",
                        "readQS": "Psalm+106",
                        "audioQS": "Ps.106"
                    },
                    {
                        "pretty": "Exodus 13:1\u201316",
                        "readQS": "Exodus+13:1-16",
                        "audioQS": "Exod.13"
                    },
                    {
                        "pretty": "1 Corinthians 15:29\u201334",
                        "readQS": "1+Corinthians+15:29-34",
                        "audioQS": "1Cor.15"
                    }
                ],
                "readQS": "Psalm+106%3B+Exodus+13:1-16%3B+1+Corinthians+15:29-34",
                "audioQS": "Ps.106,Exod.13,1Cor.15",
                "note": null
            }
        ]
    },
    "2025/04/25": {
        "datePretty": "Friday, April 25, 2025",
        "calendar": "Friday in Easter Week",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 115",
                        "readQS": "Psalm+115",
                        "audioQS": "Ps.115"
                    },
                    {
                        "pretty": "Song of Solomon 7:10\u20138:4",
                        "readQS": "Song+of+Solomon+7:10-8:4",
                        "audioQS": "Song.7"
                    },
                    {
                        "pretty": "Luke 8:41\u2013end",
                        "readQS": "Luke+8:41-",
                        "audioQS": "Luke.8"
                    }
                ],
                "readQS": "Psalm+115%3B+Song+of+Solomon+7:10-8:4%3B+Luke+8:41-",
                "audioQS": "Ps.115,Song.7,Luke.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 107",
                        "readQS": "Psalm+107",
                        "audioQS": "Ps.107"
                    },
                    {
                        "pretty": "Exodus 13:17\u201314:14",
                        "readQS": "Exodus+13:17-14:14",
                        "audioQS": "Exod.13"
                    },
                    {
                        "pretty": "1 Corinthians 15:35\u201350",
                        "readQS": "1+Corinthians+15:35-50",
                        "audioQS": "1Cor.15"
                    }
                ],
                "readQS": "Psalm+107%3B+Exodus+13:17-14:14%3B+1+Corinthians+15:35-50",
                "audioQS": "Ps.107,Exod.13,1Cor.15",
                "note": null
            }
        ]
    },
    "2025/04/26": {
        "datePretty": "Saturday, April 26, 2025",
        "calendar": "Saturday in Easter Week",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 116",
                        "readQS": "Psalm+116",
                        "audioQS": "Ps.116"
                    },
                    {
                        "pretty": "Song of Solomon 8:5\u20137",
                        "readQS": "Song+of+Solomon+8:5-7",
                        "audioQS": "Song.8"
                    },
                    {
                        "pretty": "John 11:17\u201344",
                        "readQS": "John+11:17-44",
                        "audioQS": "John.11"
                    }
                ],
                "readQS": "Psalm+116%3B+Song+of+Solomon+8:5-7%3B+John+11:17-44",
                "audioQS": "Ps.116,Song.8,John.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Exodus 14:15\u2013end",
                        "readQS": "Exodus+14:15-",
                        "audioQS": "Exod.14"
                    },
                    {
                        "pretty": "1 Corinthians 15:51\u2013end",
                        "readQS": "1+Corinthians+15:51-",
                        "audioQS": "1Cor.15"
                    }
                ],
                "readQS": "Psalm+145%3B+Exodus+14:15-%3B+1+Corinthians+15:51-",
                "audioQS": "Ps.145,Exod.14,1Cor.15",
                "note": null
            }
        ]
    },
    "2025/04/27": {
        "datePretty": "Sunday, April 27, 2025",
        "calendar": "2nd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 118:14\u201329",
                        "readQS": "Psalm+118:14-29",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "Acts 5:27\u201332",
                        "readQS": "Acts+5:27-32",
                        "audioQS": "Acts.5"
                    },
                    {
                        "pretty": "Revelation 1:4\u20138",
                        "readQS": "Revelation+1:4-8",
                        "audioQS": "Rev.1"
                    },
                    {
                        "pretty": "John 20:19\u201331",
                        "readQS": "John+20:19-31",
                        "audioQS": "John.20"
                    }
                ],
                "readQS": "Psalm+118:14-29%3B+Acts+5:27-32%3B+Revelation+1:4-8%3B+John+20:19-31",
                "audioQS": "Ps.118,Acts.5,Rev.1,John.20",
                "note": "The reading from Acts must be used as either the first or second reading."
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 136:1\u201316",
                        "readQS": "Psalm+136:1-16",
                        "audioQS": "Ps.136"
                    },
                    {
                        "pretty": "Exodus 12:1\u201313",
                        "readQS": "Exodus+12:1-13",
                        "audioQS": "Exod.12"
                    },
                    {
                        "pretty": "1 Peter 1:3\u201312",
                        "readQS": "1+Peter+1:3-12",
                        "audioQS": "1Pet.1"
                    }
                ],
                "readQS": "Psalm+136:1-16%3B+Exodus+12:1-13%3B+1+Peter+1:3-12",
                "audioQS": "Ps.136,Exod.12,1Pet.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Mark)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 19",
                        "readQS": "Psalm+19",
                        "audioQS": "Ps.19"
                    },
                    {
                        "pretty": "Isaiah 52:7\u201310",
                        "readQS": "Isaiah+52:7-10",
                        "audioQS": "Isa.52"
                    },
                    {
                        "pretty": "Mark 1:1\u201315",
                        "readQS": "Mark+1:1-15",
                        "audioQS": "Mark.1"
                    }
                ],
                "readQS": "Psalm+19%3B+Isaiah+52:7-10%3B+Mark+1:1-15",
                "audioQS": "Ps.19,Isa.52,Mark.1",
                "note": null
            }
        ]
    },
    "2025/04/28": {
        "datePretty": "Monday, April 28, 2025",
        "calendar": "St Mark the Evangelist",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 37:23\u201341,148",
                        "readQS": "Psalm+37:23-41,148",
                        "audioQS": "Ps.37"
                    },
                    {
                        "pretty": "Isaiah 62:6\u201310",
                        "readQS": "Isaiah+62:6-10",
                        "audioQS": "Isa.62"
                    },
                    {
                        "pretty": "Acts 12:25\u2013end,13:1\u201313",
                        "readQS": "Acts+12:25-,13:1-13",
                        "audioQS": "Acts.12"
                    }
                ],
                "readQS": "Psalm+37:23-41,148%3B+Isaiah+62:6-10%3B+Acts+12:25-,13:1-13",
                "audioQS": "Ps.37,Isa.62,Acts.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 45",
                        "readQS": "Psalm+45",
                        "audioQS": "Ps.45"
                    },
                    {
                        "pretty": "Ezekiel 1:4\u201314",
                        "readQS": "Ezekiel+1:4-14",
                        "audioQS": "Ezek.1"
                    },
                    {
                        "pretty": "2 Timothy 4:1\u201311",
                        "readQS": "2+Timothy+4:1-11",
                        "audioQS": "2Tim.4"
                    }
                ],
                "readQS": "Psalm+45%3B+Ezekiel+1:4-14%3B+2+Timothy+4:1-11",
                "audioQS": "Ps.45,Ezek.1,2Tim.4",
                "note": null
            }
        ]
    },
    "2025/04/29": {
        "datePretty": "Tuesday, April 29, 2025",
        "calendar": "Week of 2nd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 8",
                        "readQS": "Psalm+8",
                        "audioQS": "Ps.8"
                    },
                    {
                        "pretty": "Deuteronomy 1:19\u201340",
                        "readQS": "Deuteronomy+1:19-40",
                        "audioQS": "Deut.1"
                    },
                    {
                        "pretty": "John 20:11\u201318",
                        "readQS": "John+20:11-18",
                        "audioQS": "John.20"
                    }
                ],
                "readQS": "Psalm+8%3B+Deuteronomy+1:19-40%3B+John+20:11-18",
                "audioQS": "Ps.8,Deut.1,John.20",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 104",
                        "readQS": "Psalm+104",
                        "audioQS": "Ps.104"
                    },
                    {
                        "pretty": "Exodus 15:22\u201316:10",
                        "readQS": "Exodus+15:22-16:10",
                        "audioQS": "Exod.15"
                    },
                    {
                        "pretty": "Colossians 1:15\u2013end",
                        "readQS": "Colossians+1:15-",
                        "audioQS": "Col.1"
                    }
                ],
                "readQS": "Psalm+104%3B+Exodus+15:22-16:10%3B+Colossians+1:15-",
                "audioQS": "Ps.104,Exod.15,Col.1",
                "note": null
            }
        ]
    },
    "2025/04/30": {
        "datePretty": "Wednesday, April 30, 2025",
        "calendar": "Week of 2nd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 30",
                        "readQS": "Psalm+30",
                        "audioQS": "Ps.30"
                    },
                    {
                        "pretty": "Deuteronomy 3:18\u2013end",
                        "readQS": "Deuteronomy+3:18-",
                        "audioQS": "Deut.3"
                    },
                    {
                        "pretty": "John 20:19\u2013end",
                        "readQS": "John+20:19-",
                        "audioQS": "John.20"
                    }
                ],
                "readQS": "Psalm+30%3B+Deuteronomy+3:18-%3B+John+20:19-",
                "audioQS": "Ps.30,Deut.3,John.20",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Phillip and St James)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 25",
                        "readQS": "Psalm+25",
                        "audioQS": "Ps.25"
                    },
                    {
                        "pretty": "Isaiah 40:27\u201331",
                        "readQS": "Isaiah+40:27-31",
                        "audioQS": "Isa.40"
                    },
                    {
                        "pretty": "John 12:20\u201326",
                        "readQS": "John+12:20-26",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+25%3B+Isaiah+40:27-31%3B+John+12:20-26",
                "audioQS": "Ps.25,Isa.40,John.12",
                "note": null
            }
        ]
    },
    "2025/05/01": {
        "datePretty": "Thursday, May 1, 2025",
        "calendar": "St Phillip and St James, Apostles",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 139,146",
                        "readQS": "Psalm+139,146",
                        "audioQS": "Ps.139,146"
                    },
                    {
                        "pretty": "Proverbs 4:10\u201318",
                        "readQS": "Proverbs+4:10-18",
                        "audioQS": "Prov.4"
                    },
                    {
                        "pretty": "James 1:1\u201312",
                        "readQS": "James+1:1-12",
                        "audioQS": "Jas.1"
                    }
                ],
                "readQS": "Psalm+139,146%3B+Proverbs+4:10-18%3B+James+1:1-12",
                "audioQS": "Ps.139,146,Prov.4,Jas.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 149",
                        "readQS": "Psalm+149",
                        "audioQS": "Ps.149"
                    },
                    {
                        "pretty": "Job 23:1\u201312",
                        "readQS": "Job+23:1-12",
                        "audioQS": "Job.23"
                    },
                    {
                        "pretty": "John 1:43\u201351",
                        "readQS": "John+1:43-51",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+149%3B+Job+23:1-12%3B+John+1:43-51",
                "audioQS": "Ps.149,Job.23,John.1",
                "note": null
            }
        ]
    },
    "2025/05/02": {
        "datePretty": "Friday, May 2, 2025",
        "calendar": "Week of 2nd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 61",
                        "readQS": "Psalm+61",
                        "audioQS": "Ps.61"
                    },
                    {
                        "pretty": "Deuteronomy 4:15\u201331",
                        "readQS": "Deuteronomy+4:15-31",
                        "audioQS": "Deut.4"
                    },
                    {
                        "pretty": "John 21:15\u201319",
                        "readQS": "John+21:15-19",
                        "audioQS": "John.21"
                    }
                ],
                "readQS": "Psalm+61%3B+Deuteronomy+4:15-31%3B+John+21:15-19",
                "audioQS": "Ps.61,Deut.4,John.21",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 118",
                        "readQS": "Psalm+118",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "Exodus 18:1\u201312",
                        "readQS": "Exodus+18:1-12",
                        "audioQS": "Exod.18"
                    },
                    {
                        "pretty": "Colossians 3:12\u20134:1",
                        "readQS": "Colossians+3:12-4:1",
                        "audioQS": "Col.3"
                    }
                ],
                "readQS": "Psalm+118%3B+Exodus+18:1-12%3B+Colossians+3:12-4:1",
                "audioQS": "Ps.118,Exod.18,Col.3",
                "note": null
            }
        ]
    },
    "2025/05/03": {
        "datePretty": "Saturday, May 3, 2025",
        "calendar": "Week of 2nd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 84",
                        "readQS": "Psalm+84",
                        "audioQS": "Ps.84"
                    },
                    {
                        "pretty": "Deuteronomy 4:32\u201340",
                        "readQS": "Deuteronomy+4:32-40",
                        "audioQS": "Deut.4"
                    },
                    {
                        "pretty": "John 21:20\u2013end",
                        "readQS": "John+21:20-",
                        "audioQS": "John.21"
                    }
                ],
                "readQS": "Psalm+84%3B+Deuteronomy+4:32-40%3B+John+21:20-",
                "audioQS": "Ps.84,Deut.4,John.21",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 66",
                        "readQS": "Psalm+66",
                        "audioQS": "Ps.66"
                    },
                    {
                        "pretty": "Exodus 18:13\u2013end",
                        "readQS": "Exodus+18:13-",
                        "audioQS": "Exod.18"
                    },
                    {
                        "pretty": "Colossians 4:2\u2013end",
                        "readQS": "Colossians+4:2-",
                        "audioQS": "Col.4"
                    }
                ],
                "readQS": "Psalm+66%3B+Exodus+18:13-%3B+Colossians+4:2-",
                "audioQS": "Ps.66,Exod.18,Col.4",
                "note": null
            }
        ]
    },
    "2025/05/04": {
        "datePretty": "Sunday, May 4, 2025",
        "calendar": "3rd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 30",
                        "readQS": "Psalm+30",
                        "audioQS": "Ps.30"
                    },
                    {
                        "pretty": "Acts 9:1\u20136,(7\u201320)",
                        "readQS": "Acts+9:1-6,%287-20%29",
                        "audioQS": "Acts.9"
                    },
                    {
                        "pretty": "Revelation 5:11\u201314",
                        "readQS": "Revelation+5:11-14",
                        "audioQS": "Rev.5"
                    },
                    {
                        "pretty": "John 21:1\u201319",
                        "readQS": "John+21:1-19",
                        "audioQS": "John.21"
                    }
                ],
                "readQS": "Psalm+30%3B+Acts+9:1-6,%287-20%29%3B+Revelation+5:11-14%3B+John+21:1-19",
                "audioQS": "Ps.30,Acts.9,Rev.5,John.21",
                "note": "The reading from Acts must be used as either the first or second reading."
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 80:1\u20138",
                        "readQS": "Psalm+80:1-8",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Exodus 15:1\u20132,9\u201318",
                        "readQS": "Exodus+15:1-2,9-18",
                        "audioQS": "Exod.15"
                    },
                    {
                        "pretty": "John 10:1\u201319",
                        "readQS": "John+10:1-19",
                        "audioQS": "John.10"
                    }
                ],
                "readQS": "Psalm+80:1-8%3B+Exodus+15:1-2,9-18%3B+John+10:1-19",
                "audioQS": "Ps.80,Exod.15,John.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 86",
                        "readQS": "Psalm+86",
                        "audioQS": "Ps.86"
                    },
                    {
                        "pretty": "Isaiah 38:9\u201320",
                        "readQS": "Isaiah+38:9-20",
                        "audioQS": "Isa.38"
                    },
                    {
                        "pretty": "John 11:(17\u201326),27\u201344",
                        "readQS": "John+11:%2817-26%29,27-44",
                        "audioQS": "John.11"
                    }
                ],
                "readQS": "Psalm+86%3B+Isaiah+38:9-20%3B+John+11:%2817-26%29,27-44",
                "audioQS": "Ps.86,Isa.38,John.11",
                "note": null
            }
        ]
    },
    "2025/05/05": {
        "datePretty": "Monday, May 5, 2025",
        "calendar": "Week of 3rd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 96",
                        "readQS": "Psalm+96",
                        "audioQS": "Ps.96"
                    },
                    {
                        "pretty": "Deuteronomy 5:1\u201322",
                        "readQS": "Deuteronomy+5:1-22",
                        "audioQS": "Deut.5"
                    },
                    {
                        "pretty": "Ephesians 1:1\u201314",
                        "readQS": "Ephesians+1:1-14",
                        "audioQS": "Eph.1"
                    }
                ],
                "readQS": "Psalm+96%3B+Deuteronomy+5:1-22%3B+Ephesians+1:1-14",
                "audioQS": "Ps.96,Deut.5,Eph.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 61",
                        "readQS": "Psalm+61",
                        "audioQS": "Ps.61"
                    },
                    {
                        "pretty": "Exodus 19",
                        "readQS": "Exodus+19",
                        "audioQS": "Exod.19"
                    },
                    {
                        "pretty": "Luke 1:1\u201325",
                        "readQS": "Luke+1:1-25",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+61%3B+Exodus+19%3B+Luke+1:1-25",
                "audioQS": "Ps.61,Exod.19,Luke.1",
                "note": null
            }
        ]
    },
    "2025/05/06": {
        "datePretty": "Tuesday, May 6, 2025",
        "calendar": "Week of 3rd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 98",
                        "readQS": "Psalm+98",
                        "audioQS": "Ps.98"
                    },
                    {
                        "pretty": "Deuteronomy 5:22\u2013end",
                        "readQS": "Deuteronomy+5:22-",
                        "audioQS": "Deut.5"
                    },
                    {
                        "pretty": "Ephesians 1:15\u2013end",
                        "readQS": "Ephesians+1:15-",
                        "audioQS": "Eph.1"
                    }
                ],
                "readQS": "Psalm+98%3B+Deuteronomy+5:22-%3B+Ephesians+1:15-",
                "audioQS": "Ps.98,Deut.5,Eph.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Exodus 20:1\u201321",
                        "readQS": "Exodus+20:1-21",
                        "audioQS": "Exod.20"
                    },
                    {
                        "pretty": "Luke 1:26\u201338",
                        "readQS": "Luke+1:26-38",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+71%3B+Exodus+20:1-21%3B+Luke+1:26-38",
                "audioQS": "Ps.71,Exod.20,Luke.1",
                "note": null
            }
        ]
    },
    "2025/05/07": {
        "datePretty": "Wednesday, May 7, 2025",
        "calendar": "Week of 3rd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 105",
                        "readQS": "Psalm+105",
                        "audioQS": "Ps.105"
                    },
                    {
                        "pretty": "Deuteronomy 6",
                        "readQS": "Deuteronomy+6",
                        "audioQS": "Deut.6"
                    },
                    {
                        "pretty": "Ephesians 2:1\u201310",
                        "readQS": "Ephesians+2:1-10",
                        "audioQS": "Eph.2"
                    }
                ],
                "readQS": "Psalm+105%3B+Deuteronomy+6%3B+Ephesians+2:1-10",
                "audioQS": "Ps.105,Deut.6,Eph.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 72",
                        "readQS": "Psalm+72",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "Exodus 24",
                        "readQS": "Exodus+24",
                        "audioQS": "Exod.24"
                    },
                    {
                        "pretty": "Luke 1:39\u201356",
                        "readQS": "Luke+1:39-56",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+72%3B+Exodus+24%3B+Luke+1:39-56",
                "audioQS": "Ps.72,Exod.24,Luke.1",
                "note": null
            }
        ]
    },
    "2025/05/08": {
        "datePretty": "Thursday, May 8, 2025",
        "calendar": "Week of 3rd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 136",
                        "readQS": "Psalm+136",
                        "audioQS": "Ps.136"
                    },
                    {
                        "pretty": "Deuteronomy 7:1\u201311",
                        "readQS": "Deuteronomy+7:1-11",
                        "audioQS": "Deut.7"
                    },
                    {
                        "pretty": "Ephesians 2:11\u2013end",
                        "readQS": "Ephesians+2:11-",
                        "audioQS": "Eph.2"
                    }
                ],
                "readQS": "Psalm+136%3B+Deuteronomy+7:1-11%3B+Ephesians+2:11-",
                "audioQS": "Ps.136,Deut.7,Eph.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Exodus 25:1\u201322",
                        "readQS": "Exodus+25:1-22",
                        "audioQS": "Exod.25"
                    },
                    {
                        "pretty": "Luke 1:57\u2013end",
                        "readQS": "Luke+1:57-",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+73%3B+Exodus+25:1-22%3B+Luke+1:57-",
                "audioQS": "Ps.73,Exod.25,Luke.1",
                "note": null
            }
        ]
    },
    "2025/05/09": {
        "datePretty": "Friday, May 9, 2025",
        "calendar": "Week of 3rd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 107",
                        "readQS": "Psalm+107",
                        "audioQS": "Ps.107"
                    },
                    {
                        "pretty": "Deuteronomy 7:12\u2013end",
                        "readQS": "Deuteronomy+7:12-",
                        "audioQS": "Deut.7"
                    },
                    {
                        "pretty": "Ephesians 3:1\u201313",
                        "readQS": "Ephesians+3:1-13",
                        "audioQS": "Eph.3"
                    }
                ],
                "readQS": "Psalm+107%3B+Deuteronomy+7:12-%3B+Ephesians+3:1-13",
                "audioQS": "Ps.107,Deut.7,Eph.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Exodus 28:1\u20134a,28:29\u201338",
                        "readQS": "Exodus+28:1-4a,28:29-38",
                        "audioQS": "Exod.28"
                    },
                    {
                        "pretty": "Luke 2:1\u201320",
                        "readQS": "Luke+2:1-20",
                        "audioQS": "Luke.2"
                    }
                ],
                "readQS": "Psalm+77%3B+Exodus+28:1-4a,28:29-38%3B+Luke+2:1-20",
                "audioQS": "Ps.77,Exod.28,Luke.2",
                "note": null
            }
        ]
    },
    "2025/05/10": {
        "datePretty": "Saturday, May 10, 2025",
        "calendar": "Week of 3rd Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 110",
                        "readQS": "Psalm+110",
                        "audioQS": "Ps.110"
                    },
                    {
                        "pretty": "Deuteronomy 8",
                        "readQS": "Deuteronomy+8",
                        "audioQS": "Deut.8"
                    },
                    {
                        "pretty": "Ephesians 3:14\u2013end",
                        "readQS": "Ephesians+3:14-",
                        "audioQS": "Eph.3"
                    }
                ],
                "readQS": "Psalm+110%3B+Deuteronomy+8%3B+Ephesians+3:14-",
                "audioQS": "Ps.110,Deut.8,Eph.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 27",
                        "readQS": "Psalm+27",
                        "audioQS": "Ps.27"
                    },
                    {
                        "pretty": "Exodus 29:1\u20139",
                        "readQS": "Exodus+29:1-9",
                        "audioQS": "Exod.29"
                    },
                    {
                        "pretty": "Luke 2:21\u201340",
                        "readQS": "Luke+2:21-40",
                        "audioQS": "Luke.2"
                    }
                ],
                "readQS": "Psalm+27%3B+Exodus+29:1-9%3B+Luke+2:21-40",
                "audioQS": "Ps.27,Exod.29,Luke.2",
                "note": null
            }
        ]
    },
    "2025/05/11": {
        "datePretty": "Sunday, May 11, 2025",
        "calendar": "4th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 23",
                        "readQS": "Psalm+23",
                        "audioQS": "Ps.23"
                    },
                    {
                        "pretty": "Acts 9:36\u201343",
                        "readQS": "Acts+9:36-43",
                        "audioQS": "Acts.9"
                    },
                    {
                        "pretty": "Revelation 7:9\u201317",
                        "readQS": "Revelation+7:9-17",
                        "audioQS": "Rev.7"
                    },
                    {
                        "pretty": "John 10:22\u201330",
                        "readQS": "John+10:22-30",
                        "audioQS": "John.10"
                    }
                ],
                "readQS": "Psalm+23%3B+Acts+9:36-43%3B+Revelation+7:9-17%3B+John+10:22-30",
                "audioQS": "Ps.23,Acts.9,Rev.7,John.10",
                "note": "The reading from Acts must be used as either the first or second reading."
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 146",
                        "readQS": "Psalm+146",
                        "audioQS": "Ps.146"
                    },
                    {
                        "pretty": "1 Kings 17:17\u201324",
                        "readQS": "1+Kings+17:17-24",
                        "audioQS": "1Kgs.17"
                    },
                    {
                        "pretty": "Luke 7:11\u201323",
                        "readQS": "Luke+7:11-23",
                        "audioQS": "Luke.7"
                    }
                ],
                "readQS": "Psalm+146%3B+1+Kings+17:17-24%3B+Luke+7:11-23",
                "audioQS": "Ps.146,1Kgs.17,Luke.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 113,114",
                        "readQS": "Psalm+113,114",
                        "audioQS": "Ps.113,114"
                    },
                    {
                        "pretty": "Isaiah 63:7\u201314",
                        "readQS": "Isaiah+63:7-14",
                        "audioQS": "Isa.63"
                    },
                    {
                        "pretty": "Luke 24:36\u201349",
                        "readQS": "Luke+24:36-49",
                        "audioQS": "Luke.24"
                    }
                ],
                "readQS": "Psalm+113,114%3B+Isaiah+63:7-14%3B+Luke+24:36-49",
                "audioQS": "Ps.113,114,Isa.63,Luke.24",
                "note": null
            }
        ]
    },
    "2025/05/12": {
        "datePretty": "Monday, May 12, 2025",
        "calendar": "Week of 4th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 103",
                        "readQS": "Psalm+103",
                        "audioQS": "Ps.103"
                    },
                    {
                        "pretty": "Deuteronomy 9:1\u201321",
                        "readQS": "Deuteronomy+9:1-21",
                        "audioQS": "Deut.9"
                    },
                    {
                        "pretty": "Ephesians 4:1\u201316",
                        "readQS": "Ephesians+4:1-16",
                        "audioQS": "Eph.4"
                    }
                ],
                "readQS": "Psalm+103%3B+Deuteronomy+9:1-21%3B+Ephesians+4:1-16",
                "audioQS": "Ps.103,Deut.9,Eph.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 114",
                        "readQS": "Psalm+114",
                        "audioQS": "Ps.114"
                    },
                    {
                        "pretty": "Exodus 32:1\u201314",
                        "readQS": "Exodus+32:1-14",
                        "audioQS": "Exod.32"
                    },
                    {
                        "pretty": "Luke 2:41\u2013end",
                        "readQS": "Luke+2:41-",
                        "audioQS": "Luke.2"
                    }
                ],
                "readQS": "Psalm+114%3B+Exodus+32:1-14%3B+Luke+2:41-",
                "audioQS": "Ps.114,Exod.32,Luke.2",
                "note": null
            }
        ]
    },
    "2025/05/13": {
        "datePretty": "Tuesday, May 13, 2025",
        "calendar": "Week of 4th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 139",
                        "readQS": "Psalm+139",
                        "audioQS": "Ps.139"
                    },
                    {
                        "pretty": "Deuteronomy 9:23\u201310:5",
                        "readQS": "Deuteronomy+9:23-10:5",
                        "audioQS": "Deut.9"
                    },
                    {
                        "pretty": "Ephesians 4:17\u2013end",
                        "readQS": "Ephesians+4:17-",
                        "audioQS": "Eph.4"
                    }
                ],
                "readQS": "Psalm+139%3B+Deuteronomy+9:23-10:5%3B+Ephesians+4:17-",
                "audioQS": "Ps.139,Deut.9,Eph.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Matthias)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 147",
                        "readQS": "Psalm+147",
                        "audioQS": "Ps.147"
                    },
                    {
                        "pretty": "Isaiah 22:15\u201322",
                        "readQS": "Isaiah+22:15-22",
                        "audioQS": "Isa.22"
                    },
                    {
                        "pretty": "Philippians 3:13b\u2013end,4:1",
                        "readQS": "Philippians+3:13b-,4:1",
                        "audioQS": "Phil.3"
                    }
                ],
                "readQS": "Psalm+147%3B+Isaiah+22:15-22%3B+Philippians+3:13b-,4:1",
                "audioQS": "Ps.147,Isa.22,Phil.3",
                "note": null
            }
        ]
    },
    "2025/05/14": {
        "datePretty": "Wednesday, May 14, 2025",
        "calendar": "St Matthias the Apostle",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 16",
                        "readQS": "Psalm+16",
                        "audioQS": "Ps.16"
                    },
                    {
                        "pretty": "1 Samuel 2:27\u201335",
                        "readQS": "1+Samuel+2:27-35",
                        "audioQS": "1Sam.2"
                    },
                    {
                        "pretty": "Acts 2:37\u201347",
                        "readQS": "Acts+2:37-47",
                        "audioQS": "Acts.2"
                    }
                ],
                "readQS": "Psalm+16%3B+1+Samuel+2:27-35%3B+Acts+2:37-47",
                "audioQS": "Ps.16,1Sam.2,Acts.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "1 Samuel 16:1\u201313a",
                        "readQS": "1+Samuel+16:1-13a",
                        "audioQS": "1Sam.16"
                    },
                    {
                        "pretty": "Matthew 7:15\u201327",
                        "readQS": "Matthew+7:15-27",
                        "audioQS": "Matt.7"
                    }
                ],
                "readQS": "Psalm+80%3B+1+Samuel+16:1-13a%3B+Matthew+7:15-27",
                "audioQS": "Ps.80,1Sam.16,Matt.7",
                "note": null
            }
        ]
    },
    "2025/05/15": {
        "datePretty": "Thursday, May 15, 2025",
        "calendar": "Week of 4th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 118",
                        "readQS": "Psalm+118",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "Deuteronomy 11:8\u2013end",
                        "readQS": "Deuteronomy+11:8-",
                        "audioQS": "Deut.11"
                    },
                    {
                        "pretty": "Ephesians 5:15\u2013end",
                        "readQS": "Ephesians+5:15-",
                        "audioQS": "Eph.5"
                    }
                ],
                "readQS": "Psalm+118%3B+Deuteronomy+11:8-%3B+Ephesians+5:15-",
                "audioQS": "Ps.118,Deut.11,Eph.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "Exodus 34:1\u201310,34:27\u2013end",
                        "readQS": "Exodus+34:1-10,34:27-",
                        "audioQS": "Exod.34"
                    },
                    {
                        "pretty": "Luke 4:1\u201313",
                        "readQS": "Luke+4:1-13",
                        "audioQS": "Luke.4"
                    }
                ],
                "readQS": "Psalm+85%3B+Exodus+34:1-10,34:27-%3B+Luke+4:1-13",
                "audioQS": "Ps.85,Exod.34,Luke.4",
                "note": null
            }
        ]
    },
    "2025/05/16": {
        "datePretty": "Friday, May 16, 2025",
        "calendar": "Week of 4th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 33",
                        "readQS": "Psalm+33",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "Deuteronomy 12:1\u201314",
                        "readQS": "Deuteronomy+12:1-14",
                        "audioQS": "Deut.12"
                    },
                    {
                        "pretty": "Ephesians 6:1\u20139",
                        "readQS": "Ephesians+6:1-9",
                        "audioQS": "Eph.6"
                    }
                ],
                "readQS": "Psalm+33%3B+Deuteronomy+12:1-14%3B+Ephesians+6:1-9",
                "audioQS": "Ps.33,Deut.12,Eph.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 36",
                        "readQS": "Psalm+36",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "Exodus 35:20\u201336:7",
                        "readQS": "Exodus+35:20-36:7",
                        "audioQS": "Exod.35"
                    },
                    {
                        "pretty": "Luke 4:14\u201330",
                        "readQS": "Luke+4:14-30",
                        "audioQS": "Luke.4"
                    }
                ],
                "readQS": "Psalm+36%3B+Exodus+35:20-36:7%3B+Luke+4:14-30",
                "audioQS": "Ps.36,Exod.35,Luke.4",
                "note": null
            }
        ]
    },
    "2025/05/17": {
        "datePretty": "Saturday, May 17, 2025",
        "calendar": "Week of 4th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 34",
                        "readQS": "Psalm+34",
                        "audioQS": "Ps.34"
                    },
                    {
                        "pretty": "Deuteronomy 15:1\u201318",
                        "readQS": "Deuteronomy+15:1-18",
                        "audioQS": "Deut.15"
                    },
                    {
                        "pretty": "Ephesians 6:10\u2013end",
                        "readQS": "Ephesians+6:10-",
                        "audioQS": "Eph.6"
                    }
                ],
                "readQS": "Psalm+34%3B+Deuteronomy+15:1-18%3B+Ephesians+6:10-",
                "audioQS": "Ps.34,Deut.15,Eph.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 84",
                        "readQS": "Psalm+84",
                        "audioQS": "Ps.84"
                    },
                    {
                        "pretty": "Exodus 40:17\u2013end",
                        "readQS": "Exodus+40:17-",
                        "audioQS": "Exod.40"
                    },
                    {
                        "pretty": "Luke 4:31\u201337",
                        "readQS": "Luke+4:31-37",
                        "audioQS": "Luke.4"
                    }
                ],
                "readQS": "Psalm+84%3B+Exodus+40:17-%3B+Luke+4:31-37",
                "audioQS": "Ps.84,Exod.40,Luke.4",
                "note": null
            }
        ]
    },
    "2025/05/18": {
        "datePretty": "Sunday, May 18, 2025",
        "calendar": "5th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 148",
                        "readQS": "Psalm+148",
                        "audioQS": "Ps.148"
                    },
                    {
                        "pretty": "Acts 11:1\u201318",
                        "readQS": "Acts+11:1-18",
                        "audioQS": "Acts.11"
                    },
                    {
                        "pretty": "Revelation 21:1\u20136",
                        "readQS": "Revelation+21:1-6",
                        "audioQS": "Rev.21"
                    },
                    {
                        "pretty": "John 13:31\u201335",
                        "readQS": "John+13:31-35",
                        "audioQS": "John.13"
                    }
                ],
                "readQS": "Psalm+148%3B+Acts+11:1-18%3B+Revelation+21:1-6%3B+John+13:31-35",
                "audioQS": "Ps.148,Acts.11,Rev.21,John.13",
                "note": "The reading from Acts must be used as either the first or second reading."
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 16",
                        "readQS": "Psalm+16",
                        "audioQS": "Ps.16"
                    },
                    {
                        "pretty": "2 Samuel 7:4\u201313",
                        "readQS": "2+Samuel+7:4-13",
                        "audioQS": "2Sam.7"
                    },
                    {
                        "pretty": "Acts 2:14a,22\u201332,(33\u201336)",
                        "readQS": "Acts+2:14a,22-32,%2833-36%29",
                        "audioQS": "Acts.2"
                    }
                ],
                "readQS": "Psalm+16%3B+2+Samuel+7:4-13%3B+Acts+2:14a,22-32,%2833-36%29",
                "audioQS": "Ps.16,2Sam.7,Acts.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 98",
                        "readQS": "Psalm+98",
                        "audioQS": "Ps.98"
                    },
                    {
                        "pretty": "Daniel 6:(1\u20135),6\u201323",
                        "readQS": "Daniel+6:%281-5%29,6-23",
                        "audioQS": "Dan.6"
                    },
                    {
                        "pretty": "Mark 15:46\u201316:8",
                        "readQS": "Mark+15:46-16:8",
                        "audioQS": "Mark.15"
                    }
                ],
                "readQS": "Psalm+98%3B+Daniel+6:%281-5%29,6-23%3B+Mark+15:46-16:8",
                "audioQS": "Ps.98,Dan.6,Mark.15",
                "note": null
            }
        ]
    },
    "2025/05/19": {
        "datePretty": "Monday, May 19, 2025",
        "calendar": "Week of 5th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Deuteronomy 16:1\u201320",
                        "readQS": "Deuteronomy+16:1-20",
                        "audioQS": "Deut.16"
                    },
                    {
                        "pretty": "1 Peter 1:1\u201312",
                        "readQS": "1+Peter+1:1-12",
                        "audioQS": "1Pet.1"
                    }
                ],
                "readQS": "Psalm+145%3B+Deuteronomy+16:1-20%3B+1+Peter+1:1-12",
                "audioQS": "Ps.145,Deut.16,1Pet.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 105",
                        "readQS": "Psalm+105",
                        "audioQS": "Ps.105"
                    },
                    {
                        "pretty": "Numbers 9:15\u2013end,10:33\u2013end",
                        "readQS": "Numbers+9:15-,10:33-",
                        "audioQS": "Num.9"
                    },
                    {
                        "pretty": "Luke 4:38\u2013end",
                        "readQS": "Luke+4:38-",
                        "audioQS": "Luke.4"
                    }
                ],
                "readQS": "Psalm+105%3B+Numbers+9:15-,10:33-%3B+Luke+4:38-",
                "audioQS": "Ps.105,Num.9,Luke.4",
                "note": null
            }
        ]
    },
    "2025/05/20": {
        "datePretty": "Tuesday, May 20, 2025",
        "calendar": "Week of 5th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 19",
                        "readQS": "Psalm+19",
                        "audioQS": "Ps.19"
                    },
                    {
                        "pretty": "Deuteronomy 17:8\u2013end",
                        "readQS": "Deuteronomy+17:8-",
                        "audioQS": "Deut.17"
                    },
                    {
                        "pretty": "1 Peter 1:13\u2013end",
                        "readQS": "1+Peter+1:13-",
                        "audioQS": "1Pet.1"
                    }
                ],
                "readQS": "Psalm+19%3B+Deuteronomy+17:8-%3B+1+Peter+1:13-",
                "audioQS": "Ps.19,Deut.17,1Pet.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Numbers 11:1\u201333",
                        "readQS": "Numbers+11:1-33",
                        "audioQS": "Num.11"
                    },
                    {
                        "pretty": "Luke 5:1\u201311",
                        "readQS": "Luke+5:1-11",
                        "audioQS": "Luke.5"
                    }
                ],
                "readQS": "Psalm+97%3B+Numbers+11:1-33%3B+Luke+5:1-11",
                "audioQS": "Ps.97,Num.11,Luke.5",
                "note": null
            }
        ]
    },
    "2025/05/21": {
        "datePretty": "Wednesday, May 21, 2025",
        "calendar": "Week of 5th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 30",
                        "readQS": "Psalm+30",
                        "audioQS": "Ps.30"
                    },
                    {
                        "pretty": "Deuteronomy 18:9\u2013end",
                        "readQS": "Deuteronomy+18:9-",
                        "audioQS": "Deut.18"
                    },
                    {
                        "pretty": "1 Peter 2:1\u201310",
                        "readQS": "1+Peter+2:1-10",
                        "audioQS": "1Pet.2"
                    }
                ],
                "readQS": "Psalm+30%3B+Deuteronomy+18:9-%3B+1+Peter+2:1-10",
                "audioQS": "Ps.30,Deut.18,1Pet.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 99",
                        "readQS": "Psalm+99",
                        "audioQS": "Ps.99"
                    },
                    {
                        "pretty": "Numbers 12",
                        "readQS": "Numbers+12",
                        "audioQS": "Num.12"
                    },
                    {
                        "pretty": "Luke 5:12\u201326",
                        "readQS": "Luke+5:12-26",
                        "audioQS": "Luke.5"
                    }
                ],
                "readQS": "Psalm+99%3B+Numbers+12%3B+Luke+5:12-26",
                "audioQS": "Ps.99,Num.12,Luke.5",
                "note": null
            }
        ]
    },
    "2025/05/22": {
        "datePretty": "Thursday, May 22, 2025",
        "calendar": "Week of 5th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 57",
                        "readQS": "Psalm+57",
                        "audioQS": "Ps.57"
                    },
                    {
                        "pretty": "Deuteronomy 19",
                        "readQS": "Deuteronomy+19",
                        "audioQS": "Deut.19"
                    },
                    {
                        "pretty": "1 Peter 2:11\u2013end",
                        "readQS": "1+Peter+2:11-",
                        "audioQS": "1Pet.2"
                    }
                ],
                "readQS": "Psalm+57%3B+Deuteronomy+19%3B+1+Peter+2:11-",
                "audioQS": "Ps.57,Deut.19,1Pet.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 104",
                        "readQS": "Psalm+104",
                        "audioQS": "Ps.104"
                    },
                    {
                        "pretty": "Numbers 13:1\u20133,13:17\u2013end",
                        "readQS": "Numbers+13:1-3,13:17-",
                        "audioQS": "Num.13"
                    },
                    {
                        "pretty": "Luke 5:27\u2013end",
                        "readQS": "Luke+5:27-",
                        "audioQS": "Luke.5"
                    }
                ],
                "readQS": "Psalm+104%3B+Numbers+13:1-3,13:17-%3B+Luke+5:27-",
                "audioQS": "Ps.104,Num.13,Luke.5",
                "note": null
            }
        ]
    },
    "2025/05/23": {
        "datePretty": "Friday, May 23, 2025",
        "calendar": "Week of 5th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 138",
                        "readQS": "Psalm+138",
                        "audioQS": "Ps.138"
                    },
                    {
                        "pretty": "Deuteronomy 21:22\u201322:8",
                        "readQS": "Deuteronomy+21:22-22:8",
                        "audioQS": "Deut.21"
                    },
                    {
                        "pretty": "1 Peter 3:1\u201312",
                        "readQS": "1+Peter+3:1-12",
                        "audioQS": "1Pet.3"
                    }
                ],
                "readQS": "Psalm+138%3B+Deuteronomy+21:22-22:8%3B+1+Peter+3:1-12",
                "audioQS": "Ps.138,Deut.21,1Pet.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 66",
                        "readQS": "Psalm+66",
                        "audioQS": "Ps.66"
                    },
                    {
                        "pretty": "Numbers 14:1\u201325",
                        "readQS": "Numbers+14:1-25",
                        "audioQS": "Num.14"
                    },
                    {
                        "pretty": "Luke 6:1\u201311",
                        "readQS": "Luke+6:1-11",
                        "audioQS": "Luke.6"
                    }
                ],
                "readQS": "Psalm+66%3B+Numbers+14:1-25%3B+Luke+6:1-11",
                "audioQS": "Ps.66,Num.14,Luke.6",
                "note": null
            }
        ]
    },
    "2025/05/24": {
        "datePretty": "Saturday, May 24, 2025",
        "calendar": "Week of 5th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 146",
                        "readQS": "Psalm+146",
                        "audioQS": "Ps.146"
                    },
                    {
                        "pretty": "Deuteronomy 24:5\u2013end",
                        "readQS": "Deuteronomy+24:5-",
                        "audioQS": "Deut.24"
                    },
                    {
                        "pretty": "1 Peter 3:13\u2013end",
                        "readQS": "1+Peter+3:13-",
                        "audioQS": "1Pet.3"
                    }
                ],
                "readQS": "Psalm+146%3B+Deuteronomy+24:5-%3B+1+Peter+3:13-",
                "audioQS": "Ps.146,Deut.24,1Pet.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 118",
                        "readQS": "Psalm+118",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "Numbers 14:26\u2013end",
                        "readQS": "Numbers+14:26-",
                        "audioQS": "Num.14"
                    },
                    {
                        "pretty": "Luke 6:12\u201326",
                        "readQS": "Luke+6:12-26",
                        "audioQS": "Luke.6"
                    }
                ],
                "readQS": "Psalm+118%3B+Numbers+14:26-%3B+Luke+6:12-26",
                "audioQS": "Ps.118,Num.14,Luke.6",
                "note": null
            }
        ]
    },
    "2025/05/25": {
        "datePretty": "Sunday, May 25, 2025",
        "calendar": "6th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 67",
                        "readQS": "Psalm+67",
                        "audioQS": "Ps.67"
                    },
                    {
                        "pretty": "Acts 16:9\u201315",
                        "readQS": "Acts+16:9-15",
                        "audioQS": "Acts.16"
                    },
                    {
                        "pretty": "Revelation 21:10,22\u201322:5",
                        "readQS": "Revelation+21:10,22-22:5",
                        "audioQS": "Rev.21"
                    },
                    {
                        "pretty": "John 14:23\u201329",
                        "readQS": "John+14:23-29",
                        "audioQS": "John.14"
                    }
                ],
                "readQS": "Psalm+67%3B+Acts+16:9-15%3B+Revelation+21:10,22-22:5%3B+John+14:23-29",
                "audioQS": "Ps.67,Acts.16,Rev.21,John.14",
                "note": "The reading from Acts must be used as either the first or second reading."
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 40:1\u20139",
                        "readQS": "Psalm+40:1-9",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Genesis 1:26\u201328,(29\u201331)",
                        "readQS": "Genesis+1:26-28,%2829-31%29",
                        "audioQS": "Gen.1"
                    },
                    {
                        "pretty": "Colossians 3:1\u201311",
                        "readQS": "Colossians+3:1-11",
                        "audioQS": "Col.3"
                    }
                ],
                "readQS": "Psalm+40:1-9%3B+Genesis+1:26-28,%2829-31%29%3B+Colossians+3:1-11",
                "audioQS": "Ps.40,Gen.1,Col.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 126,127",
                        "readQS": "Psalm+126,127",
                        "audioQS": "Ps.126,127"
                    },
                    {
                        "pretty": "Zephaniah 3:14\u201320",
                        "readQS": "Zephaniah+3:14-20",
                        "audioQS": "Zeph.3"
                    },
                    {
                        "pretty": "Matthew 28:1\u201310,16\u201320",
                        "readQS": "Matthew+28:1-10,16-20",
                        "audioQS": "Matt.28"
                    }
                ],
                "readQS": "Psalm+126,127%3B+Zephaniah+3:14-20%3B+Matthew+28:1-10,16-20",
                "audioQS": "Ps.126,127,Zeph.3,Matt.28",
                "note": null
            }
        ]
    },
    "2025/05/26": {
        "datePretty": "Monday, May 26, 2025",
        "calendar": "Week of 6th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 65",
                        "readQS": "Psalm+65",
                        "audioQS": "Ps.65"
                    },
                    {
                        "pretty": "Deuteronomy 26",
                        "readQS": "Deuteronomy+26",
                        "audioQS": "Deut.26"
                    },
                    {
                        "pretty": "1 Peter 4:1\u201311",
                        "readQS": "1+Peter+4:1-11",
                        "audioQS": "1Pet.4"
                    }
                ],
                "readQS": "Psalm+65%3B+Deuteronomy+26%3B+1+Peter+4:1-11",
                "audioQS": "Ps.65,Deut.26,1Pet.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 121",
                        "readQS": "Psalm+121",
                        "audioQS": "Ps.121"
                    },
                    {
                        "pretty": "Numbers 16:1\u201335",
                        "readQS": "Numbers+16:1-35",
                        "audioQS": "Num.16"
                    },
                    {
                        "pretty": "Luke 6:27\u201338",
                        "readQS": "Luke+6:27-38",
                        "audioQS": "Luke.6"
                    }
                ],
                "readQS": "Psalm+121%3B+Numbers+16:1-35%3B+Luke+6:27-38",
                "audioQS": "Ps.121,Num.16,Luke.6",
                "note": null
            }
        ]
    },
    "2025/05/27": {
        "datePretty": "Tuesday, May 27, 2025",
        "calendar": "Week of 6th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 126",
                        "readQS": "Psalm+126",
                        "audioQS": "Ps.126"
                    },
                    {
                        "pretty": "Deuteronomy 28:1\u201314",
                        "readQS": "Deuteronomy+28:1-14",
                        "audioQS": "Deut.28"
                    },
                    {
                        "pretty": "1 Peter 4:12\u2013end",
                        "readQS": "1+Peter+4:12-",
                        "audioQS": "1Pet.4"
                    }
                ],
                "readQS": "Psalm+126%3B+Deuteronomy+28:1-14%3B+1+Peter+4:12-",
                "audioQS": "Ps.126,Deut.28,1Pet.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 128",
                        "readQS": "Psalm+128",
                        "audioQS": "Ps.128"
                    },
                    {
                        "pretty": "Numbers 16:36\u2013end",
                        "readQS": "Numbers+16:36-",
                        "audioQS": "Num.16"
                    },
                    {
                        "pretty": "Luke 6:39\u2013end",
                        "readQS": "Luke+6:39-",
                        "audioQS": "Luke.6"
                    }
                ],
                "readQS": "Psalm+128%3B+Numbers+16:36-%3B+Luke+6:39-",
                "audioQS": "Ps.128,Num.16,Luke.6",
                "note": null
            }
        ]
    },
    "2025/05/28": {
        "datePretty": "Wednesday, May 28, 2025",
        "calendar": "Week of 6th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Deuteronomy 28:58\u2013end",
                        "readQS": "Deuteronomy+28:58-",
                        "audioQS": "Deut.28"
                    },
                    {
                        "pretty": "1 Peter 5",
                        "readQS": "1+Peter+5",
                        "audioQS": "1Pet.5"
                    }
                ],
                "readQS": "Psalm+132%3B+Deuteronomy+28:58-%3B+1+Peter+5",
                "audioQS": "Ps.132,Deut.28,1Pet.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of Ascension Day)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 15,24",
                        "readQS": "Psalm+15,24",
                        "audioQS": "Ps.15,24"
                    },
                    {
                        "pretty": "2 Samuel 23:1\u20135",
                        "readQS": "2+Samuel+23:1-5",
                        "audioQS": "2Sam.23"
                    },
                    {
                        "pretty": "Colossians 2:20\u2013end,3:1\u20134",
                        "readQS": "Colossians+2:20-,3:1-4",
                        "audioQS": "Col.2"
                    }
                ],
                "readQS": "Psalm+15,24%3B+2+Samuel+23:1-5%3B+Colossians+2:20-,3:1-4",
                "audioQS": "Ps.15,24,2Sam.23,Col.2",
                "note": null
            }
        ]
    },
    "2025/05/29": {
        "datePretty": "Thursday, May 29, 2025",
        "calendar": "Ascension Day",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 47",
                        "readQS": "Psalm+47",
                        "audioQS": "Ps.47"
                    },
                    {
                        "pretty": "Acts 1:1\u201311",
                        "readQS": "Acts+1:1-11",
                        "audioQS": "Acts.1"
                    },
                    {
                        "pretty": "Ephesians 1:15\u201323",
                        "readQS": "Ephesians+1:15-23",
                        "audioQS": "Eph.1"
                    },
                    {
                        "pretty": "Luke 24:44\u201353",
                        "readQS": "Luke+24:44-53",
                        "audioQS": "Luke.24"
                    }
                ],
                "readQS": "Psalm+47%3B+Acts+1:1-11%3B+Ephesians+1:15-23%3B+Luke+24:44-53",
                "audioQS": "Ps.47,Acts.1,Eph.1,Luke.24",
                "note": "These readings can be used on the Sunday after Ascension day."
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 110",
                        "readQS": "Psalm+110",
                        "audioQS": "Ps.110"
                    },
                    {
                        "pretty": "Isaiah 52:7\u201315",
                        "readQS": "Isaiah+52:7-15",
                        "audioQS": "Isa.52"
                    },
                    {
                        "pretty": "Hebrews 7:(11\u201325),26\u201328",
                        "readQS": "Hebrews+7:%2811-25%29,26-28",
                        "audioQS": "Heb.7"
                    }
                ],
                "readQS": "Psalm+110%3B+Isaiah+52:7-15%3B+Hebrews+7:%2811-25%29,26-28",
                "audioQS": "Ps.110,Isa.52,Heb.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 8",
                        "readQS": "Psalm+8",
                        "audioQS": "Ps.8"
                    },
                    {
                        "pretty": "2 Kings 2:1\u201315",
                        "readQS": "2+Kings+2:1-15",
                        "audioQS": "2Kgs.2"
                    },
                    {
                        "pretty": "Revelation 5",
                        "readQS": "Revelation+5",
                        "audioQS": "Rev.5"
                    }
                ],
                "readQS": "Psalm+8%3B+2+Kings+2:1-15%3B+Revelation+5",
                "audioQS": "Ps.8,2Kgs.2,Rev.5",
                "note": null
            }
        ]
    },
    "2025/05/30": {
        "datePretty": "Friday, May 30, 2025",
        "calendar": "10 days between Ascension Day and Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 81",
                        "readQS": "Psalm+81",
                        "audioQS": "Ps.81"
                    },
                    {
                        "pretty": "Deuteronomy 29:2\u201315",
                        "readQS": "Deuteronomy+29:2-15",
                        "audioQS": "Deut.29"
                    },
                    {
                        "pretty": "1 John 1:1\u20132:6",
                        "readQS": "1+John+1:1-2:6",
                        "audioQS": "1John.1"
                    }
                ],
                "readQS": "Psalm+81%3B+Deuteronomy+29:2-15%3B+1+John+1:1-2:6",
                "audioQS": "Ps.81,Deut.29,1John.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Visitation of Mary to Elizabeth)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 45",
                        "readQS": "Psalm+45",
                        "audioQS": "Ps.45"
                    },
                    {
                        "pretty": "Song of Solomon 2:8\u201314",
                        "readQS": "Song+of+Solomon+2:8-14",
                        "audioQS": "Song.2"
                    },
                    {
                        "pretty": "Luke 1:26\u201338",
                        "readQS": "Luke+1:26-38",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+45%3B+Song+of+Solomon+2:8-14%3B+Luke+1:26-38",
                "audioQS": "Ps.45,Song.2,Luke.1",
                "note": null
            }
        ]
    },
    "2025/05/31": {
        "datePretty": "Saturday, May 31, 2025",
        "calendar": "The Visitation of Mary to Elizabeth",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "1 Samuel 2:1\u201310",
                        "readQS": "1+Samuel+2:1-10",
                        "audioQS": "1Sam.2"
                    },
                    {
                        "pretty": "Mark 3:31\u201335",
                        "readQS": "Mark+3:31-35",
                        "audioQS": "Mark.3"
                    }
                ],
                "readQS": "Psalm+85%3B+1+Samuel+2:1-10%3B+Mark+3:31-35",
                "audioQS": "Ps.85,1Sam.2,Mark.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 122",
                        "readQS": "Psalm+122",
                        "audioQS": "Ps.122"
                    },
                    {
                        "pretty": "Zechariah 2:10\u201313",
                        "readQS": "Zechariah+2:10-13",
                        "audioQS": "Zech.2"
                    },
                    {
                        "pretty": "John 3:25\u201330",
                        "readQS": "John+3:25-30",
                        "audioQS": "John.3"
                    }
                ],
                "readQS": "Psalm+122%3B+Zechariah+2:10-13%3B+John+3:25-30",
                "audioQS": "Ps.122,Zech.2,John.3",
                "note": null
            }
        ]
    },
    "2025/06/01": {
        "datePretty": "Sunday, June 1, 2025",
        "calendar": "7th Sunday of Easter",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Acts 16:16\u201334",
                        "readQS": "Acts+16:16-34",
                        "audioQS": "Acts.16"
                    },
                    {
                        "pretty": "Revelation 22:12\u201314,16\u201317,20\u201321",
                        "readQS": "Revelation+22:12-14,16-17,20-21",
                        "audioQS": "Rev.22"
                    },
                    {
                        "pretty": "John 17:20\u201326",
                        "readQS": "John+17:20-26",
                        "audioQS": "John.17"
                    }
                ],
                "readQS": "Psalm+97%3B+Acts+16:16-34%3B+Revelation+22:12-14,16-17,20-21%3B+John+17:20-26",
                "audioQS": "Ps.97,Acts.16,Rev.22,John.17",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "Ascension",
                "readingSetSubtitle": "RCL (Readings from Ascension Day)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 47",
                        "readQS": "Psalm+47",
                        "audioQS": "Ps.47"
                    },
                    {
                        "pretty": "Acts 1:1\u201311",
                        "readQS": "Acts+1:1-11",
                        "audioQS": "Acts.1"
                    },
                    {
                        "pretty": "Ephesians 1:15\u201323",
                        "readQS": "Ephesians+1:15-23",
                        "audioQS": "Eph.1"
                    },
                    {
                        "pretty": "Luke 24:44\u201353",
                        "readQS": "Luke+24:44-53",
                        "audioQS": "Luke.24"
                    }
                ],
                "readQS": "Psalm+47%3B+Acts+1:1-11%3B+Ephesians+1:15-23%3B+Luke+24:44-53",
                "audioQS": "Ps.47,Acts.1,Eph.1,Luke.24",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 99",
                        "readQS": "Psalm+99",
                        "audioQS": "Ps.99"
                    },
                    {
                        "pretty": "Deuteronomy 34",
                        "readQS": "Deuteronomy+34",
                        "audioQS": "Deut.34"
                    },
                    {
                        "pretty": "Luke 24:44\u201353",
                        "readQS": "Luke+24:44-53",
                        "audioQS": "Luke.24"
                    }
                ],
                "readQS": "Psalm+99%3B+Deuteronomy+34%3B+Luke+24:44-53",
                "audioQS": "Ps.99,Deut.34,Luke.24",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 68",
                        "readQS": "Psalm+68",
                        "audioQS": "Ps.68"
                    },
                    {
                        "pretty": "Isaiah 44:1\u20138",
                        "readQS": "Isaiah+44:1-8",
                        "audioQS": "Isa.44"
                    },
                    {
                        "pretty": "Ephesians 4:7\u201316",
                        "readQS": "Ephesians+4:7-16",
                        "audioQS": "Eph.4"
                    }
                ],
                "readQS": "Psalm+68%3B+Isaiah+44:1-8%3B+Ephesians+4:7-16",
                "audioQS": "Ps.68,Isa.44,Eph.4",
                "note": null
            }
        ]
    },
    "2025/06/02": {
        "datePretty": "Monday, June 2, 2025",
        "calendar": "10 days between Ascension Day and Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 93",
                        "readQS": "Psalm+93",
                        "audioQS": "Ps.93"
                    },
                    {
                        "pretty": "Deuteronomy 31:1\u201313",
                        "readQS": "Deuteronomy+31:1-13",
                        "audioQS": "Deut.31"
                    },
                    {
                        "pretty": "1 John 2:18\u2013end",
                        "readQS": "1+John+2:18-",
                        "audioQS": "1John.2"
                    }
                ],
                "readQS": "Psalm+93%3B+Deuteronomy+31:1-13%3B+1+John+2:18-",
                "audioQS": "Ps.93,Deut.31,1John.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 18",
                        "readQS": "Psalm+18",
                        "audioQS": "Ps.18"
                    },
                    {
                        "pretty": "Numbers 22:1\u201335",
                        "readQS": "Numbers+22:1-35",
                        "audioQS": "Num.22"
                    },
                    {
                        "pretty": "Luke 7:36\u2013end",
                        "readQS": "Luke+7:36-",
                        "audioQS": "Luke.7"
                    }
                ],
                "readQS": "Psalm+18%3B+Numbers+22:1-35%3B+Luke+7:36-",
                "audioQS": "Ps.18,Num.22,Luke.7",
                "note": null
            }
        ]
    },
    "2025/06/03": {
        "datePretty": "Tuesday, June 3, 2025",
        "calendar": "10 days between Ascension Day and Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 99",
                        "readQS": "Psalm+99",
                        "audioQS": "Ps.99"
                    },
                    {
                        "pretty": "Deuteronomy 31:14\u201329",
                        "readQS": "Deuteronomy+31:14-29",
                        "audioQS": "Deut.31"
                    },
                    {
                        "pretty": "1 John 3:1\u201310",
                        "readQS": "1+John+3:1-10",
                        "audioQS": "1John.3"
                    }
                ],
                "readQS": "Psalm+99%3B+Deuteronomy+31:14-29%3B+1+John+3:1-10",
                "audioQS": "Ps.99,Deut.31,1John.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 68",
                        "readQS": "Psalm+68",
                        "audioQS": "Ps.68"
                    },
                    {
                        "pretty": "Numbers 22:36\u201323:12",
                        "readQS": "Numbers+22:36-23:12",
                        "audioQS": "Num.22"
                    },
                    {
                        "pretty": "Luke 8:1\u201315",
                        "readQS": "Luke+8:1-15",
                        "audioQS": "Luke.8"
                    }
                ],
                "readQS": "Psalm+68%3B+Numbers+22:36-23:12%3B+Luke+8:1-15",
                "audioQS": "Ps.68,Num.22,Luke.8",
                "note": null
            }
        ]
    },
    "2025/06/04": {
        "datePretty": "Wednesday, June 4, 2025",
        "calendar": "10 days between Ascension Day and Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 29",
                        "readQS": "Psalm+29",
                        "audioQS": "Ps.29"
                    },
                    {
                        "pretty": "Deuteronomy 31:30\u201332:14",
                        "readQS": "Deuteronomy+31:30-32:14",
                        "audioQS": "Deut.31"
                    },
                    {
                        "pretty": "1 John 3:11\u2013end",
                        "readQS": "1+John+3:11-",
                        "audioQS": "1John.3"
                    }
                ],
                "readQS": "Psalm+29%3B+Deuteronomy+31:30-32:14%3B+1+John+3:11-",
                "audioQS": "Ps.29,Deut.31,1John.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 46",
                        "readQS": "Psalm+46",
                        "audioQS": "Ps.46"
                    },
                    {
                        "pretty": "Numbers 23:13\u2013end",
                        "readQS": "Numbers+23:13-",
                        "audioQS": "Num.23"
                    },
                    {
                        "pretty": "Luke 8:16\u201325",
                        "readQS": "Luke+8:16-25",
                        "audioQS": "Luke.8"
                    }
                ],
                "readQS": "Psalm+46%3B+Numbers+23:13-%3B+Luke+8:16-25",
                "audioQS": "Ps.46,Num.23,Luke.8",
                "note": null
            }
        ]
    },
    "2025/06/05": {
        "datePretty": "Thursday, June 5, 2025",
        "calendar": "10 days between Ascension Day and Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 24",
                        "readQS": "Psalm+24",
                        "audioQS": "Ps.24"
                    },
                    {
                        "pretty": "Deuteronomy 32:15\u201347",
                        "readQS": "Deuteronomy+32:15-47",
                        "audioQS": "Deut.32"
                    },
                    {
                        "pretty": "1 John 4:1\u20136",
                        "readQS": "1+John+4:1-6",
                        "audioQS": "1John.4"
                    }
                ],
                "readQS": "Psalm+24%3B+Deuteronomy+32:15-47%3B+1+John+4:1-6",
                "audioQS": "Ps.24,Deut.32,1John.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 139",
                        "readQS": "Psalm+139",
                        "audioQS": "Ps.139"
                    },
                    {
                        "pretty": "Numbers 24",
                        "readQS": "Numbers+24",
                        "audioQS": "Num.24"
                    },
                    {
                        "pretty": "Luke 8:26\u201339",
                        "readQS": "Luke+8:26-39",
                        "audioQS": "Luke.8"
                    }
                ],
                "readQS": "Psalm+139%3B+Numbers+24%3B+Luke+8:26-39",
                "audioQS": "Ps.139,Num.24,Luke.8",
                "note": null
            }
        ]
    },
    "2025/06/06": {
        "datePretty": "Friday, June 6, 2025",
        "calendar": "10 days between Ascension Day and Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 28",
                        "readQS": "Psalm+28",
                        "audioQS": "Ps.28"
                    },
                    {
                        "pretty": "Deuteronomy 33",
                        "readQS": "Deuteronomy+33",
                        "audioQS": "Deut.33"
                    },
                    {
                        "pretty": "1 John 4:7\u2013end",
                        "readQS": "1+John+4:7-",
                        "audioQS": "1John.4"
                    }
                ],
                "readQS": "Psalm+28%3B+Deuteronomy+33%3B+1+John+4:7-",
                "audioQS": "Ps.28,Deut.33,1John.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 147",
                        "readQS": "Psalm+147",
                        "audioQS": "Ps.147"
                    },
                    {
                        "pretty": "Numbers 27:12\u2013end",
                        "readQS": "Numbers+27:12-",
                        "audioQS": "Num.27"
                    },
                    {
                        "pretty": "Luke 8:40\u2013end",
                        "readQS": "Luke+8:40-",
                        "audioQS": "Luke.8"
                    }
                ],
                "readQS": "Psalm+147%3B+Numbers+27:12-%3B+Luke+8:40-",
                "audioQS": "Ps.147,Num.27,Luke.8",
                "note": null
            }
        ]
    },
    "2025/06/07": {
        "datePretty": "Saturday, June 7, 2025",
        "calendar": "10 days between Ascension Day and Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 43",
                        "readQS": "Psalm+43",
                        "audioQS": "Ps.43"
                    },
                    {
                        "pretty": "Deuteronomy 32:48\u2013end",
                        "readQS": "Deuteronomy+32:48-",
                        "audioQS": "Deut.32"
                    },
                    {
                        "pretty": "Deuteronomy 34",
                        "readQS": "Deuteronomy+34",
                        "audioQS": "Deut.34"
                    },
                    {
                        "pretty": "1 John 5",
                        "readQS": "1+John+5",
                        "audioQS": "1John.5"
                    }
                ],
                "readQS": "Psalm+43%3B+Deuteronomy+32:48-%3B+Deuteronomy+34%3B+1+John+5",
                "audioQS": "Ps.43,Deut.32,Deut.34,1John.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of Pentecost)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 48",
                        "readQS": "Psalm+48",
                        "audioQS": "Ps.48"
                    },
                    {
                        "pretty": "Deuteronomy 16:9\u201315",
                        "readQS": "Deuteronomy+16:9-15",
                        "audioQS": "Deut.16"
                    },
                    {
                        "pretty": "John 7:37\u201339",
                        "readQS": "John+7:37-39",
                        "audioQS": "John.7"
                    }
                ],
                "readQS": "Psalm+48%3B+Deuteronomy+16:9-15%3B+John+7:37-39",
                "audioQS": "Ps.48,Deut.16,John.7",
                "note": null
            }
        ]
    },
    "2025/06/08": {
        "datePretty": "Sunday, June 8, 2025",
        "calendar": "Day of Pentecost",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 104:26\u201336,37b",
                        "readQS": "Psalm+104:26-36,37b",
                        "audioQS": "Ps.104"
                    },
                    {
                        "pretty": "Acts 2:1\u201321",
                        "readQS": "Acts+2:1-21",
                        "audioQS": "Acts.2"
                    },
                    {
                        "pretty": "Romans 8:14\u201317",
                        "readQS": "Romans+8:14-17",
                        "audioQS": "Rom.8"
                    },
                    {
                        "pretty": "John 14:8\u201317,(25\u201327)",
                        "readQS": "John+14:8-17,%2825-27%29",
                        "audioQS": "John.14"
                    }
                ],
                "readQS": "Psalm+104:26-36,37b%3B+Acts+2:1-21%3B+Romans+8:14-17%3B+John+14:8-17,%2825-27%29",
                "audioQS": "Ps.104,Acts.2,Rom.8,John.14",
                "note": "The reading from Acts must be used as either the first or second reading."
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 36:5\u201310,150",
                        "readQS": "Psalm+36:5-10,150",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "Isaiah 40:12\u201323",
                        "readQS": "Isaiah+40:12-23",
                        "audioQS": "Isa.40"
                    },
                    {
                        "pretty": "1 Corinthians 2:6\u201316",
                        "readQS": "1+Corinthians+2:6-16",
                        "audioQS": "1Cor.2"
                    }
                ],
                "readQS": "Psalm+36:5-10,150%3B+Isaiah+40:12-23%3B+1+Corinthians+2:6-16",
                "audioQS": "Ps.36,Isa.40,1Cor.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 33:1\u201312",
                        "readQS": "Psalm+33:1-12",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "Exodus 33:7\u201320",
                        "readQS": "Exodus+33:7-20",
                        "audioQS": "Exod.33"
                    },
                    {
                        "pretty": "2 Corinthians 3:4\u201318",
                        "readQS": "2+Corinthians+3:4-18",
                        "audioQS": "2Cor.3"
                    }
                ],
                "readQS": "Psalm+33:1-12%3B+Exodus+33:7-20%3B+2+Corinthians+3:4-18",
                "audioQS": "Ps.33,Exod.33,2Cor.3",
                "note": null
            }
        ]
    },
    "2025/06/09": {
        "datePretty": "Monday, June 9, 2025",
        "calendar": "Week after Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 126",
                        "readQS": "Psalm+126",
                        "audioQS": "Ps.126"
                    },
                    {
                        "pretty": "Job 1",
                        "readQS": "Job+1",
                        "audioQS": "Job.1"
                    },
                    {
                        "pretty": "Romans 1:1\u201317",
                        "readQS": "Romans+1:1-17",
                        "audioQS": "Rom.1"
                    }
                ],
                "readQS": "Psalm+126%3B+Job+1%3B+Romans+1:1-17",
                "audioQS": "Ps.126,Job.1,Rom.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 127",
                        "readQS": "Psalm+127",
                        "audioQS": "Ps.127"
                    },
                    {
                        "pretty": "Joshua 1",
                        "readQS": "Joshua+1",
                        "audioQS": "Josh.1"
                    },
                    {
                        "pretty": "Luke 9:18\u201327",
                        "readQS": "Luke+9:18-27",
                        "audioQS": "Luke.9"
                    }
                ],
                "readQS": "Psalm+127%3B+Joshua+1%3B+Luke+9:18-27",
                "audioQS": "Ps.127,Josh.1,Luke.9",
                "note": null
            }
        ]
    },
    "2025/06/10": {
        "datePretty": "Tuesday, June 10, 2025",
        "calendar": "Week after Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Job 2",
                        "readQS": "Job+2",
                        "audioQS": "Job.2"
                    },
                    {
                        "pretty": "Romans 1:18\u2013end",
                        "readQS": "Romans+1:18-",
                        "audioQS": "Rom.1"
                    }
                ],
                "readQS": "Psalm+132%3B+Job+2%3B+Romans+1:18-",
                "audioQS": "Ps.132,Job.2,Rom.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Barnabas)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 1,15",
                        "readQS": "Psalm+1,15",
                        "audioQS": "Ps.1,15"
                    },
                    {
                        "pretty": "Isaiah 42:5\u201312",
                        "readQS": "Isaiah+42:5-12",
                        "audioQS": "Isa.42"
                    },
                    {
                        "pretty": "Acts 14:8\u201328",
                        "readQS": "Acts+14:8-28",
                        "audioQS": "Acts.14"
                    }
                ],
                "readQS": "Psalm+1,15%3B+Isaiah+42:5-12%3B+Acts+14:8-28",
                "audioQS": "Ps.1,15,Isa.42,Acts.14",
                "note": null
            }
        ]
    },
    "2025/06/11": {
        "datePretty": "Wednesday, June 11, 2025",
        "calendar": "Barnabas the Apostle",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 100,101,117",
                        "readQS": "Psalm+100,101,117",
                        "audioQS": "Ps.100,101,117"
                    },
                    {
                        "pretty": "Jeremaiah 9:23\u201324",
                        "readQS": "Jeremaiah+9:23-24",
                        "audioQS": "Jeremaiah 9"
                    },
                    {
                        "pretty": "Acts 4:32\u201337",
                        "readQS": "Acts+4:32-37",
                        "audioQS": "Acts.4"
                    }
                ],
                "readQS": "Psalm+100,101,117%3B+Jeremaiah+9:23-24%3B+Acts+4:32-37",
                "audioQS": "Ps.100,101,117,Jeremaiah 9,Acts.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 147",
                        "readQS": "Psalm+147",
                        "audioQS": "Ps.147"
                    },
                    {
                        "pretty": "Ecclesiastes 12:9\u201324",
                        "readQS": "Ecclesiastes+12:9-24",
                        "audioQS": "Eccl.12"
                    },
                    {
                        "pretty": "Acts 9:26\u201331",
                        "readQS": "Acts+9:26-31",
                        "audioQS": "Acts.9"
                    }
                ],
                "readQS": "Psalm+147%3B+Ecclesiastes+12:9-24%3B+Acts+9:26-31",
                "audioQS": "Ps.147,Eccl.12,Acts.9",
                "note": null
            }
        ]
    },
    "2025/06/12": {
        "datePretty": "Thursday, June 12, 2025",
        "calendar": "Week after Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 143",
                        "readQS": "Psalm+143",
                        "audioQS": "Ps.143"
                    },
                    {
                        "pretty": "Job 4",
                        "readQS": "Job+4",
                        "audioQS": "Job.4"
                    },
                    {
                        "pretty": "Romans 2:17\u2013end",
                        "readQS": "Romans+2:17-",
                        "audioQS": "Rom.2"
                    }
                ],
                "readQS": "Psalm+143%3B+Job+4%3B+Romans+2:17-",
                "audioQS": "Ps.143,Job.4,Rom.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 138",
                        "readQS": "Psalm+138",
                        "audioQS": "Ps.138"
                    },
                    {
                        "pretty": "Joshua 4:1\u20135:1",
                        "readQS": "Joshua+4:1-5:1",
                        "audioQS": "Josh.4"
                    },
                    {
                        "pretty": "Luke 9:51\u2013end",
                        "readQS": "Luke+9:51-",
                        "audioQS": "Luke.9"
                    }
                ],
                "readQS": "Psalm+138%3B+Joshua+4:1-5:1%3B+Luke+9:51-",
                "audioQS": "Ps.138,Josh.4,Luke.9",
                "note": null
            }
        ]
    },
    "2025/06/13": {
        "datePretty": "Friday, June 13, 2025",
        "calendar": "Week after Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 144",
                        "readQS": "Psalm+144",
                        "audioQS": "Ps.144"
                    },
                    {
                        "pretty": "Job 5",
                        "readQS": "Job+5",
                        "audioQS": "Job.5"
                    },
                    {
                        "pretty": "Romans 3:1\u201320",
                        "readQS": "Romans+3:1-20",
                        "audioQS": "Rom.3"
                    }
                ],
                "readQS": "Psalm+144%3B+Job+5%3B+Romans+3:1-20",
                "audioQS": "Ps.144,Job.5,Rom.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Joshua 5:2\u2013end",
                        "readQS": "Joshua+5:2-",
                        "audioQS": "Josh.5"
                    },
                    {
                        "pretty": "Luke 10:1\u201316",
                        "readQS": "Luke+10:1-16",
                        "audioQS": "Luke.10"
                    }
                ],
                "readQS": "Psalm+145%3B+Joshua+5:2-%3B+Luke+10:1-16",
                "audioQS": "Ps.145,Josh.5,Luke.10",
                "note": null
            }
        ]
    },
    "2025/06/14": {
        "datePretty": "Saturday, June 14, 2025",
        "calendar": "Week after Pentecost",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 147",
                        "readQS": "Psalm+147",
                        "audioQS": "Ps.147"
                    },
                    {
                        "pretty": "Job 6",
                        "readQS": "Job+6",
                        "audioQS": "Job.6"
                    },
                    {
                        "pretty": "Romans 3:21\u2013end",
                        "readQS": "Romans+3:21-",
                        "audioQS": "Rom.3"
                    }
                ],
                "readQS": "Psalm+147%3B+Job+6%3B+Romans+3:21-",
                "audioQS": "Ps.147,Job.6,Rom.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of Trinity Sunday)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 97,98",
                        "readQS": "Psalm+97,98",
                        "audioQS": "Ps.97,98"
                    },
                    {
                        "pretty": "Isaiah 40:12\u201331",
                        "readQS": "Isaiah+40:12-31",
                        "audioQS": "Isa.40"
                    },
                    {
                        "pretty": "Mark 1:1\u201313",
                        "readQS": "Mark+1:1-13",
                        "audioQS": "Mark.1"
                    }
                ],
                "readQS": "Psalm+97,98%3B+Isaiah+40:12-31%3B+Mark+1:1-13",
                "audioQS": "Ps.97,98,Isa.40,Mark.1",
                "note": null
            }
        ]
    },
    "2025/06/15": {
        "datePretty": "Sunday, June 15, 2025",
        "calendar": "Trinity Sunday",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 8",
                        "readQS": "Psalm+8",
                        "audioQS": "Ps.8"
                    },
                    {
                        "pretty": "Proverbs 8:1\u20134,22\u201331",
                        "readQS": "Proverbs+8:1-4,22-31",
                        "audioQS": "Prov.8"
                    },
                    {
                        "pretty": "Romans 5:1\u20135",
                        "readQS": "Romans+5:1-5",
                        "audioQS": "Rom.5"
                    },
                    {
                        "pretty": "John 16:12\u201315",
                        "readQS": "John+16:12-15",
                        "audioQS": "John.16"
                    }
                ],
                "readQS": "Psalm+8%3B+Proverbs+8:1-4,22-31%3B+Romans+5:1-5%3B+John+16:12-15",
                "audioQS": "Ps.8,Prov.8,Rom.5,John.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 29",
                        "readQS": "Psalm+29",
                        "audioQS": "Ps.29"
                    },
                    {
                        "pretty": "Isaiah 6:1\u20138",
                        "readQS": "Isaiah+6:1-8",
                        "audioQS": "Isa.6"
                    },
                    {
                        "pretty": "Revelation 4",
                        "readQS": "Revelation+4",
                        "audioQS": "Rev.4"
                    }
                ],
                "readQS": "Psalm+29%3B+Isaiah+6:1-8%3B+Revelation+4",
                "audioQS": "Ps.29,Isa.6,Rev.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 73:1\u20133,16\u201328",
                        "readQS": "Psalm+73:1-3,16-28",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Exodus 3:1\u201315",
                        "readQS": "Exodus+3:1-15",
                        "audioQS": "Exod.3"
                    },
                    {
                        "pretty": "John 3:1\u201317",
                        "readQS": "John+3:1-17",
                        "audioQS": "John.3"
                    }
                ],
                "readQS": "Psalm+73:1-3,16-28%3B+Exodus+3:1-15%3B+John+3:1-17",
                "audioQS": "Ps.73,Exod.3,John.3",
                "note": null
            }
        ]
    },
    "2025/06/16": {
        "datePretty": "Monday, June 16, 2025",
        "calendar": "Week of Trinity Sunday",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 1",
                        "readQS": "Psalm+1",
                        "audioQS": "Ps.1"
                    },
                    {
                        "pretty": "Job 7",
                        "readQS": "Job+7",
                        "audioQS": "Job.7"
                    },
                    {
                        "pretty": "Romans 4:1\u201312",
                        "readQS": "Romans+4:1-12",
                        "audioQS": "Rom.4"
                    }
                ],
                "readQS": "Psalm+1%3B+Job+7%3B+Romans+4:1-12",
                "audioQS": "Ps.1,Job.7,Rom.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 4",
                        "readQS": "Psalm+4",
                        "audioQS": "Ps.4"
                    },
                    {
                        "pretty": "Joshua 7:1\u201315",
                        "readQS": "Joshua+7:1-15",
                        "audioQS": "Josh.7"
                    },
                    {
                        "pretty": "Luke 10:25\u201337",
                        "readQS": "Luke+10:25-37",
                        "audioQS": "Luke.10"
                    }
                ],
                "readQS": "Psalm+4%3B+Joshua+7:1-15%3B+Luke+10:25-37",
                "audioQS": "Ps.4,Josh.7,Luke.10",
                "note": null
            }
        ]
    },
    "2025/06/17": {
        "datePretty": "Tuesday, June 17, 2025",
        "calendar": "Week of Trinity Sunday",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 5",
                        "readQS": "Psalm+5",
                        "audioQS": "Ps.5"
                    },
                    {
                        "pretty": "Job 8",
                        "readQS": "Job+8",
                        "audioQS": "Job.8"
                    },
                    {
                        "pretty": "Romans 4:13\u2013end",
                        "readQS": "Romans+4:13-",
                        "audioQS": "Rom.4"
                    }
                ],
                "readQS": "Psalm+5%3B+Job+8%3B+Romans+4:13-",
                "audioQS": "Ps.5,Job.8,Rom.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 9",
                        "readQS": "Psalm+9",
                        "audioQS": "Ps.9"
                    },
                    {
                        "pretty": "Joshua 7:16\u2013end",
                        "readQS": "Joshua+7:16-",
                        "audioQS": "Josh.7"
                    },
                    {
                        "pretty": "Luke 10:38\u2013end",
                        "readQS": "Luke+10:38-",
                        "audioQS": "Luke.10"
                    }
                ],
                "readQS": "Psalm+9%3B+Joshua+7:16-%3B+Luke+10:38-",
                "audioQS": "Ps.9,Josh.7,Luke.10",
                "note": null
            }
        ]
    },
    "2025/06/18": {
        "datePretty": "Wednesday, June 18, 2025",
        "calendar": "Week of Trinity Sunday",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:1\u201332",
                        "readQS": "Psalm+119:1-32",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Job 9",
                        "readQS": "Job+9",
                        "audioQS": "Job.9"
                    },
                    {
                        "pretty": "Romans 5:1\u201311",
                        "readQS": "Romans+5:1-11",
                        "audioQS": "Rom.5"
                    }
                ],
                "readQS": "Psalm+119:1-32%3B+Job+9%3B+Romans+5:1-11",
                "audioQS": "Ps.119,Job.9,Rom.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 11",
                        "readQS": "Psalm+11",
                        "audioQS": "Ps.11"
                    },
                    {
                        "pretty": "Joshua 8:1\u201329",
                        "readQS": "Joshua+8:1-29",
                        "audioQS": "Josh.8"
                    },
                    {
                        "pretty": "Luke 11:1\u201313",
                        "readQS": "Luke+11:1-13",
                        "audioQS": "Luke.11"
                    }
                ],
                "readQS": "Psalm+11%3B+Joshua+8:1-29%3B+Luke+11:1-13",
                "audioQS": "Ps.11,Josh.8,Luke.11",
                "note": null
            }
        ]
    },
    "2025/06/19": {
        "datePretty": "Thursday, June 19, 2025",
        "calendar": "Week of Trinity Sunday",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 15",
                        "readQS": "Psalm+15",
                        "audioQS": "Ps.15"
                    },
                    {
                        "pretty": "Job 10",
                        "readQS": "Job+10",
                        "audioQS": "Job.10"
                    },
                    {
                        "pretty": "Romans 5:12\u2013end",
                        "readQS": "Romans+5:12-",
                        "audioQS": "Rom.5"
                    }
                ],
                "readQS": "Psalm+15%3B+Job+10%3B+Romans+5:12-",
                "audioQS": "Ps.15,Job.10,Rom.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 18",
                        "readQS": "Psalm+18",
                        "audioQS": "Ps.18"
                    },
                    {
                        "pretty": "Joshua 8:30\u2013end",
                        "readQS": "Joshua+8:30-",
                        "audioQS": "Josh.8"
                    },
                    {
                        "pretty": "Luke 11:14\u201328",
                        "readQS": "Luke+11:14-28",
                        "audioQS": "Luke.11"
                    }
                ],
                "readQS": "Psalm+18%3B+Joshua+8:30-%3B+Luke+11:14-28",
                "audioQS": "Ps.18,Josh.8,Luke.11",
                "note": null
            }
        ]
    },
    "2025/06/20": {
        "datePretty": "Friday, June 20, 2025",
        "calendar": "Week of Trinity Sunday",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 19",
                        "readQS": "Psalm+19",
                        "audioQS": "Ps.19"
                    },
                    {
                        "pretty": "Job 11",
                        "readQS": "Job+11",
                        "audioQS": "Job.11"
                    },
                    {
                        "pretty": "Romans 6:1\u201314",
                        "readQS": "Romans+6:1-14",
                        "audioQS": "Rom.6"
                    }
                ],
                "readQS": "Psalm+19%3B+Job+11%3B+Romans+6:1-14",
                "audioQS": "Ps.19,Job.11,Rom.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 22",
                        "readQS": "Psalm+22",
                        "audioQS": "Ps.22"
                    },
                    {
                        "pretty": "Joshua 9:3\u201326",
                        "readQS": "Joshua+9:3-26",
                        "audioQS": "Josh.9"
                    },
                    {
                        "pretty": "Luke 11:29\u201336",
                        "readQS": "Luke+11:29-36",
                        "audioQS": "Luke.11"
                    }
                ],
                "readQS": "Psalm+22%3B+Joshua+9:3-26%3B+Luke+11:29-36",
                "audioQS": "Ps.22,Josh.9,Luke.11",
                "note": null
            }
        ]
    },
    "2025/06/21": {
        "datePretty": "Saturday, June 21, 2025",
        "calendar": "Week of Trinity Sunday",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 23",
                        "readQS": "Psalm+23",
                        "audioQS": "Ps.23"
                    },
                    {
                        "pretty": "Job 12",
                        "readQS": "Job+12",
                        "audioQS": "Job.12"
                    },
                    {
                        "pretty": "Romans 6:15\u2013end",
                        "readQS": "Romans+6:15-",
                        "audioQS": "Rom.6"
                    }
                ],
                "readQS": "Psalm+23%3B+Job+12%3B+Romans+6:15-",
                "audioQS": "Ps.23,Job.12,Rom.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 24",
                        "readQS": "Psalm+24",
                        "audioQS": "Ps.24"
                    },
                    {
                        "pretty": "Joshua 10:1\u201315",
                        "readQS": "Joshua+10:1-15",
                        "audioQS": "Josh.10"
                    },
                    {
                        "pretty": "Luke 11:37\u2013end",
                        "readQS": "Luke+11:37-",
                        "audioQS": "Luke.11"
                    }
                ],
                "readQS": "Psalm+24%3B+Joshua+10:1-15%3B+Luke+11:37-",
                "audioQS": "Ps.24,Josh.10,Luke.11",
                "note": null
            }
        ]
    },
    "2025/06/22": {
        "datePretty": "Sunday, June 22, 2025",
        "calendar": "12th Sunday in Ordinary Time (Te Pouhere Sunday)",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 22:19\u201328",
                        "readQS": "Psalm+22:19-28",
                        "audioQS": "Ps.22"
                    },
                    {
                        "pretty": "Isaiah 65:1\u20139",
                        "readQS": "Isaiah+65:1-9",
                        "audioQS": "Isa.65"
                    },
                    {
                        "pretty": "Galatians 3:23\u201329",
                        "readQS": "Galatians+3:23-29",
                        "audioQS": "Gal.3"
                    },
                    {
                        "pretty": "Luke 8:26\u201339",
                        "readQS": "Luke+8:26-39",
                        "audioQS": "Luke.8"
                    }
                ],
                "readQS": "Psalm+22:19-28%3B+Isaiah+65:1-9%3B+Galatians+3:23-29%3B+Luke+8:26-39",
                "audioQS": "Ps.22,Isa.65,Gal.3,Luke.8",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 42, 43",
                        "readQS": "Psalm+42,+43",
                        "audioQS": "Ps.42,.43"
                    },
                    {
                        "pretty": "1 Kings 19:1\u20134,(5\u20137),8\u201315a",
                        "readQS": "1+Kings+19:1-4,%285-7%29,8-15a",
                        "audioQS": "1Kgs.19"
                    },
                    {
                        "pretty": "Galatians 3:23\u201329",
                        "readQS": "Galatians+3:23-29",
                        "audioQS": "Gal.3"
                    },
                    {
                        "pretty": "Luke 8:26\u201339",
                        "readQS": "Luke+8:26-39",
                        "audioQS": "Luke.8"
                    }
                ],
                "readQS": "Psalm+42,+43%3B+1+Kings+19:1-4,%285-7%29,8-15a%3B+Galatians+3:23-29%3B+Luke+8:26-39",
                "audioQS": "Ps.42,.43,1Kgs.19,Gal.3,Luke.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 55:1\u201316,18\u201321",
                        "readQS": "Psalm+55:1-16,18-21",
                        "audioQS": "Ps.55"
                    },
                    {
                        "pretty": "Deuteronomy 11:1\u201315",
                        "readQS": "Deuteronomy+11:1-15",
                        "audioQS": "Deut.11"
                    },
                    {
                        "pretty": "Acts 27:1\u201312",
                        "readQS": "Acts+27:1-12",
                        "audioQS": "Acts.27"
                    }
                ],
                "readQS": "Psalm+55:1-16,18-21%3B+Deuteronomy+11:1-15%3B+Acts+27:1-12",
                "audioQS": "Ps.55,Deut.11,Acts.27",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 57",
                        "readQS": "Psalm+57",
                        "audioQS": "Ps.57"
                    },
                    {
                        "pretty": "Genesis 24:1\u201327",
                        "readQS": "Genesis+24:1-27",
                        "audioQS": "Gen.24"
                    },
                    {
                        "pretty": "Mark 5:21\u201343",
                        "readQS": "Mark+5:21-43",
                        "audioQS": "Mark.5"
                    }
                ],
                "readQS": "Psalm+57%3B+Genesis+24:1-27%3B+Mark+5:21-43",
                "audioQS": "Ps.57,Gen.24,Mark.5",
                "note": null
            }
        ]
    },
    "2025/06/23": {
        "datePretty": "Monday, June 23, 2025",
        "calendar": "Week of 12th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 30",
                        "readQS": "Psalm+30",
                        "audioQS": "Ps.30"
                    },
                    {
                        "pretty": "Job 13",
                        "readQS": "Job+13",
                        "audioQS": "Job.13"
                    },
                    {
                        "pretty": "Romans 7:1\u20136",
                        "readQS": "Romans+7:1-6",
                        "audioQS": "Rom.7"
                    }
                ],
                "readQS": "Psalm+30%3B+Job+13%3B+Romans+7:1-6",
                "audioQS": "Ps.30,Job.13,Rom.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St John the Baptist)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Judges 13:2\u20137,13:24\u201325",
                        "readQS": "Judges+13:2-7,13:24-25",
                        "audioQS": "Judg.13"
                    },
                    {
                        "pretty": "Luke 1:5\u201325",
                        "readQS": "Luke+1:5-25",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+71%3B+Judges+13:2-7,13:24-25%3B+Luke+1:5-25",
                "audioQS": "Ps.71,Judg.13,Luke.1",
                "note": null
            }
        ]
    },
    "2025/06/24": {
        "datePretty": "Tuesday, June 24, 2025",
        "calendar": "St John the Baptist",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 50,149",
                        "readQS": "Psalm+50,149",
                        "audioQS": "Ps.50,149"
                    },
                    {
                        "pretty": "Malachi 3:1\u20136",
                        "readQS": "Malachi+3:1-6",
                        "audioQS": "Mal.3"
                    },
                    {
                        "pretty": "Luke 3:1\u201317",
                        "readQS": "Luke+3:1-17",
                        "audioQS": "Luke.3"
                    }
                ],
                "readQS": "Psalm+50,149%3B+Malachi+3:1-6%3B+Luke+3:1-17",
                "audioQS": "Ps.50,149,Mal.3,Luke.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 80,82",
                        "readQS": "Psalm+80,82",
                        "audioQS": "Ps.80,82"
                    },
                    {
                        "pretty": "Malachi 4",
                        "readQS": "Malachi+4",
                        "audioQS": "Mal.4"
                    },
                    {
                        "pretty": "Matthew 11:2\u201319",
                        "readQS": "Matthew+11:2-19",
                        "audioQS": "Matt.11"
                    }
                ],
                "readQS": "Psalm+80,82%3B+Malachi+4%3B+Matthew+11:2-19",
                "audioQS": "Ps.80,82,Mal.4,Matt.11",
                "note": null
            }
        ]
    },
    "2025/06/25": {
        "datePretty": "Wednesday, June 25, 2025",
        "calendar": "Week of 12th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 34",
                        "readQS": "Psalm+34",
                        "audioQS": "Ps.34"
                    },
                    {
                        "pretty": "Job 15",
                        "readQS": "Job+15",
                        "audioQS": "Job.15"
                    },
                    {
                        "pretty": "Romans 8:1\u201311",
                        "readQS": "Romans+8:1-11",
                        "audioQS": "Rom.8"
                    }
                ],
                "readQS": "Psalm+34%3B+Job+15%3B+Romans+8:1-11",
                "audioQS": "Ps.34,Job.15,Rom.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:33\u201356",
                        "readQS": "Psalm+119:33-56",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Joshua 22:9\u2013end",
                        "readQS": "Joshua+22:9-",
                        "audioQS": "Josh.22"
                    },
                    {
                        "pretty": "Luke 12:22\u201331",
                        "readQS": "Luke+12:22-31",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+119:33-56%3B+Joshua+22:9-%3B+Luke+12:22-31",
                "audioQS": "Ps.119,Josh.22,Luke.12",
                "note": null
            }
        ]
    },
    "2025/06/26": {
        "datePretty": "Thursday, June 26, 2025",
        "calendar": "Week of 12th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 37",
                        "readQS": "Psalm+37",
                        "audioQS": "Ps.37"
                    },
                    {
                        "pretty": "Job 16:1\u201317:2",
                        "readQS": "Job+16:1-17:2",
                        "audioQS": "Job.16"
                    },
                    {
                        "pretty": "Romans 8:12\u201317",
                        "readQS": "Romans+8:12-17",
                        "audioQS": "Rom.8"
                    }
                ],
                "readQS": "Psalm+37%3B+Job+16:1-17:2%3B+Romans+8:12-17",
                "audioQS": "Ps.37,Job.16,Rom.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Joshua 23",
                        "readQS": "Joshua+23",
                        "audioQS": "Josh.23"
                    },
                    {
                        "pretty": "Luke 12:32\u201340",
                        "readQS": "Luke+12:32-40",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+40%3B+Joshua+23%3B+Luke+12:32-40",
                "audioQS": "Ps.40,Josh.23,Luke.12",
                "note": null
            }
        ]
    },
    "2025/06/27": {
        "datePretty": "Friday, June 27, 2025",
        "calendar": "Week of 12th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 31",
                        "readQS": "Psalm+31",
                        "audioQS": "Ps.31"
                    },
                    {
                        "pretty": "Job 17:3\u2013end",
                        "readQS": "Job+17:3-",
                        "audioQS": "Job.17"
                    },
                    {
                        "pretty": "Romans 8:18\u201330",
                        "readQS": "Romans+8:18-30",
                        "audioQS": "Rom.8"
                    }
                ],
                "readQS": "Psalm+31%3B+Job+17:3-%3B+Romans+8:18-30",
                "audioQS": "Ps.31,Job.17,Rom.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 35",
                        "readQS": "Psalm+35",
                        "audioQS": "Ps.35"
                    },
                    {
                        "pretty": "Joshua 24:1\u201328",
                        "readQS": "Joshua+24:1-28",
                        "audioQS": "Josh.24"
                    },
                    {
                        "pretty": "Luke 12:41\u201348",
                        "readQS": "Luke+12:41-48",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+35%3B+Joshua+24:1-28%3B+Luke+12:41-48",
                "audioQS": "Ps.35,Josh.24,Luke.12",
                "note": null
            }
        ]
    },
    "2025/06/28": {
        "datePretty": "Saturday, June 28, 2025",
        "calendar": "Week of 12th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 42",
                        "readQS": "Psalm+42",
                        "audioQS": "Ps.42"
                    },
                    {
                        "pretty": "Job 18",
                        "readQS": "Job+18",
                        "audioQS": "Job.18"
                    },
                    {
                        "pretty": "Romans 8:31\u2013end",
                        "readQS": "Romans+8:31-",
                        "audioQS": "Rom.8"
                    }
                ],
                "readQS": "Psalm+42%3B+Job+18%3B+Romans+8:31-",
                "audioQS": "Ps.42,Job.18,Rom.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Peter and St Paul)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 66,67",
                        "readQS": "Psalm+66,67",
                        "audioQS": "Ps.66,67"
                    },
                    {
                        "pretty": "Ezekiel 3:4\u201311",
                        "readQS": "Ezekiel+3:4-11",
                        "audioQS": "Ezek.3"
                    },
                    {
                        "pretty": "Galatians 1:13\u2013end,2:1\u20138",
                        "readQS": "Galatians+1:13-,2:1-8",
                        "audioQS": "Gal.1"
                    }
                ],
                "readQS": "Psalm+66,67%3B+Ezekiel+3:4-11%3B+Galatians+1:13-,2:1-8",
                "audioQS": "Ps.66,67,Ezek.3,Gal.1",
                "note": null
            }
        ]
    },
    "2025/06/29": {
        "datePretty": "Sunday, June 29, 2025",
        "calendar": "St Peter and St Paul, Apostles, Martyrs",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 16",
                        "readQS": "Psalm+16",
                        "audioQS": "Ps.16"
                    },
                    {
                        "pretty": "1 Kings 19:15\u201316,19\u201321",
                        "readQS": "1+Kings+19:15-16,19-21",
                        "audioQS": "1Kgs.19"
                    },
                    {
                        "pretty": "Galatians 5:1,13\u201325",
                        "readQS": "Galatians+5:1,13-25",
                        "audioQS": "Gal.5"
                    },
                    {
                        "pretty": "Luke 9:51\u201362",
                        "readQS": "Luke+9:51-62",
                        "audioQS": "Luke.9"
                    }
                ],
                "readQS": "Psalm+16%3B+1+Kings+19:15-16,19-21%3B+Galatians+5:1,13-25%3B+Luke+9:51-62",
                "audioQS": "Ps.16,1Kgs.19,Gal.5,Luke.9",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 77:1\u20132,11\u201320",
                        "readQS": "Psalm+77:1-2,11-20",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "2 Kings 2:1\u20132,6\u201314",
                        "readQS": "2+Kings+2:1-2,6-14",
                        "audioQS": "2Kgs.2"
                    },
                    {
                        "pretty": "Galatians 5:1,13\u201325",
                        "readQS": "Galatians+5:1,13-25",
                        "audioQS": "Gal.5"
                    },
                    {
                        "pretty": "Luke 9:51\u201362",
                        "readQS": "Luke+9:51-62",
                        "audioQS": "Luke.9"
                    }
                ],
                "readQS": "Psalm+77:1-2,11-20%3B+2+Kings+2:1-2,6-14%3B+Galatians+5:1,13-25%3B+Luke+9:51-62",
                "audioQS": "Ps.77,2Kgs.2,Gal.5,Luke.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 71,113",
                        "readQS": "Psalm+71,113",
                        "audioQS": "Ps.71,113"
                    },
                    {
                        "pretty": "Isaiah 49:1\u20136",
                        "readQS": "Isaiah+49:1-6",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "Acts 11:1\u201318",
                        "readQS": "Acts+11:1-18",
                        "audioQS": "Acts.11"
                    }
                ],
                "readQS": "Psalm+71,113%3B+Isaiah+49:1-6%3B+Acts+11:1-18",
                "audioQS": "Ps.71,113,Isa.49,Acts.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 124,138",
                        "readQS": "Psalm+124,138",
                        "audioQS": "Ps.124,138"
                    },
                    {
                        "pretty": "Ezekiel 34:11\u201316",
                        "readQS": "Ezekiel+34:11-16",
                        "audioQS": "Ezek.34"
                    },
                    {
                        "pretty": "John 21:15\u201322",
                        "readQS": "John+21:15-22",
                        "audioQS": "John.21"
                    }
                ],
                "readQS": "Psalm+124,138%3B+Ezekiel+34:11-16%3B+John+21:15-22",
                "audioQS": "Ps.124,138,Ezek.34,John.21",
                "note": null
            }
        ]
    },
    "2025/06/30": {
        "datePretty": "Monday, June 30, 2025",
        "calendar": "Week of 13th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "Job 19",
                        "readQS": "Job+19",
                        "audioQS": "Job.19"
                    },
                    {
                        "pretty": "Romans 9:1\u201318",
                        "readQS": "Romans+9:1-18",
                        "audioQS": "Rom.9"
                    }
                ],
                "readQS": "Psalm+44%3B+Job+19%3B+Romans+9:1-18",
                "audioQS": "Ps.44,Job.19,Rom.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 47",
                        "readQS": "Psalm+47",
                        "audioQS": "Ps.47"
                    },
                    {
                        "pretty": "Judges 2",
                        "readQS": "Judges+2",
                        "audioQS": "Judg.2"
                    },
                    {
                        "pretty": "Luke 13:1\u20139",
                        "readQS": "Luke+13:1-9",
                        "audioQS": "Luke.13"
                    }
                ],
                "readQS": "Psalm+47%3B+Judges+2%3B+Luke+13:1-9",
                "audioQS": "Ps.47,Judg.2,Luke.13",
                "note": null
            }
        ]
    },
    "2025/07/01": {
        "datePretty": "Tuesday, July 1, 2025",
        "calendar": "Week of 13th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 48",
                        "readQS": "Psalm+48",
                        "audioQS": "Ps.48"
                    },
                    {
                        "pretty": "Job 21",
                        "readQS": "Job+21",
                        "audioQS": "Job.21"
                    },
                    {
                        "pretty": "Romans 9:19\u2013end",
                        "readQS": "Romans+9:19-",
                        "audioQS": "Rom.9"
                    }
                ],
                "readQS": "Psalm+48%3B+Job+21%3B+Romans+9:19-",
                "audioQS": "Ps.48,Job.21,Rom.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 50",
                        "readQS": "Psalm+50",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "Judges 4:1\u201323",
                        "readQS": "Judges+4:1-23",
                        "audioQS": "Judg.4"
                    },
                    {
                        "pretty": "Luke 13:10\u201321",
                        "readQS": "Luke+13:10-21",
                        "audioQS": "Luke.13"
                    }
                ],
                "readQS": "Psalm+50%3B+Judges+4:1-23%3B+Luke+13:10-21",
                "audioQS": "Ps.50,Judg.4,Luke.13",
                "note": null
            }
        ]
    },
    "2025/07/02": {
        "datePretty": "Wednesday, July 2, 2025",
        "calendar": "Week of 13th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:57\u201380",
                        "readQS": "Psalm+119:57-80",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Job 22",
                        "readQS": "Job+22",
                        "audioQS": "Job.22"
                    },
                    {
                        "pretty": "Romans 10:1\u201310",
                        "readQS": "Romans+10:1-10",
                        "audioQS": "Rom.10"
                    }
                ],
                "readQS": "Psalm+119:57-80%3B+Job+22%3B+Romans+10:1-10",
                "audioQS": "Ps.119,Job.22,Rom.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Annunciation)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 27",
                        "readQS": "Psalm+27",
                        "audioQS": "Ps.27"
                    },
                    {
                        "pretty": "Isaiah 35",
                        "readQS": "Isaiah+35",
                        "audioQS": "Isa.35"
                    },
                    {
                        "pretty": "Hebrews 10:35\u2013end,11:1",
                        "readQS": "Hebrews+10:35-,11:1",
                        "audioQS": "Heb.10"
                    }
                ],
                "readQS": "Psalm+27%3B+Isaiah+35%3B+Hebrews+10:35-,11:1",
                "audioQS": "Ps.27,Isa.35,Heb.10",
                "note": null
            }
        ]
    },
    "2025/07/03": {
        "datePretty": "Thursday, July 3, 2025",
        "calendar": "Thomas the Apostle",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 92,146",
                        "readQS": "Psalm+92,146",
                        "audioQS": "Ps.92,146"
                    },
                    {
                        "pretty": "2 Samuel 15:17\u201321",
                        "readQS": "2+Samuel+15:17-21",
                        "audioQS": "2Sam.15"
                    },
                    {
                        "pretty": "John 11:1\u201316",
                        "readQS": "John+11:1-16",
                        "audioQS": "John.11"
                    }
                ],
                "readQS": "Psalm+92,146%3B+2+Samuel+15:17-21%3B+John+11:1-16",
                "audioQS": "Ps.92,146,2Sam.15,John.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 139",
                        "readQS": "Psalm+139",
                        "audioQS": "Ps.139"
                    },
                    {
                        "pretty": "Job 42:1\u20136",
                        "readQS": "Job+42:1-6",
                        "audioQS": "Job.42"
                    },
                    {
                        "pretty": "1 Peter 1:3\u201312",
                        "readQS": "1+Peter+1:3-12",
                        "audioQS": "1Pet.1"
                    }
                ],
                "readQS": "Psalm+139%3B+Job+42:1-6%3B+1+Peter+1:3-12",
                "audioQS": "Ps.139,Job.42,1Pet.1",
                "note": null
            }
        ]
    },
    "2025/07/04": {
        "datePretty": "Friday, July 4, 2025",
        "calendar": "Week of 13th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 51",
                        "readQS": "Psalm+51",
                        "audioQS": "Ps.51"
                    },
                    {
                        "pretty": "Job 24",
                        "readQS": "Job+24",
                        "audioQS": "Job.24"
                    },
                    {
                        "pretty": "Romans 11:1\u201312",
                        "readQS": "Romans+11:1-12",
                        "audioQS": "Rom.11"
                    }
                ],
                "readQS": "Psalm+51%3B+Job+24%3B+Romans+11:1-12",
                "audioQS": "Ps.51,Job.24,Rom.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 38",
                        "readQS": "Psalm+38",
                        "audioQS": "Ps.38"
                    },
                    {
                        "pretty": "Judges 6:25\u2013end",
                        "readQS": "Judges+6:25-",
                        "audioQS": "Judg.6"
                    },
                    {
                        "pretty": "Luke 14:12\u201324",
                        "readQS": "Luke+14:12-24",
                        "audioQS": "Luke.14"
                    }
                ],
                "readQS": "Psalm+38%3B+Judges+6:25-%3B+Luke+14:12-24",
                "audioQS": "Ps.38,Judg.6,Luke.14",
                "note": null
            }
        ]
    },
    "2025/07/05": {
        "datePretty": "Saturday, July 5, 2025",
        "calendar": "Week of 13th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 68",
                        "readQS": "Psalm+68",
                        "audioQS": "Ps.68"
                    },
                    {
                        "pretty": "Job 25\u201326",
                        "readQS": "Job+25-26",
                        "audioQS": "Job.25\u201326"
                    },
                    {
                        "pretty": "Romans 11:13\u201324",
                        "readQS": "Romans+11:13-24",
                        "audioQS": "Rom.11"
                    }
                ],
                "readQS": "Psalm+68%3B+Job+25-26%3B+Romans+11:13-24",
                "audioQS": "Ps.68,Job.25\u201326,Rom.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 66",
                        "readQS": "Psalm+66",
                        "audioQS": "Ps.66"
                    },
                    {
                        "pretty": "Judges 7",
                        "readQS": "Judges+7",
                        "audioQS": "Judg.7"
                    },
                    {
                        "pretty": "Luke 14:25\u2013end",
                        "readQS": "Luke+14:25-",
                        "audioQS": "Luke.14"
                    }
                ],
                "readQS": "Psalm+66%3B+Judges+7%3B+Luke+14:25-",
                "audioQS": "Ps.66,Judg.7,Luke.14",
                "note": null
            }
        ]
    },
    "2025/07/06": {
        "datePretty": "Sunday, July 6, 2025",
        "calendar": "14th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 66:1\u20138",
                        "readQS": "Psalm+66:1-8",
                        "audioQS": "Ps.66"
                    },
                    {
                        "pretty": "Isaiah 66:10\u201314",
                        "readQS": "Isaiah+66:10-14",
                        "audioQS": "Isa.66"
                    },
                    {
                        "pretty": "Galatians 6:(1\u20136),7\u201316",
                        "readQS": "Galatians+6:%281-6%29,7-16",
                        "audioQS": "Gal.6"
                    },
                    {
                        "pretty": "Luke 10:1\u201311,16\u201320",
                        "readQS": "Luke+10:1-11,16-20",
                        "audioQS": "Luke.10"
                    }
                ],
                "readQS": "Psalm+66:1-8%3B+Isaiah+66:10-14%3B+Galatians+6:%281-6%29,7-16%3B+Luke+10:1-11,16-20",
                "audioQS": "Ps.66,Isa.66,Gal.6,Luke.10",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 30",
                        "readQS": "Psalm+30",
                        "audioQS": "Ps.30"
                    },
                    {
                        "pretty": "2 Kings 5:1\u201314",
                        "readQS": "2+Kings+5:1-14",
                        "audioQS": "2Kgs.5"
                    },
                    {
                        "pretty": "Galatians 6:(1\u20136),7\u201316",
                        "readQS": "Galatians+6:%281-6%29,7-16",
                        "audioQS": "Gal.6"
                    },
                    {
                        "pretty": "Luke 10:1\u201311,16\u201320",
                        "readQS": "Luke+10:1-11,16-20",
                        "audioQS": "Luke.10"
                    }
                ],
                "readQS": "Psalm+30%3B+2+Kings+5:1-14%3B+Galatians+6:%281-6%29,7-16%3B+Luke+10:1-11,16-20",
                "audioQS": "Ps.30,2Kgs.5,Gal.6,Luke.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 74",
                        "readQS": "Psalm+74",
                        "audioQS": "Ps.74"
                    },
                    {
                        "pretty": "Deuteronomy 24:10\u201322",
                        "readQS": "Deuteronomy+24:10-22",
                        "audioQS": "Deut.24"
                    },
                    {
                        "pretty": "Acts 28:1\u201316",
                        "readQS": "Acts+28:1-16",
                        "audioQS": "Acts.28"
                    }
                ],
                "readQS": "Psalm+74%3B+Deuteronomy+24:10-22%3B+Acts+28:1-16",
                "audioQS": "Ps.74,Deut.24,Acts.28",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 65",
                        "readQS": "Psalm+65",
                        "audioQS": "Ps.65"
                    },
                    {
                        "pretty": "Genesis 29:1\u201320",
                        "readQS": "Genesis+29:1-20",
                        "audioQS": "Gen.29"
                    },
                    {
                        "pretty": "Mark 6:7\u201329",
                        "readQS": "Mark+6:7-29",
                        "audioQS": "Mark.6"
                    }
                ],
                "readQS": "Psalm+65%3B+Genesis+29:1-20%3B+Mark+6:7-29",
                "audioQS": "Ps.65,Gen.29,Mark.6",
                "note": null
            }
        ]
    },
    "2025/07/07": {
        "datePretty": "Monday, July 7, 2025",
        "calendar": "Week of 14th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Job 27",
                        "readQS": "Job+27",
                        "audioQS": "Job.27"
                    },
                    {
                        "pretty": "Romans 11:25\u2013end",
                        "readQS": "Romans+11:25-",
                        "audioQS": "Rom.11"
                    }
                ],
                "readQS": "Psalm+71%3B+Job+27%3B+Romans+11:25-",
                "audioQS": "Ps.71,Job.27,Rom.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 72",
                        "readQS": "Psalm+72",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "Judges 8:22\u2013end",
                        "readQS": "Judges+8:22-",
                        "audioQS": "Judg.8"
                    },
                    {
                        "pretty": "Luke 15:1\u201310",
                        "readQS": "Luke+15:1-10",
                        "audioQS": "Luke.15"
                    }
                ],
                "readQS": "Psalm+72%3B+Judges+8:22-%3B+Luke+15:1-10",
                "audioQS": "Ps.72,Judg.8,Luke.15",
                "note": null
            }
        ]
    },
    "2025/07/08": {
        "datePretty": "Tuesday, July 8, 2025",
        "calendar": "Week of 14th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Job 28",
                        "readQS": "Job+28",
                        "audioQS": "Job.28"
                    },
                    {
                        "pretty": "Romans 12:1\u20138",
                        "readQS": "Romans+12:1-8",
                        "audioQS": "Rom.12"
                    }
                ],
                "readQS": "Psalm+73%3B+Job+28%3B+Romans+12:1-8",
                "audioQS": "Ps.73,Job.28,Rom.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 74",
                        "readQS": "Psalm+74",
                        "audioQS": "Ps.74"
                    },
                    {
                        "pretty": "Judges 9:1\u201321",
                        "readQS": "Judges+9:1-21",
                        "audioQS": "Judg.9"
                    },
                    {
                        "pretty": "Luke 15:11\u2013end",
                        "readQS": "Luke+15:11-",
                        "audioQS": "Luke.15"
                    }
                ],
                "readQS": "Psalm+74%3B+Judges+9:1-21%3B+Luke+15:11-",
                "audioQS": "Ps.74,Judg.9,Luke.15",
                "note": null
            }
        ]
    },
    "2025/07/09": {
        "datePretty": "Wednesday, July 9, 2025",
        "calendar": "Week of 14th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Job 29",
                        "readQS": "Job+29",
                        "audioQS": "Job.29"
                    },
                    {
                        "pretty": "Romans 12:9\u2013end",
                        "readQS": "Romans+12:9-",
                        "audioQS": "Rom.12"
                    }
                ],
                "readQS": "Psalm+77%3B+Job+29%3B+Romans+12:9-",
                "audioQS": "Ps.77,Job.29,Rom.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:81\u2013104",
                        "readQS": "Psalm+119:81-104",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Judges 9:22\u2013end",
                        "readQS": "Judges+9:22-",
                        "audioQS": "Judg.9"
                    },
                    {
                        "pretty": "Luke 16:1\u201318",
                        "readQS": "Luke+16:1-18",
                        "audioQS": "Luke.16"
                    }
                ],
                "readQS": "Psalm+119:81-104%3B+Judges+9:22-%3B+Luke+16:1-18",
                "audioQS": "Ps.119,Judg.9,Luke.16",
                "note": null
            }
        ]
    },
    "2025/07/10": {
        "datePretty": "Thursday, July 10, 2025",
        "calendar": "Week of 14th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 78:1\u201339",
                        "readQS": "Psalm+78:1-39",
                        "audioQS": "Ps.78"
                    },
                    {
                        "pretty": "Job 30",
                        "readQS": "Job+30",
                        "audioQS": "Job.30"
                    },
                    {
                        "pretty": "Romans 13:1\u20137",
                        "readQS": "Romans+13:1-7",
                        "audioQS": "Rom.13"
                    }
                ],
                "readQS": "Psalm+78:1-39%3B+Job+30%3B+Romans+13:1-7",
                "audioQS": "Ps.78,Job.30,Rom.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 78:40\u2013end",
                        "readQS": "Psalm+78:40-",
                        "audioQS": "Ps.78"
                    },
                    {
                        "pretty": "Judges 11:1\u201311",
                        "readQS": "Judges+11:1-11",
                        "audioQS": "Judg.11"
                    },
                    {
                        "pretty": "Luke 16:19\u2013end",
                        "readQS": "Luke+16:19-",
                        "audioQS": "Luke.16"
                    }
                ],
                "readQS": "Psalm+78:40-%3B+Judges+11:1-11%3B+Luke+16:19-",
                "audioQS": "Ps.78,Judg.11,Luke.16",
                "note": null
            }
        ]
    },
    "2025/07/11": {
        "datePretty": "Friday, July 11, 2025",
        "calendar": "Week of 14th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 55",
                        "readQS": "Psalm+55",
                        "audioQS": "Ps.55"
                    },
                    {
                        "pretty": "Job 31",
                        "readQS": "Job+31",
                        "audioQS": "Job.31"
                    },
                    {
                        "pretty": "Romans 13:8\u2013end",
                        "readQS": "Romans+13:8-",
                        "audioQS": "Rom.13"
                    }
                ],
                "readQS": "Psalm+55%3B+Job+31%3B+Romans+13:8-",
                "audioQS": "Ps.55,Job.31,Rom.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 69",
                        "readQS": "Psalm+69",
                        "audioQS": "Ps.69"
                    },
                    {
                        "pretty": "Judges 11:29\u2013end",
                        "readQS": "Judges+11:29-",
                        "audioQS": "Judg.11"
                    },
                    {
                        "pretty": "Luke 17:1\u201310",
                        "readQS": "Luke+17:1-10",
                        "audioQS": "Luke.17"
                    }
                ],
                "readQS": "Psalm+69%3B+Judges+11:29-%3B+Luke+17:1-10",
                "audioQS": "Ps.69,Judg.11,Luke.17",
                "note": null
            }
        ]
    },
    "2025/07/12": {
        "datePretty": "Saturday, July 12, 2025",
        "calendar": "Week of 14th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 76",
                        "readQS": "Psalm+76",
                        "audioQS": "Ps.76"
                    },
                    {
                        "pretty": "Job 32",
                        "readQS": "Job+32",
                        "audioQS": "Job.32"
                    },
                    {
                        "pretty": "Romans 14:1\u201312",
                        "readQS": "Romans+14:1-12",
                        "audioQS": "Rom.14"
                    }
                ],
                "readQS": "Psalm+76%3B+Job+32%3B+Romans+14:1-12",
                "audioQS": "Ps.76,Job.32,Rom.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 84",
                        "readQS": "Psalm+84",
                        "audioQS": "Ps.84"
                    },
                    {
                        "pretty": "Judges 12:1\u20137",
                        "readQS": "Judges+12:1-7",
                        "audioQS": "Judg.12"
                    },
                    {
                        "pretty": "Luke 17:11\u201319",
                        "readQS": "Luke+17:11-19",
                        "audioQS": "Luke.17"
                    }
                ],
                "readQS": "Psalm+84%3B+Judges+12:1-7%3B+Luke+17:11-19",
                "audioQS": "Ps.84,Judg.12,Luke.17",
                "note": null
            }
        ]
    },
    "2025/07/13": {
        "datePretty": "Sunday, July 13, 2025",
        "calendar": "15th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 25:1\u201310",
                        "readQS": "Psalm+25:1-10",
                        "audioQS": "Ps.25"
                    },
                    {
                        "pretty": "Deuteronomy 30:9\u201314",
                        "readQS": "Deuteronomy+30:9-14",
                        "audioQS": "Deut.30"
                    },
                    {
                        "pretty": "Colossians 1:1\u201314",
                        "readQS": "Colossians+1:1-14",
                        "audioQS": "Col.1"
                    },
                    {
                        "pretty": "Luke 10:25\u201337",
                        "readQS": "Luke+10:25-37",
                        "audioQS": "Luke.10"
                    }
                ],
                "readQS": "Psalm+25:1-10%3B+Deuteronomy+30:9-14%3B+Colossians+1:1-14%3B+Luke+10:25-37",
                "audioQS": "Ps.25,Deut.30,Col.1,Luke.10",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 82",
                        "readQS": "Psalm+82",
                        "audioQS": "Ps.82"
                    },
                    {
                        "pretty": "Amos 7:7\u201317",
                        "readQS": "Amos+7:7-17",
                        "audioQS": "Amos.7"
                    },
                    {
                        "pretty": "Colossians 1:1\u201314",
                        "readQS": "Colossians+1:1-14",
                        "audioQS": "Col.1"
                    },
                    {
                        "pretty": "Luke 10:25\u201337",
                        "readQS": "Luke+10:25-37",
                        "audioQS": "Luke.10"
                    }
                ],
                "readQS": "Psalm+82%3B+Amos+7:7-17%3B+Colossians+1:1-14%3B+Luke+10:25-37",
                "audioQS": "Ps.82,Amos.7,Col.1,Luke.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 76",
                        "readQS": "Psalm+76",
                        "audioQS": "Ps.76"
                    },
                    {
                        "pretty": "Deuteronomy 28:1\u201314",
                        "readQS": "Deuteronomy+28:1-14",
                        "audioQS": "Deut.28"
                    },
                    {
                        "pretty": "Acts 28:17\u201331",
                        "readQS": "Acts+28:17-31",
                        "audioQS": "Acts.28"
                    }
                ],
                "readQS": "Psalm+76%3B+Deuteronomy+28:1-14%3B+Acts+28:17-31",
                "audioQS": "Ps.76,Deut.28,Acts.28",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Genesis 32:9\u201330",
                        "readQS": "Genesis+32:9-30",
                        "audioQS": "Gen.32"
                    },
                    {
                        "pretty": "Mark 7:1\u201323",
                        "readQS": "Mark+7:1-23",
                        "audioQS": "Mark.7"
                    }
                ],
                "readQS": "Psalm+77%3B+Genesis+32:9-30%3B+Mark+7:1-23",
                "audioQS": "Ps.77,Gen.32,Mark.7",
                "note": null
            }
        ]
    },
    "2025/07/14": {
        "datePretty": "Monday, July 14, 2025",
        "calendar": "Week of 15th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Job 33",
                        "readQS": "Job+33",
                        "audioQS": "Job.33"
                    },
                    {
                        "pretty": "Romans 14:13\u2013end",
                        "readQS": "Romans+14:13-",
                        "audioQS": "Rom.14"
                    }
                ],
                "readQS": "Psalm+80%3B+Job+33%3B+Romans+14:13-",
                "audioQS": "Ps.80,Job.33,Rom.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "Judges 13:1\u201324",
                        "readQS": "Judges+13:1-24",
                        "audioQS": "Judg.13"
                    },
                    {
                        "pretty": "Luke 17:20\u2013end",
                        "readQS": "Luke+17:20-",
                        "audioQS": "Luke.17"
                    }
                ],
                "readQS": "Psalm+85%3B+Judges+13:1-24%3B+Luke+17:20-",
                "audioQS": "Ps.85,Judg.13,Luke.17",
                "note": null
            }
        ]
    },
    "2025/07/15": {
        "datePretty": "Tuesday, July 15, 2025",
        "calendar": "Week of 15th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 89:1\u201318",
                        "readQS": "Psalm+89:1-18",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Job 38",
                        "readQS": "Job+38",
                        "audioQS": "Job.38"
                    },
                    {
                        "pretty": "Romans 15:1\u201313",
                        "readQS": "Romans+15:1-13",
                        "audioQS": "Rom.15"
                    }
                ],
                "readQS": "Psalm+89:1-18%3B+Job+38%3B+Romans+15:1-13",
                "audioQS": "Ps.89,Job.38,Rom.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 89:19\u2013end",
                        "readQS": "Psalm+89:19-",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Judges 14",
                        "readQS": "Judges+14",
                        "audioQS": "Judg.14"
                    },
                    {
                        "pretty": "Luke 18:1\u201314",
                        "readQS": "Luke+18:1-14",
                        "audioQS": "Luke.18"
                    }
                ],
                "readQS": "Psalm+89:19-%3B+Judges+14%3B+Luke+18:1-14",
                "audioQS": "Ps.89,Judg.14,Luke.18",
                "note": null
            }
        ]
    },
    "2025/07/16": {
        "datePretty": "Wednesday, July 16, 2025",
        "calendar": "Week of 15th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:105\u2013128",
                        "readQS": "Psalm+119:105-128",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Job 39",
                        "readQS": "Job+39",
                        "audioQS": "Job.39"
                    },
                    {
                        "pretty": "Romans 15:14\u201321",
                        "readQS": "Romans+15:14-21",
                        "audioQS": "Rom.15"
                    }
                ],
                "readQS": "Psalm+119:105-128%3B+Job+39%3B+Romans+15:14-21",
                "audioQS": "Ps.119,Job.39,Rom.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 91",
                        "readQS": "Psalm+91",
                        "audioQS": "Ps.91"
                    },
                    {
                        "pretty": "Judges 15:1\u201316:3",
                        "readQS": "Judges+15:1-16:3",
                        "audioQS": "Judg.15"
                    },
                    {
                        "pretty": "Luke 18:15\u201330",
                        "readQS": "Luke+18:15-30",
                        "audioQS": "Luke.18"
                    }
                ],
                "readQS": "Psalm+91%3B+Judges+15:1-16:3%3B+Luke+18:15-30",
                "audioQS": "Ps.91,Judg.15,Luke.18",
                "note": null
            }
        ]
    },
    "2025/07/17": {
        "datePretty": "Thursday, July 17, 2025",
        "calendar": "Week of 15th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 92",
                        "readQS": "Psalm+92",
                        "audioQS": "Ps.92"
                    },
                    {
                        "pretty": "Job 40",
                        "readQS": "Job+40",
                        "audioQS": "Job.40"
                    },
                    {
                        "pretty": "Romans 15:22\u2013end",
                        "readQS": "Romans+15:22-",
                        "audioQS": "Rom.15"
                    }
                ],
                "readQS": "Psalm+92%3B+Job+40%3B+Romans+15:22-",
                "audioQS": "Ps.92,Job.40,Rom.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 94",
                        "readQS": "Psalm+94",
                        "audioQS": "Ps.94"
                    },
                    {
                        "pretty": "Judges 16:4\u2013end",
                        "readQS": "Judges+16:4-",
                        "audioQS": "Judg.16"
                    },
                    {
                        "pretty": "Luke 18:31\u2013end",
                        "readQS": "Luke+18:31-",
                        "audioQS": "Luke.18"
                    }
                ],
                "readQS": "Psalm+94%3B+Judges+16:4-%3B+Luke+18:31-",
                "audioQS": "Ps.94,Judg.16,Luke.18",
                "note": null
            }
        ]
    },
    "2025/07/18": {
        "datePretty": "Friday, July 18, 2025",
        "calendar": "Week of 15th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 88",
                        "readQS": "Psalm+88",
                        "audioQS": "Ps.88"
                    },
                    {
                        "pretty": "Job 41",
                        "readQS": "Job+41",
                        "audioQS": "Job.41"
                    },
                    {
                        "pretty": "Romans 16:1\u201316",
                        "readQS": "Romans+16:1-16",
                        "audioQS": "Rom.16"
                    }
                ],
                "readQS": "Psalm+88%3B+Job+41%3B+Romans+16:1-16",
                "audioQS": "Ps.88,Job.41,Rom.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 102",
                        "readQS": "Psalm+102",
                        "audioQS": "Ps.102"
                    },
                    {
                        "pretty": "Judges 17",
                        "readQS": "Judges+17",
                        "audioQS": "Judg.17"
                    },
                    {
                        "pretty": "Luke 19:1\u201310",
                        "readQS": "Luke+19:1-10",
                        "audioQS": "Luke.19"
                    }
                ],
                "readQS": "Psalm+102%3B+Judges+17%3B+Luke+19:1-10",
                "audioQS": "Ps.102,Judg.17,Luke.19",
                "note": null
            }
        ]
    },
    "2025/07/19": {
        "datePretty": "Saturday, July 19, 2025",
        "calendar": "Week of 15th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Job 42",
                        "readQS": "Job+42",
                        "audioQS": "Job.42"
                    },
                    {
                        "pretty": "Romans 16:17\u2013end",
                        "readQS": "Romans+16:17-",
                        "audioQS": "Rom.16"
                    }
                ],
                "readQS": "Psalm+97%3B+Job+42%3B+Romans+16:17-",
                "audioQS": "Ps.97,Job.42,Rom.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 104",
                        "readQS": "Psalm+104",
                        "audioQS": "Ps.104"
                    },
                    {
                        "pretty": "Judges 18:1\u201320,18:27\u2013end",
                        "readQS": "Judges+18:1-20,18:27-",
                        "audioQS": "Judg.18"
                    },
                    {
                        "pretty": "Luke 19:11\u201327",
                        "readQS": "Luke+19:11-27",
                        "audioQS": "Luke.19"
                    }
                ],
                "readQS": "Psalm+104%3B+Judges+18:1-20,18:27-%3B+Luke+19:11-27",
                "audioQS": "Ps.104,Judg.18,Luke.19",
                "note": null
            }
        ]
    },
    "2025/07/20": {
        "datePretty": "Sunday, July 20, 2025",
        "calendar": "16th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 15",
                        "readQS": "Psalm+15",
                        "audioQS": "Ps.15"
                    },
                    {
                        "pretty": "Genesis 18:1\u201310a",
                        "readQS": "Genesis+18:1-10a",
                        "audioQS": "Gen.18"
                    },
                    {
                        "pretty": "Colossians 1:15\u201328",
                        "readQS": "Colossians+1:15-28",
                        "audioQS": "Col.1"
                    },
                    {
                        "pretty": "Luke 10:38\u201342",
                        "readQS": "Luke+10:38-42",
                        "audioQS": "Luke.10"
                    }
                ],
                "readQS": "Psalm+15%3B+Genesis+18:1-10a%3B+Colossians+1:15-28%3B+Luke+10:38-42",
                "audioQS": "Ps.15,Gen.18,Col.1,Luke.10",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 52",
                        "readQS": "Psalm+52",
                        "audioQS": "Ps.52"
                    },
                    {
                        "pretty": "Amos 8:1\u201312",
                        "readQS": "Amos+8:1-12",
                        "audioQS": "Amos.8"
                    },
                    {
                        "pretty": "Colossians 1:15\u201328",
                        "readQS": "Colossians+1:15-28",
                        "audioQS": "Col.1"
                    },
                    {
                        "pretty": "Luke 10:38\u201342",
                        "readQS": "Luke+10:38-42",
                        "audioQS": "Luke.10"
                    }
                ],
                "readQS": "Psalm+52%3B+Amos+8:1-12%3B+Colossians+1:15-28%3B+Luke+10:38-42",
                "audioQS": "Ps.52,Amos.8,Col.1,Luke.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 82,100",
                        "readQS": "Psalm+82,100",
                        "audioQS": "Ps.82,100"
                    },
                    {
                        "pretty": "Deuteronomy 30:1\u201310",
                        "readQS": "Deuteronomy+30:1-10",
                        "audioQS": "Deut.30"
                    },
                    {
                        "pretty": "1 Peter 3:8\u201318",
                        "readQS": "1+Peter+3:8-18",
                        "audioQS": "1Pet.3"
                    }
                ],
                "readQS": "Psalm+82,100%3B+Deuteronomy+30:1-10%3B+1+Peter+3:8-18",
                "audioQS": "Ps.82,100,Deut.30,1Pet.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 81",
                        "readQS": "Psalm+81",
                        "audioQS": "Ps.81"
                    },
                    {
                        "pretty": "Genesis 41:1\u201316,25\u201337",
                        "readQS": "Genesis+41:1-16,25-37",
                        "audioQS": "Gen.41"
                    },
                    {
                        "pretty": "1 Corinthians 4:8\u201313",
                        "readQS": "1+Corinthians+4:8-13",
                        "audioQS": "1Cor.4"
                    }
                ],
                "readQS": "Psalm+81%3B+Genesis+41:1-16,25-37%3B+1+Corinthians+4:8-13",
                "audioQS": "Ps.81,Gen.41,1Cor.4",
                "note": null
            }
        ]
    },
    "2025/07/21": {
        "datePretty": "Monday, July 21, 2025",
        "calendar": "Week of 16th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 98",
                        "readQS": "Psalm+98",
                        "audioQS": "Ps.98"
                    },
                    {
                        "pretty": "Ezekiel 1:1\u201314",
                        "readQS": "Ezekiel+1:1-14",
                        "audioQS": "Ezek.1"
                    },
                    {
                        "pretty": "2 Corinthians 1:1\u201314",
                        "readQS": "2+Corinthians+1:1-14",
                        "audioQS": "2Cor.1"
                    }
                ],
                "readQS": "Psalm+98%3B+Ezekiel+1:1-14%3B+2+Corinthians+1:1-14",
                "audioQS": "Ps.98,Ezek.1,2Cor.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Mary Magdalene)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 139",
                        "readQS": "Psalm+139",
                        "audioQS": "Ps.139"
                    },
                    {
                        "pretty": "Isaiah 25:1\u20139",
                        "readQS": "Isaiah+25:1-9",
                        "audioQS": "Isa.25"
                    },
                    {
                        "pretty": "2 Corinthians 1:3\u20137",
                        "readQS": "2+Corinthians+1:3-7",
                        "audioQS": "2Cor.1"
                    }
                ],
                "readQS": "Psalm+139%3B+Isaiah+25:1-9%3B+2+Corinthians+1:3-7",
                "audioQS": "Ps.139,Isa.25,2Cor.1",
                "note": null
            }
        ]
    },
    "2025/07/22": {
        "datePretty": "Tuesday, July 22, 2025",
        "calendar": "St Mary Magdalene",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 30,32,150",
                        "readQS": "Psalm+30,32,150",
                        "audioQS": "Ps.30,32,150"
                    },
                    {
                        "pretty": "1 Samuel 16:14\u201323",
                        "readQS": "1+Samuel+16:14-23",
                        "audioQS": "1Sam.16"
                    },
                    {
                        "pretty": "Luke 8:1\u20133",
                        "readQS": "Luke+8:1-3",
                        "audioQS": "Luke.8"
                    }
                ],
                "readQS": "Psalm+30,32,150%3B+1+Samuel+16:14-23%3B+Luke+8:1-3",
                "audioQS": "Ps.30,32,150,1Sam.16,Luke.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 63",
                        "readQS": "Psalm+63",
                        "audioQS": "Ps.63"
                    },
                    {
                        "pretty": "Zephaniah 3:14\u201320",
                        "readQS": "Zephaniah+3:14-20",
                        "audioQS": "Zeph.3"
                    },
                    {
                        "pretty": "Mark 15:40\u2013end,16:1\u20137",
                        "readQS": "Mark+15:40-,16:1-7",
                        "audioQS": "Mark.15"
                    }
                ],
                "readQS": "Psalm+63%3B+Zephaniah+3:14-20%3B+Mark+15:40-,16:1-7",
                "audioQS": "Ps.63,Zeph.3,Mark.15",
                "note": null
            }
        ]
    },
    "2025/07/23": {
        "datePretty": "Wednesday, July 23, 2025",
        "calendar": "Week of 16th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 111",
                        "readQS": "Psalm+111",
                        "audioQS": "Ps.111"
                    },
                    {
                        "pretty": "Ezekiel 2:3\u20133:11",
                        "readQS": "Ezekiel+2:3-3:11",
                        "audioQS": "Ezek.2"
                    },
                    {
                        "pretty": "2 Corinthians 2:5\u2013end",
                        "readQS": "2+Corinthians+2:5-",
                        "audioQS": "2Cor.2"
                    }
                ],
                "readQS": "Psalm+111%3B+Ezekiel+2:3-3:11%3B+2+Corinthians+2:5-",
                "audioQS": "Ps.111,Ezek.2,2Cor.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:129\u2013152",
                        "readQS": "Psalm+119:129-152",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "1 Samuel 2:12\u201326",
                        "readQS": "1+Samuel+2:12-26",
                        "audioQS": "1Sam.2"
                    },
                    {
                        "pretty": "Luke 20:1\u20138",
                        "readQS": "Luke+20:1-8",
                        "audioQS": "Luke.20"
                    }
                ],
                "readQS": "Psalm+119:129-152%3B+1+Samuel+2:12-26%3B+Luke+20:1-8",
                "audioQS": "Ps.119,1Sam.2,Luke.20",
                "note": null
            }
        ]
    },
    "2025/07/24": {
        "datePretty": "Thursday, July 24, 2025",
        "calendar": "Week of 16th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 115",
                        "readQS": "Psalm+115",
                        "audioQS": "Ps.115"
                    },
                    {
                        "pretty": "Ezekiel 3:12\u2013end",
                        "readQS": "Ezekiel+3:12-",
                        "audioQS": "Ezek.3"
                    },
                    {
                        "pretty": "2 Corinthians 3",
                        "readQS": "2+Corinthians+3",
                        "audioQS": "2Cor.3"
                    }
                ],
                "readQS": "Psalm+115%3B+Ezekiel+3:12-%3B+2+Corinthians+3",
                "audioQS": "Ps.115,Ezek.3,2Cor.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St James)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 144",
                        "readQS": "Psalm+144",
                        "audioQS": "Ps.144"
                    },
                    {
                        "pretty": "Deuteronomy 30:11\u201320",
                        "readQS": "Deuteronomy+30:11-20",
                        "audioQS": "Deut.30"
                    },
                    {
                        "pretty": "Mark 5:21\u201343",
                        "readQS": "Mark+5:21-43",
                        "audioQS": "Mark.5"
                    }
                ],
                "readQS": "Psalm+144%3B+Deuteronomy+30:11-20%3B+Mark+5:21-43",
                "audioQS": "Ps.144,Deut.30,Mark.5",
                "note": null
            }
        ]
    },
    "2025/07/25": {
        "datePretty": "Friday, July 25, 2025",
        "calendar": "St James the Apostle",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 7,29,117",
                        "readQS": "Psalm+7,29,117",
                        "audioQS": "Ps.7,29,117"
                    },
                    {
                        "pretty": "2 Kings 1:9\u201315",
                        "readQS": "2+Kings+1:9-15",
                        "audioQS": "2Kgs.1"
                    },
                    {
                        "pretty": "Luke 9:46\u201356",
                        "readQS": "Luke+9:46-56",
                        "audioQS": "Luke.9"
                    }
                ],
                "readQS": "Psalm+7,29,117%3B+2+Kings+1:9-15%3B+Luke+9:46-56",
                "audioQS": "Ps.7,29,117,2Kgs.1,Luke.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 94",
                        "readQS": "Psalm+94",
                        "audioQS": "Ps.94"
                    },
                    {
                        "pretty": "Jeremiah 26:1\u201315",
                        "readQS": "Jeremiah+26:1-15",
                        "audioQS": "Jer.26"
                    },
                    {
                        "pretty": "Mark 1:14\u201320",
                        "readQS": "Mark+1:14-20",
                        "audioQS": "Mark.1"
                    }
                ],
                "readQS": "Psalm+94%3B+Jeremiah+26:1-15%3B+Mark+1:14-20",
                "audioQS": "Ps.94,Jer.26,Mark.1",
                "note": null
            }
        ]
    },
    "2025/07/26": {
        "datePretty": "Saturday, July 26, 2025",
        "calendar": "Week of 16th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 121",
                        "readQS": "Psalm+121",
                        "audioQS": "Ps.121"
                    },
                    {
                        "pretty": "Ezekiel 9",
                        "readQS": "Ezekiel+9",
                        "audioQS": "Ezek.9"
                    },
                    {
                        "pretty": "2 Corinthians 5",
                        "readQS": "2+Corinthians+5",
                        "audioQS": "2Cor.5"
                    }
                ],
                "readQS": "Psalm+121%3B+Ezekiel+9%3B+2+Corinthians+5",
                "audioQS": "Ps.121,Ezek.9,2Cor.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 118",
                        "readQS": "Psalm+118",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "1 Samuel 4:1b\u2013end",
                        "readQS": "1+Samuel+4:1b-",
                        "audioQS": "1Sam.4"
                    },
                    {
                        "pretty": "Luke 20:27\u201340",
                        "readQS": "Luke+20:27-40",
                        "audioQS": "Luke.20"
                    }
                ],
                "readQS": "Psalm+118%3B+1+Samuel+4:1b-%3B+Luke+20:27-40",
                "audioQS": "Ps.118,1Sam.4,Luke.20",
                "note": null
            }
        ]
    },
    "2025/07/27": {
        "datePretty": "Sunday, July 27, 2025",
        "calendar": "17th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 138",
                        "readQS": "Psalm+138",
                        "audioQS": "Ps.138"
                    },
                    {
                        "pretty": "Genesis 18:20\u201332",
                        "readQS": "Genesis+18:20-32",
                        "audioQS": "Gen.18"
                    },
                    {
                        "pretty": "Colossians 2:6\u201315,(16\u201319)",
                        "readQS": "Colossians+2:6-15,%2816-19%29",
                        "audioQS": "Col.2"
                    },
                    {
                        "pretty": "Luke 11:1\u201313",
                        "readQS": "Luke+11:1-13",
                        "audioQS": "Luke.11"
                    }
                ],
                "readQS": "Psalm+138%3B+Genesis+18:20-32%3B+Colossians+2:6-15,%2816-19%29%3B+Luke+11:1-13",
                "audioQS": "Ps.138,Gen.18,Col.2,Luke.11",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "Hosea 1:2\u201310",
                        "readQS": "Hosea+1:2-10",
                        "audioQS": "Hos.1"
                    },
                    {
                        "pretty": "Colossians 2:6\u201315,(16\u201319)",
                        "readQS": "Colossians+2:6-15,%2816-19%29",
                        "audioQS": "Col.2"
                    },
                    {
                        "pretty": "Luke 11:1\u201313",
                        "readQS": "Luke+11:1-13",
                        "audioQS": "Luke.11"
                    }
                ],
                "readQS": "Psalm+85%3B+Hosea+1:2-10%3B+Colossians+2:6-15,%2816-19%29%3B+Luke+11:1-13",
                "audioQS": "Ps.85,Hos.1,Col.2,Luke.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 95",
                        "readQS": "Psalm+95",
                        "audioQS": "Ps.95"
                    },
                    {
                        "pretty": "Song of Solomon 2",
                        "readQS": "Song+of+Solomon+2",
                        "audioQS": "Song.2"
                    },
                    {
                        "pretty": "1 Peter 4:7\u201314",
                        "readQS": "1+Peter+4:7-14",
                        "audioQS": "1Pet.4"
                    }
                ],
                "readQS": "Psalm+95%3B+Song+of+Solomon+2%3B+1+Peter+4:7-14",
                "audioQS": "Ps.95,Song.2,1Pet.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 88",
                        "readQS": "Psalm+88",
                        "audioQS": "Ps.88"
                    },
                    {
                        "pretty": "Genesis 42:1\u201325",
                        "readQS": "Genesis+42:1-25",
                        "audioQS": "Gen.42"
                    },
                    {
                        "pretty": "1 Corinthians 10:1\u201324",
                        "readQS": "1+Corinthians+10:1-24",
                        "audioQS": "1Cor.10"
                    }
                ],
                "readQS": "Psalm+88%3B+Genesis+42:1-25%3B+1+Corinthians+10:1-24",
                "audioQS": "Ps.88,Gen.42,1Cor.10",
                "note": null
            }
        ]
    },
    "2025/07/28": {
        "datePretty": "Monday, July 28, 2025",
        "calendar": "Week of 17th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 126",
                        "readQS": "Psalm+126",
                        "audioQS": "Ps.126"
                    },
                    {
                        "pretty": "Ezekiel 10:1\u201319",
                        "readQS": "Ezekiel+10:1-19",
                        "audioQS": "Ezek.10"
                    },
                    {
                        "pretty": "2 Corinthians 6:1\u20137:1",
                        "readQS": "2+Corinthians+6:1-7:1",
                        "audioQS": "2Cor.6"
                    }
                ],
                "readQS": "Psalm+126%3B+Ezekiel+10:1-19%3B+2+Corinthians+6:1-7:1",
                "audioQS": "Ps.126,Ezek.10,2Cor.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 127",
                        "readQS": "Psalm+127",
                        "audioQS": "Ps.127"
                    },
                    {
                        "pretty": "1 Samuel 5",
                        "readQS": "1+Samuel+5",
                        "audioQS": "1Sam.5"
                    },
                    {
                        "pretty": "Luke 20:41\u201321:4",
                        "readQS": "Luke+20:41-21:4",
                        "audioQS": "Luke.20"
                    }
                ],
                "readQS": "Psalm+127%3B+1+Samuel+5%3B+Luke+20:41-21:4",
                "audioQS": "Ps.127,1Sam.5,Luke.20",
                "note": null
            }
        ]
    },
    "2025/07/29": {
        "datePretty": "Tuesday, July 29, 2025",
        "calendar": "Week of 17th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Ezekiel 11:14\u2013end",
                        "readQS": "Ezekiel+11:14-",
                        "audioQS": "Ezek.11"
                    },
                    {
                        "pretty": "2 Corinthians 7:2\u2013end",
                        "readQS": "2+Corinthians+7:2-",
                        "audioQS": "2Cor.7"
                    }
                ],
                "readQS": "Psalm+132%3B+Ezekiel+11:14-%3B+2+Corinthians+7:2-",
                "audioQS": "Ps.132,Ezek.11,2Cor.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 135",
                        "readQS": "Psalm+135",
                        "audioQS": "Ps.135"
                    },
                    {
                        "pretty": "1 Samuel 6:1\u201316",
                        "readQS": "1+Samuel+6:1-16",
                        "audioQS": "1Sam.6"
                    },
                    {
                        "pretty": "Luke 21:5\u201319",
                        "readQS": "Luke+21:5-19",
                        "audioQS": "Luke.21"
                    }
                ],
                "readQS": "Psalm+135%3B+1+Samuel+6:1-16%3B+Luke+21:5-19",
                "audioQS": "Ps.135,1Sam.6,Luke.21",
                "note": null
            }
        ]
    },
    "2025/07/30": {
        "datePretty": "Wednesday, July 30, 2025",
        "calendar": "Week of 17th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:153\u2013end",
                        "readQS": "Psalm+119:153-",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Ezekiel 12:1\u201316",
                        "readQS": "Ezekiel+12:1-16",
                        "audioQS": "Ezek.12"
                    },
                    {
                        "pretty": "2 Corinthians 8:1\u201315",
                        "readQS": "2+Corinthians+8:1-15",
                        "audioQS": "2Cor.8"
                    }
                ],
                "readQS": "Psalm+119:153-%3B+Ezekiel+12:1-16%3B+2+Corinthians+8:1-15",
                "audioQS": "Ps.119,Ezek.12,2Cor.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 136",
                        "readQS": "Psalm+136",
                        "audioQS": "Ps.136"
                    },
                    {
                        "pretty": "1 Samuel 7",
                        "readQS": "1+Samuel+7",
                        "audioQS": "1Sam.7"
                    },
                    {
                        "pretty": "Luke 21:20\u201328",
                        "readQS": "Luke+21:20-28",
                        "audioQS": "Luke.21"
                    }
                ],
                "readQS": "Psalm+136%3B+1+Samuel+7%3B+Luke+21:20-28",
                "audioQS": "Ps.136,1Sam.7,Luke.21",
                "note": null
            }
        ]
    },
    "2025/07/31": {
        "datePretty": "Thursday, July 31, 2025",
        "calendar": "Week of 17th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 143",
                        "readQS": "Psalm+143",
                        "audioQS": "Ps.143"
                    },
                    {
                        "pretty": "Ezekiel 12:17\u2013end",
                        "readQS": "Ezekiel+12:17-",
                        "audioQS": "Ezek.12"
                    },
                    {
                        "pretty": "2 Corinthians 8:16\u20139:5",
                        "readQS": "2+Corinthians+8:16-9:5",
                        "audioQS": "2Cor.8"
                    }
                ],
                "readQS": "Psalm+143%3B+Ezekiel+12:17-%3B+2+Corinthians+8:16-9:5",
                "audioQS": "Ps.143,Ezek.12,2Cor.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 138",
                        "readQS": "Psalm+138",
                        "audioQS": "Ps.138"
                    },
                    {
                        "pretty": "1 Samuel 8",
                        "readQS": "1+Samuel+8",
                        "audioQS": "1Sam.8"
                    },
                    {
                        "pretty": "Luke 21:29\u2013end",
                        "readQS": "Luke+21:29-",
                        "audioQS": "Luke.21"
                    }
                ],
                "readQS": "Psalm+138%3B+1+Samuel+8%3B+Luke+21:29-",
                "audioQS": "Ps.138,1Sam.8,Luke.21",
                "note": null
            }
        ]
    },
    "2025/08/01": {
        "datePretty": "Friday, August 1, 2025",
        "calendar": "Week of 17th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 144",
                        "readQS": "Psalm+144",
                        "audioQS": "Ps.144"
                    },
                    {
                        "pretty": "Ezekiel 13:1\u201316",
                        "readQS": "Ezekiel+13:1-16",
                        "audioQS": "Ezek.13"
                    },
                    {
                        "pretty": "2 Corinthians 9:6\u2013end",
                        "readQS": "2+Corinthians+9:6-",
                        "audioQS": "2Cor.9"
                    }
                ],
                "readQS": "Psalm+144%3B+Ezekiel+13:1-16%3B+2+Corinthians+9:6-",
                "audioQS": "Ps.144,Ezek.13,2Cor.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "1 Samuel 9:1\u201314",
                        "readQS": "1+Samuel+9:1-14",
                        "audioQS": "1Sam.9"
                    },
                    {
                        "pretty": "Luke 22:1\u201313",
                        "readQS": "Luke+22:1-13",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+145%3B+1+Samuel+9:1-14%3B+Luke+22:1-13",
                "audioQS": "Ps.145,1Sam.9,Luke.22",
                "note": null
            }
        ]
    },
    "2025/08/02": {
        "datePretty": "Saturday, August 2, 2025",
        "calendar": "Week of 17th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 147",
                        "readQS": "Psalm+147",
                        "audioQS": "Ps.147"
                    },
                    {
                        "pretty": "Ezekiel 14:1\u201311",
                        "readQS": "Ezekiel+14:1-11",
                        "audioQS": "Ezek.14"
                    },
                    {
                        "pretty": "2 Corinthians 10",
                        "readQS": "2+Corinthians+10",
                        "audioQS": "2Cor.10"
                    }
                ],
                "readQS": "Psalm+147%3B+Ezekiel+14:1-11%3B+2+Corinthians+10",
                "audioQS": "Ps.147,Ezek.14,2Cor.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 148",
                        "readQS": "Psalm+148",
                        "audioQS": "Ps.148"
                    },
                    {
                        "pretty": "1 Samuel 9:15\u201310:1",
                        "readQS": "1+Samuel+9:15-10:1",
                        "audioQS": "1Sam.9"
                    },
                    {
                        "pretty": "Luke 22:14\u201323",
                        "readQS": "Luke+22:14-23",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+148%3B+1+Samuel+9:15-10:1%3B+Luke+22:14-23",
                "audioQS": "Ps.148,1Sam.9,Luke.22",
                "note": null
            }
        ]
    },
    "2025/08/03": {
        "datePretty": "Sunday, August 3, 2025",
        "calendar": "18th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 49:1\u201312",
                        "readQS": "Psalm+49:1-12",
                        "audioQS": "Ps.49"
                    },
                    {
                        "pretty": "Ecclesiastes 1:2,12\u201314,2:18\u201323",
                        "readQS": "Ecclesiastes+1:2,12-14,2:18-23",
                        "audioQS": "Eccl.1"
                    },
                    {
                        "pretty": "Colossians 3:1\u201311",
                        "readQS": "Colossians+3:1-11",
                        "audioQS": "Col.3"
                    },
                    {
                        "pretty": "Luke 12:13\u201321",
                        "readQS": "Luke+12:13-21",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+49:1-12%3B+Ecclesiastes+1:2,12-14,2:18-23%3B+Colossians+3:1-11%3B+Luke+12:13-21",
                "audioQS": "Ps.49,Eccl.1,Col.3,Luke.12",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 107:1\u20139,43",
                        "readQS": "Psalm+107:1-9,43",
                        "audioQS": "Ps.107"
                    },
                    {
                        "pretty": "Hosea 11:1\u201311",
                        "readQS": "Hosea+11:1-11",
                        "audioQS": "Hos.11"
                    },
                    {
                        "pretty": "Colossians 3:1\u201311",
                        "readQS": "Colossians+3:1-11",
                        "audioQS": "Col.3"
                    },
                    {
                        "pretty": "Luke 12:13\u201321",
                        "readQS": "Luke+12:13-21",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+107:1-9,43%3B+Hosea+11:1-11%3B+Colossians+3:1-11%3B+Luke+12:13-21",
                "audioQS": "Ps.107,Hos.11,Col.3,Luke.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 106:1\u201310",
                        "readQS": "Psalm+106:1-10",
                        "audioQS": "Ps.106"
                    },
                    {
                        "pretty": "Song of Solomon 5:2\u201316",
                        "readQS": "Song+of+Solomon+5:2-16",
                        "audioQS": "Song.5"
                    },
                    {
                        "pretty": "2 Peter 1:1\u201315",
                        "readQS": "2+Peter+1:1-15",
                        "audioQS": "2Pet.1"
                    }
                ],
                "readQS": "Psalm+106:1-10%3B+Song+of+Solomon+5:2-16%3B+2+Peter+1:1-15",
                "audioQS": "Ps.106,Song.5,2Pet.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 107:1\u201332",
                        "readQS": "Psalm+107:1-32",
                        "audioQS": "Ps.107"
                    },
                    {
                        "pretty": "Genesis 50:4\u201326",
                        "readQS": "Genesis+50:4-26",
                        "audioQS": "Gen.50"
                    },
                    {
                        "pretty": "1 Corinthians 14:1\u201319",
                        "readQS": "1+Corinthians+14:1-19",
                        "audioQS": "1Cor.14"
                    }
                ],
                "readQS": "Psalm+107:1-32%3B+Genesis+50:4-26%3B+1+Corinthians+14:1-19",
                "audioQS": "Ps.107,Gen.50,1Cor.14",
                "note": null
            }
        ]
    },
    "2025/08/04": {
        "datePretty": "Monday, August 4, 2025",
        "calendar": "Week of 18th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 1",
                        "readQS": "Psalm+1",
                        "audioQS": "Ps.1"
                    },
                    {
                        "pretty": "Ezekiel 14:12\u2013end",
                        "readQS": "Ezekiel+14:12-",
                        "audioQS": "Ezek.14"
                    },
                    {
                        "pretty": "2 Corinthians 11:1\u201315",
                        "readQS": "2+Corinthians+11:1-15",
                        "audioQS": "2Cor.11"
                    }
                ],
                "readQS": "Psalm+1%3B+Ezekiel+14:12-%3B+2+Corinthians+11:1-15",
                "audioQS": "Ps.1,Ezek.14,2Cor.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 4",
                        "readQS": "Psalm+4",
                        "audioQS": "Ps.4"
                    },
                    {
                        "pretty": "1 Samuel 10:1\u201316",
                        "readQS": "1+Samuel+10:1-16",
                        "audioQS": "1Sam.10"
                    },
                    {
                        "pretty": "Luke 22:24\u201330",
                        "readQS": "Luke+22:24-30",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+4%3B+1+Samuel+10:1-16%3B+Luke+22:24-30",
                "audioQS": "Ps.4,1Sam.10,Luke.22",
                "note": null
            }
        ]
    },
    "2025/08/05": {
        "datePretty": "Tuesday, August 5, 2025",
        "calendar": "Week of 18th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 5",
                        "readQS": "Psalm+5",
                        "audioQS": "Ps.5"
                    },
                    {
                        "pretty": "Ezekiel 18:1\u201320",
                        "readQS": "Ezekiel+18:1-20",
                        "audioQS": "Ezek.18"
                    },
                    {
                        "pretty": "2 Corinthians 11:16\u2013end",
                        "readQS": "2+Corinthians+11:16-",
                        "audioQS": "2Cor.11"
                    }
                ],
                "readQS": "Psalm+5%3B+Ezekiel+18:1-20%3B+2+Corinthians+11:16-",
                "audioQS": "Ps.5,Ezek.18,2Cor.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Transfiguration)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 99,110",
                        "readQS": "Psalm+99,110",
                        "audioQS": "Ps.99,110"
                    },
                    {
                        "pretty": "Exodus 24:12\u201318",
                        "readQS": "Exodus+24:12-18",
                        "audioQS": "Exod.24"
                    },
                    {
                        "pretty": "John 12:27\u201336a",
                        "readQS": "John+12:27-36a",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+99,110%3B+Exodus+24:12-18%3B+John+12:27-36a",
                "audioQS": "Ps.99,110,Exod.24,John.12",
                "note": null
            }
        ]
    },
    "2025/08/06": {
        "datePretty": "Wednesday, August 6, 2025",
        "calendar": "The Transfiguration of the Beloved Son",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 27,150",
                        "readQS": "Psalm+27,150",
                        "audioQS": "Ps.27,150"
                    },
                    {
                        "pretty": "1 Kings 19:1\u201316",
                        "readQS": "1+Kings+19:1-16",
                        "audioQS": "1Kgs.19"
                    },
                    {
                        "pretty": "1 John 3:1\u20133",
                        "readQS": "1+John+3:1-3",
                        "audioQS": "1John.3"
                    }
                ],
                "readQS": "Psalm+27,150%3B+1+Kings+19:1-16%3B+1+John+3:1-3",
                "audioQS": "Ps.27,150,1Kgs.19,1John.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 72",
                        "readQS": "Psalm+72",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "Exodus 34:29\u201335",
                        "readQS": "Exodus+34:29-35",
                        "audioQS": "Exod.34"
                    },
                    {
                        "pretty": "2 Corinthians 3",
                        "readQS": "2+Corinthians+3",
                        "audioQS": "2Cor.3"
                    }
                ],
                "readQS": "Psalm+72%3B+Exodus+34:29-35%3B+2+Corinthians+3",
                "audioQS": "Ps.72,Exod.34,2Cor.3",
                "note": null
            }
        ]
    },
    "2025/08/07": {
        "datePretty": "Thursday, August 7, 2025",
        "calendar": "Week of 18th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 15",
                        "readQS": "Psalm+15",
                        "audioQS": "Ps.15"
                    },
                    {
                        "pretty": "Ezekiel 20:1\u201320",
                        "readQS": "Ezekiel+20:1-20",
                        "audioQS": "Ezek.20"
                    },
                    {
                        "pretty": "2 Corinthians 13",
                        "readQS": "2+Corinthians+13",
                        "audioQS": "2Cor.13"
                    }
                ],
                "readQS": "Psalm+15%3B+Ezekiel+20:1-20%3B+2+Corinthians+13",
                "audioQS": "Ps.15,Ezek.20,2Cor.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 18",
                        "readQS": "Psalm+18",
                        "audioQS": "Ps.18"
                    },
                    {
                        "pretty": "1 Samuel 12",
                        "readQS": "1+Samuel+12",
                        "audioQS": "1Sam.12"
                    },
                    {
                        "pretty": "Luke 22:47\u201362",
                        "readQS": "Luke+22:47-62",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+18%3B+1+Samuel+12%3B+Luke+22:47-62",
                "audioQS": "Ps.18,1Sam.12,Luke.22",
                "note": null
            }
        ]
    },
    "2025/08/08": {
        "datePretty": "Friday, August 8, 2025",
        "calendar": "Week of 18th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 19",
                        "readQS": "Psalm+19",
                        "audioQS": "Ps.19"
                    },
                    {
                        "pretty": "Ezekiel 20:21\u201338",
                        "readQS": "Ezekiel+20:21-38",
                        "audioQS": "Ezek.20"
                    },
                    {
                        "pretty": "James 1:1\u201311",
                        "readQS": "James+1:1-11",
                        "audioQS": "Jas.1"
                    }
                ],
                "readQS": "Psalm+19%3B+Ezekiel+20:21-38%3B+James+1:1-11",
                "audioQS": "Ps.19,Ezek.20,Jas.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 22",
                        "readQS": "Psalm+22",
                        "audioQS": "Ps.22"
                    },
                    {
                        "pretty": "1 Samuel 13:5\u201318",
                        "readQS": "1+Samuel+13:5-18",
                        "audioQS": "1Sam.13"
                    },
                    {
                        "pretty": "Luke 22:63\u2013end",
                        "readQS": "Luke+22:63-",
                        "audioQS": "Luke.22"
                    }
                ],
                "readQS": "Psalm+22%3B+1+Samuel+13:5-18%3B+Luke+22:63-",
                "audioQS": "Ps.22,1Sam.13,Luke.22",
                "note": null
            }
        ]
    },
    "2025/08/09": {
        "datePretty": "Saturday, August 9, 2025",
        "calendar": "Week of 18th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 23",
                        "readQS": "Psalm+23",
                        "audioQS": "Ps.23"
                    },
                    {
                        "pretty": "Ezekiel 24:15\u2013end",
                        "readQS": "Ezekiel+24:15-",
                        "audioQS": "Ezek.24"
                    },
                    {
                        "pretty": "James 1:12\u2013end",
                        "readQS": "James+1:12-",
                        "audioQS": "Jas.1"
                    }
                ],
                "readQS": "Psalm+23%3B+Ezekiel+24:15-%3B+James+1:12-",
                "audioQS": "Ps.23,Ezek.24,Jas.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 24",
                        "readQS": "Psalm+24",
                        "audioQS": "Ps.24"
                    },
                    {
                        "pretty": "1 Samuel 13:19\u201314:15",
                        "readQS": "1+Samuel+13:19-14:15",
                        "audioQS": "1Sam.13"
                    },
                    {
                        "pretty": "Luke 23:1\u201312",
                        "readQS": "Luke+23:1-12",
                        "audioQS": "Luke.23"
                    }
                ],
                "readQS": "Psalm+24%3B+1+Samuel+13:19-14:15%3B+Luke+23:1-12",
                "audioQS": "Ps.24,1Sam.13,Luke.23",
                "note": null
            }
        ]
    },
    "2025/08/10": {
        "datePretty": "Sunday, August 10, 2025",
        "calendar": "19th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 33:12\u201322",
                        "readQS": "Psalm+33:12-22",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "Genesis 15:1\u20136",
                        "readQS": "Genesis+15:1-6",
                        "audioQS": "Gen.15"
                    },
                    {
                        "pretty": "Hebrews 11:1\u20133,8\u201316",
                        "readQS": "Hebrews+11:1-3,8-16",
                        "audioQS": "Heb.11"
                    },
                    {
                        "pretty": "Luke 12:32\u201340",
                        "readQS": "Luke+12:32-40",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+33:12-22%3B+Genesis+15:1-6%3B+Hebrews+11:1-3,8-16%3B+Luke+12:32-40",
                "audioQS": "Ps.33,Gen.15,Heb.11,Luke.12",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 50:1\u20138,23\u201324",
                        "readQS": "Psalm+50:1-8,23-24",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "Isaiah 1:1,10\u201320",
                        "readQS": "Isaiah+1:1,10-20",
                        "audioQS": "Isa.1"
                    },
                    {
                        "pretty": "Hebrews 11:1\u20133,8\u201316",
                        "readQS": "Hebrews+11:1-3,8-16",
                        "audioQS": "Heb.11"
                    },
                    {
                        "pretty": "Luke 12:32\u201340",
                        "readQS": "Luke+12:32-40",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+50:1-8,23-24%3B+Isaiah+1:1,10-20%3B+Hebrews+11:1-3,8-16%3B+Luke+12:32-40",
                "audioQS": "Ps.50,Isa.1,Heb.11,Luke.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 115",
                        "readQS": "Psalm+115",
                        "audioQS": "Ps.115"
                    },
                    {
                        "pretty": "Song of Solomon 8:5\u20137",
                        "readQS": "Song+of+Solomon+8:5-7",
                        "audioQS": "Song.8"
                    },
                    {
                        "pretty": "2 Peter 3:8\u201313",
                        "readQS": "2+Peter+3:8-13",
                        "audioQS": "2Pet.3"
                    }
                ],
                "readQS": "Psalm+115%3B+Song+of+Solomon+8:5-7%3B+2+Peter+3:8-13",
                "audioQS": "Ps.115,Song.8,2Pet.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 108",
                        "readQS": "Psalm+108",
                        "audioQS": "Ps.108"
                    },
                    {
                        "pretty": "Isaiah 11:10\u201312:6",
                        "readQS": "Isaiah+11:10-12:6",
                        "audioQS": "Isa.11"
                    },
                    {
                        "pretty": "2 Corinthians 1:1\u201322",
                        "readQS": "2+Corinthians+1:1-22",
                        "audioQS": "2Cor.1"
                    }
                ],
                "readQS": "Psalm+108%3B+Isaiah+11:10-12:6%3B+2+Corinthians+1:1-22",
                "audioQS": "Ps.108,Isa.11,2Cor.1",
                "note": null
            }
        ]
    },
    "2025/08/11": {
        "datePretty": "Monday, August 11, 2025",
        "calendar": "Week of 19th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 30",
                        "readQS": "Psalm+30",
                        "audioQS": "Ps.30"
                    },
                    {
                        "pretty": "Ezekiel 28:1\u201319",
                        "readQS": "Ezekiel+28:1-19",
                        "audioQS": "Ezek.28"
                    },
                    {
                        "pretty": "James 2:1\u201313",
                        "readQS": "James+2:1-13",
                        "audioQS": "Jas.2"
                    }
                ],
                "readQS": "Psalm+30%3B+Ezekiel+28:1-19%3B+James+2:1-13",
                "audioQS": "Ps.30,Ezek.28,Jas.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 28",
                        "readQS": "Psalm+28",
                        "audioQS": "Ps.28"
                    },
                    {
                        "pretty": "1 Samuel 14:24\u201346",
                        "readQS": "1+Samuel+14:24-46",
                        "audioQS": "1Sam.14"
                    },
                    {
                        "pretty": "Luke 23:13\u201325",
                        "readQS": "Luke+23:13-25",
                        "audioQS": "Luke.23"
                    }
                ],
                "readQS": "Psalm+28%3B+1+Samuel+14:24-46%3B+Luke+23:13-25",
                "audioQS": "Ps.28,1Sam.14,Luke.23",
                "note": null
            }
        ]
    },
    "2025/08/12": {
        "datePretty": "Tuesday, August 12, 2025",
        "calendar": "Week of 19th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 36",
                        "readQS": "Psalm+36",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "Ezekiel 33:1\u201320",
                        "readQS": "Ezekiel+33:1-20",
                        "audioQS": "Ezek.33"
                    },
                    {
                        "pretty": "James 2:14\u2013end",
                        "readQS": "James+2:14-",
                        "audioQS": "Jas.2"
                    }
                ],
                "readQS": "Psalm+36%3B+Ezekiel+33:1-20%3B+James+2:14-",
                "audioQS": "Ps.36,Ezek.33,Jas.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 33",
                        "readQS": "Psalm+33",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "1 Samuel 15:1\u201323",
                        "readQS": "1+Samuel+15:1-23",
                        "audioQS": "1Sam.15"
                    },
                    {
                        "pretty": "Luke 23:26\u201343",
                        "readQS": "Luke+23:26-43",
                        "audioQS": "Luke.23"
                    }
                ],
                "readQS": "Psalm+33%3B+1+Samuel+15:1-23%3B+Luke+23:26-43",
                "audioQS": "Ps.33,1Sam.15,Luke.23",
                "note": null
            }
        ]
    },
    "2025/08/13": {
        "datePretty": "Wednesday, August 13, 2025",
        "calendar": "Week of 19th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 34",
                        "readQS": "Psalm+34",
                        "audioQS": "Ps.34"
                    },
                    {
                        "pretty": "Ezekiel 33:21\u2013end",
                        "readQS": "Ezekiel+33:21-",
                        "audioQS": "Ezek.33"
                    },
                    {
                        "pretty": "James 3",
                        "readQS": "James+3",
                        "audioQS": "Jas.3"
                    }
                ],
                "readQS": "Psalm+34%3B+Ezekiel+33:21-%3B+James+3",
                "audioQS": "Ps.34,Ezek.33,Jas.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:33\u201356",
                        "readQS": "Psalm+119:33-56",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "1 Samuel 16",
                        "readQS": "1+Samuel+16",
                        "audioQS": "1Sam.16"
                    },
                    {
                        "pretty": "Luke 23:44\u201356a",
                        "readQS": "Luke+23:44-56a",
                        "audioQS": "Luke.23"
                    }
                ],
                "readQS": "Psalm+119:33-56%3B+1+Samuel+16%3B+Luke+23:44-56a",
                "audioQS": "Ps.119,1Sam.16,Luke.23",
                "note": null
            }
        ]
    },
    "2025/08/14": {
        "datePretty": "Thursday, August 14, 2025",
        "calendar": "Week of 19th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 37",
                        "readQS": "Psalm+37",
                        "audioQS": "Ps.37"
                    },
                    {
                        "pretty": "Ezekiel 34:1\u201316",
                        "readQS": "Ezekiel+34:1-16",
                        "audioQS": "Ezek.34"
                    },
                    {
                        "pretty": "James 4:1\u201312",
                        "readQS": "James+4:1-12",
                        "audioQS": "Jas.4"
                    }
                ],
                "readQS": "Psalm+37%3B+Ezekiel+34:1-16%3B+James+4:1-12",
                "audioQS": "Ps.37,Ezek.34,Jas.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of the Blessed Virgin Mary)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 72",
                        "readQS": "Psalm+72",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "Proverbs 8:22\u201331",
                        "readQS": "Proverbs+8:22-31",
                        "audioQS": "Prov.8"
                    },
                    {
                        "pretty": "John 19:23\u201327",
                        "readQS": "John+19:23-27",
                        "audioQS": "John.19"
                    }
                ],
                "readQS": "Psalm+72%3B+Proverbs+8:22-31%3B+John+19:23-27",
                "audioQS": "Ps.72,Prov.8,John.19",
                "note": null
            }
        ]
    },
    "2025/08/15": {
        "datePretty": "Friday, August 15, 2025",
        "calendar": "The Blessed Virgin Mary",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 98,138,147:1\u201311",
                        "readQS": "Psalm+98,138,147:1-11",
                        "audioQS": "Ps.98,138,147"
                    },
                    {
                        "pretty": "Isaiah 7:10\u201315",
                        "readQS": "Isaiah+7:10-15",
                        "audioQS": "Isa.7"
                    },
                    {
                        "pretty": "Luke 11:27\u201328",
                        "readQS": "Luke+11:27-28",
                        "audioQS": "Luke.11"
                    }
                ],
                "readQS": "Psalm+98,138,147:1-11%3B+Isaiah+7:10-15%3B+Luke+11:27-28",
                "audioQS": "Ps.98,138,147,Isa.7,Luke.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Song of Solomon 2:1\u20137",
                        "readQS": "Song+of+Solomon+2:1-7",
                        "audioQS": "Song.2"
                    },
                    {
                        "pretty": "Acts 1:6\u201314",
                        "readQS": "Acts+1:6-14",
                        "audioQS": "Acts.1"
                    }
                ],
                "readQS": "Psalm+132%3B+Song+of+Solomon+2:1-7%3B+Acts+1:6-14",
                "audioQS": "Ps.132,Song.2,Acts.1",
                "note": null
            }
        ]
    },
    "2025/08/16": {
        "datePretty": "Saturday, August 16, 2025",
        "calendar": "Week of 19th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 42",
                        "readQS": "Psalm+42",
                        "audioQS": "Ps.42"
                    },
                    {
                        "pretty": "Ezekiel 36:16\u201336",
                        "readQS": "Ezekiel+36:16-36",
                        "audioQS": "Ezek.36"
                    },
                    {
                        "pretty": "James 5:7\u2013end",
                        "readQS": "James+5:7-",
                        "audioQS": "Jas.5"
                    }
                ],
                "readQS": "Psalm+42%3B+Ezekiel+36:16-36%3B+James+5:7-",
                "audioQS": "Ps.42,Ezek.36,Jas.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 46",
                        "readQS": "Psalm+46",
                        "audioQS": "Ps.46"
                    },
                    {
                        "pretty": "1 Samuel 17:55\u201318:16",
                        "readQS": "1+Samuel+17:55-18:16",
                        "audioQS": "1Sam.17"
                    },
                    {
                        "pretty": "Luke 24:36\u2013end",
                        "readQS": "Luke+24:36-",
                        "audioQS": "Luke.24"
                    }
                ],
                "readQS": "Psalm+46%3B+1+Samuel+17:55-18:16%3B+Luke+24:36-",
                "audioQS": "Ps.46,1Sam.17,Luke.24",
                "note": null
            }
        ]
    },
    "2025/08/17": {
        "datePretty": "Sunday, August 17, 2025",
        "calendar": "20th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 82",
                        "readQS": "Psalm+82",
                        "audioQS": "Ps.82"
                    },
                    {
                        "pretty": "Jeremiah 23:23\u201329",
                        "readQS": "Jeremiah+23:23-29",
                        "audioQS": "Jer.23"
                    },
                    {
                        "pretty": "Hebrews 11:29\u201312:2",
                        "readQS": "Hebrews+11:29-12:2",
                        "audioQS": "Heb.11"
                    },
                    {
                        "pretty": "Luke 12:49\u201356",
                        "readQS": "Luke+12:49-56",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+82%3B+Jeremiah+23:23-29%3B+Hebrews+11:29-12:2%3B+Luke+12:49-56",
                "audioQS": "Ps.82,Jer.23,Heb.11,Luke.12",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 80:1\u20132,9\u201320",
                        "readQS": "Psalm+80:1-2,9-20",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Isaiah 5:1\u20137",
                        "readQS": "Isaiah+5:1-7",
                        "audioQS": "Isa.5"
                    },
                    {
                        "pretty": "Hebrews 11:29\u201312:2",
                        "readQS": "Hebrews+11:29-12:2",
                        "audioQS": "Heb.11"
                    },
                    {
                        "pretty": "Luke 12:49\u201356",
                        "readQS": "Luke+12:49-56",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+80:1-2,9-20%3B+Isaiah+5:1-7%3B+Hebrews+11:29-12:2%3B+Luke+12:49-56",
                "audioQS": "Ps.80,Isa.5,Heb.11,Luke.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:33\u201348",
                        "readQS": "Psalm+119:33-48",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Jonah 1",
                        "readQS": "Jonah+1",
                        "audioQS": "Jonah.1"
                    },
                    {
                        "pretty": "2 Peter 3:14\u201318",
                        "readQS": "2+Peter+3:14-18",
                        "audioQS": "2Pet.3"
                    }
                ],
                "readQS": "Psalm+119:33-48%3B+Jonah+1%3B+2+Peter+3:14-18",
                "audioQS": "Ps.119,Jonah.1,2Pet.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:17\u201332",
                        "readQS": "Psalm+119:17-32",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Isaiah 28:9\u201322",
                        "readQS": "Isaiah+28:9-22",
                        "audioQS": "Isa.28"
                    },
                    {
                        "pretty": "2 Corinthians 8:1\u20139",
                        "readQS": "2+Corinthians+8:1-9",
                        "audioQS": "2Cor.8"
                    }
                ],
                "readQS": "Psalm+119:17-32%3B+Isaiah+28:9-22%3B+2+Corinthians+8:1-9",
                "audioQS": "Ps.119,Isa.28,2Cor.8",
                "note": null
            }
        ]
    },
    "2025/08/18": {
        "datePretty": "Monday, August 18, 2025",
        "calendar": "Week of 20th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "Ezekiel 37:1\u201314",
                        "readQS": "Ezekiel+37:1-14",
                        "audioQS": "Ezek.37"
                    },
                    {
                        "pretty": "Mark 1:1\u201313",
                        "readQS": "Mark+1:1-13",
                        "audioQS": "Mark.1"
                    }
                ],
                "readQS": "Psalm+44%3B+Ezekiel+37:1-14%3B+Mark+1:1-13",
                "audioQS": "Ps.44,Ezek.37,Mark.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 47",
                        "readQS": "Psalm+47",
                        "audioQS": "Ps.47"
                    },
                    {
                        "pretty": "1 Samuel 19:1\u201318",
                        "readQS": "1+Samuel+19:1-18",
                        "audioQS": "1Sam.19"
                    },
                    {
                        "pretty": "Acts 1:1\u201314",
                        "readQS": "Acts+1:1-14",
                        "audioQS": "Acts.1"
                    }
                ],
                "readQS": "Psalm+47%3B+1+Samuel+19:1-18%3B+Acts+1:1-14",
                "audioQS": "Ps.47,1Sam.19,Acts.1",
                "note": null
            }
        ]
    },
    "2025/08/19": {
        "datePretty": "Tuesday, August 19, 2025",
        "calendar": "Week of 20th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 48",
                        "readQS": "Psalm+48",
                        "audioQS": "Ps.48"
                    },
                    {
                        "pretty": "Ezekiel 37:15\u2013end",
                        "readQS": "Ezekiel+37:15-",
                        "audioQS": "Ezek.37"
                    },
                    {
                        "pretty": "Mark 1:14\u201320",
                        "readQS": "Mark+1:14-20",
                        "audioQS": "Mark.1"
                    }
                ],
                "readQS": "Psalm+48%3B+Ezekiel+37:15-%3B+Mark+1:14-20",
                "audioQS": "Ps.48,Ezek.37,Mark.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 50",
                        "readQS": "Psalm+50",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "1 Samuel 20:1\u201317",
                        "readQS": "1+Samuel+20:1-17",
                        "audioQS": "1Sam.20"
                    },
                    {
                        "pretty": "Acts 1:15\u2013end",
                        "readQS": "Acts+1:15-",
                        "audioQS": "Acts.1"
                    }
                ],
                "readQS": "Psalm+50%3B+1+Samuel+20:1-17%3B+Acts+1:15-",
                "audioQS": "Ps.50,1Sam.20,Acts.1",
                "note": null
            }
        ]
    },
    "2025/08/20": {
        "datePretty": "Wednesday, August 20, 2025",
        "calendar": "Week of 20th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:57\u201380",
                        "readQS": "Psalm+119:57-80",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Ezekiel 39:21\u2013end",
                        "readQS": "Ezekiel+39:21-",
                        "audioQS": "Ezek.39"
                    },
                    {
                        "pretty": "Mark 1:21\u201328",
                        "readQS": "Mark+1:21-28",
                        "audioQS": "Mark.1"
                    }
                ],
                "readQS": "Psalm+119:57-80%3B+Ezekiel+39:21-%3B+Mark+1:21-28",
                "audioQS": "Ps.119,Ezek.39,Mark.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 59",
                        "readQS": "Psalm+59",
                        "audioQS": "Ps.59"
                    },
                    {
                        "pretty": "1 Samuel 20:18\u2013end",
                        "readQS": "1+Samuel+20:18-",
                        "audioQS": "1Sam.20"
                    },
                    {
                        "pretty": "Acts 2:1\u201321",
                        "readQS": "Acts+2:1-21",
                        "audioQS": "Acts.2"
                    }
                ],
                "readQS": "Psalm+59%3B+1+Samuel+20:18-%3B+Acts+2:1-21",
                "audioQS": "Ps.59,1Sam.20,Acts.2",
                "note": null
            }
        ]
    },
    "2025/08/21": {
        "datePretty": "Thursday, August 21, 2025",
        "calendar": "Week of 20th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 57",
                        "readQS": "Psalm+57",
                        "audioQS": "Ps.57"
                    },
                    {
                        "pretty": "Ezekiel 43:1\u201312",
                        "readQS": "Ezekiel+43:1-12",
                        "audioQS": "Ezek.43"
                    },
                    {
                        "pretty": "Mark 1:29\u2013end",
                        "readQS": "Mark+1:29-",
                        "audioQS": "Mark.1"
                    }
                ],
                "readQS": "Psalm+57%3B+Ezekiel+43:1-12%3B+Mark+1:29-",
                "audioQS": "Ps.57,Ezek.43,Mark.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 62",
                        "readQS": "Psalm+62",
                        "audioQS": "Ps.62"
                    },
                    {
                        "pretty": "1 Samuel 21:1\u201322:5",
                        "readQS": "1+Samuel+21:1-22:5",
                        "audioQS": "1Sam.21"
                    },
                    {
                        "pretty": "Acts 2:22\u201336",
                        "readQS": "Acts+2:22-36",
                        "audioQS": "Acts.2"
                    }
                ],
                "readQS": "Psalm+62%3B+1+Samuel+21:1-22:5%3B+Acts+2:22-36",
                "audioQS": "Ps.62,1Sam.21,Acts.2",
                "note": null
            }
        ]
    },
    "2025/08/22": {
        "datePretty": "Friday, August 22, 2025",
        "calendar": "Week of 20th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 51",
                        "readQS": "Psalm+51",
                        "audioQS": "Ps.51"
                    },
                    {
                        "pretty": "Ezekiel 44:4\u201316",
                        "readQS": "Ezekiel+44:4-16",
                        "audioQS": "Ezek.44"
                    },
                    {
                        "pretty": "Mark 2:1\u201312",
                        "readQS": "Mark+2:1-12",
                        "audioQS": "Mark.2"
                    }
                ],
                "readQS": "Psalm+51%3B+Ezekiel+44:4-16%3B+Mark+2:1-12",
                "audioQS": "Ps.51,Ezek.44,Mark.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 38",
                        "readQS": "Psalm+38",
                        "audioQS": "Ps.38"
                    },
                    {
                        "pretty": "1 Samuel 22:6\u2013end",
                        "readQS": "1+Samuel+22:6-",
                        "audioQS": "1Sam.22"
                    },
                    {
                        "pretty": "Acts 2:37\u2013end",
                        "readQS": "Acts+2:37-",
                        "audioQS": "Acts.2"
                    }
                ],
                "readQS": "Psalm+38%3B+1+Samuel+22:6-%3B+Acts+2:37-",
                "audioQS": "Ps.38,1Sam.22,Acts.2",
                "note": null
            }
        ]
    },
    "2025/08/23": {
        "datePretty": "Saturday, August 23, 2025",
        "calendar": "Week of 20th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 68",
                        "readQS": "Psalm+68",
                        "audioQS": "Ps.68"
                    },
                    {
                        "pretty": "Ezekiel 47:1\u201312",
                        "readQS": "Ezekiel+47:1-12",
                        "audioQS": "Ezek.47"
                    },
                    {
                        "pretty": "Mark 2:13\u201322",
                        "readQS": "Mark+2:13-22",
                        "audioQS": "Mark.2"
                    }
                ],
                "readQS": "Psalm+68%3B+Ezekiel+47:1-12%3B+Mark+2:13-22",
                "audioQS": "Ps.68,Ezek.47,Mark.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Bartholomew)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Isaiah 61:1\u20139",
                        "readQS": "Isaiah+61:1-9",
                        "audioQS": "Isa.61"
                    },
                    {
                        "pretty": "2 Corinthians 6:1\u201310",
                        "readQS": "2+Corinthians+6:1-10",
                        "audioQS": "2Cor.6"
                    }
                ],
                "readQS": "Psalm+97%3B+Isaiah+61:1-9%3B+2+Corinthians+6:1-10",
                "audioQS": "Ps.97,Isa.61,2Cor.6",
                "note": null
            }
        ]
    },
    "2025/08/24": {
        "datePretty": "Sunday, August 24, 2025",
        "calendar": "St Bartholomew the Apostle",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 103:1\u20138",
                        "readQS": "Psalm+103:1-8",
                        "audioQS": "Ps.103"
                    },
                    {
                        "pretty": "Isaiah 58:9b\u201314",
                        "readQS": "Isaiah+58:9b-14",
                        "audioQS": "Isa.58"
                    },
                    {
                        "pretty": "Hebrews 12:18\u201329",
                        "readQS": "Hebrews+12:18-29",
                        "audioQS": "Heb.12"
                    },
                    {
                        "pretty": "Luke 13:10\u201317",
                        "readQS": "Luke+13:10-17",
                        "audioQS": "Luke.13"
                    }
                ],
                "readQS": "Psalm+103:1-8%3B+Isaiah+58:9b-14%3B+Hebrews+12:18-29%3B+Luke+13:10-17",
                "audioQS": "Ps.103,Isa.58,Heb.12,Luke.13",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 71:1\u20136",
                        "readQS": "Psalm+71:1-6",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Jeremiah 1:4\u201310",
                        "readQS": "Jeremiah+1:4-10",
                        "audioQS": "Jer.1"
                    },
                    {
                        "pretty": "Hebrews 12:18\u201329",
                        "readQS": "Hebrews+12:18-29",
                        "audioQS": "Heb.12"
                    },
                    {
                        "pretty": "Luke 13:10\u201317",
                        "readQS": "Luke+13:10-17",
                        "audioQS": "Luke.13"
                    }
                ],
                "readQS": "Psalm+71:1-6%3B+Jeremiah+1:4-10%3B+Hebrews+12:18-29%3B+Luke+13:10-17",
                "audioQS": "Ps.71,Jer.1,Heb.12,Luke.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 86,117",
                        "readQS": "Psalm+86,117",
                        "audioQS": "Ps.86,117"
                    },
                    {
                        "pretty": "Genesis 28:10\u201317",
                        "readQS": "Genesis+28:10-17",
                        "audioQS": "Gen.28"
                    },
                    {
                        "pretty": "John 1:43\u201351",
                        "readQS": "John+1:43-51",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+86,117%3B+Genesis+28:10-17%3B+John+1:43-51",
                "audioQS": "Ps.86,117,Gen.28,John.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 91,116",
                        "readQS": "Psalm+91,116",
                        "audioQS": "Ps.91,116"
                    },
                    {
                        "pretty": "Deuteronomy 18:15\u201319",
                        "readQS": "Deuteronomy+18:15-19",
                        "audioQS": "Deut.18"
                    },
                    {
                        "pretty": "Matthew 10:1\u201322",
                        "readQS": "Matthew+10:1-22",
                        "audioQS": "Matt.10"
                    }
                ],
                "readQS": "Psalm+91,116%3B+Deuteronomy+18:15-19%3B+Matthew+10:1-22",
                "audioQS": "Ps.91,116,Deut.18,Matt.10",
                "note": null
            }
        ]
    },
    "2025/08/25": {
        "datePretty": "Monday, August 25, 2025",
        "calendar": "Week of 21st Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Proverbs 1:1\u201319",
                        "readQS": "Proverbs+1:1-19",
                        "audioQS": "Prov.1"
                    },
                    {
                        "pretty": "Mark 2:23\u20133:6",
                        "readQS": "Mark+2:23-3:6",
                        "audioQS": "Mark.2"
                    }
                ],
                "readQS": "Psalm+71%3B+Proverbs+1:1-19%3B+Mark+2:23-3:6",
                "audioQS": "Ps.71,Prov.1,Mark.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 72",
                        "readQS": "Psalm+72",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "1 Samuel 24",
                        "readQS": "1+Samuel+24",
                        "audioQS": "1Sam.24"
                    },
                    {
                        "pretty": "Acts 3:11\u2013end",
                        "readQS": "Acts+3:11-",
                        "audioQS": "Acts.3"
                    }
                ],
                "readQS": "Psalm+72%3B+1+Samuel+24%3B+Acts+3:11-",
                "audioQS": "Ps.72,1Sam.24,Acts.3",
                "note": null
            }
        ]
    },
    "2025/08/26": {
        "datePretty": "Tuesday, August 26, 2025",
        "calendar": "Week of 21st Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Proverbs 1:20\u2013end",
                        "readQS": "Proverbs+1:20-",
                        "audioQS": "Prov.1"
                    },
                    {
                        "pretty": "Mark 3:7\u201319a",
                        "readQS": "Mark+3:7-19a",
                        "audioQS": "Mark.3"
                    }
                ],
                "readQS": "Psalm+73%3B+Proverbs+1:20-%3B+Mark+3:7-19a",
                "audioQS": "Ps.73,Prov.1,Mark.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 74",
                        "readQS": "Psalm+74",
                        "audioQS": "Ps.74"
                    },
                    {
                        "pretty": "1 Samuel 26",
                        "readQS": "1+Samuel+26",
                        "audioQS": "1Sam.26"
                    },
                    {
                        "pretty": "Acts 4:1\u201312",
                        "readQS": "Acts+4:1-12",
                        "audioQS": "Acts.4"
                    }
                ],
                "readQS": "Psalm+74%3B+1+Samuel+26%3B+Acts+4:1-12",
                "audioQS": "Ps.74,1Sam.26,Acts.4",
                "note": null
            }
        ]
    },
    "2025/08/27": {
        "datePretty": "Wednesday, August 27, 2025",
        "calendar": "Week of 21st Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Proverbs 2",
                        "readQS": "Proverbs+2",
                        "audioQS": "Prov.2"
                    },
                    {
                        "pretty": "Mark 3:19b\u2013end",
                        "readQS": "Mark+3:19b-",
                        "audioQS": "Mark.3"
                    }
                ],
                "readQS": "Psalm+77%3B+Proverbs+2%3B+Mark+3:19b-",
                "audioQS": "Ps.77,Prov.2,Mark.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:81\u2013104",
                        "readQS": "Psalm+119:81-104",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "1 Samuel 28:3\u2013end",
                        "readQS": "1+Samuel+28:3-",
                        "audioQS": "1Sam.28"
                    },
                    {
                        "pretty": "Acts 4:13\u201331",
                        "readQS": "Acts+4:13-31",
                        "audioQS": "Acts.4"
                    }
                ],
                "readQS": "Psalm+119:81-104%3B+1+Samuel+28:3-%3B+Acts+4:13-31",
                "audioQS": "Ps.119,1Sam.28,Acts.4",
                "note": null
            }
        ]
    },
    "2025/08/28": {
        "datePretty": "Thursday, August 28, 2025",
        "calendar": "Week of 21st Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 78:1\u201339",
                        "readQS": "Psalm+78:1-39",
                        "audioQS": "Ps.78"
                    },
                    {
                        "pretty": "Proverbs 3:1\u201326",
                        "readQS": "Proverbs+3:1-26",
                        "audioQS": "Prov.3"
                    },
                    {
                        "pretty": "Mark 4:1\u201320",
                        "readQS": "Mark+4:1-20",
                        "audioQS": "Mark.4"
                    }
                ],
                "readQS": "Psalm+78:1-39%3B+Proverbs+3:1-26%3B+Mark+4:1-20",
                "audioQS": "Ps.78,Prov.3,Mark.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 78:40\u2013end",
                        "readQS": "Psalm+78:40-",
                        "audioQS": "Ps.78"
                    },
                    {
                        "pretty": "1 Samuel 31",
                        "readQS": "1+Samuel+31",
                        "audioQS": "1Sam.31"
                    },
                    {
                        "pretty": "Acts 4:32\u20135:11",
                        "readQS": "Acts+4:32-5:11",
                        "audioQS": "Acts.4"
                    }
                ],
                "readQS": "Psalm+78:40-%3B+1+Samuel+31%3B+Acts+4:32-5:11",
                "audioQS": "Ps.78,1Sam.31,Acts.4",
                "note": null
            }
        ]
    },
    "2025/08/29": {
        "datePretty": "Friday, August 29, 2025",
        "calendar": "Week of 21st Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 55",
                        "readQS": "Psalm+55",
                        "audioQS": "Ps.55"
                    },
                    {
                        "pretty": "Proverbs 3:27\u20134:19",
                        "readQS": "Proverbs+3:27-4:19",
                        "audioQS": "Prov.3"
                    },
                    {
                        "pretty": "Mark 4:21\u201334",
                        "readQS": "Mark+4:21-34",
                        "audioQS": "Mark.4"
                    }
                ],
                "readQS": "Psalm+55%3B+Proverbs+3:27-4:19%3B+Mark+4:21-34",
                "audioQS": "Ps.55,Prov.3,Mark.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 69",
                        "readQS": "Psalm+69",
                        "audioQS": "Ps.69"
                    },
                    {
                        "pretty": "2 Samuel 1",
                        "readQS": "2+Samuel+1",
                        "audioQS": "2Sam.1"
                    },
                    {
                        "pretty": "Acts 5:12\u201326",
                        "readQS": "Acts+5:12-26",
                        "audioQS": "Acts.5"
                    }
                ],
                "readQS": "Psalm+69%3B+2+Samuel+1%3B+Acts+5:12-26",
                "audioQS": "Ps.69,2Sam.1,Acts.5",
                "note": null
            }
        ]
    },
    "2025/08/30": {
        "datePretty": "Saturday, August 30, 2025",
        "calendar": "Week of 21st Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 76",
                        "readQS": "Psalm+76",
                        "audioQS": "Ps.76"
                    },
                    {
                        "pretty": "Proverbs 6:1\u201319",
                        "readQS": "Proverbs+6:1-19",
                        "audioQS": "Prov.6"
                    },
                    {
                        "pretty": "Mark 4:35\u2013end",
                        "readQS": "Mark+4:35-",
                        "audioQS": "Mark.4"
                    }
                ],
                "readQS": "Psalm+76%3B+Proverbs+6:1-19%3B+Mark+4:35-",
                "audioQS": "Ps.76,Prov.6,Mark.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 84",
                        "readQS": "Psalm+84",
                        "audioQS": "Ps.84"
                    },
                    {
                        "pretty": "2 Samuel 2:1\u201311",
                        "readQS": "2+Samuel+2:1-11",
                        "audioQS": "2Sam.2"
                    },
                    {
                        "pretty": "Acts 5:27\u2013end",
                        "readQS": "Acts+5:27-",
                        "audioQS": "Acts.5"
                    }
                ],
                "readQS": "Psalm+84%3B+2+Samuel+2:1-11%3B+Acts+5:27-",
                "audioQS": "Ps.84,2Sam.2,Acts.5",
                "note": null
            }
        ]
    },
    "2025/08/31": {
        "datePretty": "Sunday, August 31, 2025",
        "calendar": "22nd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 112",
                        "readQS": "Psalm+112",
                        "audioQS": "Ps.112"
                    },
                    {
                        "pretty": "Proverbs 25:6\u20137",
                        "readQS": "Proverbs+25:6-7",
                        "audioQS": "Prov.25"
                    },
                    {
                        "pretty": "Hebrews 13:1\u20138,15\u201316",
                        "readQS": "Hebrews+13:1-8,15-16",
                        "audioQS": "Heb.13"
                    },
                    {
                        "pretty": "Luke 14:1",
                        "readQS": "Luke+14:1",
                        "audioQS": "Luke.14"
                    }
                ],
                "readQS": "Psalm+112%3B+Proverbs+25:6-7%3B+Hebrews+13:1-8,15-16%3B+Luke+14:1",
                "audioQS": "Ps.112,Prov.25,Heb.13,Luke.14",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 81:1,10\u201316",
                        "readQS": "Psalm+81:1,10-16",
                        "audioQS": "Ps.81"
                    },
                    {
                        "pretty": "Jeremiah 2:4\u201313",
                        "readQS": "Jeremiah+2:4-13",
                        "audioQS": "Jer.2"
                    },
                    {
                        "pretty": "Hebrews 13:1\u20138,15\u201316",
                        "readQS": "Hebrews+13:1-8,15-16",
                        "audioQS": "Heb.13"
                    },
                    {
                        "pretty": "Luke 14:1,7\u201314",
                        "readQS": "Luke+14:1,7-14",
                        "audioQS": "Luke.14"
                    }
                ],
                "readQS": "Psalm+81:1,10-16%3B+Jeremiah+2:4-13%3B+Hebrews+13:1-8,15-16%3B+Luke+14:1,7-14",
                "audioQS": "Ps.81,Jer.2,Heb.13,Luke.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:161\u2013176",
                        "readQS": "Psalm+119:161-176",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Jonah 3:1\u20139",
                        "readQS": "Jonah+3:1-9",
                        "audioQS": "Jonah.3"
                    },
                    {
                        "pretty": "Revelation 3:14\u201322",
                        "readQS": "Revelation+3:14-22",
                        "audioQS": "Rev.3"
                    }
                ],
                "readQS": "Psalm+119:161-176%3B+Jonah+3:1-9%3B+Revelation+3:14-22",
                "audioQS": "Ps.119,Jonah.3,Rev.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:81\u201396",
                        "readQS": "Psalm+119:81-96",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Isaiah 33:13\u201322",
                        "readQS": "Isaiah+33:13-22",
                        "audioQS": "Isa.33"
                    },
                    {
                        "pretty": "John 3:22\u201336",
                        "readQS": "John+3:22-36",
                        "audioQS": "John.3"
                    }
                ],
                "readQS": "Psalm+119:81-96%3B+Isaiah+33:13-22%3B+John+3:22-36",
                "audioQS": "Ps.119,Isa.33,John.3",
                "note": null
            }
        ]
    },
    "2025/09/01": {
        "datePretty": "Monday, September 1, 2025",
        "calendar": "Week of 22nd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Proverbs 8:1\u201321",
                        "readQS": "Proverbs+8:1-21",
                        "audioQS": "Prov.8"
                    },
                    {
                        "pretty": "Mark 5:1\u201320",
                        "readQS": "Mark+5:1-20",
                        "audioQS": "Mark.5"
                    }
                ],
                "readQS": "Psalm+80%3B+Proverbs+8:1-21%3B+Mark+5:1-20",
                "audioQS": "Ps.80,Prov.8,Mark.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "2 Samuel 3:12\u2013end",
                        "readQS": "2+Samuel+3:12-",
                        "audioQS": "2Sam.3"
                    },
                    {
                        "pretty": "Acts 6",
                        "readQS": "Acts+6",
                        "audioQS": "Acts.6"
                    }
                ],
                "readQS": "Psalm+85%3B+2+Samuel+3:12-%3B+Acts+6",
                "audioQS": "Ps.85,2Sam.3,Acts.6",
                "note": null
            }
        ]
    },
    "2025/09/02": {
        "datePretty": "Tuesday, September 2, 2025",
        "calendar": "Week of 22nd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 89:1\u201318",
                        "readQS": "Psalm+89:1-18",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "Proverbs 8:22\u2013end",
                        "readQS": "Proverbs+8:22-",
                        "audioQS": "Prov.8"
                    },
                    {
                        "pretty": "Mark 5:21\u201334",
                        "readQS": "Mark+5:21-34",
                        "audioQS": "Mark.5"
                    }
                ],
                "readQS": "Psalm+89:1-18%3B+Proverbs+8:22-%3B+Mark+5:21-34",
                "audioQS": "Ps.89,Prov.8,Mark.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 89:19\u2013end",
                        "readQS": "Psalm+89:19-",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "2 Samuel 5:1\u201312",
                        "readQS": "2+Samuel+5:1-12",
                        "audioQS": "2Sam.5"
                    },
                    {
                        "pretty": "Acts 7:1\u201316",
                        "readQS": "Acts+7:1-16",
                        "audioQS": "Acts.7"
                    }
                ],
                "readQS": "Psalm+89:19-%3B+2+Samuel+5:1-12%3B+Acts+7:1-16",
                "audioQS": "Ps.89,2Sam.5,Acts.7",
                "note": null
            }
        ]
    },
    "2025/09/03": {
        "datePretty": "Wednesday, September 3, 2025",
        "calendar": "Week of 22nd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:105\u2013128",
                        "readQS": "Psalm+119:105-128",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Proverbs 9",
                        "readQS": "Proverbs+9",
                        "audioQS": "Prov.9"
                    },
                    {
                        "pretty": "Mark 5:35\u2013end",
                        "readQS": "Mark+5:35-",
                        "audioQS": "Mark.5"
                    }
                ],
                "readQS": "Psalm+119:105-128%3B+Proverbs+9%3B+Mark+5:35-",
                "audioQS": "Ps.119,Prov.9,Mark.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 91",
                        "readQS": "Psalm+91",
                        "audioQS": "Ps.91"
                    },
                    {
                        "pretty": "2 Samuel 6:1\u201319",
                        "readQS": "2+Samuel+6:1-19",
                        "audioQS": "2Sam.6"
                    },
                    {
                        "pretty": "Acts 7:17\u201343",
                        "readQS": "Acts+7:17-43",
                        "audioQS": "Acts.7"
                    }
                ],
                "readQS": "Psalm+91%3B+2+Samuel+6:1-19%3B+Acts+7:17-43",
                "audioQS": "Ps.91,2Sam.6,Acts.7",
                "note": null
            }
        ]
    },
    "2025/09/04": {
        "datePretty": "Thursday, September 4, 2025",
        "calendar": "Week of 22nd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 92",
                        "readQS": "Psalm+92",
                        "audioQS": "Ps.92"
                    },
                    {
                        "pretty": "Proverbs 10:1\u201312",
                        "readQS": "Proverbs+10:1-12",
                        "audioQS": "Prov.10"
                    },
                    {
                        "pretty": "Mark 6:1\u201313",
                        "readQS": "Mark+6:1-13",
                        "audioQS": "Mark.6"
                    }
                ],
                "readQS": "Psalm+92%3B+Proverbs+10:1-12%3B+Mark+6:1-13",
                "audioQS": "Ps.92,Prov.10,Mark.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 94",
                        "readQS": "Psalm+94",
                        "audioQS": "Ps.94"
                    },
                    {
                        "pretty": "2 Samuel 7:1\u201317",
                        "readQS": "2+Samuel+7:1-17",
                        "audioQS": "2Sam.7"
                    },
                    {
                        "pretty": "Acts 7:44\u201353",
                        "readQS": "Acts+7:44-53",
                        "audioQS": "Acts.7"
                    }
                ],
                "readQS": "Psalm+94%3B+2+Samuel+7:1-17%3B+Acts+7:44-53",
                "audioQS": "Ps.94,2Sam.7,Acts.7",
                "note": null
            }
        ]
    },
    "2025/09/05": {
        "datePretty": "Friday, September 5, 2025",
        "calendar": "Week of 22nd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 88",
                        "readQS": "Psalm+88",
                        "audioQS": "Ps.88"
                    },
                    {
                        "pretty": "Proverbs 11:1\u201312",
                        "readQS": "Proverbs+11:1-12",
                        "audioQS": "Prov.11"
                    },
                    {
                        "pretty": "Mark 6:14\u201329",
                        "readQS": "Mark+6:14-29",
                        "audioQS": "Mark.6"
                    }
                ],
                "readQS": "Psalm+88%3B+Proverbs+11:1-12%3B+Mark+6:14-29",
                "audioQS": "Ps.88,Prov.11,Mark.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 102",
                        "readQS": "Psalm+102",
                        "audioQS": "Ps.102"
                    },
                    {
                        "pretty": "2 Samuel 7:18\u2013end",
                        "readQS": "2+Samuel+7:18-",
                        "audioQS": "2Sam.7"
                    },
                    {
                        "pretty": "Acts 7:54\u20138:3",
                        "readQS": "Acts+7:54-8:3",
                        "audioQS": "Acts.7"
                    }
                ],
                "readQS": "Psalm+102%3B+2+Samuel+7:18-%3B+Acts+7:54-8:3",
                "audioQS": "Ps.102,2Sam.7,Acts.7",
                "note": null
            }
        ]
    },
    "2025/09/06": {
        "datePretty": "Saturday, September 6, 2025",
        "calendar": "Week of 22nd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Proverbs 12:10\u2013end",
                        "readQS": "Proverbs+12:10-",
                        "audioQS": "Prov.12"
                    },
                    {
                        "pretty": "Mark 6:30\u201344",
                        "readQS": "Mark+6:30-44",
                        "audioQS": "Mark.6"
                    }
                ],
                "readQS": "Psalm+97%3B+Proverbs+12:10-%3B+Mark+6:30-44",
                "audioQS": "Ps.97,Prov.12,Mark.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 104",
                        "readQS": "Psalm+104",
                        "audioQS": "Ps.104"
                    },
                    {
                        "pretty": "2 Samuel 9",
                        "readQS": "2+Samuel+9",
                        "audioQS": "2Sam.9"
                    },
                    {
                        "pretty": "Acts 8:4\u201325",
                        "readQS": "Acts+8:4-25",
                        "audioQS": "Acts.8"
                    }
                ],
                "readQS": "Psalm+104%3B+2+Samuel+9%3B+Acts+8:4-25",
                "audioQS": "Ps.104,2Sam.9,Acts.8",
                "note": null
            }
        ]
    },
    "2025/09/07": {
        "datePretty": "Sunday, September 7, 2025",
        "calendar": "23rd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 1",
                        "readQS": "Psalm+1",
                        "audioQS": "Ps.1"
                    },
                    {
                        "pretty": "Deuteronomy 30:15\u201320",
                        "readQS": "Deuteronomy+30:15-20",
                        "audioQS": "Deut.30"
                    },
                    {
                        "pretty": "Philemon 1\u201321",
                        "readQS": "Philemon+1-21",
                        "audioQS": "Phlm.1\u201321"
                    },
                    {
                        "pretty": "Luke 14:25\u201333",
                        "readQS": "Luke+14:25-33",
                        "audioQS": "Luke.14"
                    }
                ],
                "readQS": "Psalm+1%3B+Deuteronomy+30:15-20%3B+Philemon+1-21%3B+Luke+14:25-33",
                "audioQS": "Ps.1,Deut.30,Phlm.1\u201321,Luke.14",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 139:1\u20135,12\u201318",
                        "readQS": "Psalm+139:1-5,12-18",
                        "audioQS": "Ps.139"
                    },
                    {
                        "pretty": "Jeremiah 18:1\u201311",
                        "readQS": "Jeremiah+18:1-11",
                        "audioQS": "Jer.18"
                    },
                    {
                        "pretty": "Philemon 1\u201321",
                        "readQS": "Philemon+1-21",
                        "audioQS": "Phlm.1\u201321"
                    },
                    {
                        "pretty": "Luke 14:25\u201333",
                        "readQS": "Luke+14:25-33",
                        "audioQS": "Luke.14"
                    }
                ],
                "readQS": "Psalm+139:1-5,12-18%3B+Jeremiah+18:1-11%3B+Philemon+1-21%3B+Luke+14:25-33",
                "audioQS": "Ps.139,Jer.18,Phlm.1\u201321,Luke.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 122,123",
                        "readQS": "Psalm+122,123",
                        "audioQS": "Ps.122,123"
                    },
                    {
                        "pretty": "Jonah 3:10\u20134:11",
                        "readQS": "Jonah+3:10-4:11",
                        "audioQS": "Jonah.3"
                    },
                    {
                        "pretty": "Revelation 8:1\u20135",
                        "readQS": "Revelation+8:1-5",
                        "audioQS": "Rev.8"
                    }
                ],
                "readQS": "Psalm+122,123%3B+Jonah+3:10-4:11%3B+Revelation+8:1-5",
                "audioQS": "Ps.122,123,Jonah.3,Rev.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 121",
                        "readQS": "Psalm+121",
                        "audioQS": "Ps.121"
                    },
                    {
                        "pretty": "Isaiah 43:14\u201344:5",
                        "readQS": "Isaiah+43:14-44:5",
                        "audioQS": "Isa.43"
                    },
                    {
                        "pretty": "John 5:30\u201347",
                        "readQS": "John+5:30-47",
                        "audioQS": "John.5"
                    }
                ],
                "readQS": "Psalm+121%3B+Isaiah+43:14-44:5%3B+John+5:30-47",
                "audioQS": "Ps.121,Isa.43,John.5",
                "note": null
            }
        ]
    },
    "2025/09/08": {
        "datePretty": "Monday, September 8, 2025",
        "calendar": "Week of 23rd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 98",
                        "readQS": "Psalm+98",
                        "audioQS": "Ps.98"
                    },
                    {
                        "pretty": "Proverbs 14:31\u201315:17",
                        "readQS": "Proverbs+14:31-15:17",
                        "audioQS": "Prov.14"
                    },
                    {
                        "pretty": "Mark 6:45\u2013end",
                        "readQS": "Mark+6:45-",
                        "audioQS": "Mark.6"
                    }
                ],
                "readQS": "Psalm+98%3B+Proverbs+14:31-15:17%3B+Mark+6:45-",
                "audioQS": "Ps.98,Prov.14,Mark.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 105",
                        "readQS": "Psalm+105",
                        "audioQS": "Ps.105"
                    },
                    {
                        "pretty": "2 Samuel 11",
                        "readQS": "2+Samuel+11",
                        "audioQS": "2Sam.11"
                    },
                    {
                        "pretty": "Acts 8:26\u2013end",
                        "readQS": "Acts+8:26-",
                        "audioQS": "Acts.8"
                    }
                ],
                "readQS": "Psalm+105%3B+2+Samuel+11%3B+Acts+8:26-",
                "audioQS": "Ps.105,2Sam.11,Acts.8",
                "note": null
            }
        ]
    },
    "2025/09/09": {
        "datePretty": "Tuesday, September 9, 2025",
        "calendar": "Week of 23rd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 106",
                        "readQS": "Psalm+106",
                        "audioQS": "Ps.106"
                    },
                    {
                        "pretty": "Proverbs 15:18\u2013end",
                        "readQS": "Proverbs+15:18-",
                        "audioQS": "Prov.15"
                    },
                    {
                        "pretty": "Mark 7:1\u201313",
                        "readQS": "Mark+7:1-13",
                        "audioQS": "Mark.7"
                    }
                ],
                "readQS": "Psalm+106%3B+Proverbs+15:18-%3B+Mark+7:1-13",
                "audioQS": "Ps.106,Prov.15,Mark.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 107",
                        "readQS": "Psalm+107",
                        "audioQS": "Ps.107"
                    },
                    {
                        "pretty": "2 Samuel 12:1\u201325",
                        "readQS": "2+Samuel+12:1-25",
                        "audioQS": "2Sam.12"
                    },
                    {
                        "pretty": "Acts 9:1\u201319a",
                        "readQS": "Acts+9:1-19a",
                        "audioQS": "Acts.9"
                    }
                ],
                "readQS": "Psalm+107%3B+2+Samuel+12:1-25%3B+Acts+9:1-19a",
                "audioQS": "Ps.107,2Sam.12,Acts.9",
                "note": null
            }
        ]
    },
    "2025/09/10": {
        "datePretty": "Wednesday, September 10, 2025",
        "calendar": "Week of 23rd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 111",
                        "readQS": "Psalm+111",
                        "audioQS": "Ps.111"
                    },
                    {
                        "pretty": "Proverbs 18:10\u2013end",
                        "readQS": "Proverbs+18:10-",
                        "audioQS": "Prov.18"
                    },
                    {
                        "pretty": "Mark 7:14\u201323",
                        "readQS": "Mark+7:14-23",
                        "audioQS": "Mark.7"
                    }
                ],
                "readQS": "Psalm+111%3B+Proverbs+18:10-%3B+Mark+7:14-23",
                "audioQS": "Ps.111,Prov.18,Mark.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:129\u2013152",
                        "readQS": "Psalm+119:129-152",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "2 Samuel 15:1\u201312",
                        "readQS": "2+Samuel+15:1-12",
                        "audioQS": "2Sam.15"
                    },
                    {
                        "pretty": "Acts 9:19b\u201331",
                        "readQS": "Acts+9:19b-31",
                        "audioQS": "Acts.9"
                    }
                ],
                "readQS": "Psalm+119:129-152%3B+2+Samuel+15:1-12%3B+Acts+9:19b-31",
                "audioQS": "Ps.119,2Sam.15,Acts.9",
                "note": null
            }
        ]
    },
    "2025/09/11": {
        "datePretty": "Thursday, September 11, 2025",
        "calendar": "Week of 23rd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 115",
                        "readQS": "Psalm+115",
                        "audioQS": "Ps.115"
                    },
                    {
                        "pretty": "Proverbs 20:1\u201322",
                        "readQS": "Proverbs+20:1-22",
                        "audioQS": "Prov.20"
                    },
                    {
                        "pretty": "Mark 7:24\u201330",
                        "readQS": "Mark+7:24-30",
                        "audioQS": "Mark.7"
                    }
                ],
                "readQS": "Psalm+115%3B+Proverbs+20:1-22%3B+Mark+7:24-30",
                "audioQS": "Ps.115,Prov.20,Mark.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 116",
                        "readQS": "Psalm+116",
                        "audioQS": "Ps.116"
                    },
                    {
                        "pretty": "2 Samuel 15:13\u2013end",
                        "readQS": "2+Samuel+15:13-",
                        "audioQS": "2Sam.15"
                    },
                    {
                        "pretty": "Acts 9:32\u2013end",
                        "readQS": "Acts+9:32-",
                        "audioQS": "Acts.9"
                    }
                ],
                "readQS": "Psalm+116%3B+2+Samuel+15:13-%3B+Acts+9:32-",
                "audioQS": "Ps.116,2Sam.15,Acts.9",
                "note": null
            }
        ]
    },
    "2025/09/12": {
        "datePretty": "Friday, September 12, 2025",
        "calendar": "Week of 23rd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 139",
                        "readQS": "Psalm+139",
                        "audioQS": "Ps.139"
                    },
                    {
                        "pretty": "Proverbs 22:1\u201316",
                        "readQS": "Proverbs+22:1-16",
                        "audioQS": "Prov.22"
                    },
                    {
                        "pretty": "Mark 7:31\u2013end",
                        "readQS": "Mark+7:31-",
                        "audioQS": "Mark.7"
                    }
                ],
                "readQS": "Psalm+139%3B+Proverbs+22:1-16%3B+Mark+7:31-",
                "audioQS": "Ps.139,Prov.22,Mark.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 130",
                        "readQS": "Psalm+130",
                        "audioQS": "Ps.130"
                    },
                    {
                        "pretty": "2 Samuel 16:1\u201314",
                        "readQS": "2+Samuel+16:1-14",
                        "audioQS": "2Sam.16"
                    },
                    {
                        "pretty": "Acts 10:1\u201316",
                        "readQS": "Acts+10:1-16",
                        "audioQS": "Acts.10"
                    }
                ],
                "readQS": "Psalm+130%3B+2+Samuel+16:1-14%3B+Acts+10:1-16",
                "audioQS": "Ps.130,2Sam.16,Acts.10",
                "note": null
            }
        ]
    },
    "2025/09/13": {
        "datePretty": "Saturday, September 13, 2025",
        "calendar": "Week of 23rd Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 121",
                        "readQS": "Psalm+121",
                        "audioQS": "Ps.121"
                    },
                    {
                        "pretty": "Proverbs 24:23\u2013end",
                        "readQS": "Proverbs+24:23-",
                        "audioQS": "Prov.24"
                    },
                    {
                        "pretty": "Mark 8:1\u201310",
                        "readQS": "Mark+8:1-10",
                        "audioQS": "Mark.8"
                    }
                ],
                "readQS": "Psalm+121%3B+Proverbs+24:23-%3B+Mark+8:1-10",
                "audioQS": "Ps.121,Prov.24,Mark.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of Holy Cross Day)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 66",
                        "readQS": "Psalm+66",
                        "audioQS": "Ps.66"
                    },
                    {
                        "pretty": "Isaiah 52:13\u201353:12",
                        "readQS": "Isaiah+52:13-53:12",
                        "audioQS": "Isa.52"
                    },
                    {
                        "pretty": "Ephesians 2:11\u201322",
                        "readQS": "Ephesians+2:11-22",
                        "audioQS": "Eph.2"
                    }
                ],
                "readQS": "Psalm+66%3B+Isaiah+52:13-53:12%3B+Ephesians+2:11-22",
                "audioQS": "Ps.66,Isa.52,Eph.2",
                "note": null
            }
        ]
    },
    "2025/09/14": {
        "datePretty": "Sunday, September 14, 2025",
        "calendar": "Holy Cross Day",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 51:1\u201311",
                        "readQS": "Psalm+51:1-11",
                        "audioQS": "Ps.51"
                    },
                    {
                        "pretty": "Exodus 32:7\u201314",
                        "readQS": "Exodus+32:7-14",
                        "audioQS": "Exod.32"
                    },
                    {
                        "pretty": "1 Timothy 1:12\u201317",
                        "readQS": "1+Timothy+1:12-17",
                        "audioQS": "1Tim.1"
                    },
                    {
                        "pretty": "Luke 15:1\u201310",
                        "readQS": "Luke+15:1-10",
                        "audioQS": "Luke.15"
                    }
                ],
                "readQS": "Psalm+51:1-11%3B+Exodus+32:7-14%3B+1+Timothy+1:12-17%3B+Luke+15:1-10",
                "audioQS": "Ps.51,Exod.32,1Tim.1,Luke.15",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 14",
                        "readQS": "Psalm+14",
                        "audioQS": "Ps.14"
                    },
                    {
                        "pretty": "Jeremiah 4:11\u201312,22\u201328",
                        "readQS": "Jeremiah+4:11-12,22-28",
                        "audioQS": "Jer.4"
                    },
                    {
                        "pretty": "1 Timothy 1:12\u201317",
                        "readQS": "1+Timothy+1:12-17",
                        "audioQS": "1Tim.1"
                    },
                    {
                        "pretty": "Luke 15:1\u201310",
                        "readQS": "Luke+15:1-10",
                        "audioQS": "Luke.15"
                    }
                ],
                "readQS": "Psalm+14%3B+Jeremiah+4:11-12,22-28%3B+1+Timothy+1:12-17%3B+Luke+15:1-10",
                "audioQS": "Ps.14,Jer.4,1Tim.1,Luke.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 2,8,146",
                        "readQS": "Psalm+2,8,146",
                        "audioQS": "Ps.2,8,146"
                    },
                    {
                        "pretty": "Genesis 3:1\u201315",
                        "readQS": "Genesis+3:1-15",
                        "audioQS": "Gen.3"
                    },
                    {
                        "pretty": "John 12:27\u201336a",
                        "readQS": "John+12:27-36a",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+2,8,146%3B+Genesis+3:1-15%3B+John+12:27-36a",
                "audioQS": "Ps.2,8,146,Gen.3,John.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 110,150",
                        "readQS": "Psalm+110,150",
                        "audioQS": "Ps.110,150"
                    },
                    {
                        "pretty": "Isaiah 63:1\u201316",
                        "readQS": "Isaiah+63:1-16",
                        "audioQS": "Isa.63"
                    },
                    {
                        "pretty": "1 Corinthians 1:18\u201325",
                        "readQS": "1+Corinthians+1:18-25",
                        "audioQS": "1Cor.1"
                    }
                ],
                "readQS": "Psalm+110,150%3B+Isaiah+63:1-16%3B+1+Corinthians+1:18-25",
                "audioQS": "Ps.110,150,Isa.63,1Cor.1",
                "note": null
            }
        ]
    },
    "2025/09/15": {
        "datePretty": "Monday, September 15, 2025",
        "calendar": "Week of 24th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 126",
                        "readQS": "Psalm+126",
                        "audioQS": "Ps.126"
                    },
                    {
                        "pretty": "Proverbs 25:1\u201314",
                        "readQS": "Proverbs+25:1-14",
                        "audioQS": "Prov.25"
                    },
                    {
                        "pretty": "Mark 8:11\u201321",
                        "readQS": "Mark+8:11-21",
                        "audioQS": "Mark.8"
                    }
                ],
                "readQS": "Psalm+126%3B+Proverbs+25:1-14%3B+Mark+8:11-21",
                "audioQS": "Ps.126,Prov.25,Mark.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 127",
                        "readQS": "Psalm+127",
                        "audioQS": "Ps.127"
                    },
                    {
                        "pretty": "2 Samuel 18:1\u201318",
                        "readQS": "2+Samuel+18:1-18",
                        "audioQS": "2Sam.18"
                    },
                    {
                        "pretty": "Acts 10:34\u2013end",
                        "readQS": "Acts+10:34-",
                        "audioQS": "Acts.10"
                    }
                ],
                "readQS": "Psalm+127%3B+2+Samuel+18:1-18%3B+Acts+10:34-",
                "audioQS": "Ps.127,2Sam.18,Acts.10",
                "note": null
            }
        ]
    },
    "2025/09/16": {
        "datePretty": "Tuesday, September 16, 2025",
        "calendar": "Week of 24th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Proverbs 25:15\u2013end",
                        "readQS": "Proverbs+25:15-",
                        "audioQS": "Prov.25"
                    },
                    {
                        "pretty": "Mark 8:22\u201326",
                        "readQS": "Mark+8:22-26",
                        "audioQS": "Mark.8"
                    }
                ],
                "readQS": "Psalm+132%3B+Proverbs+25:15-%3B+Mark+8:22-26",
                "audioQS": "Ps.132,Prov.25,Mark.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 135",
                        "readQS": "Psalm+135",
                        "audioQS": "Ps.135"
                    },
                    {
                        "pretty": "2 Samuel 18:19\u201319:8a",
                        "readQS": "2+Samuel+18:19-19:8a",
                        "audioQS": "2Sam.18"
                    },
                    {
                        "pretty": "Acts 11:1\u201318",
                        "readQS": "Acts+11:1-18",
                        "audioQS": "Acts.11"
                    }
                ],
                "readQS": "Psalm+135%3B+2+Samuel+18:19-19:8a%3B+Acts+11:1-18",
                "audioQS": "Ps.135,2Sam.18,Acts.11",
                "note": null
            }
        ]
    },
    "2025/09/17": {
        "datePretty": "Wednesday, September 17, 2025",
        "calendar": "Week of 24th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:153\u2013end",
                        "readQS": "Psalm+119:153-",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Proverbs 26:12\u2013end",
                        "readQS": "Proverbs+26:12-",
                        "audioQS": "Prov.26"
                    },
                    {
                        "pretty": "Mark 8:27\u20139:1",
                        "readQS": "Mark+8:27-9:1",
                        "audioQS": "Mark.8"
                    }
                ],
                "readQS": "Psalm+119:153-%3B+Proverbs+26:12-%3B+Mark+8:27-9:1",
                "audioQS": "Ps.119,Prov.26,Mark.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 136",
                        "readQS": "Psalm+136",
                        "audioQS": "Ps.136"
                    },
                    {
                        "pretty": "2 Samuel 19:8b\u201323",
                        "readQS": "2+Samuel+19:8b-23",
                        "audioQS": "2Sam.19"
                    },
                    {
                        "pretty": "Acts 11:19\u2013end",
                        "readQS": "Acts+11:19-",
                        "audioQS": "Acts.11"
                    }
                ],
                "readQS": "Psalm+136%3B+2+Samuel+19:8b-23%3B+Acts+11:19-",
                "audioQS": "Ps.136,2Sam.19,Acts.11",
                "note": null
            }
        ]
    },
    "2025/09/18": {
        "datePretty": "Thursday, September 18, 2025",
        "calendar": "Week of 24th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 143",
                        "readQS": "Psalm+143",
                        "audioQS": "Ps.143"
                    },
                    {
                        "pretty": "Proverbs 27:1\u201322",
                        "readQS": "Proverbs+27:1-22",
                        "audioQS": "Prov.27"
                    },
                    {
                        "pretty": "Mark 9:2\u201313",
                        "readQS": "Mark+9:2-13",
                        "audioQS": "Mark.9"
                    }
                ],
                "readQS": "Psalm+143%3B+Proverbs+27:1-22%3B+Mark+9:2-13",
                "audioQS": "Ps.143,Prov.27,Mark.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 138",
                        "readQS": "Psalm+138",
                        "audioQS": "Ps.138"
                    },
                    {
                        "pretty": "2 Samuel 19:24\u2013end",
                        "readQS": "2+Samuel+19:24-",
                        "audioQS": "2Sam.19"
                    },
                    {
                        "pretty": "Acts 12:1\u201317",
                        "readQS": "Acts+12:1-17",
                        "audioQS": "Acts.12"
                    }
                ],
                "readQS": "Psalm+138%3B+2+Samuel+19:24-%3B+Acts+12:1-17",
                "audioQS": "Ps.138,2Sam.19,Acts.12",
                "note": null
            }
        ]
    },
    "2025/09/19": {
        "datePretty": "Friday, September 19, 2025",
        "calendar": "Week of 24th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 144",
                        "readQS": "Psalm+144",
                        "audioQS": "Ps.144"
                    },
                    {
                        "pretty": "Proverbs 30:1\u20139,30:24\u201331",
                        "readQS": "Proverbs+30:1-9,30:24-31",
                        "audioQS": "Prov.30"
                    },
                    {
                        "pretty": "Mark 9:14\u201329",
                        "readQS": "Mark+9:14-29",
                        "audioQS": "Mark.9"
                    }
                ],
                "readQS": "Psalm+144%3B+Proverbs+30:1-9,30:24-31%3B+Mark+9:14-29",
                "audioQS": "Ps.144,Prov.30,Mark.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "2 Samuel 23:1\u20137",
                        "readQS": "2+Samuel+23:1-7",
                        "audioQS": "2Sam.23"
                    },
                    {
                        "pretty": "Acts 12:18\u2013end",
                        "readQS": "Acts+12:18-",
                        "audioQS": "Acts.12"
                    }
                ],
                "readQS": "Psalm+145%3B+2+Samuel+23:1-7%3B+Acts+12:18-",
                "audioQS": "Ps.145,2Sam.23,Acts.12",
                "note": null
            }
        ]
    },
    "2025/09/20": {
        "datePretty": "Saturday, September 20, 2025",
        "calendar": "Week of 24th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 147",
                        "readQS": "Psalm+147",
                        "audioQS": "Ps.147"
                    },
                    {
                        "pretty": "Proverbs 31:10\u2013end",
                        "readQS": "Proverbs+31:10-",
                        "audioQS": "Prov.31"
                    },
                    {
                        "pretty": "Mark 9:30\u201337",
                        "readQS": "Mark+9:30-37",
                        "audioQS": "Mark.9"
                    }
                ],
                "readQS": "Psalm+147%3B+Proverbs+31:10-%3B+Mark+9:30-37",
                "audioQS": "Ps.147,Prov.31,Mark.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Matthew)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 34",
                        "readQS": "Psalm+34",
                        "audioQS": "Ps.34"
                    },
                    {
                        "pretty": "Isaiah 33:13\u201317",
                        "readQS": "Isaiah+33:13-17",
                        "audioQS": "Isa.33"
                    },
                    {
                        "pretty": "Matthew 6:19\u201334",
                        "readQS": "Matthew+6:19-34",
                        "audioQS": "Matt.6"
                    }
                ],
                "readQS": "Psalm+34%3B+Isaiah+33:13-17%3B+Matthew+6:19-34",
                "audioQS": "Ps.34,Isa.33,Matt.6",
                "note": null
            }
        ]
    },
    "2025/09/21": {
        "datePretty": "Sunday, September 21, 2025",
        "calendar": "St Matthew, Apostle and Evangelist",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 113",
                        "readQS": "Psalm+113",
                        "audioQS": "Ps.113"
                    },
                    {
                        "pretty": "Amos 8:4\u20137",
                        "readQS": "Amos+8:4-7",
                        "audioQS": "Amos.8"
                    },
                    {
                        "pretty": "1 Timothy 2:1\u20137",
                        "readQS": "1+Timothy+2:1-7",
                        "audioQS": "1Tim.2"
                    },
                    {
                        "pretty": "Luke 16:1\u201313",
                        "readQS": "Luke+16:1-13",
                        "audioQS": "Luke.16"
                    }
                ],
                "readQS": "Psalm+113%3B+Amos+8:4-7%3B+1+Timothy+2:1-7%3B+Luke+16:1-13",
                "audioQS": "Ps.113,Amos.8,1Tim.2,Luke.16",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 79:1\u20139",
                        "readQS": "Psalm+79:1-9",
                        "audioQS": "Ps.79"
                    },
                    {
                        "pretty": "Jeremiah 8:18\u20139:1",
                        "readQS": "Jeremiah+8:18-9:1",
                        "audioQS": "Jer.8"
                    },
                    {
                        "pretty": "1 Timothy 2:1\u20137",
                        "readQS": "1+Timothy+2:1-7",
                        "audioQS": "1Tim.2"
                    },
                    {
                        "pretty": "Luke 16:1\u201313",
                        "readQS": "Luke+16:1-13",
                        "audioQS": "Luke.16"
                    }
                ],
                "readQS": "Psalm+79:1-9%3B+Jeremiah+8:18-9:1%3B+1+Timothy+2:1-7%3B+Luke+16:1-13",
                "audioQS": "Ps.79,Jer.8,1Tim.2,Luke.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 49,117",
                        "readQS": "Psalm+49,117",
                        "audioQS": "Ps.49,117"
                    },
                    {
                        "pretty": "1 Kings 19:15\u201321",
                        "readQS": "1+Kings+19:15-21",
                        "audioQS": "1Kgs.19"
                    },
                    {
                        "pretty": "2 Timothy 3:14\u201317",
                        "readQS": "2+Timothy+3:14-17",
                        "audioQS": "2Tim.3"
                    }
                ],
                "readQS": "Psalm+49,117%3B+1+Kings+19:15-21%3B+2+Timothy+3:14-17",
                "audioQS": "Ps.49,117,1Kgs.19,2Tim.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:33\u201340,119:89\u201396",
                        "readQS": "Psalm+119:33-40,119:89-96",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Ecclesiastes 5:4\u201312",
                        "readQS": "Ecclesiastes+5:4-12",
                        "audioQS": "Eccl.5"
                    },
                    {
                        "pretty": "Matthew 19:16\u201330",
                        "readQS": "Matthew+19:16-30",
                        "audioQS": "Matt.19"
                    }
                ],
                "readQS": "Psalm+119:33-40,119:89-96%3B+Ecclesiastes+5:4-12%3B+Matthew+19:16-30",
                "audioQS": "Ps.119,Eccl.5,Matt.19",
                "note": null
            }
        ]
    },
    "2025/09/22": {
        "datePretty": "Monday, September 22, 2025",
        "calendar": "Week of 25th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 1",
                        "readQS": "Psalm+1",
                        "audioQS": "Ps.1"
                    },
                    {
                        "pretty": "1 Chronicles 10:1\u201311:9",
                        "readQS": "1+Chronicles+10:1-11:9",
                        "audioQS": "1Chr.10"
                    },
                    {
                        "pretty": "Mark 9:38\u2013end",
                        "readQS": "Mark+9:38-",
                        "audioQS": "Mark.9"
                    }
                ],
                "readQS": "Psalm+1%3B+1+Chronicles+10:1-11:9%3B+Mark+9:38-",
                "audioQS": "Ps.1,1Chr.10,Mark.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 4",
                        "readQS": "Psalm+4",
                        "audioQS": "Ps.4"
                    },
                    {
                        "pretty": "1 Kings 1:5\u201331",
                        "readQS": "1+Kings+1:5-31",
                        "audioQS": "1Kgs.1"
                    },
                    {
                        "pretty": "Acts 13:13\u201343",
                        "readQS": "Acts+13:13-43",
                        "audioQS": "Acts.13"
                    }
                ],
                "readQS": "Psalm+4%3B+1+Kings+1:5-31%3B+Acts+13:13-43",
                "audioQS": "Ps.4,1Kgs.1,Acts.13",
                "note": null
            }
        ]
    },
    "2025/09/23": {
        "datePretty": "Tuesday, September 23, 2025",
        "calendar": "Week of 25th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 5",
                        "readQS": "Psalm+5",
                        "audioQS": "Ps.5"
                    },
                    {
                        "pretty": "1 Chronicles 13",
                        "readQS": "1+Chronicles+13",
                        "audioQS": "1Chr.13"
                    },
                    {
                        "pretty": "Mark 10:1\u201316",
                        "readQS": "Mark+10:1-16",
                        "audioQS": "Mark.10"
                    }
                ],
                "readQS": "Psalm+5%3B+1+Chronicles+13%3B+Mark+10:1-16",
                "audioQS": "Ps.5,1Chr.13,Mark.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 9",
                        "readQS": "Psalm+9",
                        "audioQS": "Ps.9"
                    },
                    {
                        "pretty": "1 Kings 1:32\u20132:4,2:10\u201312",
                        "readQS": "1+Kings+1:32-2:4,2:10-12",
                        "audioQS": "1Kgs.1"
                    },
                    {
                        "pretty": "Acts 13:44\u201314:7",
                        "readQS": "Acts+13:44-14:7",
                        "audioQS": "Acts.13"
                    }
                ],
                "readQS": "Psalm+9%3B+1+Kings+1:32-2:4,2:10-12%3B+Acts+13:44-14:7",
                "audioQS": "Ps.9,1Kgs.1,Acts.13",
                "note": null
            }
        ]
    },
    "2025/09/24": {
        "datePretty": "Wednesday, September 24, 2025",
        "calendar": "Week of 25th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:1\u201332",
                        "readQS": "Psalm+119:1-32",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "1 Chronicles 15:1\u201316:3",
                        "readQS": "1+Chronicles+15:1-16:3",
                        "audioQS": "1Chr.15"
                    },
                    {
                        "pretty": "Mark 10:17\u201331",
                        "readQS": "Mark+10:17-31",
                        "audioQS": "Mark.10"
                    }
                ],
                "readQS": "Psalm+119:1-32%3B+1+Chronicles+15:1-16:3%3B+Mark+10:17-31",
                "audioQS": "Ps.119,1Chr.15,Mark.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 11",
                        "readQS": "Psalm+11",
                        "audioQS": "Ps.11"
                    },
                    {
                        "pretty": "1 Kings 3",
                        "readQS": "1+Kings+3",
                        "audioQS": "1Kgs.3"
                    },
                    {
                        "pretty": "Acts 14:8\u2013end",
                        "readQS": "Acts+14:8-",
                        "audioQS": "Acts.14"
                    }
                ],
                "readQS": "Psalm+11%3B+1+Kings+3%3B+Acts+14:8-",
                "audioQS": "Ps.11,1Kgs.3,Acts.14",
                "note": null
            }
        ]
    },
    "2025/09/25": {
        "datePretty": "Thursday, September 25, 2025",
        "calendar": "Week of 25th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 15",
                        "readQS": "Psalm+15",
                        "audioQS": "Ps.15"
                    },
                    {
                        "pretty": "1 Chronicles 17",
                        "readQS": "1+Chronicles+17",
                        "audioQS": "1Chr.17"
                    },
                    {
                        "pretty": "Mark 10:32\u201334",
                        "readQS": "Mark+10:32-34",
                        "audioQS": "Mark.10"
                    }
                ],
                "readQS": "Psalm+15%3B+1+Chronicles+17%3B+Mark+10:32-34",
                "audioQS": "Ps.15,1Chr.17,Mark.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 18",
                        "readQS": "Psalm+18",
                        "audioQS": "Ps.18"
                    },
                    {
                        "pretty": "1 Kings 4:29\u20135:12",
                        "readQS": "1+Kings+4:29-5:12",
                        "audioQS": "1Kgs.4"
                    },
                    {
                        "pretty": "Acts 15:1\u201321",
                        "readQS": "Acts+15:1-21",
                        "audioQS": "Acts.15"
                    }
                ],
                "readQS": "Psalm+18%3B+1+Kings+4:29-5:12%3B+Acts+15:1-21",
                "audioQS": "Ps.18,1Kgs.4,Acts.15",
                "note": null
            }
        ]
    },
    "2025/09/26": {
        "datePretty": "Friday, September 26, 2025",
        "calendar": "Week of 25th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 19",
                        "readQS": "Psalm+19",
                        "audioQS": "Ps.19"
                    },
                    {
                        "pretty": "1 Chronicles 21:1\u201322:1",
                        "readQS": "1+Chronicles+21:1-22:1",
                        "audioQS": "1Chr.21"
                    },
                    {
                        "pretty": "Mark 10:35\u201345",
                        "readQS": "Mark+10:35-45",
                        "audioQS": "Mark.10"
                    }
                ],
                "readQS": "Psalm+19%3B+1+Chronicles+21:1-22:1%3B+Mark+10:35-45",
                "audioQS": "Ps.19,1Chr.21,Mark.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 22",
                        "readQS": "Psalm+22",
                        "audioQS": "Ps.22"
                    },
                    {
                        "pretty": "1 Kings 6:1,6:11\u201328",
                        "readQS": "1+Kings+6:1,6:11-28",
                        "audioQS": "1Kgs.6"
                    },
                    {
                        "pretty": "Acts 15:22\u201335",
                        "readQS": "Acts+15:22-35",
                        "audioQS": "Acts.15"
                    }
                ],
                "readQS": "Psalm+22%3B+1+Kings+6:1,6:11-28%3B+Acts+15:22-35",
                "audioQS": "Ps.22,1Kgs.6,Acts.15",
                "note": null
            }
        ]
    },
    "2025/09/27": {
        "datePretty": "Saturday, September 27, 2025",
        "calendar": "Week of 25th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 23",
                        "readQS": "Psalm+23",
                        "audioQS": "Ps.23"
                    },
                    {
                        "pretty": "1 Chronicles 22:2\u2013end",
                        "readQS": "1+Chronicles+22:2-",
                        "audioQS": "1Chr.22"
                    },
                    {
                        "pretty": "Mark 10:46\u2013end",
                        "readQS": "Mark+10:46-",
                        "audioQS": "Mark.10"
                    }
                ],
                "readQS": "Psalm+23%3B+1+Chronicles+22:2-%3B+Mark+10:46-",
                "audioQS": "Ps.23,1Chr.22,Mark.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 24",
                        "readQS": "Psalm+24",
                        "audioQS": "Ps.24"
                    },
                    {
                        "pretty": "1 Kings 8:1\u201330",
                        "readQS": "1+Kings+8:1-30",
                        "audioQS": "1Kgs.8"
                    },
                    {
                        "pretty": "Acts 15:36\u201316:5",
                        "readQS": "Acts+15:36-16:5",
                        "audioQS": "Acts.15"
                    }
                ],
                "readQS": "Psalm+24%3B+1+Kings+8:1-30%3B+Acts+15:36-16:5",
                "audioQS": "Ps.24,1Kgs.8,Acts.15",
                "note": null
            }
        ]
    },
    "2025/09/28": {
        "datePretty": "Sunday, September 28, 2025",
        "calendar": "26th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 146",
                        "readQS": "Psalm+146",
                        "audioQS": "Ps.146"
                    },
                    {
                        "pretty": "Amos 6:1a,4\u20137",
                        "readQS": "Amos+6:1a,4-7",
                        "audioQS": "Amos.6"
                    },
                    {
                        "pretty": "1 Timothy 6:6\u201319",
                        "readQS": "1+Timothy+6:6-19",
                        "audioQS": "1Tim.6"
                    },
                    {
                        "pretty": "Luke 16:19\u201331",
                        "readQS": "Luke+16:19-31",
                        "audioQS": "Luke.16"
                    }
                ],
                "readQS": "Psalm+146%3B+Amos+6:1a,4-7%3B+1+Timothy+6:6-19%3B+Luke+16:19-31",
                "audioQS": "Ps.146,Amos.6,1Tim.6,Luke.16",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 91:1\u20136,14\u201316",
                        "readQS": "Psalm+91:1-6,14-16",
                        "audioQS": "Ps.91"
                    },
                    {
                        "pretty": "Jeremiah 32:1\u20133a,6\u201315",
                        "readQS": "Jeremiah+32:1-3a,6-15",
                        "audioQS": "Jer.32"
                    },
                    {
                        "pretty": "1 Timothy 6:6\u201319",
                        "readQS": "1+Timothy+6:6-19",
                        "audioQS": "1Tim.6"
                    },
                    {
                        "pretty": "Luke 16:19\u201331",
                        "readQS": "Luke+16:19-31",
                        "audioQS": "Luke.16"
                    }
                ],
                "readQS": "Psalm+91:1-6,14-16%3B+Jeremiah+32:1-3a,6-15%3B+1+Timothy+6:6-19%3B+Luke+16:19-31",
                "audioQS": "Ps.91,Jer.32,1Tim.6,Luke.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "Isaiah 48:12\u201322",
                        "readQS": "Isaiah+48:12-22",
                        "audioQS": "Isa.48"
                    },
                    {
                        "pretty": "Luke 11:37\u201354",
                        "readQS": "Luke+11:37-54",
                        "audioQS": "Luke.11"
                    }
                ],
                "readQS": "Psalm+132%3B+Isaiah+48:12-22%3B+Luke+11:37-54",
                "audioQS": "Ps.132,Isa.48,Luke.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of Michaelmas)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 91",
                        "readQS": "Psalm+91",
                        "audioQS": "Ps.91"
                    },
                    {
                        "pretty": "2 Kings 6:8\u201317",
                        "readQS": "2+Kings+6:8-17",
                        "audioQS": "2Kgs.6"
                    },
                    {
                        "pretty": "Matthew 18:1\u20136,10",
                        "readQS": "Matthew+18:1-6,10",
                        "audioQS": "Matt.18"
                    }
                ],
                "readQS": "Psalm+91%3B+2+Kings+6:8-17%3B+Matthew+18:1-6,10",
                "audioQS": "Ps.91,2Kgs.6,Matt.18",
                "note": null
            }
        ]
    },
    "2025/09/29": {
        "datePretty": "Monday, September 29, 2025",
        "calendar": "St Michael and All Angels / Michaelmas",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 34,150",
                        "readQS": "Psalm+34,150",
                        "audioQS": "Ps.34,150"
                    },
                    {
                        "pretty": "Daniel 12:1\u20134",
                        "readQS": "Daniel+12:1-4",
                        "audioQS": "Dan.12"
                    },
                    {
                        "pretty": "Acts 12:1\u201311",
                        "readQS": "Acts+12:1-11",
                        "audioQS": "Acts.12"
                    }
                ],
                "readQS": "Psalm+34,150%3B+Daniel+12:1-4%3B+Acts+12:1-11",
                "audioQS": "Ps.34,150,Dan.12,Acts.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 138,148",
                        "readQS": "Psalm+138,148",
                        "audioQS": "Ps.138,148"
                    },
                    {
                        "pretty": "Daniel 10:4\u201321",
                        "readQS": "Daniel+10:4-21",
                        "audioQS": "Dan.10"
                    },
                    {
                        "pretty": "Revelations 5",
                        "readQS": "Revelations+5",
                        "audioQS": "Revs.5"
                    }
                ],
                "readQS": "Psalm+138,148%3B+Daniel+10:4-21%3B+Revelations+5",
                "audioQS": "Ps.138,148,Dan.10,Revs.5",
                "note": null
            }
        ]
    },
    "2025/09/30": {
        "datePretty": "Tuesday, September 30, 2025",
        "calendar": "Week of 26th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 36",
                        "readQS": "Psalm+36",
                        "audioQS": "Ps.36"
                    },
                    {
                        "pretty": "1 Chronicles 28:11\u2013end",
                        "readQS": "1+Chronicles+28:11-",
                        "audioQS": "1Chr.28"
                    },
                    {
                        "pretty": "Mark 11:12\u201326",
                        "readQS": "Mark+11:12-26",
                        "audioQS": "Mark.11"
                    }
                ],
                "readQS": "Psalm+36%3B+1+Chronicles+28:11-%3B+Mark+11:12-26",
                "audioQS": "Ps.36,1Chr.28,Mark.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 33",
                        "readQS": "Psalm+33",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "1 Kings 8:63\u20139:9",
                        "readQS": "1+Kings+8:63-9:9",
                        "audioQS": "1Kgs.8"
                    },
                    {
                        "pretty": "Acts 16:25\u2013end",
                        "readQS": "Acts+16:25-",
                        "audioQS": "Acts.16"
                    }
                ],
                "readQS": "Psalm+33%3B+1+Kings+8:63-9:9%3B+Acts+16:25-",
                "audioQS": "Ps.33,1Kgs.8,Acts.16",
                "note": null
            }
        ]
    },
    "2025/10/01": {
        "datePretty": "Wednesday, October 1, 2025",
        "calendar": "Week of 26th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 34",
                        "readQS": "Psalm+34",
                        "audioQS": "Ps.34"
                    },
                    {
                        "pretty": "1 Chronicles 29:1\u20139",
                        "readQS": "1+Chronicles+29:1-9",
                        "audioQS": "1Chr.29"
                    },
                    {
                        "pretty": "Mark 11:27\u2013end",
                        "readQS": "Mark+11:27-",
                        "audioQS": "Mark.11"
                    }
                ],
                "readQS": "Psalm+34%3B+1+Chronicles+29:1-9%3B+Mark+11:27-",
                "audioQS": "Ps.34,1Chr.29,Mark.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:33\u201356",
                        "readQS": "Psalm+119:33-56",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "1 Kings 10:1\u201325",
                        "readQS": "1+Kings+10:1-25",
                        "audioQS": "1Kgs.10"
                    },
                    {
                        "pretty": "Acts 17:1\u201315",
                        "readQS": "Acts+17:1-15",
                        "audioQS": "Acts.17"
                    }
                ],
                "readQS": "Psalm+119:33-56%3B+1+Kings+10:1-25%3B+Acts+17:1-15",
                "audioQS": "Ps.119,1Kgs.10,Acts.17",
                "note": null
            }
        ]
    },
    "2025/10/02": {
        "datePretty": "Thursday, October 2, 2025",
        "calendar": "Week of 26th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 37",
                        "readQS": "Psalm+37",
                        "audioQS": "Ps.37"
                    },
                    {
                        "pretty": "1 Chronicles 29:10\u201320",
                        "readQS": "1+Chronicles+29:10-20",
                        "audioQS": "1Chr.29"
                    },
                    {
                        "pretty": "Mark 12:1\u201312",
                        "readQS": "Mark+12:1-12",
                        "audioQS": "Mark.12"
                    }
                ],
                "readQS": "Psalm+37%3B+1+Chronicles+29:10-20%3B+Mark+12:1-12",
                "audioQS": "Ps.37,1Chr.29,Mark.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "1 Kings 11:1\u201313",
                        "readQS": "1+Kings+11:1-13",
                        "audioQS": "1Kgs.11"
                    },
                    {
                        "pretty": "Acts 17:16\u2013end",
                        "readQS": "Acts+17:16-",
                        "audioQS": "Acts.17"
                    }
                ],
                "readQS": "Psalm+40%3B+1+Kings+11:1-13%3B+Acts+17:16-",
                "audioQS": "Ps.40,1Kgs.11,Acts.17",
                "note": null
            }
        ]
    },
    "2025/10/03": {
        "datePretty": "Friday, October 3, 2025",
        "calendar": "Week of 26th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 31",
                        "readQS": "Psalm+31",
                        "audioQS": "Ps.31"
                    },
                    {
                        "pretty": "1 Chronicles 29:21\u2013end",
                        "readQS": "1+Chronicles+29:21-",
                        "audioQS": "1Chr.29"
                    },
                    {
                        "pretty": "Mark 12:13\u201317",
                        "readQS": "Mark+12:13-17",
                        "audioQS": "Mark.12"
                    }
                ],
                "readQS": "Psalm+31%3B+1+Chronicles+29:21-%3B+Mark+12:13-17",
                "audioQS": "Ps.31,1Chr.29,Mark.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 35",
                        "readQS": "Psalm+35",
                        "audioQS": "Ps.35"
                    },
                    {
                        "pretty": "1 Kings 11:26\u2013end",
                        "readQS": "1+Kings+11:26-",
                        "audioQS": "1Kgs.11"
                    },
                    {
                        "pretty": "Acts 18:1\u201321",
                        "readQS": "Acts+18:1-21",
                        "audioQS": "Acts.18"
                    }
                ],
                "readQS": "Psalm+35%3B+1+Kings+11:26-%3B+Acts+18:1-21",
                "audioQS": "Ps.35,1Kgs.11,Acts.18",
                "note": null
            }
        ]
    },
    "2025/10/04": {
        "datePretty": "Saturday, October 4, 2025",
        "calendar": "Week of 26th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 42",
                        "readQS": "Psalm+42",
                        "audioQS": "Ps.42"
                    },
                    {
                        "pretty": "2 Chronicles 1:1\u201313",
                        "readQS": "2+Chronicles+1:1-13",
                        "audioQS": "2Chr.1"
                    },
                    {
                        "pretty": "Mark 12:18\u201327",
                        "readQS": "Mark+12:18-27",
                        "audioQS": "Mark.12"
                    }
                ],
                "readQS": "Psalm+42%3B+2+Chronicles+1:1-13%3B+Mark+12:18-27",
                "audioQS": "Ps.42,2Chr.1,Mark.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 46",
                        "readQS": "Psalm+46",
                        "audioQS": "Ps.46"
                    },
                    {
                        "pretty": "1 Kings 12:1\u201324",
                        "readQS": "1+Kings+12:1-24",
                        "audioQS": "1Kgs.12"
                    },
                    {
                        "pretty": "Acts 18:22\u201319:7",
                        "readQS": "Acts+18:22-19:7",
                        "audioQS": "Acts.18"
                    }
                ],
                "readQS": "Psalm+46%3B+1+Kings+12:1-24%3B+Acts+18:22-19:7",
                "audioQS": "Ps.46,1Kgs.12,Acts.18",
                "note": null
            }
        ]
    },
    "2025/10/05": {
        "datePretty": "Sunday, October 5, 2025",
        "calendar": "27th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 37:1\u20139",
                        "readQS": "Psalm+37:1-9",
                        "audioQS": "Ps.37"
                    },
                    {
                        "pretty": "Habakkuk 1:1\u20134,2:1\u20134",
                        "readQS": "Habakkuk+1:1-4,2:1-4",
                        "audioQS": "Hab.1"
                    },
                    {
                        "pretty": "2 Timothy 1:1\u201314",
                        "readQS": "2+Timothy+1:1-14",
                        "audioQS": "2Tim.1"
                    },
                    {
                        "pretty": "Luke 17:5\u201310",
                        "readQS": "Luke+17:5-10",
                        "audioQS": "Luke.17"
                    }
                ],
                "readQS": "Psalm+37:1-9%3B+Habakkuk+1:1-4,2:1-4%3B+2+Timothy+1:1-14%3B+Luke+17:5-10",
                "audioQS": "Ps.37,Hab.1,2Tim.1,Luke.17",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 137",
                        "readQS": "Psalm+137",
                        "audioQS": "Ps.137"
                    },
                    {
                        "pretty": "Canticle: Lamentations 3:19\u201326",
                        "readQS": "Lamentations+3:19-26",
                        "audioQS": "Lam.3"
                    },
                    {
                        "pretty": "Lamentations 1:1\u20136",
                        "readQS": "Lamentations+1:1-6",
                        "audioQS": "Lam.1"
                    },
                    {
                        "pretty": "2 Timothy 1:1\u201314",
                        "readQS": "2+Timothy+1:1-14",
                        "audioQS": "2Tim.1"
                    },
                    {
                        "pretty": "Luke 17:5\u201310",
                        "readQS": "Luke+17:5-10",
                        "audioQS": "Luke.17"
                    }
                ],
                "readQS": "Psalm+137%3B+Canticle:+Lamentations+3:19-26%3B+Lamentations+1:1-6%3B+2+Timothy+1:1-14%3B+Luke+17:5-10",
                "audioQS": "Ps.137,Canticle,Lam.1,2Tim.1,Luke.17",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 141",
                        "readQS": "Psalm+141",
                        "audioQS": "Ps.141"
                    },
                    {
                        "pretty": "Isaiah 49:13\u201323",
                        "readQS": "Isaiah+49:13-23",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "Luke 12:1\u201312",
                        "readQS": "Luke+12:1-12",
                        "audioQS": "Luke.12"
                    }
                ],
                "readQS": "Psalm+141%3B+Isaiah+49:13-23%3B+Luke+12:1-12",
                "audioQS": "Ps.141,Isa.49,Luke.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 142",
                        "readQS": "Psalm+142",
                        "audioQS": "Ps.142"
                    },
                    {
                        "pretty": "Nehemiah 5:1\u201313",
                        "readQS": "Nehemiah+5:1-13",
                        "audioQS": "Neh.5"
                    },
                    {
                        "pretty": "John 9",
                        "readQS": "John+9",
                        "audioQS": "John.9"
                    }
                ],
                "readQS": "Psalm+142%3B+Nehemiah+5:1-13%3B+John+9",
                "audioQS": "Ps.142,Neh.5,John.9",
                "note": null
            }
        ]
    },
    "2025/10/06": {
        "datePretty": "Monday, October 6, 2025",
        "calendar": "Week of 27th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "2 Chronicles 2:1\u201316",
                        "readQS": "2+Chronicles+2:1-16",
                        "audioQS": "2Chr.2"
                    },
                    {
                        "pretty": "Mark 12:28\u201334",
                        "readQS": "Mark+12:28-34",
                        "audioQS": "Mark.12"
                    }
                ],
                "readQS": "Psalm+44%3B+2+Chronicles+2:1-16%3B+Mark+12:28-34",
                "audioQS": "Ps.44,2Chr.2,Mark.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 47",
                        "readQS": "Psalm+47",
                        "audioQS": "Ps.47"
                    },
                    {
                        "pretty": "1 Kings 12:25\u201313:10",
                        "readQS": "1+Kings+12:25-13:10",
                        "audioQS": "1Kgs.12"
                    },
                    {
                        "pretty": "Acts 19:8\u201320",
                        "readQS": "Acts+19:8-20",
                        "audioQS": "Acts.19"
                    }
                ],
                "readQS": "Psalm+47%3B+1+Kings+12:25-13:10%3B+Acts+19:8-20",
                "audioQS": "Ps.47,1Kgs.12,Acts.19",
                "note": null
            }
        ]
    },
    "2025/10/07": {
        "datePretty": "Tuesday, October 7, 2025",
        "calendar": "Week of 27th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 48",
                        "readQS": "Psalm+48",
                        "audioQS": "Ps.48"
                    },
                    {
                        "pretty": "2 Chronicles 3",
                        "readQS": "2+Chronicles+3",
                        "audioQS": "2Chr.3"
                    },
                    {
                        "pretty": "Mark 12:35\u2013end",
                        "readQS": "Mark+12:35-",
                        "audioQS": "Mark.12"
                    }
                ],
                "readQS": "Psalm+48%3B+2+Chronicles+3%3B+Mark+12:35-",
                "audioQS": "Ps.48,2Chr.3,Mark.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 50",
                        "readQS": "Psalm+50",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "1 Kings 13:11\u2013end",
                        "readQS": "1+Kings+13:11-",
                        "audioQS": "1Kgs.13"
                    },
                    {
                        "pretty": "Acts 19:21\u2013end",
                        "readQS": "Acts+19:21-",
                        "audioQS": "Acts.19"
                    }
                ],
                "readQS": "Psalm+50%3B+1+Kings+13:11-%3B+Acts+19:21-",
                "audioQS": "Ps.50,1Kgs.13,Acts.19",
                "note": null
            }
        ]
    },
    "2025/10/08": {
        "datePretty": "Wednesday, October 8, 2025",
        "calendar": "Week of 27th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:57\u201380",
                        "readQS": "Psalm+119:57-80",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "2 Chronicles 5",
                        "readQS": "2+Chronicles+5",
                        "audioQS": "2Chr.5"
                    },
                    {
                        "pretty": "Mark 13:1\u201313",
                        "readQS": "Mark+13:1-13",
                        "audioQS": "Mark.13"
                    }
                ],
                "readQS": "Psalm+119:57-80%3B+2+Chronicles+5%3B+Mark+13:1-13",
                "audioQS": "Ps.119,2Chr.5,Mark.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 59",
                        "readQS": "Psalm+59",
                        "audioQS": "Ps.59"
                    },
                    {
                        "pretty": "1 Kings 17",
                        "readQS": "1+Kings+17",
                        "audioQS": "1Kgs.17"
                    },
                    {
                        "pretty": "Acts 20:1\u201316",
                        "readQS": "Acts+20:1-16",
                        "audioQS": "Acts.20"
                    }
                ],
                "readQS": "Psalm+59%3B+1+Kings+17%3B+Acts+20:1-16",
                "audioQS": "Ps.59,1Kgs.17,Acts.20",
                "note": null
            }
        ]
    },
    "2025/10/09": {
        "datePretty": "Thursday, October 9, 2025",
        "calendar": "Week of 27th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 57",
                        "readQS": "Psalm+57",
                        "audioQS": "Ps.57"
                    },
                    {
                        "pretty": "2 Chronicles 6:1\u201321",
                        "readQS": "2+Chronicles+6:1-21",
                        "audioQS": "2Chr.6"
                    },
                    {
                        "pretty": "Mark 13:14\u201323",
                        "readQS": "Mark+13:14-23",
                        "audioQS": "Mark.13"
                    }
                ],
                "readQS": "Psalm+57%3B+2+Chronicles+6:1-21%3B+Mark+13:14-23",
                "audioQS": "Ps.57,2Chr.6,Mark.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 62",
                        "readQS": "Psalm+62",
                        "audioQS": "Ps.62"
                    },
                    {
                        "pretty": "1 Kings 18:1\u201320",
                        "readQS": "1+Kings+18:1-20",
                        "audioQS": "1Kgs.18"
                    },
                    {
                        "pretty": "Acts 20:17\u2013end",
                        "readQS": "Acts+20:17-",
                        "audioQS": "Acts.20"
                    }
                ],
                "readQS": "Psalm+62%3B+1+Kings+18:1-20%3B+Acts+20:17-",
                "audioQS": "Ps.62,1Kgs.18,Acts.20",
                "note": null
            }
        ]
    },
    "2025/10/10": {
        "datePretty": "Friday, October 10, 2025",
        "calendar": "Week of 27th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 51",
                        "readQS": "Psalm+51",
                        "audioQS": "Ps.51"
                    },
                    {
                        "pretty": "2 Chronicles 6:22\u2013end",
                        "readQS": "2+Chronicles+6:22-",
                        "audioQS": "2Chr.6"
                    },
                    {
                        "pretty": "Mark 13:24\u201331",
                        "readQS": "Mark+13:24-31",
                        "audioQS": "Mark.13"
                    }
                ],
                "readQS": "Psalm+51%3B+2+Chronicles+6:22-%3B+Mark+13:24-31",
                "audioQS": "Ps.51,2Chr.6,Mark.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 38",
                        "readQS": "Psalm+38",
                        "audioQS": "Ps.38"
                    },
                    {
                        "pretty": "1 Kings 18:21\u2013end",
                        "readQS": "1+Kings+18:21-",
                        "audioQS": "1Kgs.18"
                    },
                    {
                        "pretty": "Acts 21:1\u201316",
                        "readQS": "Acts+21:1-16",
                        "audioQS": "Acts.21"
                    }
                ],
                "readQS": "Psalm+38%3B+1+Kings+18:21-%3B+Acts+21:1-16",
                "audioQS": "Ps.38,1Kgs.18,Acts.21",
                "note": null
            }
        ]
    },
    "2025/10/11": {
        "datePretty": "Saturday, October 11, 2025",
        "calendar": "Week of 27th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 68",
                        "readQS": "Psalm+68",
                        "audioQS": "Ps.68"
                    },
                    {
                        "pretty": "2 Chronicles 7",
                        "readQS": "2+Chronicles+7",
                        "audioQS": "2Chr.7"
                    },
                    {
                        "pretty": "Mark 13:32\u2013end",
                        "readQS": "Mark+13:32-",
                        "audioQS": "Mark.13"
                    }
                ],
                "readQS": "Psalm+68%3B+2+Chronicles+7%3B+Mark+13:32-",
                "audioQS": "Ps.68,2Chr.7,Mark.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 66",
                        "readQS": "Psalm+66",
                        "audioQS": "Ps.66"
                    },
                    {
                        "pretty": "1 Kings 19",
                        "readQS": "1+Kings+19",
                        "audioQS": "1Kgs.19"
                    },
                    {
                        "pretty": "Acts 21:17\u201336",
                        "readQS": "Acts+21:17-36",
                        "audioQS": "Acts.21"
                    }
                ],
                "readQS": "Psalm+66%3B+1+Kings+19%3B+Acts+21:17-36",
                "audioQS": "Ps.66,1Kgs.19,Acts.21",
                "note": null
            }
        ]
    },
    "2025/10/12": {
        "datePretty": "Sunday, October 12, 2025",
        "calendar": "28th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 111",
                        "readQS": "Psalm+111",
                        "audioQS": "Ps.111"
                    },
                    {
                        "pretty": "2 Kings 5:1\u20133,7\u201315c",
                        "readQS": "2+Kings+5:1-3,7-15c",
                        "audioQS": "2Kgs.5"
                    },
                    {
                        "pretty": "2 Timothy 2:8\u201315",
                        "readQS": "2+Timothy+2:8-15",
                        "audioQS": "2Tim.2"
                    },
                    {
                        "pretty": "Luke 17:11\u201319",
                        "readQS": "Luke+17:11-19",
                        "audioQS": "Luke.17"
                    }
                ],
                "readQS": "Psalm+111%3B+2+Kings+5:1-3,7-15c%3B+2+Timothy+2:8-15%3B+Luke+17:11-19",
                "audioQS": "Ps.111,2Kgs.5,2Tim.2,Luke.17",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 66:1\u201311",
                        "readQS": "Psalm+66:1-11",
                        "audioQS": "Ps.66"
                    },
                    {
                        "pretty": "Jeremiah 29:1,4\u20137",
                        "readQS": "Jeremiah+29:1,4-7",
                        "audioQS": "Jer.29"
                    },
                    {
                        "pretty": "2 Timothy 2:8\u201315",
                        "readQS": "2+Timothy+2:8-15",
                        "audioQS": "2Tim.2"
                    },
                    {
                        "pretty": "Luke 17:11\u201319",
                        "readQS": "Luke+17:11-19",
                        "audioQS": "Luke.17"
                    }
                ],
                "readQS": "Psalm+66:1-11%3B+Jeremiah+29:1,4-7%3B+2+Timothy+2:8-15%3B+Luke+17:11-19",
                "audioQS": "Ps.66,Jer.29,2Tim.2,Luke.17",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 143",
                        "readQS": "Psalm+143",
                        "audioQS": "Ps.143"
                    },
                    {
                        "pretty": "Isaiah 50:4\u201310",
                        "readQS": "Isaiah+50:4-10",
                        "audioQS": "Isa.50"
                    },
                    {
                        "pretty": "Luke 13:22\u201330",
                        "readQS": "Luke+13:22-30",
                        "audioQS": "Luke.13"
                    }
                ],
                "readQS": "Psalm+143%3B+Isaiah+50:4-10%3B+Luke+13:22-30",
                "audioQS": "Ps.143,Isa.50,Luke.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 144",
                        "readQS": "Psalm+144",
                        "audioQS": "Ps.144"
                    },
                    {
                        "pretty": "Nehemiah 6:1\u201316",
                        "readQS": "Nehemiah+6:1-16",
                        "audioQS": "Neh.6"
                    },
                    {
                        "pretty": "John 15:12\u201327",
                        "readQS": "John+15:12-27",
                        "audioQS": "John.15"
                    }
                ],
                "readQS": "Psalm+144%3B+Nehemiah+6:1-16%3B+John+15:12-27",
                "audioQS": "Ps.144,Neh.6,John.15",
                "note": null
            }
        ]
    },
    "2025/10/13": {
        "datePretty": "Monday, October 13, 2025",
        "calendar": "Week of 28th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "2 Chronicles 9:1\u201312",
                        "readQS": "2+Chronicles+9:1-12",
                        "audioQS": "2Chr.9"
                    },
                    {
                        "pretty": "Mark 14:1\u201311",
                        "readQS": "Mark+14:1-11",
                        "audioQS": "Mark.14"
                    }
                ],
                "readQS": "Psalm+71%3B+2+Chronicles+9:1-12%3B+Mark+14:1-11",
                "audioQS": "Ps.71,2Chr.9,Mark.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 72",
                        "readQS": "Psalm+72",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "1 Kings 21",
                        "readQS": "1+Kings+21",
                        "audioQS": "1Kgs.21"
                    },
                    {
                        "pretty": "Acts 21:37\u201322:21",
                        "readQS": "Acts+21:37-22:21",
                        "audioQS": "Acts.21"
                    }
                ],
                "readQS": "Psalm+72%3B+1+Kings+21%3B+Acts+21:37-22:21",
                "audioQS": "Ps.72,1Kgs.21,Acts.21",
                "note": null
            }
        ]
    },
    "2025/10/14": {
        "datePretty": "Tuesday, October 14, 2025",
        "calendar": "Week of 28th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "2 Chronicles 10:1\u201311:4",
                        "readQS": "2+Chronicles+10:1-11:4",
                        "audioQS": "2Chr.10"
                    },
                    {
                        "pretty": "Mark 14:12\u201325",
                        "readQS": "Mark+14:12-25",
                        "audioQS": "Mark.14"
                    }
                ],
                "readQS": "Psalm+73%3B+2+Chronicles+10:1-11:4%3B+Mark+14:12-25",
                "audioQS": "Ps.73,2Chr.10,Mark.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 74",
                        "readQS": "Psalm+74",
                        "audioQS": "Ps.74"
                    },
                    {
                        "pretty": "1 Kings 22:1\u201328",
                        "readQS": "1+Kings+22:1-28",
                        "audioQS": "1Kgs.22"
                    },
                    {
                        "pretty": "Acts 22:22\u201323:11",
                        "readQS": "Acts+22:22-23:11",
                        "audioQS": "Acts.22"
                    }
                ],
                "readQS": "Psalm+74%3B+1+Kings+22:1-28%3B+Acts+22:22-23:11",
                "audioQS": "Ps.74,1Kgs.22,Acts.22",
                "note": null
            }
        ]
    },
    "2025/10/15": {
        "datePretty": "Wednesday, October 15, 2025",
        "calendar": "Week of 28th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "2 Chronicles 12",
                        "readQS": "2+Chronicles+12",
                        "audioQS": "2Chr.12"
                    },
                    {
                        "pretty": "Mark 14:26\u201342",
                        "readQS": "Mark+14:26-42",
                        "audioQS": "Mark.14"
                    }
                ],
                "readQS": "Psalm+77%3B+2+Chronicles+12%3B+Mark+14:26-42",
                "audioQS": "Ps.77,2Chr.12,Mark.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:81\u2013104",
                        "readQS": "Psalm+119:81-104",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "1 Kings 22:29\u201345",
                        "readQS": "1+Kings+22:29-45",
                        "audioQS": "1Kgs.22"
                    },
                    {
                        "pretty": "Acts 23:12\u2013end",
                        "readQS": "Acts+23:12-",
                        "audioQS": "Acts.23"
                    }
                ],
                "readQS": "Psalm+119:81-104%3B+1+Kings+22:29-45%3B+Acts+23:12-",
                "audioQS": "Ps.119,1Kgs.22,Acts.23",
                "note": null
            }
        ]
    },
    "2025/10/16": {
        "datePretty": "Thursday, October 16, 2025",
        "calendar": "Week of 28th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 78:1\u201339",
                        "readQS": "Psalm+78:1-39",
                        "audioQS": "Ps.78"
                    },
                    {
                        "pretty": "2 Chronicles 13:1\u201314:1",
                        "readQS": "2+Chronicles+13:1-14:1",
                        "audioQS": "2Chr.13"
                    },
                    {
                        "pretty": "Mark 14:43\u201352",
                        "readQS": "Mark+14:43-52",
                        "audioQS": "Mark.14"
                    }
                ],
                "readQS": "Psalm+78:1-39%3B+2+Chronicles+13:1-14:1%3B+Mark+14:43-52",
                "audioQS": "Ps.78,2Chr.13,Mark.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 78:40\u2013end",
                        "readQS": "Psalm+78:40-",
                        "audioQS": "Ps.78"
                    },
                    {
                        "pretty": "2 Kings 1:2\u201317",
                        "readQS": "2+Kings+1:2-17",
                        "audioQS": "2Kgs.1"
                    },
                    {
                        "pretty": "Acts 24:1\u201323",
                        "readQS": "Acts+24:1-23",
                        "audioQS": "Acts.24"
                    }
                ],
                "readQS": "Psalm+78:40-%3B+2+Kings+1:2-17%3B+Acts+24:1-23",
                "audioQS": "Ps.78,2Kgs.1,Acts.24",
                "note": null
            }
        ]
    },
    "2025/10/17": {
        "datePretty": "Friday, October 17, 2025",
        "calendar": "Week of 28th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 55",
                        "readQS": "Psalm+55",
                        "audioQS": "Ps.55"
                    },
                    {
                        "pretty": "2 Chronicles 14:2\u2013end",
                        "readQS": "2+Chronicles+14:2-",
                        "audioQS": "2Chr.14"
                    },
                    {
                        "pretty": "Mark 14:53\u201365",
                        "readQS": "Mark+14:53-65",
                        "audioQS": "Mark.14"
                    }
                ],
                "readQS": "Psalm+55%3B+2+Chronicles+14:2-%3B+Mark+14:53-65",
                "audioQS": "Ps.55,2Chr.14,Mark.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Luke)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 33",
                        "readQS": "Psalm+33",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "Hosea 6:1\u20133",
                        "readQS": "Hosea+6:1-3",
                        "audioQS": "Hos.6"
                    },
                    {
                        "pretty": "2 Timothy 3:10\u201317",
                        "readQS": "2+Timothy+3:10-17",
                        "audioQS": "2Tim.3"
                    }
                ],
                "readQS": "Psalm+33%3B+Hosea+6:1-3%3B+2+Timothy+3:10-17",
                "audioQS": "Ps.33,Hos.6,2Tim.3",
                "note": null
            }
        ]
    },
    "2025/10/18": {
        "datePretty": "Saturday, October 18, 2025",
        "calendar": "St Luke the Evangelist",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 145,146",
                        "readQS": "Psalm+145,146",
                        "audioQS": "Ps.145,146"
                    },
                    {
                        "pretty": "Isaiah 55",
                        "readQS": "Isaiah+55",
                        "audioQS": "Isa.55"
                    },
                    {
                        "pretty": "Luke 1:1\u20134",
                        "readQS": "Luke+1:1-4",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+145,146%3B+Isaiah+55%3B+Luke+1:1-4",
                "audioQS": "Ps.145,146,Isa.55,Luke.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 103",
                        "readQS": "Psalm+103",
                        "audioQS": "Ps.103"
                    },
                    {
                        "pretty": "Isaiah 61:1\u20136",
                        "readQS": "Isaiah+61:1-6",
                        "audioQS": "Isa.61"
                    },
                    {
                        "pretty": "Colossians 4:7\u201318",
                        "readQS": "Colossians+4:7-18",
                        "audioQS": "Col.4"
                    }
                ],
                "readQS": "Psalm+103%3B+Isaiah+61:1-6%3B+Colossians+4:7-18",
                "audioQS": "Ps.103,Isa.61,Col.4",
                "note": null
            }
        ]
    },
    "2025/10/19": {
        "datePretty": "Sunday, October 19, 2025",
        "calendar": "29th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 121",
                        "readQS": "Psalm+121",
                        "audioQS": "Ps.121"
                    },
                    {
                        "pretty": "Genesis 32:22\u201331",
                        "readQS": "Genesis+32:22-31",
                        "audioQS": "Gen.32"
                    },
                    {
                        "pretty": "2 Timothy 3:14\u20134:5",
                        "readQS": "2+Timothy+3:14-4:5",
                        "audioQS": "2Tim.3"
                    },
                    {
                        "pretty": "Luke 18:1\u20138",
                        "readQS": "Luke+18:1-8",
                        "audioQS": "Luke.18"
                    }
                ],
                "readQS": "Psalm+121%3B+Genesis+32:22-31%3B+2+Timothy+3:14-4:5%3B+Luke+18:1-8",
                "audioQS": "Ps.121,Gen.32,2Tim.3,Luke.18",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 119:97\u2013104",
                        "readQS": "Psalm+119:97-104",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Jeremiah 31:27\u201334",
                        "readQS": "Jeremiah+31:27-34",
                        "audioQS": "Jer.31"
                    },
                    {
                        "pretty": "2 Timothy 3:14\u20134:5",
                        "readQS": "2+Timothy+3:14-4:5",
                        "audioQS": "2Tim.3"
                    },
                    {
                        "pretty": "Luke 18:1\u20138",
                        "readQS": "Luke+18:1-8",
                        "audioQS": "Luke.18"
                    }
                ],
                "readQS": "Psalm+119:97-104%3B+Jeremiah+31:27-34%3B+2+Timothy+3:14-4:5%3B+Luke+18:1-8",
                "audioQS": "Ps.119,Jer.31,2Tim.3,Luke.18",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 147",
                        "readQS": "Psalm+147",
                        "audioQS": "Ps.147"
                    },
                    {
                        "pretty": "Isaiah 54:1\u201314",
                        "readQS": "Isaiah+54:1-14",
                        "audioQS": "Isa.54"
                    },
                    {
                        "pretty": "Luke 13:31\u201335",
                        "readQS": "Luke+13:31-35",
                        "audioQS": "Luke.13"
                    }
                ],
                "readQS": "Psalm+147%3B+Isaiah+54:1-14%3B+Luke+13:31-35",
                "audioQS": "Ps.147,Isa.54,Luke.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 149",
                        "readQS": "Psalm+149",
                        "audioQS": "Ps.149"
                    },
                    {
                        "pretty": "Nehemiah 8:9\u201318",
                        "readQS": "Nehemiah+8:9-18",
                        "audioQS": "Neh.8"
                    },
                    {
                        "pretty": "John 16:1\u201311",
                        "readQS": "John+16:1-11",
                        "audioQS": "John.16"
                    }
                ],
                "readQS": "Psalm+149%3B+Nehemiah+8:9-18%3B+John+16:1-11",
                "audioQS": "Ps.149,Neh.8,John.16",
                "note": null
            }
        ]
    },
    "2025/10/20": {
        "datePretty": "Monday, October 20, 2025",
        "calendar": "Week of 29th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "2 Chronicles 17:1\u201312",
                        "readQS": "2+Chronicles+17:1-12",
                        "audioQS": "2Chr.17"
                    },
                    {
                        "pretty": "Mark 15:1\u201315",
                        "readQS": "Mark+15:1-15",
                        "audioQS": "Mark.15"
                    }
                ],
                "readQS": "Psalm+80%3B+2+Chronicles+17:1-12%3B+Mark+15:1-15",
                "audioQS": "Ps.80,2Chr.17,Mark.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 85",
                        "readQS": "Psalm+85",
                        "audioQS": "Ps.85"
                    },
                    {
                        "pretty": "2 Kings 5",
                        "readQS": "2+Kings+5",
                        "audioQS": "2Kgs.5"
                    },
                    {
                        "pretty": "Acts 26:1\u201323",
                        "readQS": "Acts+26:1-23",
                        "audioQS": "Acts.26"
                    }
                ],
                "readQS": "Psalm+85%3B+2+Kings+5%3B+Acts+26:1-23",
                "audioQS": "Ps.85,2Kgs.5,Acts.26",
                "note": null
            }
        ]
    },
    "2025/10/21": {
        "datePretty": "Tuesday, October 21, 2025",
        "calendar": "Week of 29th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 89:1\u201318",
                        "readQS": "Psalm+89:1-18",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "2 Chronicles 18:1\u201327",
                        "readQS": "2+Chronicles+18:1-27",
                        "audioQS": "2Chr.18"
                    },
                    {
                        "pretty": "Mark 15:16\u201332",
                        "readQS": "Mark+15:16-32",
                        "audioQS": "Mark.15"
                    }
                ],
                "readQS": "Psalm+89:1-18%3B+2+Chronicles+18:1-27%3B+Mark+15:16-32",
                "audioQS": "Ps.89,2Chr.18,Mark.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 89:19\u2013end",
                        "readQS": "Psalm+89:19-",
                        "audioQS": "Ps.89"
                    },
                    {
                        "pretty": "2 Kings 6:1\u201323",
                        "readQS": "2+Kings+6:1-23",
                        "audioQS": "2Kgs.6"
                    },
                    {
                        "pretty": "Acts 26:24\u2013end",
                        "readQS": "Acts+26:24-",
                        "audioQS": "Acts.26"
                    }
                ],
                "readQS": "Psalm+89:19-%3B+2+Kings+6:1-23%3B+Acts+26:24-",
                "audioQS": "Ps.89,2Kgs.6,Acts.26",
                "note": null
            }
        ]
    },
    "2025/10/22": {
        "datePretty": "Wednesday, October 22, 2025",
        "calendar": "Week of 29th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:105\u2013128",
                        "readQS": "Psalm+119:105-128",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "2 Chronicles 18:28\u2013end",
                        "readQS": "2+Chronicles+18:28-",
                        "audioQS": "2Chr.18"
                    },
                    {
                        "pretty": "2 Chronicles 19",
                        "readQS": "2+Chronicles+19",
                        "audioQS": "2Chr.19"
                    },
                    {
                        "pretty": "Mark 15:33\u201341",
                        "readQS": "Mark+15:33-41",
                        "audioQS": "Mark.15"
                    }
                ],
                "readQS": "Psalm+119:105-128%3B+2+Chronicles+18:28-%3B+2+Chronicles+19%3B+Mark+15:33-41",
                "audioQS": "Ps.119,2Chr.18,2Chr.19,Mark.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 91",
                        "readQS": "Psalm+91",
                        "audioQS": "Ps.91"
                    },
                    {
                        "pretty": "2 Kings 9:1\u201316",
                        "readQS": "2+Kings+9:1-16",
                        "audioQS": "2Kgs.9"
                    },
                    {
                        "pretty": "Acts 27:1\u201326",
                        "readQS": "Acts+27:1-26",
                        "audioQS": "Acts.27"
                    }
                ],
                "readQS": "Psalm+91%3B+2+Kings+9:1-16%3B+Acts+27:1-26",
                "audioQS": "Ps.91,2Kgs.9,Acts.27",
                "note": null
            }
        ]
    },
    "2025/10/23": {
        "datePretty": "Thursday, October 23, 2025",
        "calendar": "Week of 29th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 92",
                        "readQS": "Psalm+92",
                        "audioQS": "Ps.92"
                    },
                    {
                        "pretty": "2 Chronicles 20:1\u201323",
                        "readQS": "2+Chronicles+20:1-23",
                        "audioQS": "2Chr.20"
                    },
                    {
                        "pretty": "Mark 15:42\u2013end",
                        "readQS": "Mark+15:42-",
                        "audioQS": "Mark.15"
                    }
                ],
                "readQS": "Psalm+92%3B+2+Chronicles+20:1-23%3B+Mark+15:42-",
                "audioQS": "Ps.92,2Chr.20,Mark.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 94",
                        "readQS": "Psalm+94",
                        "audioQS": "Ps.94"
                    },
                    {
                        "pretty": "2 Kings 9:17\u2013end",
                        "readQS": "2+Kings+9:17-",
                        "audioQS": "2Kgs.9"
                    },
                    {
                        "pretty": "Acts 27:27\u2013end",
                        "readQS": "Acts+27:27-",
                        "audioQS": "Acts.27"
                    }
                ],
                "readQS": "Psalm+94%3B+2+Kings+9:17-%3B+Acts+27:27-",
                "audioQS": "Ps.94,2Kgs.9,Acts.27",
                "note": null
            }
        ]
    },
    "2025/10/24": {
        "datePretty": "Friday, October 24, 2025",
        "calendar": "Week of 29th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 88",
                        "readQS": "Psalm+88",
                        "audioQS": "Ps.88"
                    },
                    {
                        "pretty": "2 Chronicles 22:10\u2013end",
                        "readQS": "2+Chronicles+22:10-",
                        "audioQS": "2Chr.22"
                    },
                    {
                        "pretty": "2 Chronicles 23",
                        "readQS": "2+Chronicles+23",
                        "audioQS": "2Chr.23"
                    },
                    {
                        "pretty": "Mark 16:1\u20138",
                        "readQS": "Mark+16:1-8",
                        "audioQS": "Mark.16"
                    }
                ],
                "readQS": "Psalm+88%3B+2+Chronicles+22:10-%3B+2+Chronicles+23%3B+Mark+16:1-8",
                "audioQS": "Ps.88,2Chr.22,2Chr.23,Mark.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 102",
                        "readQS": "Psalm+102",
                        "audioQS": "Ps.102"
                    },
                    {
                        "pretty": "2 Kings 12:1\u201319",
                        "readQS": "2+Kings+12:1-19",
                        "audioQS": "2Kgs.12"
                    },
                    {
                        "pretty": "Acts 28:1\u201316",
                        "readQS": "Acts+28:1-16",
                        "audioQS": "Acts.28"
                    }
                ],
                "readQS": "Psalm+102%3B+2+Kings+12:1-19%3B+Acts+28:1-16",
                "audioQS": "Ps.102,2Kgs.12,Acts.28",
                "note": null
            }
        ]
    },
    "2025/10/25": {
        "datePretty": "Saturday, October 25, 2025",
        "calendar": "Week of 29th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "2 Chronicles 24:1\u201322",
                        "readQS": "2+Chronicles+24:1-22",
                        "audioQS": "2Chr.24"
                    },
                    {
                        "pretty": "Mark 16:9\u2013end",
                        "readQS": "Mark+16:9-",
                        "audioQS": "Mark.16"
                    }
                ],
                "readQS": "Psalm+97%3B+2+Chronicles+24:1-22%3B+Mark+16:9-",
                "audioQS": "Ps.97,2Chr.24,Mark.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 104",
                        "readQS": "Psalm+104",
                        "audioQS": "Ps.104"
                    },
                    {
                        "pretty": "2 Kings 17:1\u201323",
                        "readQS": "2+Kings+17:1-23",
                        "audioQS": "2Kgs.17"
                    },
                    {
                        "pretty": "Acts 28:17\u2013end",
                        "readQS": "Acts+28:17-",
                        "audioQS": "Acts.28"
                    }
                ],
                "readQS": "Psalm+104%3B+2+Kings+17:1-23%3B+Acts+28:17-",
                "audioQS": "Ps.104,2Kgs.17,Acts.28",
                "note": null
            }
        ]
    },
    "2025/10/26": {
        "datePretty": "Sunday, October 26, 2025",
        "calendar": "30th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 84:1\u20137",
                        "readQS": "Psalm+84:1-7",
                        "audioQS": "Ps.84"
                    },
                    {
                        "pretty": "Jeremiah 14:7\u201310,19\u201322",
                        "readQS": "Jeremiah+14:7-10,19-22",
                        "audioQS": "Jer.14"
                    },
                    {
                        "pretty": "2 Timothy 4:6\u20138,16\u201318",
                        "readQS": "2+Timothy+4:6-8,16-18",
                        "audioQS": "2Tim.4"
                    },
                    {
                        "pretty": "Luke 18:9\u201314",
                        "readQS": "Luke+18:9-14",
                        "audioQS": "Luke.18"
                    }
                ],
                "readQS": "Psalm+84:1-7%3B+Jeremiah+14:7-10,19-22%3B+2+Timothy+4:6-8,16-18%3B+Luke+18:9-14",
                "audioQS": "Ps.84,Jer.14,2Tim.4,Luke.18",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 65",
                        "readQS": "Psalm+65",
                        "audioQS": "Ps.65"
                    },
                    {
                        "pretty": "Joel 2:23\u201332",
                        "readQS": "Joel+2:23-32",
                        "audioQS": "Joel.2"
                    },
                    {
                        "pretty": "2 Timothy 4:6\u20138,16\u201318",
                        "readQS": "2+Timothy+4:6-8,16-18",
                        "audioQS": "2Tim.4"
                    },
                    {
                        "pretty": "Luke 18:9\u201314",
                        "readQS": "Luke+18:9-14",
                        "audioQS": "Luke.18"
                    }
                ],
                "readQS": "Psalm+65%3B+Joel+2:23-32%3B+2+Timothy+4:6-8,16-18%3B+Luke+18:9-14",
                "audioQS": "Ps.65,Joel.2,2Tim.4,Luke.18",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 119:105\u2013128",
                        "readQS": "Psalm+119:105-128",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Isaiah 59:9\u201320",
                        "readQS": "Isaiah+59:9-20",
                        "audioQS": "Isa.59"
                    },
                    {
                        "pretty": "Luke 14:1\u201314",
                        "readQS": "Luke+14:1-14",
                        "audioQS": "Luke.14"
                    }
                ],
                "readQS": "Psalm+119:105-128%3B+Isaiah+59:9-20%3B+Luke+14:1-14",
                "audioQS": "Ps.119,Isa.59,Luke.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:1\u201316",
                        "readQS": "Psalm+119:1-16",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Ecclesiastes 11,12",
                        "readQS": "Ecclesiastes+11,12",
                        "audioQS": "Eccl.11,12"
                    },
                    {
                        "pretty": "2 Timothy 2:1\u20137",
                        "readQS": "2+Timothy+2:1-7",
                        "audioQS": "2Tim.2"
                    }
                ],
                "readQS": "Psalm+119:1-16%3B+Ecclesiastes+11,12%3B+2+Timothy+2:1-7",
                "audioQS": "Ps.119,Eccl.11,12,2Tim.2",
                "note": null
            }
        ]
    },
    "2025/10/27": {
        "datePretty": "Monday, October 27, 2025",
        "calendar": "Week of 30th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 98",
                        "readQS": "Psalm+98",
                        "audioQS": "Ps.98"
                    },
                    {
                        "pretty": "2 Chronicles 26:1\u201321",
                        "readQS": "2+Chronicles+26:1-21",
                        "audioQS": "2Chr.26"
                    },
                    {
                        "pretty": "John 13:1\u201311",
                        "readQS": "John+13:1-11",
                        "audioQS": "John.13"
                    }
                ],
                "readQS": "Psalm+98%3B+2+Chronicles+26:1-21%3B+John+13:1-11",
                "audioQS": "Ps.98,2Chr.26,John.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Simon and St Jude)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 124,125,126",
                        "readQS": "Psalm+124,125,126",
                        "audioQS": "Ps.124,125,126"
                    },
                    {
                        "pretty": "Deuteronomy 32:1\u20134",
                        "readQS": "Deuteronomy+32:1-4",
                        "audioQS": "Deut.32"
                    },
                    {
                        "pretty": "John 14:15\u201326",
                        "readQS": "John+14:15-26",
                        "audioQS": "John.14"
                    }
                ],
                "readQS": "Psalm+124,125,126%3B+Deuteronomy+32:1-4%3B+John+14:15-26",
                "audioQS": "Ps.124,125,126,Deut.32,John.14",
                "note": null
            }
        ]
    },
    "2025/10/28": {
        "datePretty": "Tuesday, October 28, 2025",
        "calendar": "St Simon and St Jude, Apostles",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 116,117",
                        "readQS": "Psalm+116,117",
                        "audioQS": "Ps.116,117"
                    },
                    {
                        "pretty": "Isaiah 45:18\u201326",
                        "readQS": "Isaiah+45:18-26",
                        "audioQS": "Isa.45"
                    },
                    {
                        "pretty": "Luke 6:12\u201316",
                        "readQS": "Luke+6:12-16",
                        "audioQS": "Luke.6"
                    }
                ],
                "readQS": "Psalm+116,117%3B+Isaiah+45:18-26%3B+Luke+6:12-16",
                "audioQS": "Ps.116,117,Isa.45,Luke.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:1\u201316",
                        "readQS": "Psalm+119:1-16",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Jeremiah 3:11\u201318",
                        "readQS": "Jeremiah+3:11-18",
                        "audioQS": "Jer.3"
                    },
                    {
                        "pretty": "Jude 1\u20134,17\u201325",
                        "readQS": "Jude+1-4,17-25",
                        "audioQS": "Jude.1\u20134,17\u201325"
                    }
                ],
                "readQS": "Psalm+119:1-16%3B+Jeremiah+3:11-18%3B+Jude+1-4,17-25",
                "audioQS": "Ps.119,Jer.3,Jude.1\u20134,17\u201325",
                "note": null
            }
        ]
    },
    "2025/10/29": {
        "datePretty": "Wednesday, October 29, 2025",
        "calendar": "Week of 30th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 111",
                        "readQS": "Psalm+111",
                        "audioQS": "Ps.111"
                    },
                    {
                        "pretty": "2 Chronicles 29:1\u201319",
                        "readQS": "2+Chronicles+29:1-19",
                        "audioQS": "2Chr.29"
                    },
                    {
                        "pretty": "John 13:21\u201330",
                        "readQS": "John+13:21-30",
                        "audioQS": "John.13"
                    }
                ],
                "readQS": "Psalm+111%3B+2+Chronicles+29:1-19%3B+John+13:21-30",
                "audioQS": "Ps.111,2Chr.29,John.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 119:129\u2013152",
                        "readQS": "Psalm+119:129-152",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "2 Kings 18:13\u2013end",
                        "readQS": "2+Kings+18:13-",
                        "audioQS": "2Kgs.18"
                    },
                    {
                        "pretty": "Philippians 2:1\u201313",
                        "readQS": "Philippians+2:1-13",
                        "audioQS": "Phil.2"
                    }
                ],
                "readQS": "Psalm+119:129-152%3B+2+Kings+18:13-%3B+Philippians+2:1-13",
                "audioQS": "Ps.119,2Kgs.18,Phil.2",
                "note": null
            }
        ]
    },
    "2025/10/30": {
        "datePretty": "Thursday, October 30, 2025",
        "calendar": "Week of 30th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 115",
                        "readQS": "Psalm+115",
                        "audioQS": "Ps.115"
                    },
                    {
                        "pretty": "2 Chronicles 29:20\u2013end",
                        "readQS": "2+Chronicles+29:20-",
                        "audioQS": "2Chr.29"
                    },
                    {
                        "pretty": "John 13:31\u2013end",
                        "readQS": "John+13:31-",
                        "audioQS": "John.13"
                    }
                ],
                "readQS": "Psalm+115%3B+2+Chronicles+29:20-%3B+John+13:31-",
                "audioQS": "Ps.115,2Chr.29,John.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 116",
                        "readQS": "Psalm+116",
                        "audioQS": "Ps.116"
                    },
                    {
                        "pretty": "2 Kings 19:1\u201319",
                        "readQS": "2+Kings+19:1-19",
                        "audioQS": "2Kgs.19"
                    },
                    {
                        "pretty": "Philippians 2:14\u2013end",
                        "readQS": "Philippians+2:14-",
                        "audioQS": "Phil.2"
                    }
                ],
                "readQS": "Psalm+116%3B+2+Kings+19:1-19%3B+Philippians+2:14-",
                "audioQS": "Ps.116,2Kgs.19,Phil.2",
                "note": null
            }
        ]
    },
    "2025/10/31": {
        "datePretty": "Friday, October 31, 2025",
        "calendar": "Week of 30th Sunday in Ordinary Time",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 139",
                        "readQS": "Psalm+139",
                        "audioQS": "Ps.139"
                    },
                    {
                        "pretty": "2 Chronicles 30",
                        "readQS": "2+Chronicles+30",
                        "audioQS": "2Chr.30"
                    },
                    {
                        "pretty": "John 14:1\u201314",
                        "readQS": "John+14:1-14",
                        "audioQS": "John.14"
                    }
                ],
                "readQS": "Psalm+139%3B+2+Chronicles+30%3B+John+14:1-14",
                "audioQS": "Ps.139,2Chr.30,John.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of All Saints Day)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 1,5",
                        "readQS": "Psalm+1,5",
                        "audioQS": "Ps.1,5"
                    },
                    {
                        "pretty": "Isaiah 40:27\u2013end",
                        "readQS": "Isaiah+40:27-",
                        "audioQS": "Isa.40"
                    },
                    {
                        "pretty": "Revelations 19:6\u201310",
                        "readQS": "Revelations+19:6-10",
                        "audioQS": "Revs.19"
                    }
                ],
                "readQS": "Psalm+1,5%3B+Isaiah+40:27-%3B+Revelations+19:6-10",
                "audioQS": "Ps.1,5,Isa.40,Revs.19",
                "note": null
            }
        ]
    },
    "2025/11/01": {
        "datePretty": "Saturday, November 1, 2025",
        "calendar": "All Saints Day",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 33:1\u20135",
                        "readQS": "Psalm+33:1-5",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "Isaiah 56:3\u20138",
                        "readQS": "Isaiah+56:3-8",
                        "audioQS": "Isa.56"
                    },
                    {
                        "pretty": "Hebrews 12:18\u201324",
                        "readQS": "Hebrews+12:18-24",
                        "audioQS": "Heb.12"
                    },
                    {
                        "pretty": "Matthew 5:1\u201312",
                        "readQS": "Matthew+5:1-12",
                        "audioQS": "Matt.5"
                    }
                ],
                "readQS": "Psalm+33:1-5%3B+Isaiah+56:3-8%3B+Hebrews+12:18-24%3B+Matthew+5:1-12",
                "audioQS": "Ps.33,Isa.56,Heb.12,Matt.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 15,84,149",
                        "readQS": "Psalm+15,84,149",
                        "audioQS": "Ps.15,84,149"
                    },
                    {
                        "pretty": "Isaiah 35",
                        "readQS": "Isaiah+35",
                        "audioQS": "Isa.35"
                    },
                    {
                        "pretty": "Luke 9:18\u201327",
                        "readQS": "Luke+9:18-27",
                        "audioQS": "Luke.9"
                    }
                ],
                "readQS": "Psalm+15,84,149%3B+Isaiah+35%3B+Luke+9:18-27",
                "audioQS": "Ps.15,84,149,Isa.35,Luke.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 148,150",
                        "readQS": "Psalm+148,150",
                        "audioQS": "Ps.148,150"
                    },
                    {
                        "pretty": "Isaiah 65:17\u201325",
                        "readQS": "Isaiah+65:17-25",
                        "audioQS": "Isa.65"
                    },
                    {
                        "pretty": "Hebrews 11:32\u201312:2",
                        "readQS": "Hebrews+11:32-12:2",
                        "audioQS": "Heb.11"
                    }
                ],
                "readQS": "Psalm+148,150%3B+Isaiah+65:17-25%3B+Hebrews+11:32-12:2",
                "audioQS": "Ps.148,150,Isa.65,Heb.11",
                "note": null
            }
        ]
    },
    "2025/11/02": {
        "datePretty": "Sunday, November 2, 2025",
        "calendar": "4th Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 32:1\u20137",
                        "readQS": "Psalm+32:1-7",
                        "audioQS": "Ps.32"
                    },
                    {
                        "pretty": "Isaiah 1:10\u201318",
                        "readQS": "Isaiah+1:10-18",
                        "audioQS": "Isa.1"
                    },
                    {
                        "pretty": "2 Thessalonians 1:1\u20134,11\u201312",
                        "readQS": "2+Thessalonians+1:1-4,11-12",
                        "audioQS": "2Thess.1"
                    },
                    {
                        "pretty": "Luke 19:1\u201310",
                        "readQS": "Luke+19:1-10",
                        "audioQS": "Luke.19"
                    }
                ],
                "readQS": "Psalm+32:1-7%3B+Isaiah+1:10-18%3B+2+Thessalonians+1:1-4,11-12%3B+Luke+19:1-10",
                "audioQS": "Ps.32,Isa.1,2Thess.1,Luke.19",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 119:137\u2013144",
                        "readQS": "Psalm+119:137-144",
                        "audioQS": "Ps.119"
                    },
                    {
                        "pretty": "Habakkuk 1:1\u20134,2:1\u20134",
                        "readQS": "Habakkuk+1:1-4,2:1-4",
                        "audioQS": "Hab.1"
                    },
                    {
                        "pretty": "2 Thessalonians 1:1\u20134,11\u201312",
                        "readQS": "2+Thessalonians+1:1-4,11-12",
                        "audioQS": "2Thess.1"
                    },
                    {
                        "pretty": "Luke 19:1\u201310",
                        "readQS": "Luke+19:1-10",
                        "audioQS": "Luke.19"
                    }
                ],
                "readQS": "Psalm+119:137-144%3B+Habakkuk+1:1-4,2:1-4%3B+2+Thessalonians+1:1-4,11-12%3B+Luke+19:1-10",
                "audioQS": "Ps.119,Hab.1,2Thess.1,Luke.19",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 87",
                        "readQS": "Psalm+87",
                        "audioQS": "Ps.87"
                    },
                    {
                        "pretty": "Job 26",
                        "readQS": "Job+26",
                        "audioQS": "Job.26"
                    },
                    {
                        "pretty": "Colossians 1:9\u201314",
                        "readQS": "Colossians+1:9-14",
                        "audioQS": "Col.1"
                    }
                ],
                "readQS": "Psalm+87%3B+Job+26%3B+Colossians+1:9-14",
                "audioQS": "Ps.87,Job.26,Col.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Lamentations 3:22\u201333",
                        "readQS": "Lamentations+3:22-33",
                        "audioQS": "Lam.3"
                    },
                    {
                        "pretty": "John 11:(1\u201331),32\u201344",
                        "readQS": "John+11:%281-31%29,32-44",
                        "audioQS": "John.11"
                    }
                ],
                "readQS": "Psalm+145%3B+Lamentations+3:22-33%3B+John+11:%281-31%29,32-44",
                "audioQS": "Ps.145,Lam.3,John.11",
                "note": null
            }
        ]
    },
    "2025/11/03": {
        "datePretty": "Monday, November 3, 2025",
        "calendar": "Week of 4th Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 2",
                        "readQS": "Psalm+2",
                        "audioQS": "Ps.2"
                    },
                    {
                        "pretty": "Isaiah 1:1\u201320",
                        "readQS": "Isaiah+1:1-20",
                        "audioQS": "Isa.1"
                    },
                    {
                        "pretty": "Matthew 1:18\u2013end",
                        "readQS": "Matthew+1:18-",
                        "audioQS": "Matt.1"
                    }
                ],
                "readQS": "Psalm+2%3B+Isaiah+1:1-20%3B+Matthew+1:18-",
                "audioQS": "Ps.2,Isa.1,Matt.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 92",
                        "readQS": "Psalm+92",
                        "audioQS": "Ps.92"
                    },
                    {
                        "pretty": "Daniel 1",
                        "readQS": "Daniel+1",
                        "audioQS": "Dan.1"
                    },
                    {
                        "pretty": "Revelation 1",
                        "readQS": "Revelation+1",
                        "audioQS": "Rev.1"
                    }
                ],
                "readQS": "Psalm+92%3B+Daniel+1%3B+Revelation+1",
                "audioQS": "Ps.92,Dan.1,Rev.1",
                "note": null
            }
        ]
    },
    "2025/11/04": {
        "datePretty": "Tuesday, November 4, 2025",
        "calendar": "Week of 4th Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 5",
                        "readQS": "Psalm+5",
                        "audioQS": "Ps.5"
                    },
                    {
                        "pretty": "Isaiah 1:21\u2013end",
                        "readQS": "Isaiah+1:21-",
                        "audioQS": "Isa.1"
                    },
                    {
                        "pretty": "Matthew 2:1\u201315",
                        "readQS": "Matthew+2:1-15",
                        "audioQS": "Matt.2"
                    }
                ],
                "readQS": "Psalm+5%3B+Isaiah+1:21-%3B+Matthew+2:1-15",
                "audioQS": "Ps.5,Isa.1,Matt.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 100",
                        "readQS": "Psalm+100",
                        "audioQS": "Ps.100"
                    },
                    {
                        "pretty": "Daniel 2:1\u201324",
                        "readQS": "Daniel+2:1-24",
                        "audioQS": "Dan.2"
                    },
                    {
                        "pretty": "Revelation 2:1\u201311",
                        "readQS": "Revelation+2:1-11",
                        "audioQS": "Rev.2"
                    }
                ],
                "readQS": "Psalm+100%3B+Daniel+2:1-24%3B+Revelation+2:1-11",
                "audioQS": "Ps.100,Dan.2,Rev.2",
                "note": null
            }
        ]
    },
    "2025/11/05": {
        "datePretty": "Wednesday, November 5, 2025",
        "calendar": "Week of 4th Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 9",
                        "readQS": "Psalm+9",
                        "audioQS": "Ps.9"
                    },
                    {
                        "pretty": "Isaiah 2:1\u201311",
                        "readQS": "Isaiah+2:1-11",
                        "audioQS": "Isa.2"
                    },
                    {
                        "pretty": "Matthew 2:16\u2013end",
                        "readQS": "Matthew+2:16-",
                        "audioQS": "Matt.2"
                    }
                ],
                "readQS": "Psalm+9%3B+Isaiah+2:1-11%3B+Matthew+2:16-",
                "audioQS": "Ps.9,Isa.2,Matt.2",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 112",
                        "readQS": "Psalm+112",
                        "audioQS": "Ps.112"
                    },
                    {
                        "pretty": "Daniel 2:25\u2013end",
                        "readQS": "Daniel+2:25-",
                        "audioQS": "Dan.2"
                    },
                    {
                        "pretty": "Revelation 2:12\u2013end",
                        "readQS": "Revelation+2:12-",
                        "audioQS": "Rev.2"
                    }
                ],
                "readQS": "Psalm+112%3B+Daniel+2:25-%3B+Revelation+2:12-",
                "audioQS": "Ps.112,Dan.2,Rev.2",
                "note": null
            }
        ]
    },
    "2025/11/06": {
        "datePretty": "Thursday, November 6, 2025",
        "calendar": "Week of 4th Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 15",
                        "readQS": "Psalm+15",
                        "audioQS": "Ps.15"
                    },
                    {
                        "pretty": "Isaiah 2:12\u2013end",
                        "readQS": "Isaiah+2:12-",
                        "audioQS": "Isa.2"
                    },
                    {
                        "pretty": "Matthew 3",
                        "readQS": "Matthew+3",
                        "audioQS": "Matt.3"
                    }
                ],
                "readQS": "Psalm+15%3B+Isaiah+2:12-%3B+Matthew+3",
                "audioQS": "Ps.15,Isa.2,Matt.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 118",
                        "readQS": "Psalm+118",
                        "audioQS": "Ps.118"
                    },
                    {
                        "pretty": "Daniel 3:1\u201318",
                        "readQS": "Daniel+3:1-18",
                        "audioQS": "Dan.3"
                    },
                    {
                        "pretty": "Revelation 3:1\u201313",
                        "readQS": "Revelation+3:1-13",
                        "audioQS": "Rev.3"
                    }
                ],
                "readQS": "Psalm+118%3B+Daniel+3:1-18%3B+Revelation+3:1-13",
                "audioQS": "Ps.118,Dan.3,Rev.3",
                "note": null
            }
        ]
    },
    "2025/11/07": {
        "datePretty": "Friday, November 7, 2025",
        "calendar": "Week of 4th Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 16",
                        "readQS": "Psalm+16",
                        "audioQS": "Ps.16"
                    },
                    {
                        "pretty": "Isaiah 3:1\u201315",
                        "readQS": "Isaiah+3:1-15",
                        "audioQS": "Isa.3"
                    },
                    {
                        "pretty": "Matthew 4:1\u201311",
                        "readQS": "Matthew+4:1-11",
                        "audioQS": "Matt.4"
                    }
                ],
                "readQS": "Psalm+16%3B+Isaiah+3:1-15%3B+Matthew+4:1-11",
                "audioQS": "Ps.16,Isa.3,Matt.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 143",
                        "readQS": "Psalm+143",
                        "audioQS": "Ps.143"
                    },
                    {
                        "pretty": "Daniel 3:19\u2013end",
                        "readQS": "Daniel+3:19-",
                        "audioQS": "Dan.3"
                    },
                    {
                        "pretty": "Revelation 3:14\u2013end",
                        "readQS": "Revelation+3:14-",
                        "audioQS": "Rev.3"
                    }
                ],
                "readQS": "Psalm+143%3B+Daniel+3:19-%3B+Revelation+3:14-",
                "audioQS": "Ps.143,Dan.3,Rev.3",
                "note": null
            }
        ]
    },
    "2025/11/08": {
        "datePretty": "Saturday, November 8, 2025",
        "calendar": "Week of 4th Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 18.31\u2013end",
                        "readQS": "Psalm+18.31-",
                        "audioQS": "Ps.18.31\u2013end"
                    },
                    {
                        "pretty": "Isaiah 4:2\u20135:7",
                        "readQS": "Isaiah+4:2-5:7",
                        "audioQS": "Isa.4"
                    },
                    {
                        "pretty": "Matthew 4:12\u201322",
                        "readQS": "Matthew+4:12-22",
                        "audioQS": "Matt.4"
                    }
                ],
                "readQS": "Psalm+18.31-%3B+Isaiah+4:2-5:7%3B+Matthew+4:12-22",
                "audioQS": "Ps.18.31\u2013end,Isa.4,Matt.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Daniel 4:1\u201318",
                        "readQS": "Daniel+4:1-18",
                        "audioQS": "Dan.4"
                    },
                    {
                        "pretty": "Revelation 4",
                        "readQS": "Revelation+4",
                        "audioQS": "Rev.4"
                    }
                ],
                "readQS": "Psalm+145%3B+Daniel+4:1-18%3B+Revelation+4",
                "audioQS": "Ps.145,Dan.4,Rev.4",
                "note": null
            }
        ]
    },
    "2025/11/09": {
        "datePretty": "Sunday, November 9, 2025",
        "calendar": "3rd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 17:1\u20139",
                        "readQS": "Psalm+17:1-9",
                        "audioQS": "Ps.17"
                    },
                    {
                        "pretty": "Job 19:23\u201327a",
                        "readQS": "Job+19:23-27a",
                        "audioQS": "Job.19"
                    },
                    {
                        "pretty": "2 Thessalonians 2:1\u20135,13\u201317",
                        "readQS": "2+Thessalonians+2:1-5,13-17",
                        "audioQS": "2Thess.2"
                    },
                    {
                        "pretty": "Luke 20:27\u201338",
                        "readQS": "Luke+20:27-38",
                        "audioQS": "Luke.20"
                    }
                ],
                "readQS": "Psalm+17:1-9%3B+Job+19:23-27a%3B+2+Thessalonians+2:1-5,13-17%3B+Luke+20:27-38",
                "audioQS": "Ps.17,Job.19,2Thess.2,Luke.20",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 145:1\u20135,16\u201320",
                        "readQS": "Psalm+145:1-5,16-20",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Haggai 1:15b\u20132:9",
                        "readQS": "Haggai+1:15b-2:9",
                        "audioQS": "Hag.1"
                    },
                    {
                        "pretty": "2 Thessalonians 2:1\u20135,13\u201317",
                        "readQS": "2+Thessalonians+2:1-5,13-17",
                        "audioQS": "2Thess.2"
                    },
                    {
                        "pretty": "Luke 20:27\u201338",
                        "readQS": "Luke+20:27-38",
                        "audioQS": "Luke.20"
                    }
                ],
                "readQS": "Psalm+145:1-5,16-20%3B+Haggai+1:15b-2:9%3B+2+Thessalonians+2:1-5,13-17%3B+Luke+20:27-38",
                "audioQS": "Ps.145,Hag.1,2Thess.2,Luke.20",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 20,90",
                        "readQS": "Psalm+20,90",
                        "audioQS": "Ps.20,90"
                    },
                    {
                        "pretty": "Isaiah 2:1\u20135",
                        "readQS": "Isaiah+2:1-5",
                        "audioQS": "Isa.2"
                    },
                    {
                        "pretty": "James 3:13\u201318",
                        "readQS": "James+3:13-18",
                        "audioQS": "Jas.3"
                    }
                ],
                "readQS": "Psalm+20,90%3B+Isaiah+2:1-5%3B+James+3:13-18",
                "audioQS": "Ps.20,90,Isa.2,Jas.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "1 Kings 3:1\u201315",
                        "readQS": "1+Kings+3:1-15",
                        "audioQS": "1Kgs.3"
                    },
                    {
                        "pretty": "Romans 8:31\u201339",
                        "readQS": "Romans+8:31-39",
                        "audioQS": "Rom.8"
                    }
                ],
                "readQS": "Psalm+40%3B+1+Kings+3:1-15%3B+Romans+8:31-39",
                "audioQS": "Ps.40,1Kgs.3,Rom.8",
                "note": null
            }
        ]
    },
    "2025/11/10": {
        "datePretty": "Monday, November 10, 2025",
        "calendar": "Week of 3rd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 20",
                        "readQS": "Psalm+20",
                        "audioQS": "Ps.20"
                    },
                    {
                        "pretty": "Isaiah 5:8\u201324",
                        "readQS": "Isaiah+5:8-24",
                        "audioQS": "Isa.5"
                    },
                    {
                        "pretty": "Matthew 4:23\u20135:12",
                        "readQS": "Matthew+4:23-5:12",
                        "audioQS": "Matt.4"
                    }
                ],
                "readQS": "Psalm+20%3B+Isaiah+5:8-24%3B+Matthew+4:23-5:12",
                "audioQS": "Ps.20,Isa.5,Matt.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 34",
                        "readQS": "Psalm+34",
                        "audioQS": "Ps.34"
                    },
                    {
                        "pretty": "Daniel 4:19\u2013end",
                        "readQS": "Daniel+4:19-",
                        "audioQS": "Dan.4"
                    },
                    {
                        "pretty": "Revelation 5",
                        "readQS": "Revelation+5",
                        "audioQS": "Rev.5"
                    }
                ],
                "readQS": "Psalm+34%3B+Daniel+4:19-%3B+Revelation+5",
                "audioQS": "Ps.34,Dan.4,Rev.5",
                "note": null
            }
        ]
    },
    "2025/11/11": {
        "datePretty": "Tuesday, November 11, 2025",
        "calendar": "Week of 3rd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 21",
                        "readQS": "Psalm+21",
                        "audioQS": "Ps.21"
                    },
                    {
                        "pretty": "Isaiah 5:25\u2013end",
                        "readQS": "Isaiah+5:25-",
                        "audioQS": "Isa.5"
                    },
                    {
                        "pretty": "Matthew 5:13\u201320",
                        "readQS": "Matthew+5:13-20",
                        "audioQS": "Matt.5"
                    }
                ],
                "readQS": "Psalm+21%3B+Isaiah+5:25-%3B+Matthew+5:13-20",
                "audioQS": "Ps.21,Isa.5,Matt.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Daniel 5:1\u201312",
                        "readQS": "Daniel+5:1-12",
                        "audioQS": "Dan.5"
                    },
                    {
                        "pretty": "Revelation 6",
                        "readQS": "Revelation+6",
                        "audioQS": "Rev.6"
                    }
                ],
                "readQS": "Psalm+40%3B+Daniel+5:1-12%3B+Revelation+6",
                "audioQS": "Ps.40,Dan.5,Rev.6",
                "note": null
            }
        ]
    },
    "2025/11/12": {
        "datePretty": "Wednesday, November 12, 2025",
        "calendar": "Week of 3rd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 23",
                        "readQS": "Psalm+23",
                        "audioQS": "Ps.23"
                    },
                    {
                        "pretty": "Isaiah 6",
                        "readQS": "Isaiah+6",
                        "audioQS": "Isa.6"
                    },
                    {
                        "pretty": "Matthew 5:21\u201337",
                        "readQS": "Matthew+5:21-37",
                        "audioQS": "Matt.5"
                    }
                ],
                "readQS": "Psalm+23%3B+Isaiah+6%3B+Matthew+5:21-37",
                "audioQS": "Ps.23,Isa.6,Matt.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 37",
                        "readQS": "Psalm+37",
                        "audioQS": "Ps.37"
                    },
                    {
                        "pretty": "Daniel 5:13\u2013end",
                        "readQS": "Daniel+5:13-",
                        "audioQS": "Dan.5"
                    },
                    {
                        "pretty": "Revelation 7:1\u20134,9\u2013end",
                        "readQS": "Revelation+7:1-4,9-",
                        "audioQS": "Rev.7"
                    }
                ],
                "readQS": "Psalm+37%3B+Daniel+5:13-%3B+Revelation+7:1-4,9-",
                "audioQS": "Ps.37,Dan.5,Rev.7",
                "note": null
            }
        ]
    },
    "2025/11/13": {
        "datePretty": "Thursday, November 13, 2025",
        "calendar": "Week of 3rd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 26",
                        "readQS": "Psalm+26",
                        "audioQS": "Ps.26"
                    },
                    {
                        "pretty": "Isaiah 7:1\u201317",
                        "readQS": "Isaiah+7:1-17",
                        "audioQS": "Isa.7"
                    },
                    {
                        "pretty": "Matthew 5:38\u2013end",
                        "readQS": "Matthew+5:38-",
                        "audioQS": "Matt.5"
                    }
                ],
                "readQS": "Psalm+26%3B+Isaiah+7:1-17%3B+Matthew+5:38-",
                "audioQS": "Ps.26,Isa.7,Matt.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 43",
                        "readQS": "Psalm+43",
                        "audioQS": "Ps.43"
                    },
                    {
                        "pretty": "Daniel 6",
                        "readQS": "Daniel+6",
                        "audioQS": "Dan.6"
                    },
                    {
                        "pretty": "Revelation 8",
                        "readQS": "Revelation+8",
                        "audioQS": "Rev.8"
                    }
                ],
                "readQS": "Psalm+43%3B+Daniel+6%3B+Revelation+8",
                "audioQS": "Ps.43,Dan.6,Rev.8",
                "note": null
            }
        ]
    },
    "2025/11/14": {
        "datePretty": "Friday, November 14, 2025",
        "calendar": "Week of 3rd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 32",
                        "readQS": "Psalm+32",
                        "audioQS": "Ps.32"
                    },
                    {
                        "pretty": "Isaiah 8:1\u201315",
                        "readQS": "Isaiah+8:1-15",
                        "audioQS": "Isa.8"
                    },
                    {
                        "pretty": "Matthew 6:1\u201318",
                        "readQS": "Matthew+6:1-18",
                        "audioQS": "Matt.6"
                    }
                ],
                "readQS": "Psalm+32%3B+Isaiah+8:1-15%3B+Matthew+6:1-18",
                "audioQS": "Ps.32,Isa.8,Matt.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 31",
                        "readQS": "Psalm+31",
                        "audioQS": "Ps.31"
                    },
                    {
                        "pretty": "Daniel 7:1\u201314",
                        "readQS": "Daniel+7:1-14",
                        "audioQS": "Dan.7"
                    },
                    {
                        "pretty": "Revelation 9:1\u201312",
                        "readQS": "Revelation+9:1-12",
                        "audioQS": "Rev.9"
                    }
                ],
                "readQS": "Psalm+31%3B+Daniel+7:1-14%3B+Revelation+9:1-12",
                "audioQS": "Ps.31,Dan.7,Rev.9",
                "note": null
            }
        ]
    },
    "2025/11/15": {
        "datePretty": "Saturday, November 15, 2025",
        "calendar": "Week of 3rd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 33",
                        "readQS": "Psalm+33",
                        "audioQS": "Ps.33"
                    },
                    {
                        "pretty": "Isaiah 8:16\u20139:7",
                        "readQS": "Isaiah+8:16-9:7",
                        "audioQS": "Isa.8"
                    },
                    {
                        "pretty": "Matthew 6:19\u2013end",
                        "readQS": "Matthew+6:19-",
                        "audioQS": "Matt.6"
                    }
                ],
                "readQS": "Psalm+33%3B+Isaiah+8:16-9:7%3B+Matthew+6:19-",
                "audioQS": "Ps.33,Isa.8,Matt.6",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 86",
                        "readQS": "Psalm+86",
                        "audioQS": "Ps.86"
                    },
                    {
                        "pretty": "Daniel 7:15\u2013end",
                        "readQS": "Daniel+7:15-",
                        "audioQS": "Dan.7"
                    },
                    {
                        "pretty": "Revelation 9:13\u2013end",
                        "readQS": "Revelation+9:13-",
                        "audioQS": "Rev.9"
                    }
                ],
                "readQS": "Psalm+86%3B+Daniel+7:15-%3B+Revelation+9:13-",
                "audioQS": "Ps.86,Dan.7,Rev.9",
                "note": null
            }
        ]
    },
    "2025/11/16": {
        "datePretty": "Sunday, November 16, 2025",
        "calendar": "2nd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 98",
                        "readQS": "Psalm+98",
                        "audioQS": "Ps.98"
                    },
                    {
                        "pretty": "Malachi 4:1\u20132a",
                        "readQS": "Malachi+4:1-2a",
                        "audioQS": "Mal.4"
                    },
                    {
                        "pretty": "2 Thessalonians 3:6\u201313",
                        "readQS": "2+Thessalonians+3:6-13",
                        "audioQS": "2Thess.3"
                    },
                    {
                        "pretty": "Luke 21:5\u201319",
                        "readQS": "Luke+21:5-19",
                        "audioQS": "Luke.21"
                    }
                ],
                "readQS": "Psalm+98%3B+Malachi+4:1-2a%3B+2+Thessalonians+3:6-13%3B+Luke+21:5-19",
                "audioQS": "Ps.98,Mal.4,2Thess.3,Luke.21",
                "note": null
            },
            {
                "readingSetTab": "rclTrack2",
                "readingSetTabName": "RCL (Co.)",
                "readingSetSubtitle": "Revised Common Lectionary (Continuous)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Canticle: Isaiah 12",
                        "readQS": "Isaiah+12",
                        "audioQS": "Isa.12"
                    },
                    {
                        "pretty": "Isaiah 65:17\u201325",
                        "readQS": "Isaiah+65:17-25",
                        "audioQS": "Isa.65"
                    },
                    {
                        "pretty": "2 Thessalonians 3:6\u201313",
                        "readQS": "2+Thessalonians+3:6-13",
                        "audioQS": "2Thess.3"
                    },
                    {
                        "pretty": "Luke 21:5\u201319",
                        "readQS": "Luke+21:5-19",
                        "audioQS": "Luke.21"
                    }
                ],
                "readQS": "Isaiah+12%3B+Isaiah+65:17-25%3B+2+Thessalonians+3:6-13%3B+Luke+21:5-19",
                "audioQS": "Isa.12,Isa.65,2Thess.3,Luke.21",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 132",
                        "readQS": "Psalm+132",
                        "audioQS": "Ps.132"
                    },
                    {
                        "pretty": "1 Samuel 16:1\u201313",
                        "readQS": "1+Samuel+16:1-13",
                        "audioQS": "1Sam.16"
                    },
                    {
                        "pretty": "Matthew 13:44\u201352",
                        "readQS": "Matthew+13:44-52",
                        "audioQS": "Matt.13"
                    }
                ],
                "readQS": "Psalm+132%3B+1+Samuel+16:1-13%3B+Matthew+13:44-52",
                "audioQS": "Ps.132,1Sam.16,Matt.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Daniel 6",
                        "readQS": "Daniel+6",
                        "audioQS": "Dan.6"
                    },
                    {
                        "pretty": "Matthew 13:1\u20139,18\u201323",
                        "readQS": "Matthew+13:1-9,18-23",
                        "audioQS": "Matt.13"
                    }
                ],
                "readQS": "Psalm+97%3B+Daniel+6%3B+Matthew+13:1-9,18-23",
                "audioQS": "Ps.97,Dan.6,Matt.13",
                "note": null
            }
        ]
    },
    "2025/11/17": {
        "datePretty": "Monday, November 17, 2025",
        "calendar": "Week of 2nd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 47",
                        "readQS": "Psalm+47",
                        "audioQS": "Ps.47"
                    },
                    {
                        "pretty": "Isaiah 9:8\u201310:4",
                        "readQS": "Isaiah+9:8-10:4",
                        "audioQS": "Isa.9"
                    },
                    {
                        "pretty": "Matthew 7:1\u201312",
                        "readQS": "Matthew+7:1-12",
                        "audioQS": "Matt.7"
                    }
                ],
                "readQS": "Psalm+47%3B+Isaiah+9:8-10:4%3B+Matthew+7:1-12",
                "audioQS": "Ps.47,Isa.9,Matt.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 71",
                        "readQS": "Psalm+71",
                        "audioQS": "Ps.71"
                    },
                    {
                        "pretty": "Daniel 8:1\u201314",
                        "readQS": "Daniel+8:1-14",
                        "audioQS": "Dan.8"
                    },
                    {
                        "pretty": "Revelation 10",
                        "readQS": "Revelation+10",
                        "audioQS": "Rev.10"
                    }
                ],
                "readQS": "Psalm+71%3B+Daniel+8:1-14%3B+Revelation+10",
                "audioQS": "Ps.71,Dan.8,Rev.10",
                "note": null
            }
        ]
    },
    "2025/11/18": {
        "datePretty": "Tuesday, November 18, 2025",
        "calendar": "Week of 2nd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 52",
                        "readQS": "Psalm+52",
                        "audioQS": "Ps.52"
                    },
                    {
                        "pretty": "Isaiah 10:5\u201319",
                        "readQS": "Isaiah+10:5-19",
                        "audioQS": "Isa.10"
                    },
                    {
                        "pretty": "Matthew 7:13\u2013end",
                        "readQS": "Matthew+7:13-",
                        "audioQS": "Matt.7"
                    }
                ],
                "readQS": "Psalm+52%3B+Isaiah+10:5-19%3B+Matthew+7:13-",
                "audioQS": "Ps.52,Isa.10,Matt.7",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 67",
                        "readQS": "Psalm+67",
                        "audioQS": "Ps.67"
                    },
                    {
                        "pretty": "Daniel 8:15\u2013end",
                        "readQS": "Daniel+8:15-",
                        "audioQS": "Dan.8"
                    },
                    {
                        "pretty": "Revelation 11:1\u201314",
                        "readQS": "Revelation+11:1-14",
                        "audioQS": "Rev.11"
                    }
                ],
                "readQS": "Psalm+67%3B+Daniel+8:15-%3B+Revelation+11:1-14",
                "audioQS": "Ps.67,Dan.8,Rev.11",
                "note": null
            }
        ]
    },
    "2025/11/19": {
        "datePretty": "Wednesday, November 19, 2025",
        "calendar": "Week of 2nd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 56",
                        "readQS": "Psalm+56",
                        "audioQS": "Ps.56"
                    },
                    {
                        "pretty": "Isaiah 10:20\u201332",
                        "readQS": "Isaiah+10:20-32",
                        "audioQS": "Isa.10"
                    },
                    {
                        "pretty": "Matthew 8:1\u201313",
                        "readQS": "Matthew+8:1-13",
                        "audioQS": "Matt.8"
                    }
                ],
                "readQS": "Psalm+56%3B+Isaiah+10:20-32%3B+Matthew+8:1-13",
                "audioQS": "Ps.56,Isa.10,Matt.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Daniel 9:1\u201319",
                        "readQS": "Daniel+9:1-19",
                        "audioQS": "Dan.9"
                    },
                    {
                        "pretty": "Revelation 11:15\u2013end",
                        "readQS": "Revelation+11:15-",
                        "audioQS": "Rev.11"
                    }
                ],
                "readQS": "Psalm+73%3B+Daniel+9:1-19%3B+Revelation+11:15-",
                "audioQS": "Ps.73,Dan.9,Rev.11",
                "note": null
            }
        ]
    },
    "2025/11/20": {
        "datePretty": "Thursday, November 20, 2025",
        "calendar": "Week of 2nd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 62",
                        "readQS": "Psalm+62",
                        "audioQS": "Ps.62"
                    },
                    {
                        "pretty": "Isaiah 10:33\u201311:9",
                        "readQS": "Isaiah+10:33-11:9",
                        "audioQS": "Isa.10"
                    },
                    {
                        "pretty": "Matthew 8:14\u201322",
                        "readQS": "Matthew+8:14-22",
                        "audioQS": "Matt.8"
                    }
                ],
                "readQS": "Psalm+62%3B+Isaiah+10:33-11:9%3B+Matthew+8:14-22",
                "audioQS": "Ps.62,Isa.10,Matt.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 76",
                        "readQS": "Psalm+76",
                        "audioQS": "Ps.76"
                    },
                    {
                        "pretty": "Daniel 9:20\u2013end",
                        "readQS": "Daniel+9:20-",
                        "audioQS": "Dan.9"
                    },
                    {
                        "pretty": "Revelation 12",
                        "readQS": "Revelation+12",
                        "audioQS": "Rev.12"
                    }
                ],
                "readQS": "Psalm+76%3B+Daniel+9:20-%3B+Revelation+12",
                "audioQS": "Ps.76,Dan.9,Rev.12",
                "note": null
            }
        ]
    },
    "2025/11/21": {
        "datePretty": "Friday, November 21, 2025",
        "calendar": "Week of 2nd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 63",
                        "readQS": "Psalm+63",
                        "audioQS": "Ps.63"
                    },
                    {
                        "pretty": "Isaiah 11:10\u2013end of 12",
                        "readQS": "Isaiah+11:10-+of+12",
                        "audioQS": "Isa.11"
                    },
                    {
                        "pretty": "Matthew 8:23\u2013end",
                        "readQS": "Matthew+8:23-",
                        "audioQS": "Matt.8"
                    }
                ],
                "readQS": "Psalm+63%3B+Isaiah+11:10-+of+12%3B+Matthew+8:23-",
                "audioQS": "Ps.63,Isa.11,Matt.8",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Daniel 10:1\u201311:1",
                        "readQS": "Daniel+10:1-11:1",
                        "audioQS": "Dan.10"
                    },
                    {
                        "pretty": "Revelation 13:1\u201310",
                        "readQS": "Revelation+13:1-10",
                        "audioQS": "Rev.13"
                    }
                ],
                "readQS": "Psalm+77%3B+Daniel+10:1-11:1%3B+Revelation+13:1-10",
                "audioQS": "Ps.77,Dan.10,Rev.13",
                "note": null
            }
        ]
    },
    "2025/11/22": {
        "datePretty": "Saturday, November 22, 2025",
        "calendar": "Week of 2nd Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 78.1\u201339",
                        "readQS": "Psalm+78.1-39",
                        "audioQS": "Ps.78.1\u201339"
                    },
                    {
                        "pretty": "Isaiah 13:1\u201313",
                        "readQS": "Isaiah+13:1-13",
                        "audioQS": "Isa.13"
                    },
                    {
                        "pretty": "Matthew 9:1\u201317",
                        "readQS": "Matthew+9:1-17",
                        "audioQS": "Matt.9"
                    }
                ],
                "readQS": "Psalm+78.1-39%3B+Isaiah+13:1-13%3B+Matthew+9:1-17",
                "audioQS": "Ps.78.1\u201339,Isa.13,Matt.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of Christ the King)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 99,100",
                        "readQS": "Psalm+99,100",
                        "audioQS": "Ps.99,100"
                    },
                    {
                        "pretty": "Isaiah 10:33\u201311:9",
                        "readQS": "Isaiah+10:33-11:9",
                        "audioQS": "Isa.10"
                    },
                    {
                        "pretty": "1 Timothy 6:11\u201316",
                        "readQS": "1+Timothy+6:11-16",
                        "audioQS": "1Tim.6"
                    }
                ],
                "readQS": "Psalm+99,100%3B+Isaiah+10:33-11:9%3B+1+Timothy+6:11-16",
                "audioQS": "Ps.99,100,Isa.10,1Tim.6",
                "note": null
            }
        ]
    },
    "2025/11/23": {
        "datePretty": "Sunday, November 23, 2025",
        "calendar": "Christ the King Sunday",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 46",
                        "readQS": "Psalm+46",
                        "audioQS": "Ps.46"
                    },
                    {
                        "pretty": "Jeremiah 23:1\u20136",
                        "readQS": "Jeremiah+23:1-6",
                        "audioQS": "Jer.23"
                    },
                    {
                        "pretty": "Colossians 1:11\u201320",
                        "readQS": "Colossians+1:11-20",
                        "audioQS": "Col.1"
                    },
                    {
                        "pretty": "Luke 23:33\u201343",
                        "readQS": "Luke+23:33-43",
                        "audioQS": "Luke.23"
                    }
                ],
                "readQS": "Psalm+46%3B+Jeremiah+23:1-6%3B+Colossians+1:11-20%3B+Luke+23:33-43",
                "audioQS": "Ps.46,Jer.23,Col.1,Luke.23",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 29,110",
                        "readQS": "Psalm+29,110",
                        "audioQS": "Ps.29,110"
                    },
                    {
                        "pretty": "Zechariah 6:9\u201315",
                        "readQS": "Zechariah+6:9-15",
                        "audioQS": "Zech.6"
                    },
                    {
                        "pretty": "Revelation 11:15\u201318",
                        "readQS": "Revelation+11:15-18",
                        "audioQS": "Rev.11"
                    }
                ],
                "readQS": "Psalm+29,110%3B+Zechariah+6:9-15%3B+Revelation+11:15-18",
                "audioQS": "Ps.29,110,Zech.6,Rev.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 72",
                        "readQS": "Psalm+72",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "1 Samuel 8:4\u201320",
                        "readQS": "1+Samuel+8:4-20",
                        "audioQS": "1Sam.8"
                    },
                    {
                        "pretty": "John 18:33\u201337",
                        "readQS": "John+18:33-37",
                        "audioQS": "John.18"
                    }
                ],
                "readQS": "Psalm+72%3B+1+Samuel+8:4-20%3B+John+18:33-37",
                "audioQS": "Ps.72,1Sam.8,John.18",
                "note": null
            }
        ]
    },
    "2025/11/24": {
        "datePretty": "Monday, November 24, 2025",
        "calendar": "Week of 1st Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 96",
                        "readQS": "Psalm+96",
                        "audioQS": "Ps.96"
                    },
                    {
                        "pretty": "Isaiah 14:3\u201320",
                        "readQS": "Isaiah+14:3-20",
                        "audioQS": "Isa.14"
                    },
                    {
                        "pretty": "Matthew 9:18\u201334",
                        "readQS": "Matthew+9:18-34",
                        "audioQS": "Matt.9"
                    }
                ],
                "readQS": "Psalm+96%3B+Isaiah+14:3-20%3B+Matthew+9:18-34",
                "audioQS": "Ps.96,Isa.14,Matt.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Isaiah 40:1\u201311",
                        "readQS": "Isaiah+40:1-11",
                        "audioQS": "Isa.40"
                    },
                    {
                        "pretty": "Revelation 14:1\u201313",
                        "readQS": "Revelation+14:1-13",
                        "audioQS": "Rev.14"
                    }
                ],
                "readQS": "Psalm+80%3B+Isaiah+40:1-11%3B+Revelation+14:1-13",
                "audioQS": "Ps.80,Isa.40,Rev.14",
                "note": null
            }
        ]
    },
    "2025/11/25": {
        "datePretty": "Tuesday, November 25, 2025",
        "calendar": "Week of 1st Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 97",
                        "readQS": "Psalm+97",
                        "audioQS": "Ps.97"
                    },
                    {
                        "pretty": "Isaiah 17",
                        "readQS": "Isaiah+17",
                        "audioQS": "Isa.17"
                    },
                    {
                        "pretty": "Matthew 9:35\u201310:15",
                        "readQS": "Matthew+9:35-10:15",
                        "audioQS": "Matt.9"
                    }
                ],
                "readQS": "Psalm+97%3B+Isaiah+17%3B+Matthew+9:35-10:15",
                "audioQS": "Ps.97,Isa.17,Matt.9",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 101",
                        "readQS": "Psalm+101",
                        "audioQS": "Ps.101"
                    },
                    {
                        "pretty": "Isaiah 40:12\u201326",
                        "readQS": "Isaiah+40:12-26",
                        "audioQS": "Isa.40"
                    },
                    {
                        "pretty": "Revelation 14:14\u2013end,15:1\u2013end",
                        "readQS": "Revelation+14:14-,15:1-",
                        "audioQS": "Rev.14"
                    }
                ],
                "readQS": "Psalm+101%3B+Isaiah+40:12-26%3B+Revelation+14:14-,15:1-",
                "audioQS": "Ps.101,Isa.40,Rev.14",
                "note": null
            }
        ]
    },
    "2025/11/26": {
        "datePretty": "Wednesday, November 26, 2025",
        "calendar": "Week of 1st Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 112",
                        "readQS": "Psalm+112",
                        "audioQS": "Ps.112"
                    },
                    {
                        "pretty": "Isaiah 19",
                        "readQS": "Isaiah+19",
                        "audioQS": "Isa.19"
                    },
                    {
                        "pretty": "Matthew 10:16\u201333",
                        "readQS": "Matthew+10:16-33",
                        "audioQS": "Matt.10"
                    }
                ],
                "readQS": "Psalm+112%3B+Isaiah+19%3B+Matthew+10:16-33",
                "audioQS": "Ps.112,Isa.19,Matt.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 122",
                        "readQS": "Psalm+122",
                        "audioQS": "Ps.122"
                    },
                    {
                        "pretty": "Isaiah 40:27\u201341:7",
                        "readQS": "Isaiah+40:27-41:7",
                        "audioQS": "Isa.40"
                    },
                    {
                        "pretty": "Revelation 16:1\u201311",
                        "readQS": "Revelation+16:1-11",
                        "audioQS": "Rev.16"
                    }
                ],
                "readQS": "Psalm+122%3B+Isaiah+40:27-41:7%3B+Revelation+16:1-11",
                "audioQS": "Ps.122,Isa.40,Rev.16",
                "note": null
            }
        ]
    },
    "2025/11/27": {
        "datePretty": "Thursday, November 27, 2025",
        "calendar": "Week of 1st Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 125",
                        "readQS": "Psalm+125",
                        "audioQS": "Ps.125"
                    },
                    {
                        "pretty": "Isaiah 21:1\u201312",
                        "readQS": "Isaiah+21:1-12",
                        "audioQS": "Isa.21"
                    },
                    {
                        "pretty": "Matthew 10:34\u201311:1",
                        "readQS": "Matthew+10:34-11:1",
                        "audioQS": "Matt.10"
                    }
                ],
                "readQS": "Psalm+125%3B+Isaiah+21:1-12%3B+Matthew+10:34-11:1",
                "audioQS": "Ps.125,Isa.21,Matt.10",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 133",
                        "readQS": "Psalm+133",
                        "audioQS": "Ps.133"
                    },
                    {
                        "pretty": "Isaiah 41:8\u201320",
                        "readQS": "Isaiah+41:8-20",
                        "audioQS": "Isa.41"
                    },
                    {
                        "pretty": "Revelation 16:12\u2013end",
                        "readQS": "Revelation+16:12-",
                        "audioQS": "Rev.16"
                    }
                ],
                "readQS": "Psalm+133%3B+Isaiah+41:8-20%3B+Revelation+16:12-",
                "audioQS": "Ps.133,Isa.41,Rev.16",
                "note": null
            }
        ]
    },
    "2025/11/28": {
        "datePretty": "Friday, November 28, 2025",
        "calendar": "Week of 1st Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 139",
                        "readQS": "Psalm+139",
                        "audioQS": "Ps.139"
                    },
                    {
                        "pretty": "Isaiah 22:1\u201314",
                        "readQS": "Isaiah+22:1-14",
                        "audioQS": "Isa.22"
                    },
                    {
                        "pretty": "Matthew 11:2\u201319",
                        "readQS": "Matthew+11:2-19",
                        "audioQS": "Matt.11"
                    }
                ],
                "readQS": "Psalm+139%3B+Isaiah+22:1-14%3B+Matthew+11:2-19",
                "audioQS": "Ps.139,Isa.22,Matt.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 146",
                        "readQS": "Psalm+146",
                        "audioQS": "Ps.146"
                    },
                    {
                        "pretty": "Isaiah 41:21\u201342:9",
                        "readQS": "Isaiah+41:21-42:9",
                        "audioQS": "Isa.41"
                    },
                    {
                        "pretty": "Revelation 17",
                        "readQS": "Revelation+17",
                        "audioQS": "Rev.17"
                    }
                ],
                "readQS": "Psalm+146%3B+Isaiah+41:21-42:9%3B+Revelation+17",
                "audioQS": "Ps.146,Isa.41,Rev.17",
                "note": null
            }
        ]
    },
    "2025/11/29": {
        "datePretty": "Saturday, November 29, 2025",
        "calendar": "Week of 1st Sunday before Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Isaiah 24",
                        "readQS": "Isaiah+24",
                        "audioQS": "Isa.24"
                    },
                    {
                        "pretty": "Matthew 11:20\u2013end",
                        "readQS": "Matthew+11:20-",
                        "audioQS": "Matt.11"
                    }
                ],
                "readQS": "Psalm+145%3B+Isaiah+24%3B+Matthew+11:20-",
                "audioQS": "Ps.145,Isa.24,Matt.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 150",
                        "readQS": "Psalm+150",
                        "audioQS": "Ps.150"
                    },
                    {
                        "pretty": "Isaiah 42:10\u201317",
                        "readQS": "Isaiah+42:10-17",
                        "audioQS": "Isa.42"
                    },
                    {
                        "pretty": "Revelation 18",
                        "readQS": "Revelation+18",
                        "audioQS": "Rev.18"
                    }
                ],
                "readQS": "Psalm+150%3B+Isaiah+42:10-17%3B+Revelation+18",
                "audioQS": "Ps.150,Isa.42,Rev.18",
                "note": null
            }
        ]
    },
    "2025/11/30": {
        "datePretty": "Sunday, November 30, 2025",
        "calendar": "1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 122",
                        "readQS": "Psalm+122",
                        "audioQS": "Ps.122"
                    },
                    {
                        "pretty": "Isaiah 2:1\u20135",
                        "readQS": "Isaiah+2:1-5",
                        "audioQS": "Isa.2"
                    },
                    {
                        "pretty": "Romans 13:11\u201314",
                        "readQS": "Romans+13:11-14",
                        "audioQS": "Rom.13"
                    },
                    {
                        "pretty": "Matthew 24:36\u201344",
                        "readQS": "Matthew+24:36-44",
                        "audioQS": "Matt.24"
                    }
                ],
                "readQS": "Psalm+122%3B+Isaiah+2:1-5%3B+Romans+13:11-14%3B+Matthew+24:36-44",
                "audioQS": "Ps.122,Isa.2,Rom.13,Matt.24",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "Micah 4:1\u20137",
                        "readQS": "Micah+4:1-7",
                        "audioQS": "Mic.4"
                    },
                    {
                        "pretty": "1 Thessalonians 5:1\u201311",
                        "readQS": "1+Thessalonians+5:1-11",
                        "audioQS": "1Thess.5"
                    }
                ],
                "readQS": "Psalm+44%3B+Micah+4:1-7%3B+1+Thessalonians+5:1-11",
                "audioQS": "Ps.44,Mic.4,1Thess.5",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers (Eve of St Andrew)",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 48",
                        "readQS": "Psalm+48",
                        "audioQS": "Ps.48"
                    },
                    {
                        "pretty": "Isaiah 49:1\u20139a",
                        "readQS": "Isaiah+49:1-9a",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "1 Corinthians 4:9\u201316",
                        "readQS": "1+Corinthians+4:9-16",
                        "audioQS": "1Cor.4"
                    }
                ],
                "readQS": "Psalm+48%3B+Isaiah+49:1-9a%3B+1+Corinthians+4:9-16",
                "audioQS": "Ps.48,Isa.49,1Cor.4",
                "note": null
            }
        ]
    },
    "2025/12/01": {
        "datePretty": "Monday, December 1, 2025",
        "calendar": "St Andrew the Apostle",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 47,147:1\u201311",
                        "readQS": "Psalm+47,147:1-11",
                        "audioQS": "Ps.47,147"
                    },
                    {
                        "pretty": "Ezekiel 47:1\u201312",
                        "readQS": "Ezekiel+47:1-12",
                        "audioQS": "Ezek.47"
                    },
                    {
                        "pretty": "John 12:20\u201332",
                        "readQS": "John+12:20-32",
                        "audioQS": "John.12"
                    }
                ],
                "readQS": "Psalm+47,147:1-11%3B+Ezekiel+47:1-12%3B+John+12:20-32",
                "audioQS": "Ps.47,147,Ezek.47,John.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 87,96",
                        "readQS": "Psalm+87,96",
                        "audioQS": "Ps.87,96"
                    },
                    {
                        "pretty": "Zechariah 8:20\u201323",
                        "readQS": "Zechariah+8:20-23",
                        "audioQS": "Zech.8"
                    },
                    {
                        "pretty": "John 1:35\u201342",
                        "readQS": "John+1:35-42",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+87,96%3B+Zechariah+8:20-23%3B+John+1:35-42",
                "audioQS": "Ps.87,96,Zech.8,John.1",
                "note": null
            }
        ]
    },
    "2025/12/02": {
        "datePretty": "Tuesday, December 2, 2025",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Isaiah 26:1\u201313",
                        "readQS": "Isaiah+26:1-13",
                        "audioQS": "Isa.26"
                    },
                    {
                        "pretty": "Matthew 12:22\u201337",
                        "readQS": "Matthew+12:22-37",
                        "audioQS": "Matt.12"
                    }
                ],
                "readQS": "Psalm+80%3B+Isaiah+26:1-13%3B+Matthew+12:22-37",
                "audioQS": "Ps.80,Isa.26,Matt.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 74",
                        "readQS": "Psalm+74",
                        "audioQS": "Ps.74"
                    },
                    {
                        "pretty": "Isaiah 43:1\u201313",
                        "readQS": "Isaiah+43:1-13",
                        "audioQS": "Isa.43"
                    },
                    {
                        "pretty": "Revelation 20",
                        "readQS": "Revelation+20",
                        "audioQS": "Rev.20"
                    }
                ],
                "readQS": "Psalm+74%3B+Isaiah+43:1-13%3B+Revelation+20",
                "audioQS": "Ps.74,Isa.43,Rev.20",
                "note": null
            }
        ]
    },
    "2025/12/03": {
        "datePretty": "Wednesday, December 3, 2025",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 7",
                        "readQS": "Psalm+7",
                        "audioQS": "Ps.7"
                    },
                    {
                        "pretty": "Isaiah 28:1\u201313",
                        "readQS": "Isaiah+28:1-13",
                        "audioQS": "Isa.28"
                    },
                    {
                        "pretty": "Matthew 12:38\u2013end",
                        "readQS": "Matthew+12:38-",
                        "audioQS": "Matt.12"
                    }
                ],
                "readQS": "Psalm+7%3B+Isaiah+28:1-13%3B+Matthew+12:38-",
                "audioQS": "Ps.7,Isa.28,Matt.12",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 77",
                        "readQS": "Psalm+77",
                        "audioQS": "Ps.77"
                    },
                    {
                        "pretty": "Isaiah 43:14\u2013end",
                        "readQS": "Isaiah+43:14-",
                        "audioQS": "Isa.43"
                    },
                    {
                        "pretty": "Revelation 21:1\u20138",
                        "readQS": "Revelation+21:1-8",
                        "audioQS": "Rev.21"
                    }
                ],
                "readQS": "Psalm+77%3B+Isaiah+43:14-%3B+Revelation+21:1-8",
                "audioQS": "Ps.77,Isa.43,Rev.21",
                "note": null
            }
        ]
    },
    "2025/12/04": {
        "datePretty": "Thursday, December 4, 2025",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 42",
                        "readQS": "Psalm+42",
                        "audioQS": "Ps.42"
                    },
                    {
                        "pretty": "Isaiah 28:14\u2013end",
                        "readQS": "Isaiah+28:14-",
                        "audioQS": "Isa.28"
                    },
                    {
                        "pretty": "Matthew 13:1\u201323",
                        "readQS": "Matthew+13:1-23",
                        "audioQS": "Matt.13"
                    }
                ],
                "readQS": "Psalm+42%3B+Isaiah+28:14-%3B+Matthew+13:1-23",
                "audioQS": "Ps.42,Isa.28,Matt.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Isaiah 44:1\u20138",
                        "readQS": "Isaiah+44:1-8",
                        "audioQS": "Isa.44"
                    },
                    {
                        "pretty": "Revelation 21:9\u201321",
                        "readQS": "Revelation+21:9-21",
                        "audioQS": "Rev.21"
                    }
                ],
                "readQS": "Psalm+40%3B+Isaiah+44:1-8%3B+Revelation+21:9-21",
                "audioQS": "Ps.40,Isa.44,Rev.21",
                "note": null
            }
        ]
    },
    "2025/12/05": {
        "datePretty": "Friday, December 5, 2025",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 25",
                        "readQS": "Psalm+25",
                        "audioQS": "Ps.25"
                    },
                    {
                        "pretty": "Isaiah 29:1\u201314",
                        "readQS": "Isaiah+29:1-14",
                        "audioQS": "Isa.29"
                    },
                    {
                        "pretty": "Matthew 13:24\u201343",
                        "readQS": "Matthew+13:24-43",
                        "audioQS": "Matt.13"
                    }
                ],
                "readQS": "Psalm+25%3B+Isaiah+29:1-14%3B+Matthew+13:24-43",
                "audioQS": "Ps.25,Isa.29,Matt.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 17",
                        "readQS": "Psalm+17",
                        "audioQS": "Ps.17"
                    },
                    {
                        "pretty": "Isaiah 44:9\u201323",
                        "readQS": "Isaiah+44:9-23",
                        "audioQS": "Isa.44"
                    },
                    {
                        "pretty": "Revelation 21:22\u201322:5",
                        "readQS": "Revelation+21:22-22:5",
                        "audioQS": "Rev.21"
                    }
                ],
                "readQS": "Psalm+17%3B+Isaiah+44:9-23%3B+Revelation+21:22-22:5",
                "audioQS": "Ps.17,Isa.44,Rev.21",
                "note": null
            }
        ]
    },
    "2025/12/06": {
        "datePretty": "Saturday, December 6, 2025",
        "calendar": "Week of 1st Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 9",
                        "readQS": "Psalm+9",
                        "audioQS": "Ps.9"
                    },
                    {
                        "pretty": "Isaiah 29:15\u2013end",
                        "readQS": "Isaiah+29:15-",
                        "audioQS": "Isa.29"
                    },
                    {
                        "pretty": "Matthew 13:44\u2013end",
                        "readQS": "Matthew+13:44-",
                        "audioQS": "Matt.13"
                    }
                ],
                "readQS": "Psalm+9%3B+Isaiah+29:15-%3B+Matthew+13:44-",
                "audioQS": "Ps.9,Isa.29,Matt.13",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 27",
                        "readQS": "Psalm+27",
                        "audioQS": "Ps.27"
                    },
                    {
                        "pretty": "Isaiah 44:24\u201345:13",
                        "readQS": "Isaiah+44:24-45:13",
                        "audioQS": "Isa.44"
                    },
                    {
                        "pretty": "Revelation 22:6\u2013end",
                        "readQS": "Revelation+22:6-",
                        "audioQS": "Rev.22"
                    }
                ],
                "readQS": "Psalm+27%3B+Isaiah+44:24-45:13%3B+Revelation+22:6-",
                "audioQS": "Ps.27,Isa.44,Rev.22",
                "note": null
            }
        ]
    },
    "2025/12/07": {
        "datePretty": "Sunday, December 7, 2025",
        "calendar": "2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 72:1\u20137,18,19",
                        "readQS": "Psalm+72:1-7,18,19",
                        "audioQS": "Ps.72"
                    },
                    {
                        "pretty": "Isaiah 11:1\u201310",
                        "readQS": "Isaiah+11:1-10",
                        "audioQS": "Isa.11"
                    },
                    {
                        "pretty": "Romans 15:4\u201313",
                        "readQS": "Romans+15:4-13",
                        "audioQS": "Rom.15"
                    },
                    {
                        "pretty": "Matthew 3:1\u201312",
                        "readQS": "Matthew+3:1-12",
                        "audioQS": "Matt.3"
                    }
                ],
                "readQS": "Psalm+72:1-7,18,19%3B+Isaiah+11:1-10%3B+Romans+15:4-13%3B+Matthew+3:1-12",
                "audioQS": "Ps.72,Isa.11,Rom.15,Matt.3",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 80",
                        "readQS": "Psalm+80",
                        "audioQS": "Ps.80"
                    },
                    {
                        "pretty": "Amos 7",
                        "readQS": "Amos+7",
                        "audioQS": "Amos.7"
                    },
                    {
                        "pretty": "Luke 1:5\u201320",
                        "readQS": "Luke+1:5-20",
                        "audioQS": "Luke.1"
                    }
                ],
                "readQS": "Psalm+80%3B+Amos+7%3B+Luke+1:5-20",
                "audioQS": "Ps.80,Amos.7,Luke.1",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 11",
                        "readQS": "Psalm+11",
                        "audioQS": "Ps.11"
                    },
                    {
                        "pretty": "1 Kings 18:17\u201339",
                        "readQS": "1+Kings+18:17-39",
                        "audioQS": "1Kgs.18"
                    },
                    {
                        "pretty": "John 1:19\u201328",
                        "readQS": "John+1:19-28",
                        "audioQS": "John.1"
                    }
                ],
                "readQS": "Psalm+11%3B+1+Kings+18:17-39%3B+John+1:19-28",
                "audioQS": "Ps.11,1Kgs.18,John.1",
                "note": null
            }
        ]
    },
    "2025/12/08": {
        "datePretty": "Monday, December 8, 2025",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "Isaiah 30:1\u201318",
                        "readQS": "Isaiah+30:1-18",
                        "audioQS": "Isa.30"
                    },
                    {
                        "pretty": "Matthew 14:1\u201312",
                        "readQS": "Matthew+14:1-12",
                        "audioQS": "Matt.14"
                    }
                ],
                "readQS": "Psalm+44%3B+Isaiah+30:1-18%3B+Matthew+14:1-12",
                "audioQS": "Ps.44,Isa.30,Matt.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 144",
                        "readQS": "Psalm+144",
                        "audioQS": "Ps.144"
                    },
                    {
                        "pretty": "Isaiah 45:14\u2013end",
                        "readQS": "Isaiah+45:14-",
                        "audioQS": "Isa.45"
                    },
                    {
                        "pretty": "1 Thessalonians 1",
                        "readQS": "1+Thessalonians+1",
                        "audioQS": "1Thess.1"
                    }
                ],
                "readQS": "Psalm+144%3B+Isaiah+45:14-%3B+1+Thessalonians+1",
                "audioQS": "Ps.144,Isa.45,1Thess.1",
                "note": null
            }
        ]
    },
    "2025/12/09": {
        "datePretty": "Tuesday, December 9, 2025",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 56",
                        "readQS": "Psalm+56",
                        "audioQS": "Ps.56"
                    },
                    {
                        "pretty": "Isaiah 30:19\u2013end",
                        "readQS": "Isaiah+30:19-",
                        "audioQS": "Isa.30"
                    },
                    {
                        "pretty": "Matthew 14:13\u2013end",
                        "readQS": "Matthew+14:13-",
                        "audioQS": "Matt.14"
                    }
                ],
                "readQS": "Psalm+56%3B+Isaiah+30:19-%3B+Matthew+14:13-",
                "audioQS": "Ps.56,Isa.30,Matt.14",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 11",
                        "readQS": "Psalm+11",
                        "audioQS": "Ps.11"
                    },
                    {
                        "pretty": "Isaiah 46",
                        "readQS": "Isaiah+46",
                        "audioQS": "Isa.46"
                    },
                    {
                        "pretty": "1 Thessalonians 2:1\u201312",
                        "readQS": "1+Thessalonians+2:1-12",
                        "audioQS": "1Thess.2"
                    }
                ],
                "readQS": "Psalm+11%3B+Isaiah+46%3B+1+Thessalonians+2:1-12",
                "audioQS": "Ps.11,Isa.46,1Thess.2",
                "note": null
            }
        ]
    },
    "2025/12/10": {
        "datePretty": "Wednesday, December 10, 2025",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 62",
                        "readQS": "Psalm+62",
                        "audioQS": "Ps.62"
                    },
                    {
                        "pretty": "Isaiah 31",
                        "readQS": "Isaiah+31",
                        "audioQS": "Isa.31"
                    },
                    {
                        "pretty": "Matthew 15:1\u201320",
                        "readQS": "Matthew+15:1-20",
                        "audioQS": "Matt.15"
                    }
                ],
                "readQS": "Psalm+62%3B+Isaiah+31%3B+Matthew+15:1-20",
                "audioQS": "Ps.62,Isa.31,Matt.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 10",
                        "readQS": "Psalm+10",
                        "audioQS": "Ps.10"
                    },
                    {
                        "pretty": "Isaiah 47",
                        "readQS": "Isaiah+47",
                        "audioQS": "Isa.47"
                    },
                    {
                        "pretty": "1 Thessalonians 2:13\u2013end",
                        "readQS": "1+Thessalonians+2:13-",
                        "audioQS": "1Thess.2"
                    }
                ],
                "readQS": "Psalm+10%3B+Isaiah+47%3B+1+Thessalonians+2:13-",
                "audioQS": "Ps.10,Isa.47,1Thess.2",
                "note": null
            }
        ]
    },
    "2025/12/11": {
        "datePretty": "Thursday, December 11, 2025",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 54",
                        "readQS": "Psalm+54",
                        "audioQS": "Ps.54"
                    },
                    {
                        "pretty": "Isaiah 32",
                        "readQS": "Isaiah+32",
                        "audioQS": "Isa.32"
                    },
                    {
                        "pretty": "Matthew 15:21\u201328",
                        "readQS": "Matthew+15:21-28",
                        "audioQS": "Matt.15"
                    }
                ],
                "readQS": "Psalm+54%3B+Isaiah+32%3B+Matthew+15:21-28",
                "audioQS": "Ps.54,Isa.32,Matt.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 73",
                        "readQS": "Psalm+73",
                        "audioQS": "Ps.73"
                    },
                    {
                        "pretty": "Isaiah 48:1\u201311",
                        "readQS": "Isaiah+48:1-11",
                        "audioQS": "Isa.48"
                    },
                    {
                        "pretty": "1 Thessalonians 3",
                        "readQS": "1+Thessalonians+3",
                        "audioQS": "1Thess.3"
                    }
                ],
                "readQS": "Psalm+73%3B+Isaiah+48:1-11%3B+1+Thessalonians+3",
                "audioQS": "Ps.73,Isa.48,1Thess.3",
                "note": null
            }
        ]
    },
    "2025/12/12": {
        "datePretty": "Friday, December 12, 2025",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 86",
                        "readQS": "Psalm+86",
                        "audioQS": "Ps.86"
                    },
                    {
                        "pretty": "Isaiah 33:1\u201322",
                        "readQS": "Isaiah+33:1-22",
                        "audioQS": "Isa.33"
                    },
                    {
                        "pretty": "Matthew 15:29\u2013end",
                        "readQS": "Matthew+15:29-",
                        "audioQS": "Matt.15"
                    }
                ],
                "readQS": "Psalm+86%3B+Isaiah+33:1-22%3B+Matthew+15:29-",
                "audioQS": "Ps.86,Isa.33,Matt.15",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 90",
                        "readQS": "Psalm+90",
                        "audioQS": "Ps.90"
                    },
                    {
                        "pretty": "Isaiah 48:12\u2013end",
                        "readQS": "Isaiah+48:12-",
                        "audioQS": "Isa.48"
                    },
                    {
                        "pretty": "1 Thessalonians 4:1\u201312",
                        "readQS": "1+Thessalonians+4:1-12",
                        "audioQS": "1Thess.4"
                    }
                ],
                "readQS": "Psalm+90%3B+Isaiah+48:12-%3B+1+Thessalonians+4:1-12",
                "audioQS": "Ps.90,Isa.48,1Thess.4",
                "note": null
            }
        ]
    },
    "2025/12/13": {
        "datePretty": "Saturday, December 13, 2025",
        "calendar": "Week of 2nd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 145",
                        "readQS": "Psalm+145",
                        "audioQS": "Ps.145"
                    },
                    {
                        "pretty": "Isaiah 35",
                        "readQS": "Isaiah+35",
                        "audioQS": "Isa.35"
                    },
                    {
                        "pretty": "Matthew 16:1\u201312",
                        "readQS": "Matthew+16:1-12",
                        "audioQS": "Matt.16"
                    }
                ],
                "readQS": "Psalm+145%3B+Isaiah+35%3B+Matthew+16:1-12",
                "audioQS": "Ps.145,Isa.35,Matt.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 94",
                        "readQS": "Psalm+94",
                        "audioQS": "Ps.94"
                    },
                    {
                        "pretty": "Isaiah 49:1\u201313",
                        "readQS": "Isaiah+49:1-13",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "1 Thessalonians 4:13\u2013end",
                        "readQS": "1+Thessalonians+4:13-",
                        "audioQS": "1Thess.4"
                    }
                ],
                "readQS": "Psalm+94%3B+Isaiah+49:1-13%3B+1+Thessalonians+4:13-",
                "audioQS": "Ps.94,Isa.49,1Thess.4",
                "note": null
            }
        ]
    },
    "2025/12/14": {
        "datePretty": "Sunday, December 14, 2025",
        "calendar": "3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "rclTrack1",
                "readingSetTabName": "RCL (Re.)",
                "readingSetSubtitle": "Revised Common Lectionary (Related)",
                "timeOfDay": null,
                "readings": [
                    {
                        "pretty": "Psalm 146:4\u201310",
                        "readQS": "Psalm+146:4-10",
                        "audioQS": "Ps.146"
                    },
                    {
                        "pretty": "Canticle: Luke 1:46\u201355 (Magnificat)",
                        "readQS": "Luke+1:46-55",
                        "audioQS": "Luke.1"
                    },
                    {
                        "pretty": "Isaiah 35:1\u201310",
                        "readQS": "Isaiah+35:1-10",
                        "audioQS": "Isa.35"
                    },
                    {
                        "pretty": "James 5:7\u201310",
                        "readQS": "James+5:7-10",
                        "audioQS": "Jas.5"
                    },
                    {
                        "pretty": "Matthew 11:2\u201311",
                        "readQS": "Matthew+11:2-11",
                        "audioQS": "Matt.11"
                    }
                ],
                "readQS": "Psalm+146:4-10%3B+Canticle:+Luke+1:46-55+%28Magnificat%29%3B+Isaiah+35:1-10%3B+James+5:7-10%3B+Matthew+11:2-11",
                "audioQS": "Ps.146,Canticle,Isa.35,Jas.5,Matt.11",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 68:1\u201319",
                        "readQS": "Psalm+68:1-19",
                        "audioQS": "Ps.68"
                    },
                    {
                        "pretty": "Zephaniah 3:14\u201320",
                        "readQS": "Zephaniah+3:14-20",
                        "audioQS": "Zeph.3"
                    },
                    {
                        "pretty": "Philippians 4:4\u20137",
                        "readQS": "Philippians+4:4-7",
                        "audioQS": "Phil.4"
                    }
                ],
                "readQS": "Psalm+68:1-19%3B+Zephaniah+3:14-20%3B+Philippians+4:4-7",
                "audioQS": "Ps.68,Zeph.3,Phil.4",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 12",
                        "readQS": "Psalm+12",
                        "audioQS": "Ps.12"
                    },
                    {
                        "pretty": "Isaiah 5:8\u201330",
                        "readQS": "Isaiah+5:8-30",
                        "audioQS": "Isa.5"
                    },
                    {
                        "pretty": "Acts 13:13\u201341",
                        "readQS": "Acts+13:13-41",
                        "audioQS": "Acts.13"
                    }
                ],
                "readQS": "Psalm+12%3B+Isaiah+5:8-30%3B+Acts+13:13-41",
                "audioQS": "Ps.12,Isa.5,Acts.13",
                "note": null
            }
        ]
    },
    "2025/12/15": {
        "datePretty": "Monday, December 15, 2025",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 40",
                        "readQS": "Psalm+40",
                        "audioQS": "Ps.40"
                    },
                    {
                        "pretty": "Isaiah 38:1\u20138,38:21\u201322",
                        "readQS": "Isaiah+38:1-8,38:21-22",
                        "audioQS": "Isa.38"
                    },
                    {
                        "pretty": "Matthew 16:13\u2013end",
                        "readQS": "Matthew+16:13-",
                        "audioQS": "Matt.16"
                    }
                ],
                "readQS": "Psalm+40%3B+Isaiah+38:1-8,38:21-22%3B+Matthew+16:13-",
                "audioQS": "Ps.40,Isa.38,Matt.16",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 26",
                        "readQS": "Psalm+26",
                        "audioQS": "Ps.26"
                    },
                    {
                        "pretty": "Isaiah 49:14\u201325",
                        "readQS": "Isaiah+49:14-25",
                        "audioQS": "Isa.49"
                    },
                    {
                        "pretty": "1 Thessalonians 5:1\u201311",
                        "readQS": "1+Thessalonians+5:1-11",
                        "audioQS": "1Thess.5"
                    }
                ],
                "readQS": "Psalm+26%3B+Isaiah+49:14-25%3B+1+Thessalonians+5:1-11",
                "audioQS": "Ps.26,Isa.49,1Thess.5",
                "note": null
            }
        ]
    },
    "2025/12/16": {
        "datePretty": "Tuesday, December 16, 2025",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 70",
                        "readQS": "Psalm+70",
                        "audioQS": "Ps.70"
                    },
                    {
                        "pretty": "Isaiah 38:9\u201320",
                        "readQS": "Isaiah+38:9-20",
                        "audioQS": "Isa.38"
                    },
                    {
                        "pretty": "Matthew 17:1\u201313",
                        "readQS": "Matthew+17:1-13",
                        "audioQS": "Matt.17"
                    }
                ],
                "readQS": "Psalm+70%3B+Isaiah+38:9-20%3B+Matthew+17:1-13",
                "audioQS": "Ps.70,Isa.38,Matt.17",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 50",
                        "readQS": "Psalm+50",
                        "audioQS": "Ps.50"
                    },
                    {
                        "pretty": "Isaiah 50",
                        "readQS": "Isaiah+50",
                        "audioQS": "Isa.50"
                    },
                    {
                        "pretty": "1 Thessalonians 5:12\u2013end",
                        "readQS": "1+Thessalonians+5:12-",
                        "audioQS": "1Thess.5"
                    }
                ],
                "readQS": "Psalm+50%3B+Isaiah+50%3B+1+Thessalonians+5:12-",
                "audioQS": "Ps.50,Isa.50,1Thess.5",
                "note": null
            }
        ]
    },
    "2025/12/17": {
        "datePretty": "Wednesday, December 17, 2025",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 75",
                        "readQS": "Psalm+75",
                        "audioQS": "Ps.75"
                    },
                    {
                        "pretty": "Isaiah 39",
                        "readQS": "Isaiah+39",
                        "audioQS": "Isa.39"
                    },
                    {
                        "pretty": "Matthew 17:14\u201321",
                        "readQS": "Matthew+17:14-21",
                        "audioQS": "Matt.17"
                    }
                ],
                "readQS": "Psalm+75%3B+Isaiah+39%3B+Matthew+17:14-21",
                "audioQS": "Ps.75,Isa.39,Matt.17",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 82",
                        "readQS": "Psalm+82",
                        "audioQS": "Ps.82"
                    },
                    {
                        "pretty": "Isaiah 51:1\u20138",
                        "readQS": "Isaiah+51:1-8",
                        "audioQS": "Isa.51"
                    },
                    {
                        "pretty": "2 Thessalonians 1",
                        "readQS": "2+Thessalonians+1",
                        "audioQS": "2Thess.1"
                    }
                ],
                "readQS": "Psalm+82%3B+Isaiah+51:1-8%3B+2+Thessalonians+1",
                "audioQS": "Ps.82,Isa.51,2Thess.1",
                "note": null
            }
        ]
    },
    "2025/12/18": {
        "datePretty": "Thursday, December 18, 2025",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 76",
                        "readQS": "Psalm+76",
                        "audioQS": "Ps.76"
                    },
                    {
                        "pretty": "Zephaniah 1:1\u20132:3",
                        "readQS": "Zephaniah+1:1-2:3",
                        "audioQS": "Zeph.1"
                    },
                    {
                        "pretty": "Matthew 17:22\u2013end",
                        "readQS": "Matthew+17:22-",
                        "audioQS": "Matt.17"
                    }
                ],
                "readQS": "Psalm+76%3B+Zephaniah+1:1-2:3%3B+Matthew+17:22-",
                "audioQS": "Ps.76,Zeph.1,Matt.17",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 44",
                        "readQS": "Psalm+44",
                        "audioQS": "Ps.44"
                    },
                    {
                        "pretty": "Isaiah 51:9\u201316",
                        "readQS": "Isaiah+51:9-16",
                        "audioQS": "Isa.51"
                    },
                    {
                        "pretty": "2 Thessalonians 2",
                        "readQS": "2+Thessalonians+2",
                        "audioQS": "2Thess.2"
                    }
                ],
                "readQS": "Psalm+44%3B+Isaiah+51:9-16%3B+2+Thessalonians+2",
                "audioQS": "Ps.44,Isa.51,2Thess.2",
                "note": null
            }
        ]
    },
    "2025/12/19": {
        "datePretty": "Friday, December 19, 2025",
        "calendar": "Week of 3rd Sunday of Advent",
        "readingSets": [
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Morning Prayers",
                "timeOfDay": "morning",
                "readings": [
                    {
                        "pretty": "Psalm 146",
                        "readQS": "Psalm+146",
                        "audioQS": "Ps.146"
                    },
                    {
                        "pretty": "Zephaniah 3:1\u201313",
                        "readQS": "Zephaniah+3:1-13",
                        "audioQS": "Zeph.3"
                    },
                    {
                        "pretty": "Matthew 18:1\u201320",
                        "readQS": "Matthew+18:1-20",
                        "audioQS": "Matt.18"
                    }
                ],
                "readQS": "Psalm+146%3B+Zephaniah+3:1-13%3B+Matthew+18:1-20",
                "audioQS": "Ps.146,Zeph.3,Matt.18",
                "note": null
            },
            {
                "readingSetTab": "prayers",
                "readingSetTabName": "Prayers",
                "readingSetSubtitle": "Evening Prayers",
                "timeOfDay": "evening",
                "readings": [
                    {
                        "pretty": "Psalm 57",
                        "readQS": "Psalm+57",
                        "audioQS": "Ps.57"
                    },
                    {
                        "pretty": "Isaiah 51:17\u2013end",
                        "readQS": "Isaiah+51:17-",
                        "audioQS": "Isa.51"
                    },
                    {
                        "pretty": "2 Thessalonians 3",
                        "readQS": "2+Thessalonians+3",
                        "audioQS": "2Thess.3"
                    }
                ],
                "readQS": "Psalm+57%3B+Isaiah+51:17-%3B+2+Thessalonians+3",
                "audioQS": "Ps.57,Isa.51,2Thess.3",
                "note": null
            }
        ]
    }
};export default Data;